import { StatusColors } from '@models/enums';
import { ProcessEnvMap } from '@models/interfaces';

export const color: ProcessEnvMap = {
  'Pre-Scoping': StatusColors.Yellow,
  'Pending Scoping': StatusColors.Primary,
  Scoped: StatusColors.Primary,
  'Work in Progress': StatusColors.Primary,
  Complete: StatusColors.Gray,
};
