import React, { ReactElement } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { readOnlyInputNumberRender } from '@core/utils/methods';
import { RADColumnButtons } from '@shared/components';

export const getRADTableColumns = (
  editModal?: (title: string) => void,
  isEditing?: boolean,
): ColumnsType => [
  {
    title: 'RAD',
    key: 'RAD',
    dataIndex: 'RADField',
    render: (rad: string): ReactElement => (
      <div className="prov-rad-table_white-column">{rad}</div>
    ),
  },
  {
    title: (
      <RADColumnButtons
        editFunc={editModal}
        title="leased"
        isEditing={isEditing}
      />
    ),
    key: 'leased',
    dataIndex: 'leased',
    render: readOnlyInputNumberRender,
  },
  {
    title: (
      <RADColumnButtons
        editFunc={editModal}
        title="structural"
        isEditing={isEditing}
      />
    ),
    key: 'structural',
    dataIndex: 'structural',
    render: readOnlyInputNumberRender,
  },
  {
    title: (
      <RADColumnButtons
        editFunc={editModal}
        title="RFDS"
        isEditing={isEditing}
      />
    ),
    key: 'RFDS',
    dataIndex: 'RFDS',
    render: readOnlyInputNumberRender,
  },
  {
    title: (
      <RADColumnButtons
        editFunc={editModal}
        title="scoping"
        isEditing={isEditing}
      />
    ),
    key: 'scoping',
    dataIndex: 'scoping',
    render: readOnlyInputNumberRender,
  },
];
