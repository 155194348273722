import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SelectValue } from 'antd/lib/select';
import { PermittingHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { arrsToSelectableObject } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { FormInstanceExtender } from '@models/types';
import { Autocomplete, Input } from '@shared/modules';
import { CountySelectors } from '@store/selectors';

export const JurisdictionModalForm: FC<FormInstanceExtender> = ({
  form,
}: PropsWithChildren<FormInstanceExtender>) => {
  const [state, setState] = useState<string | null>(null);

  useEffect(() => (): void => setState(null), []);

  useDidUpdateEffect(() => {
    form.validateFields(['jurisdictionName']);
  }, [state]);

  const statesWithCounties = useSelector(CountySelectors.getStatesWithCounties);

  return (
    <>
      <Input
        id="jurisdictionName"
        label="Jurisdiction Name"
        formItemProps={{
          rules: [
            ...(state
              ? [
                  serverValidator(
                    {
                      method: 'getJurisdictionNameValidation',
                      errorMessage: 'Jurisdiction Name must be unique',
                    },
                    PermittingHttpService,
                    undefined,
                    state,
                  ),
                ]
              : []),
            { required: true, message: 'Jurisdiction Name is required!' },
          ],
        }}
      />
      <Autocomplete
        id="state"
        label="State"
        options={arrsToSelectableObject(statesWithCounties, 'id', 'name')}
        elementProps={{
          onChange: (v: SelectValue): void => setState(v as string),
        }}
        formItemProps={{
          rules: [{ required: true, message: 'State is required!' }],
        }}
      />
    </>
  );
};
