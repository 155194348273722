import { TabProps } from './models';
import { Route } from './route';

export class Tab<PropsType = any> extends Route {
  #isLink: boolean;
  #componentProps?: PropsType;

  constructor({
    isLink = false,
    componentProps,
    ...props
  }: TabProps<PropsType>) {
    super(props);

    this.#componentProps = componentProps;

    this.#isLink = isLink;
  }

  // GETTERS & SETTERS
  get isLink(): boolean {
    return this.#isLink;
  }

  get componentProps(): any {
    return this.#componentProps;
  }

  // METHODS
  setLink(isLink: boolean = true): this {
    this.#isLink = isLink;

    return this;
  }

  setComponentProps(componentProps: any): this {
    this.#componentProps = componentProps;

    return this;
  }
}
