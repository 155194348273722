import React, { FC, MouseEvent, PropsWithChildren } from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import { IMap, ISelectorOption } from '@symfa-inc/providence-types';
import { Button, Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

import './styles.scss';

type SelectWithButtonProps = Pick<SelectProps<string>, 'onChange' | 'value'> & {
  onClickButton: (data: ISelectorOption<string>) => void;
  options: ISelectorOption<string>[];
};

type SelectOptionProps = Pick<SelectWithButtonProps, 'onClickButton'> &
  SelectWithButtonProps['options'][number];

const SelectOptionView: FC<SelectOptionProps> = ({
  viewValue,
  value,
  onClickButton,
}: PropsWithChildren<SelectOptionProps>) => (
  <div className="select-option">
    <span>{viewValue}</span>
    <Button
      className="controls__btn"
      onClick={(event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        onClickButton({ value, viewValue });
      }}
    >
      <PlusCircleFilled className="plus-circle-icon" />
    </Button>
  </div>
);

export const SelectWithButton: FC<SelectWithButtonProps> = ({
  onClickButton,
  options,
  ...props
}: PropsWithChildren<SelectWithButtonProps>) => (
  <Select
    className="prov-select-with-button"
    dropdownClassName="prov-select-with-button__select-options-container"
    showArrow
    showSearch
    optionLabelProp="label"
    filterOption={(inputValue: string, option?: IMap<string>): boolean =>
      !!option &&
      option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    }
    {...props}
  >
    {options.map(({ viewValue, value }: (typeof options)[number]) => (
      <Select.Option key={value} value={value} label={viewValue}>
        <SelectOptionView
          value={value}
          viewValue={viewValue}
          onClickButton={onClickButton}
        />
      </Select.Option>
    ))}
  </Select>
);
