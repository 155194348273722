import nextId from 'react-id-generator';
import cloneDeep from 'lodash.clonedeep';
import { browseReducer } from '../../core/utils/methods/browse-sorter-reducer';
import { createReducer, on } from '../../core/utils/store';
import {
  AddPermitTypeData,
  JurisdictionBrowse,
  JurisdictionDetails,
  JurisdictionDetailsContacts,
  JurisdictionDetailsPermitting,
  JurisdictionDetailsPermittingType,
  JurisdictionDetailsZoning,
  JurisdictionModalValues,
  JurisdictionOption,
  JurisdictionPermittingData,
  JurisdictionZoningData,
  OptionProps,
  PaginatedResponse,
  PayloadAndState,
  PermittingBrowse,
  PermittingDetails,
  PermittingJurisdictionAuditBody,
  PermittingWorkflowBody,
} from '../../models/interfaces';
import {
  JurisdictionPermittingSectionType,
  RemovePermitTypeData,
} from '../../models/types';
import { PermittingActions } from '../actions/permitting.actions';

export interface PermittingState {
  browseData: PaginatedResponse<PermittingBrowse>;
  jurisdictionList: JurisdictionOption[];
  detailsData: PermittingDetails;
  jurisdictionBrowseData: PaginatedResponse<JurisdictionBrowse>;
  jurisdictionDetailsData: JurisdictionDetails;
  permittingUsersOptions: OptionProps[];
  isFetching: boolean;
  errors: boolean;
  originalPermitTypeTableData: {
    [permittingId: string]: JurisdictionDetailsPermittingType[];
  };
  currentPermitTypeTableData: {
    [permittingId: string]: JurisdictionDetailsPermittingType[];
  };
}

const initialState: PermittingState = {
  browseData: { items: [], total: 0 },
  jurisdictionList: [],
  detailsData: {} as PermittingDetails,
  jurisdictionBrowseData: { items: [], total: 0 },
  jurisdictionDetailsData: {} as JurisdictionDetails,
  permittingUsersOptions: [],
  isFetching: false,
  errors: false,
  originalPermitTypeTableData: {},
  currentPermitTypeTableData: {},
};

const generateId = () => nextId('PermitType');

const getProcessedPermitTypes = (
  permitTypes?: JurisdictionDetailsPermittingType[] | undefined,
) =>
  (permitTypes || []).map((permitType: JurisdictionDetailsPermittingType) => ({
    ...permitType,
    generatedId: generateId(),
  }));

export const reducer = createReducer(
  initialState,
  // GET PERMITTING BROWSE DATA
  on(PermittingActions.getPermittingBrowseData, browseReducer('browseData')),
  // get jurisdiction list
  on(
    PermittingActions.getJurisdictionList,
    ({
      payload: jurisdictionList,
    }: PayloadAndState<JurisdictionOption[], PermittingState>) => ({
      jurisdictionList,
    }),
  ),
  // get permitting users
  on(
    PermittingActions.getPermittingUsersOptions,
    ({
      payload: permittingUsersOptions,
    }: PayloadAndState<OptionProps[], PermittingState>) => ({
      permittingUsersOptions,
    }),
  ),
  // get details data
  on(
    PermittingActions.getPermittingDetailsData,
    ({
      payload: detailsData,
    }: PayloadAndState<PermittingDetails, PermittingState>) => {
      const processedPermitTypes = getProcessedPermitTypes(
        detailsData?.site?.jurisdiction?.permitting?.permittingTypes,
      );

      const permittingId =
        detailsData?.site?.jurisdiction?.permitting?.id || '';

      return {
        detailsData,
        originalPermitTypeTableData: { [permittingId]: processedPermitTypes },
        currentPermitTypeTableData: { [permittingId]: processedPermitTypes },
      };
    },
  ),
  // reset permitting types
  on(
    PermittingActions.resetPermittingTypesAction,
    ({
      payload: permittingIds,
      state: { currentPermitTypeTableData, originalPermitTypeTableData },
    }: PayloadAndState<string[], PermittingState>) => {
      const result = cloneDeep(currentPermitTypeTableData);

      permittingIds.forEach((id: string) => {
        result[id] = cloneDeep(originalPermitTypeTableData?.[id] || []);
      });

      return {
        currentPermitTypeTableData: result,
      };
    },
  ),
  // set permitting types
  on(
    PermittingActions.setPermittingTypesAction,
    ({
      payload: permittingIds,
      state: { currentPermitTypeTableData, originalPermitTypeTableData },
    }: PayloadAndState<string[], PermittingState>) => {
      const result = cloneDeep(originalPermitTypeTableData);

      permittingIds.forEach((id: string) => {
        result[id] = getProcessedPermitTypes(currentPermitTypeTableData[id]);
      });

      return {
        originalPermitTypeTableData: result,
        currentPermitTypeTableData: result,
      };
    },
  ),
  // add permitting type
  on(
    PermittingActions.permittingAddPermittingType,
    ({
      payload: { data, permittingId },
      state: { currentPermitTypeTableData },
    }: PayloadAndState<AddPermitTypeData, PermittingState>) => ({
      currentPermitTypeTableData: {
        ...currentPermitTypeTableData,
        [permittingId]: [
          ...(currentPermitTypeTableData[permittingId] || []),
          { ...data, generatedId: generateId() },
        ],
      },
    }),
  ),
  // update permitting type
  on(
    PermittingActions.permittingUpdatePermittingType,
    ({
      payload: { data, permittingId },
      state: { currentPermitTypeTableData },
    }: PayloadAndState<AddPermitTypeData, PermittingState>) => ({
      currentPermitTypeTableData: {
        ...currentPermitTypeTableData,
        [permittingId]: [
          ...(currentPermitTypeTableData[permittingId] || []).filter(
            (permitType: JurisdictionDetailsPermittingType) =>
              permitType.generatedId !== data.generatedId,
          ),
          data,
        ],
      },
    }),
  ),
  // remove permitting type
  on(
    PermittingActions.permittingRemovePermittingType,
    ({
      payload: { permittingTypeId, permittingId },
      state: { currentPermitTypeTableData },
    }: PayloadAndState<RemovePermitTypeData, PermittingState>) => ({
      currentPermitTypeTableData: {
        ...currentPermitTypeTableData,
        [permittingId]: (currentPermitTypeTableData[permittingId] || []).filter(
          (permitType: JurisdictionDetailsPermittingType) =>
            permitType.generatedId !== permittingTypeId,
        ),
      },
    }),
  ),
  // update jurisdiction audit data
  on(
    PermittingActions.permittingUpdateJurisdictionAuditData,
    ({
      payload: {
        summarySection: { id: jurisdictionId, ...jurisdiction },
        zoningSection,
        permittingSection,
      },
      state: { detailsData },
    }: PayloadAndState<PermittingJurisdictionAuditBody, PermittingState>) => {
      const { id: zoningId, ...zoning } = zoningSection ?? {};
      const { id: permittingId, ...permitting } = permittingSection ?? {};

      return {
        detailsData: {
          ...detailsData,
          site: {
            ...detailsData.site,
            jurisdiction: {
              ...detailsData.site.jurisdiction,
              ...jurisdiction,
              zoning: {
                ...detailsData.site.jurisdiction!.zoning,
                ...zoning,
              },
              permitting: {
                ...detailsData.site.jurisdiction!.permitting,
                ...permitting,
              },
            },
          },
        } as PermittingDetails,
      };
    },
  ),
  // update permittting workflow permittingFields
  on(
    PermittingActions.permittingUpdateWorkflowData,
    ({
      payload: { projectPermittingNote, permittingAgent },
      state: { detailsData },
    }: PayloadAndState<PermittingWorkflowBody, PermittingState>) => ({
      detailsData: {
        ...detailsData,
        projectPermittingNote:
          projectPermittingNote !== undefined
            ? projectPermittingNote
            : detailsData.projectPermittingNote,
        permittingAgent:
          permittingAgent !== undefined
            ? permittingAgent
            : detailsData.permittingAgent,
      },
    }),
  ),
  // JURISDICTION
  // get jurisdiction browse data
  on(
    PermittingActions.getJurisdictionBrowseData,
    ({
      payload: jurisdictionBrowseData,
    }: PayloadAndState<
      PaginatedResponse<JurisdictionBrowse>,
      PermittingState
    >) => ({
      jurisdictionBrowseData,
    }),
  ),
  // add jurisdiction
  on(
    PermittingActions.addJurisdiction,
    ({
      payload: jurisdictionBrowseData,
    }: PayloadAndState<
      PaginatedResponse<JurisdictionBrowse>,
      PermittingState
    >) => ({
      jurisdictionBrowseData,
    }),
  ),
  // get jurisdiction details data
  on(
    PermittingActions.getJurisdictionDetailsData,
    ({
      payload: jurisdictionDetailsData,
    }: PayloadAndState<JurisdictionDetails, PermittingState>) => {
      const permitTypeTablesData = jurisdictionDetailsData.permitting.reduce(
        (acc, { id, permittingTypes }) => ({
          ...acc,
          [id]: getProcessedPermitTypes(permittingTypes),
        }),
        {},
      );

      return {
        jurisdictionDetailsData,
        originalPermitTypeTableData: permitTypeTablesData,
        currentPermitTypeTableData: permitTypeTablesData,
      };
    },
  ),
  // ZONING PANEL
  // add jurisdiction zoning panel
  on(
    PermittingActions.addJurisdictionZoningPanel,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionDetailsZoning, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        zoning: [...jurisdictionDetailsData.zoning, payload],
      },
    }),
  ),
  // update jurisdiction zoning panel
  on(
    PermittingActions.updateJurisdictionZoningPanel,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionModalValues, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        zoning: jurisdictionDetailsData.zoning.map(
          (zoning: JurisdictionDetailsZoning) =>
            zoning.id !== payload.id
              ? zoning
              : {
                  ...zoning,
                  ...payload,
                },
        ),
      },
    }),
  ),
  // remove jurisdiction zoning panel
  on(
    PermittingActions.removeJurisdictionZoningPanel,
    ({
      payload: id,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<string, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        zoning: [
          ...jurisdictionDetailsData.zoning.filter(
            (zoning: JurisdictionDetailsZoning) => zoning.id !== id,
          ),
        ],
      },
    }),
  ),
  // JURISDICTION ZONING
  // update jurisdiction zoning
  on(
    PermittingActions.updateJurisdictionZoning,
    ({
      payload: { summarySection, zoningSection },
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionZoningData, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        ...summarySection,
        zoning: jurisdictionDetailsData.zoning.map(
          (zoning: JurisdictionDetailsZoning) => {
            const currentUpdatedZoning = zoningSection.find(
              (updatedZoning: JurisdictionDetailsZoning) =>
                updatedZoning.id === zoning.id,
            );

            if (currentUpdatedZoning) {
              const { id, projectType, projectSubType, ...updatedZoningData } =
                currentUpdatedZoning;

              return {
                ...zoning,
                ...updatedZoningData,
              };
            }

            return zoning;
          },
        ),
      },
    }),
  ),
  // PERMITTING PANEL
  // add jurisdiction permitting panel
  on(
    PermittingActions.addJurisdictionPermittingPanel,
    ({
      payload,
      state: {
        jurisdictionDetailsData,
        originalPermitTypeTableData,
        currentPermitTypeTableData,
      },
    }: PayloadAndState<JurisdictionDetailsPermitting, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        permitting: [...jurisdictionDetailsData.permitting, payload],
      },
      originalPermitTypeTableData: {
        ...originalPermitTypeTableData,
        [payload.id]: [],
      },
      currentPermitTypeTableData: {
        ...currentPermitTypeTableData,
        [payload.id]: [],
      },
    }),
  ),
  // update jurisdiction permitting panel
  on(
    PermittingActions.updateJurisdictionPermittingPanel,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionModalValues, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        permitting: jurisdictionDetailsData.permitting.map(
          (permitting: JurisdictionDetailsPermitting) =>
            permitting.id !== payload.id
              ? permitting
              : {
                  ...permitting,
                  ...payload,
                },
        ),
      },
    }),
  ),
  // remove jurisdiction permitting panel
  on(
    PermittingActions.removeJurisdictionPermittingPanel,
    ({
      payload: id,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<string, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        permitting: [
          ...jurisdictionDetailsData.permitting.filter(
            (permitting: JurisdictionDetailsPermitting) => permitting.id !== id,
          ),
        ],
      },
    }),
  ),
  // JURISDICTION PERMITTING
  // update jurisdiction PERMITTING
  on(
    PermittingActions.updateJurisdictionPermitting,
    ({
      payload: { summarySection, permittingSection },
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionPermittingData, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        ...summarySection,
        permitting: jurisdictionDetailsData.permitting.map(
          (permitting: JurisdictionDetailsPermitting) => {
            const currentUpdatedPermitting = permittingSection.find(
              (updatedPermitting: JurisdictionPermittingSectionType) =>
                updatedPermitting.id === permitting.id,
            );

            if (currentUpdatedPermitting) {
              const {
                id,
                projectType,
                projectSubType,
                ...updatedPermittingData
              } = currentUpdatedPermitting;

              return {
                ...permitting,
                ...updatedPermittingData,
              };
            }

            return permitting;
          },
        ),
      },
    }),
  ),
  // JURISDICTION - CONTACTS
  // add jurisdiction contact
  on(
    PermittingActions.addJurisdictionContact,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionDetailsContacts, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        contacts: [...jurisdictionDetailsData.contacts, payload],
      },
    }),
  ),
  // update jurisdiction contact
  on(
    PermittingActions.updateJurisdictionContact,
    ({
      payload,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<JurisdictionDetailsContacts, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        contacts: jurisdictionDetailsData.contacts.map(
          (contact: JurisdictionDetailsContacts) =>
            contact.id !== payload.id ? contact : { ...contact, ...payload },
        ),
      },
    }),
  ),
  // remove jurisdiction contact
  on(
    PermittingActions.removeJurisdictionContact,
    ({
      payload: id,
      state: { jurisdictionDetailsData },
    }: PayloadAndState<string, PermittingState>) => ({
      jurisdictionDetailsData: {
        ...jurisdictionDetailsData,
        contacts: [
          ...jurisdictionDetailsData.contacts.filter(
            (contact: JurisdictionDetailsContacts) => contact.id !== id,
          ),
        ],
      },
    }),
  ),
);
