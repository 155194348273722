import { arraySort, arrsToSelectableObject } from '@core/utils/methods';
import { createReducer, on } from '@core/utils/store';
import {
  AdminDistributionListResponse,
  AdminVendorDistributionListData,
  AdminVendorsData,
  OptionProps,
  PayloadAndState,
} from '@models/interfaces';
import { VendorsActions } from '@store/actions';

export interface VendorsState {
  vendors: AdminVendorsData[];
  vendorsLists: AdminVendorDistributionListData[];
  vendorsListsOptions: OptionProps[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: VendorsState = {
  vendors: [],
  vendorsLists: [],
  vendorsListsOptions: [],
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  // GET ALL VENDORS
  on(
    VendorsActions.getVendorsAction,
    ({
      payload: vendors,
    }: PayloadAndState<AdminVendorsData[], VendorsState>) => ({ vendors }),
  ),
  // ADD VENDOR
  on(
    VendorsActions.addVendorAction,
    ({
      payload,
      state: { vendors },
    }: PayloadAndState<AdminVendorsData, VendorsState>) => ({
      vendors: arraySort([...vendors, payload], 'ASC', 'name'),
    }),
  ),
  // EDIT VENDOR
  on(
    VendorsActions.updateVendorAction,
    ({
      payload,
      state: { vendors },
    }: PayloadAndState<AdminVendorsData, VendorsState>) => ({
      vendors: arraySort(
        [
          ...vendors.filter(
            (vendor: AdminVendorsData) => vendor.id !== payload.id,
          ),
          payload,
        ],
        'ASC',
        'name',
      ),
    }),
  ),
  // DELETE VENDOR
  on(
    VendorsActions.deleteVendorAction,
    ({
      payload: id,
      state: { vendors, vendorsLists },
    }: PayloadAndState<string, VendorsState>) => ({
      vendors: vendors.filter((vendor: AdminVendorsData) => vendor.id !== id),
      vendorsLists: vendorsLists.map(
        ({ vendors: vendorsListVendors, ...rest }) => ({
          ...rest,
          vendors: vendorsListVendors.filter(
            (vendor: AdminVendorsData) => vendor.id !== id,
          ),
        }),
      ),
    }),
  ),
  // GET ALL VENDORS LISTS
  on(
    VendorsActions.getVendorListsAction,
    ({
      payload,
    }: PayloadAndState<AdminVendorDistributionListData[], VendorsState>) => ({
      vendorsLists: payload,
      vendorsListsOptions: arrsToSelectableObject(payload, 'id', 'name'),
    }),
  ),
  // ADD NEW VENDOR LIST
  on(
    VendorsActions.addVendorListAction,
    ({
      payload,
      state,
    }: PayloadAndState<AdminDistributionListResponse, VendorsState>) => {
      const vendorsLists = arraySort(
        [...state.vendorsLists, { ...payload, vendors: [] }],
        'ASC',
        'name',
      );

      return {
        vendorsLists,
        vendorsListsOptions: arrsToSelectableObject(vendorsLists, 'id', 'name'),
      };
    },
  ),
  // EDIT VENDOR LIST
  on(
    VendorsActions.updateVendorListAction,
    ({
      payload,
      state,
    }: PayloadAndState<AdminDistributionListResponse, VendorsState>) => {
      const index = state.vendorsLists.findIndex(
        (vendorsList: AdminVendorDistributionListData) =>
          vendorsList.id === payload.id,
      );

      const vendorsLists = [...state.vendorsLists];

      vendorsLists.splice(index, 1, {
        ...vendorsLists[index],
        name: payload.name,
      });

      return {
        vendorsLists,
        vendorsListsOptions: arrsToSelectableObject(vendorsLists, 'id', 'name'),
      };
    },
  ),
  // DELETE VENDOR LIST
  on(
    VendorsActions.deleteVendorListAction,
    ({ payload: id, state }: PayloadAndState<string, VendorsState>) => {
      const vendorsLists = state.vendorsLists.filter(
        (vendor: AdminVendorDistributionListData) => vendor.id !== id,
      );

      return {
        vendorsLists,
        vendorsListsOptions: arrsToSelectableObject(vendorsLists, 'id', 'name'),
      };
    },
  ),
  // ASSIGN MANAGEMENT
  on(
    VendorsActions.assignManagementAction,
    ({
      payload,
      state: { vendorsLists },
    }: PayloadAndState<AdminVendorDistributionListData, VendorsState>) => ({
      vendorsLists: arraySort(
        [
          ...vendorsLists.filter(
            (vendorsList: AdminVendorDistributionListData) =>
              vendorsList.id !== payload.id,
          ),
          payload,
        ],
        'ASC',
        'name',
      ),
    }),
  ),
);
