import {
  EquipmentBrowse,
  EquipmentsBrowse,
  EquipmentsBrowseResponse,
  EquipmentsBrowseTableData,
} from '@models/interfaces';

export const convertToTableData = ({
  items,
  total,
}: EquipmentsBrowseResponse): EquipmentsBrowseTableData => ({
  total,
  items: items.map(
    (item: EquipmentsBrowse): EquipmentBrowse => ({
      id: item.id,
      equipmentType: item.equipmentType.name,
      manufacturer: item.manufacturer.name,
      modelNumber: item.modelNumber,
      dimensions: `${item.length}x${item.width}x${item.depth}`,
      weight: item.weight.toString(),
      description: item.description,
    }),
  ),
});
