import { Step } from '@models/interfaces';
import { Permissions, TaskInformation } from '../../steps';

export const getSteps = (id?: string): Step[] => [
  {
    name: 'Info',
    component: TaskInformation,
    props: { id },
  },
  {
    name: 'Permissions',
    component: Permissions,
  },
];
