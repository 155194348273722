import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { PaceTasksBrowseHttpService } from '@core/services/http';

export class ProjectPaceTasksBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      PaceTasksBrowseHttpService,
    ).getPaceTasksBrowseFilterOptions({});
  }
}
