import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { HttpService } from '@core/services';
import { EquipmentHttpService } from '@core/services/http';
import { arrsToSelectableObject, toggleModal } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import {
  EquipmentManufacturerData,
  EquipmentTypeData,
  TabComponentProps,
} from '@models/interfaces';
import { DeleteModal, VirtualTable } from '@shared/components';
import { Autocomplete, PrimaryButton } from '@shared/modules';
import { EquipmentSelectors } from '@store/selectors';
import { EquipmentManufacturerModal } from './modals';
import {
  EQUIPMENT_MANUFACTURER_DEFAULT_VALUE,
  equipmentManufacturerColumns,
} from './models';

import './styles.scss';

export const EquipmentManufacturer: FC<TabComponentProps> = ({
  isActive,
}: PropsWithChildren<TabComponentProps>) => {
  const equipmentService = useMemo(
    () => HttpService.getHttpRequests(EquipmentHttpService),
    [],
  );

  const { equipmentTypes, equipmentManufacturers } = useSelector(
    EquipmentSelectors.getEquipmentTypesWithManufacturers,
  );

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [selectorValue, setSelectorValue] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<EquipmentManufacturerData>(
    EQUIPMENT_MANUFACTURER_DEFAULT_VALUE,
  );

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem(EQUIPMENT_MANUFACTURER_DEFAULT_VALUE);
    }
  };

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await equipmentService.getEquipmentManufacturers(selectorValue);
    };

    fetchData();
  }, [selectorValue]);

  useEffect(() => {
    if (!isActive) {
      setSelectorValue('');
    }
  }, [isActive]);

  const remove = async (): Promise<void> => {
    await equipmentService.removeEquipmentManufacturer(
      selectedItem.id,
      selectorValue,
    );

    setSelectedItem(EQUIPMENT_MANUFACTURER_DEFAULT_VALUE);
    setDeleteModalVisible(false);
  };

  const selectEquipmentManufacturer = (
    equipmentManufacturer: EquipmentManufacturerData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(equipmentManufacturer);
    setter(!prevState);
  };

  return (
    <div className="prov-equipment-manufacturer">
      <DeleteModal
        onOk={remove}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
      />
      <EquipmentManufacturerModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        value={selectedItem}
        selectorValue={selectorValue}
      />
      <Row className="prov-equipment-manufacturer__table-handler">
        <Col span="5">
          <Form>
            <Autocomplete
              label="Select Equipment Type"
              id="equipment-type"
              options={arrsToSelectableObject<EquipmentTypeData>(
                equipmentTypes,
                'id',
                'name',
              )}
              elementProps={{
                allowClear: true,
                value: selectorValue,
                onChange: (value: SelectValue): void =>
                  setSelectorValue(value as string),
              }}
            />
          </Form>
        </Col>
      </Row>
      <VirtualTable
        dataSource={equipmentManufacturers}
        columns={equipmentManufacturerColumns(
          (equipmentManufacturer: EquipmentManufacturerData): void =>
            selectEquipmentManufacturer(
              equipmentManufacturer,
              () => toggleAddAndEditModal(ModalMainTypes.Edit),
              isAddEditModalVisible,
            ),
          (equipmentManufacturer: EquipmentManufacturerData): void =>
            selectEquipmentManufacturer(
              equipmentManufacturer,
              setDeleteModalVisible,
              deleteModalVisible,
            ),
          !!selectorValue,
        )}
        maxViewRow={9}
      />
      <PrimaryButton
        icon="+"
        title="Add"
        className="prov-equipment-manufacturer__add-button"
        disabled={!selectorValue}
        onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
      />
    </div>
  );
};
