import React, { FC, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input } from 'antd';
import { Store } from 'antd/es/form/interface';
import { HttpService, LocalStorageService } from '@core/services';
import { AuthHttpService } from '@core/services/http';
import { emailValidator } from '@core/utils/validators';
import { AuthLayout } from '@layout/components';
import { StorageItem } from '@models/enums';
import { UserCredentials } from '@models/interfaces';
import { PrimaryButton } from '@shared/modules';
import { PASSWORD_VALIDATION } from '../../models/constants';

const { Password } = Input;

export const Login: FC = () => {
  const history = useHistory();

  const onLogin = useCallback(async (values: Store): Promise<void> => {
    try {
      const { accessToken, refreshToken } = await HttpService.getHttpRequests(
        AuthHttpService,
      ).logIn(values as UserCredentials);

      if (accessToken && refreshToken) {
        new LocalStorageService()
          .setItem(StorageItem.ACCESS_TOKEN, accessToken)
          .setItem(StorageItem.REFRESH_TOKEN, refreshToken);

        history.push('/');
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <AuthLayout>
      <Form onFinish={onLogin}>
        <Form.Item name="email" rules={emailValidator(true)}>
          <Input placeholder="Email" className="wrap__input" />
        </Form.Item>
        <Form.Item name="password" rules={PASSWORD_VALIDATION}>
          <Password placeholder="Password" className="wrap__password" />
        </Form.Item>
        <Form.Item>
          <PrimaryButton
            className="wrap__button"
            title="Log in"
            htmlType="submit"
          />
          <Link to="forgot-password" className="wrap__link">
            Forgot password?
          </Link>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};
