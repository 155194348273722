import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-types';
import { SelectValue } from 'antd/lib/select';
import { useFindCountyAndJurisdictions } from '@core/utils/hooks';
import { arrsToSelectableObject } from '@core/utils/methods';
import {
  AdminDropdownValuesData,
  ConfigureFilterProps,
  OptionProps,
  ProjectBrowseFilter,
  RangePickerValues,
  StateWithCounties,
} from '@models/interfaces';
import { InputNumberFormatterType as UndefinableStringNumber } from '@models/types';
import { Autocomplete, InputNumber, RangePicker } from '@shared/modules';
import { SharedSelectors } from '@store/selectors';

export const Filters: FC<ConfigureFilterProps<ProjectBrowseFilter>> = ({
  value,
  onChanges,
  formProps,
}: PropsWithChildren<ConfigureFilterProps<ProjectBrowseFilter>>) => {
  const { dropdownValues, states } = useSelector(
    SharedSelectors.getDropdownValuesAndStates,
  );

  const [stateId, setStateId] = useState<Undefinable<string>>();
  const [countyOptions, setCountyOptions] = useState<OptionProps[]>([]);
  const [jurisdictionOptions, setJurisdictionOptions] = useState<OptionProps[]>(
    [],
  );

  useFindCountyAndJurisdictions({
    stateId,
    setCountyOptions,
    setJurisdictionOptions,
    effectDepArray: [stateId, states],
  });

  useEffect(() => {
    if (value.stateId !== stateId) {
      setStateId(value.stateId);
    }
  }, [value]);

  return (
    <div className="prov-project-browse-filters">
      <InputNumber
        id="projectBundleID"
        key="projectBundleID"
        label="Project Bundle ID"
        className="prov-input-number"
        elementProps={{
          value: value.projectBundleID,
          onChange: (e: UndefinableStringNumber): void =>
            onChanges({ projectBundleID: e as number }),
        }}
      />
      <Autocomplete
        id="projectTypeId"
        key="projectTypeId"
        label="Project Type"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProjectType,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.projectTypeId,
          onChange: (e: SelectValue): void =>
            onChanges({ projectTypeId: e as string }),
        }}
      />
      <Autocomplete
        id="projectWorkTypeId"
        key="projectWorkTypeId"
        label="Project Work Type"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProjectWorkType,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.projectWorkTypeId,
          onChange: (e: SelectValue): void =>
            onChanges({ projectWorkTypeId: e as string }),
        }}
      />
      <Autocomplete
        id="projectStatusId"
        key="projectStatusId"
        label="Project Status"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProjectStatus,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.projectStatusId,
          onChange: (e: SelectValue): void =>
            onChanges({ projectStatusId: e as string }),
        }}
      />
      <Autocomplete
        id="progressStatusId"
        key="progressStatusId"
        label="Progress Status"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProgressStatus,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.progressStatusId,
          onChange: (e: SelectValue): void =>
            onChanges({ progressStatusId: e as string }),
        }}
      />
      <Autocomplete
        id="civilVendorId"
        key="civilVendorId"
        label="Civil Vendor"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.CivilVendor,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.civilVendorId,
          onChange: (e: SelectValue): void =>
            onChanges({ civilVendorId: e as string }),
        }}
      />
      <Autocomplete
        id="siteAcqVendorId"
        key="siteAcqVendorId"
        label="Site Acq Vendor"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.SiteACQVendor,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.siteAcqVendorId,
          onChange: (e: SelectValue): void =>
            onChanges({ siteAcqVendorId: e as string }),
        }}
      />
      <Autocomplete
        id="projectSubtypeId"
        key="projectSubtypeId"
        label="Project Subtype"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProjectSubtype,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.projectSubtypeId,
          onChange: (e: SelectValue): void =>
            onChanges({ projectSubtypeId: e as string }),
        }}
      />
      <RangePicker
        id="RE020ForecastedDate"
        key="RE020ForecastedDate"
        label="RE020 Site Acq Complete Forecasted date"
        formItemProps={{ className: 'aligned-form-item--evened' }}
        onChange={(date?: RangePickerValues): void =>
          onChanges({ RE020ForecastedDate: date })
        }
      />
      <RangePicker
        id="RE020ActualDate"
        key="RE020ActualDate"
        label="RE020 Site Acq Complete Actual date"
        formItemProps={{ className: 'aligned-form-item--evened' }}
        onChange={(date?: RangePickerValues): void =>
          onChanges({ RE020ActualDate: date })
        }
      />
      <RangePicker
        id="CI032ForecastedDate"
        key="CI032ForecastedDate"
        label="CI032 On Air Forecasted date"
        formItemProps={{ className: 'aligned-form-item--evened' }}
        onChange={(date?: RangePickerValues): void =>
          onChanges({ CI032ForecastedDate: date })
        }
      />
      <RangePicker
        id="CI032ActualDate"
        key="CI032ActualDate"
        label="CI032 On Air Actual date"
        formItemProps={{ className: 'aligned-form-item' }}
        onChange={(date?: RangePickerValues): void =>
          onChanges({ CI032ActualDate: date })
        }
      />
      <RangePicker
        id="assignedDate"
        key="assignedDate"
        label="Assigned Date"
        formItemProps={{ className: 'aligned-form-item' }}
        onChange={(date?: RangePickerValues): void =>
          onChanges({ assignedDate: date })
        }
      />
      <Autocomplete
        id="towerOwnerId"
        key="towerOwnerId"
        label="Tower Owner"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.TowerOwner,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.towerOwnerId,
          onChange: (e: SelectValue): void =>
            onChanges({ towerOwnerId: e as string }),
        }}
      />
      <Autocomplete
        id="stateId"
        key="stateId"
        label="State"
        options={arrsToSelectableObject<StateWithCounties>(
          states,
          'id',
          'name',
        )}
        elementProps={{
          allowClear: true,
          value: value.stateId,
          onChange: (e: SelectValue): void => {
            formProps?.form?.resetFields(['countyId', 'jurisdictionId']);
            onChanges({
              countyId: undefined,
              jurisdictionId: undefined,
              stateId: e as string,
            });
            setStateId(e as string);
          },
        }}
      />
      <Autocomplete
        id="countyId"
        key="countyId"
        label="County"
        title="You should first select a state"
        tooltip={!countyOptions?.length}
        options={countyOptions}
        elementProps={{
          allowClear: true,
          value: value.countyId,
          onChange: (e: SelectValue): void => {
            onChanges({ countyId: e as string });
          },
          disabled: !countyOptions?.length,
        }}
      />
      <Autocomplete
        id="jurisdictionId"
        key="jurisdictionId"
        label="Jurisdiction"
        title="You should first select a state"
        tooltip={!countyOptions?.length}
        options={jurisdictionOptions}
        elementProps={{
          allowClear: true,
          value: value.jurisdictionId,
          onChange: (e: SelectValue): void =>
            onChanges({ jurisdictionId: e as string }),
          disabled: !jurisdictionOptions.length,
        }}
      />
    </div>
  );
};
