import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { ApiMethods } from '@models/enums';
import {
  EditImageParams,
  OptionProps,
  SiteImageData,
} from '@models/interfaces';
import { SitePicturesUploadActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class SitePicturesUploadHttpService extends CommonHttpService {
  @StoredRequest(SitePicturesUploadActions.getFALocationNumberOptionsList)
  getFALocationNumberOptions(): Promise<OptionProps[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/site-picture/filter-options`,
    );
  }

  editImage(file: File, params: EditImageParams): Promise<Blob> {
    const formData = new FormData();

    formData.append('image', file);

    return this.http.callApi.patch(
      `${this.apiUrl}/admin/site-picture`,
      formData,
      {
        params,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      },
    );
  }

  @StoredRequest(SitePicturesUploadActions.getSiteImages)
  getSiteImagesByFALocationNumber(
    FALocationNumber: number,
  ): Promise<SiteImageData[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/site-picture/${FALocationNumber}`,
    );
  }

  @StoredRequest(SitePicturesUploadActions.uploadSiteImages)
  uploadSiteImages(
    FALocationNumber: number,
    files: File[],
  ): Promise<SiteImageData[]> {
    return this.uploadMultipleFiles(
      ApiMethods.Post,
      `${this.apiUrl}/admin/site-picture/${FALocationNumber}`,
      null,
      files,
      'images',
    );
  }

  @StoredRequest(SitePicturesUploadActions.deleteSiteImageAction)
  async deleteSiteImage(id: string): Promise<string> {
    await this.http.callApi.delete(`${this.apiUrl}/admin/site-picture/${id}`);

    return id;
  }
}
