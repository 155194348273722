import { createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableComponents } from 'rc-table/lib/interface';
import { DraggableRow } from '../../components';

export const COMPONENTS: TableComponents<any> = {
  body: {
    row: DraggableRow,
  },
};

export const RND_CONTEXT = createDndContext(HTML5Backend);
