import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { SitePicturesUploadHttpService } from '@core/services/http';

export class SitePicturesUploadResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      SitePicturesUploadHttpService,
    ).getFALocationNumberOptions();
  }
}
