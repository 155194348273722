export const createMapScript = (
  callback: () => void,
  callbackName: string = 'createMap',
): void => {
  const scriptTag = document.createElement('script');

  scriptTag.setAttribute('type', 'text/javascript');
  scriptTag.setAttribute(
    'src',
    `https://www.bing.com/api/maps/mapcontrol?callback=${callbackName}&setMkt=en-US&setLang=en`,
  );
  scriptTag.async = true;
  scriptTag.defer = true;

  document.body.appendChild(scriptTag);

  (window as any)[callbackName] = callback;
};
