import { TabProps } from '@models/classes/models';
import {
  JurisdictionPermitting,
  JurisdictionZoning,
  PermittingJurisdictionContacts,
} from '../details/tabs';

export const PERMITTING_JURISDICTION_TABS: TabProps[] = [
  {
    name: 'Zoning',
    component: JurisdictionZoning,
    path: '/zoning',
  },
  {
    name: 'Permitting',
    component: JurisdictionPermitting,
    path: '/permitting',
  },
  {
    name: 'Contacts',
    component: PermittingJurisdictionContacts,
    path: '/contacts',
  },
];
