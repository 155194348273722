import React, { FC, PropsWithChildren } from 'react';
import { Input as AntInput } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { FormItem } from '@shared/modules';
import { getReadonlyElementFunction } from '../../models/constants';
import { FormElement } from '../../models/interfaces';

export const Input: FC<FormElement<TextAreaProps>> = ({
  id,
  elementProps: { onFocus, ...elementProps } = {},
  readOnlyElementRender,
  ...props
}: PropsWithChildren<FormElement<TextAreaProps>>) => (
  <FormItem
    id={id}
    editingElement={
      <AntInput.TextArea className="model-input" id={id} {...elementProps} />
    }
    readOnlyElement={getReadonlyElementFunction<TextAreaProps>(
      id,
      props,
      readOnlyElementRender,
    )}
    {...props}
  />
);
