import React, { FC, ReactText, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import { tableCount } from '@models/constants';
import { DirectMessageData } from '@models/interfaces';
import { CustomTable, DeleteModal, Search } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { NotificationSelectors } from '@store/selectors';
import { directMessagesSearcher } from './helpers';
import { DirectMessageViewer } from './modals';
import { DIRECT_MESSAGE_COLUMNS } from './models';

export const DirectMessages: FC = () => {
  const notificationService = HttpService.getHttpRequests(
    NotificationsHttpService,
  );

  const items = useSelector(NotificationSelectors.getDirectMessages);
  const userList = useSelector(NotificationSelectors.getUserList);

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [tableData, setTableData] = useState<DirectMessageData[]>(items);
  const [currentItem, setCurrentItem] = useState<DirectMessageData | null>(
    null,
  );
  const [visible, setVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setTableData(directMessagesSearcher(items, userList, searchValue));
    setSelectedRows([]);
  }, [searchValue, items]);

  const showMessageHandler = useCallback(
    (directMessage: DirectMessageData): void => {
      setSelectedRows([]);
      setCurrentItem(directMessage);
      setVisible(true);
    },
    [],
  );

  const hideMessageHandler = useCallback((): void => setVisible(false), []);

  const showDeleteModalHandler = useCallback(
    (directMessage: DirectMessageData): void => {
      setCurrentItem(directMessage);
      setDeleteModalVisible(true);
    },
    [],
  );

  const hideDeleteModalHandler = useCallback(
    (): void => setDeleteModalVisible(false),
    [],
  );

  const changeRowSelected = useCallback(
    (rows: ReactText[]): void => setSelectedRows(rows),
    [],
  );

  const removeDirectMessage = useCallback(async (): Promise<void> => {
    await notificationService.removeDirectMessages(currentItem!._id);

    setDeleteModalVisible(false);
    setCurrentItem(null);
  }, [currentItem]);

  const multiRemoveDirectMessages = useCallback(async (): Promise<void> => {
    await notificationService.multiRemoveDirectMessages(
      selectedRows as string[],
    );

    setSelectedRows([]);
  }, [selectedRows]);

  return (
    <div className="prov-notification">
      <div className="prov-notification__header-controls">
        <Search onChange={setSearchValue} allowClear />
        <PrimaryButton
          title="Remove selected"
          className="multi-remover-btn"
          onClick={multiRemoveDirectMessages}
          disabled={!selectedRows.length}
        />
      </div>
      <CustomTable
        dataSource={tableData}
        columns={DIRECT_MESSAGE_COLUMNS(
          userList,
          showMessageHandler,
          showDeleteModalHandler,
        )}
        legendProps={{
          items: [
            { value: 'Completed', color: 'gray' },
            { value: 'Not completed', color: 'green' },
          ],
        }}
        hoverable={false}
        place={tableCount(true)}
        pagination={{
          total: tableData?.length,
        }}
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: changeRowSelected,
        }}
      />
      <DirectMessageViewer
        visible={visible}
        onCancel={hideMessageHandler}
        usersList={userList}
        item={currentItem}
      />
      <DeleteModal
        visible={deleteModalVisible}
        onOk={removeDirectMessage}
        onCancel={hideDeleteModalHandler}
      />
    </div>
  );
};
