import { actionCreatorFactory } from '../../core/utils/factories';
import {
  ExportsBrowse,
  ExportsBrowseFilterOptionsResponse,
  PaginatedResponse,
} from '../../models/interfaces';

const actionCreator = actionCreatorFactory('EXPORTS');

export namespace ExportsActions {
  export const getExportsBrowseDataAction =
    actionCreator.createAsyncAction<PaginatedResponse<ExportsBrowse>>(
      'GET_EXPORTS_BROWSE',
    );
  export const getExportsBrowseFilters =
    actionCreator.createAsyncAction<ExportsBrowseFilterOptionsResponse>(
      'GET_EXPORTS_BROWSE_FILTERS',
    );
}
