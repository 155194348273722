import React, { FC, PropsWithChildren } from 'react';
import { Form } from 'antd';
import { isValidForm } from '@core/utils/methods';
import { SectorEquipmentType } from '@models/enums';
import {
  OptionProps,
  SectorEquipment,
  SectorEquipmentType as ISectorEquipmentType,
} from '@models/interfaces';
import { SectorDataState } from '@models/types';
import { Autocomplete, Modal } from '@shared/modules';
import { DuplicateSectorModalProps } from './models/interfaces';

import './styles.scss';

const { useForm } = Form;

export const DuplicateSectorModal: FC<DuplicateSectorModalProps> = ({
  sectorsForDuplicate,
  sectorType,
  setSectorsDataVersions,
  onCancel,
  ...props
}: PropsWithChildren<DuplicateSectorModalProps>) => {
  const [form] = useForm();

  const buttons: OptionProps[] = sectorsForDuplicate.map(
    (sector: SectorEquipmentType) => ({
      value: sector,
      viewValue: sector,
    }),
  );

  const add = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      const { duplicateSector } = form.getFieldsValue();

      if (duplicateSector?.length) {
        setSectorsDataVersions(versions =>
          versions.map(version => {
            const { scopingSectors } = version;

            return version.isSelected
              ? {
                  ...version,
                  scopingSectors: {
                    ...scopingSectors,
                    ...duplicateSector.reduce(
                      (acc: SectorDataState, duplicate: string) => ({
                        ...acc,
                        [duplicate]: scopingSectors[sectorType]!.map(
                          ({ equipments, ...value }: ISectorEquipmentType) => ({
                            ...value,
                            equipments: equipments.map(
                              (equipmentForDuplicate: SectorEquipment) => {
                                const equipment = { ...equipmentForDuplicate };

                                delete equipment.id;

                                return {
                                  ...equipment,
                                };
                              },
                            ),
                          }),
                        ),
                      }),
                      {},
                    ),
                  },
                }
              : version;
          }),
        );

        onCancel?.(e);
      }
    }
  };

  return (
    <Modal
      title="Duplicate Sector"
      okText="Duplicate"
      className="prov-duplicate-sector-modal"
      onOk={add}
      onCancel={onCancel}
      {...props}
    >
      <Form labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} form={form}>
        <Autocomplete
          label="Duplicate Sector"
          id="duplicateSector"
          options={buttons}
          elementProps={{
            mode: 'multiple',
          }}
          formItemProps={{
            rules: [
              { required: true, message: 'Duplicate Sector is required!' },
            ],
          }}
        />
      </Form>
    </Modal>
  );
};
