import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { AdminState } from '../reducers/admin';

const adminState = (state: AppState): AdminState => state.admin;

export namespace SharedSelectors {
  export const getDropdownValuesAndStates = createSelector(
    [adminState],
    ({
      dropdownValues: { dropdownValues },
      countyValues: { states },
    }: AdminState) => ({
      dropdownValues,
      states,
    }),
  );

  export const getDropdownOptionsAndStates = createSelector(
    [adminState],
    ({
      dropdownValues: { dropdownsOptions },
      countyValues: { states },
    }: AdminState) => ({
      dropdownsOptions,
      states,
    }),
  );
}
