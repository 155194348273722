import { OptionProps, SectorEquipment } from '@models/interfaces';

export const POSITIONS: OptionProps[] = Array.from(
  { length: 5 },
  (_: undefined, index: number) => {
    const value = index + 1;

    return {
      value,
      viewValue: value,
    };
  },
);

export const EMPTY_POSITIONS: SectorEquipment[] = Array.from(
  { length: 5 },
  (_: unknown, index: number) => ({
    position: index + 1,
    equipment: '',
    manufacturer: '',
    quantity: 1,
  }),
);
