import { DirectMessageAreaKey } from '@symfa-inc/providence-types';
import { arraySort } from '@core/utils/methods/array-sort';

export const AREA_OPTIONS = arraySort(
  Object.values(DirectMessageAreaKey)
    .filter(
      (value: DirectMessageAreaKey) =>
        ![
          DirectMessageAreaKey.ProjectManagementSummary,
          DirectMessageAreaKey.ProjectManagementFinancial,
        ].includes(value),
    )
    .map((value: DirectMessageAreaKey) => ({
      value,
      viewValue: value,
    })),
  'ASC',
  'viewValue',
);
