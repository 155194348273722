import { BrowseFilterType } from './browse-filter.type';

export const SOF_FILTERS: BrowseFilterType[] = [
  {
    id: 'FALocationNumber',
    name: 'FA Location Number',
    valueKey: 'FALocationNumbers',
  },
  {
    id: 'siteNumber',
    name: 'Site Number',
    valueKey: 'siteNumbers',
  },
  {
    id: 'PACEProjectNumber',
    name: 'PACE Project Number',
    valueKey: 'PACEProjectNumbers',
  },
  {
    id: 'IWMJobNumber',
    name: 'IWM Job Number',
    valueKey: 'IWMJobNumbers',
  },
];
