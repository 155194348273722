import React, { FC, PropsWithChildren } from 'react';
import { Modal } from '@shared/modules/ui-kit/modal';
import { DeleteModalType, VendorsDeleteModalProps } from './models';

export const VendorsDeleteModal: FC<VendorsDeleteModalProps> = ({
  modalType,
  ...props
}: PropsWithChildren<VendorsDeleteModalProps>) => {
  return (
    <Modal
      okText={modalType === DeleteModalType.Delete ? 'Delete' : 'Unassign'}
      cancelText="Cancel"
      className="prov-delete-modal"
      cancelType="danger-outline"
      centered
      {...props}
    >
      <span className="prov-delete-modal__title">
        {modalType === DeleteModalType.Delete
          ? 'Do you want to delete this vendor and unassign it from all lists?'
          : 'Do you want to unassign this vendor from the list?'}
      </span>
    </Modal>
  );
};
