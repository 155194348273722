import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { SubPage, Tab } from '@symfa-inc/providence-types';
import { canUserEditByProjectStatusAndRoles } from '@core/utils/methods';
import { EditablePage } from '@shared/components';
import {
  LeasingSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import { LeasingSummary } from './components';

export const LeasingSummaryTab: FC<RouterProps> = () => {
  const projectType = useSelector(LeasingSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit('leasing', SubPage.LeasingBrowse, Tab.Summary),
  );
  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <EditablePage
      editableComponent={LeasingSummary}
      canUserEdit={
        canEdit &&
        projectTypePermission &&
        canUserEditByProjectStatusAndRoles(projectStatusId, userRoles)
      }
    />
  );
};
