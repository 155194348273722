import { Guard } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import store from '../../store';
import { UserState } from '../../store/reducers/user.reducer';
import { UserHttpService } from '../services/http';
import { requestWithStoreWaiting } from '../utils/methods';

export class UserGuard implements Guard {
  private static get _userState(): UserState {
    return store.getState().user;
  }

  async canActivate(): Promise<boolean> {
    if (!Object.keys(UserGuard._userState.activeUser).length) {
      await requestWithStoreWaiting(
        () => HttpService.getHttpRequests(UserHttpService).getSelf(),
        () => UserGuard._userState,
      );
    }

    return !!UserGuard._userState.activeUser;
  }
}
