import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';

export class ProjectBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(ProjectHttpService).getProjectBrowse(
      BROWSE_DEFAULT_FILTER_VALUE,
    );
  }
}
