import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  createOnChangeModalVisibility,
  momentizeRangePickerValues,
} from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
} from '@models/constants';
import { MainBrowseFilter, ProjectBrowseFilter } from '@models/interfaces';
import { BrowsePage } from '@shared/modules/browse-page';
import { ProjectSelectors } from '@store/selectors';
import {
  legend,
  place,
  PROJECT_BROWSE_COLUMNS,
  PROJECT_BROWSE_DEFAULT_CONFIGURE_FILTER,
} from './models/constants';
import { Filters } from './filters';

import './styles.scss';

const { useForm } = Form;

export const ProjectBrowse: FC = () => {
  const projectBrowse = useSelector(ProjectSelectors.getProjectBrowseInfo);
  const [form] = useForm();

  const [filterValue, setFilterValue] = useState<ProjectBrowseFilter>(
    BROWSE_DEFAULT_FILTER_VALUE,
  );
  const [configureFilter, setConfigureFilter] = useState<
    Partial<ProjectBrowseFilter>
  >(PROJECT_BROWSE_DEFAULT_CONFIGURE_FILTER);

  useDidUpdateEffect(() => {
    HttpService.getHttpRequests(ProjectHttpService).getProjectBrowse(
      momentizeRangePickerValues<ProjectBrowseFilter>(
        filterValue,
        [
          'RE020ForecastedDate',
          'RE020ActualDate',
          'CI032ForecastedDate',
          'CI032ActualDate',
          'assignedDate',
        ],
        true,
      ),
    );
  }, [filterValue]);

  const onSearchChanges = useCallback((search: string): void => {
    setFilterValue((prevState: MainBrowseFilter) => ({
      ...prevState,
      search,
    }));
  }, []);

  const onConfigureFilterChanges = useCallback(
    (value: Partial<ProjectBrowseFilter>): void => {
      setConfigureFilter((prevState: Partial<ProjectBrowseFilter>) => ({
        ...prevState,
        ...value,
      }));
    },
    [],
  );

  const onClear = useCallback((): void => {
    form.resetFields();
    setConfigureFilter(PROJECT_BROWSE_DEFAULT_CONFIGURE_FILTER);
  }, []);

  const onApplyConfigureFilter = useCallback((): void => {
    setFilterValue((prevState: MainBrowseFilter) => ({
      ...prevState,
      ...configureFilter,
    }));
  }, [configureFilter]);

  const onChangeFilterModalVisibility = useCallback(
    createOnChangeModalVisibility(
      form,
      configureFilter,
      filterValue,
      setConfigureFilter,
    ),
    [configureFilter, filterValue],
  );

  return (
    <BrowsePage
      className="prov-project-browse"
      legend={legend}
      columns={PROJECT_BROWSE_COLUMNS}
      data={projectBrowse.items}
      onSearchChanges={onSearchChanges}
      onChange={browseFiltersChange(setFilterValue)}
      filters={
        <Filters
          value={configureFilter}
          formProps={{ form }}
          onChanges={onConfigureFilterChanges}
        />
      }
      validation
      onChangeFilterModalVisibility={onChangeFilterModalVisibility}
      onApplyFilter={onApplyConfigureFilter}
      place={place}
      filterFormProps={{ labelCol: { span: 10 }, form }}
      tableProps={{
        pagination: {
          total: projectBrowse.total,
        },
        module: 'project',
      }}
      cancelButtonProps={{
        onClick: onClear,
      }}
    />
  );
};
