import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-types';
import { SelectValue } from 'antd/lib/select';
import { useFindCountyAndJurisdictions } from '@core/utils/hooks';
import { arrsToSelectableObject } from '@core/utils/methods';
import { YES_NO_GROUP } from '@models/constants';
import {
  AdminDropdownValuesData,
  ConfigureFilterProps,
  OptionProps,
  RangePickerValues,
  SiteAuditBrowseFilter,
  StateWithCounties,
} from '@models/interfaces';
import { Autocomplete, RangePicker } from '@shared/modules';
import { SharedSelectors } from '@store/selectors';

export const Filters: FC<ConfigureFilterProps<SiteAuditBrowseFilter>> = ({
  value,
  formProps,
  onChanges,
}: PropsWithChildren<ConfigureFilterProps<SiteAuditBrowseFilter>>) => {
  const { dropdownValues, states } = useSelector(
    SharedSelectors.getDropdownValuesAndStates,
  );

  const [stateId, setStateId] = useState<Undefinable<string>>();
  const [jurisdictionOptions, setJurisdictionOptions] = useState<OptionProps[]>(
    [],
  );

  useFindCountyAndJurisdictions({
    stateId,
    setJurisdictionOptions,
    effectDepArray: [stateId],
  });

  useEffect(() => {
    if (value.stateId !== stateId) {
      setStateId(value.stateId);
    }
  }, [value]);

  return (
    <div className="prov-project-browse-filters">
      <Autocomplete
        id="projectTypeId"
        key="projectTypeId"
        label="Project Type"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.ProjectType,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.projectTypeId,
          onChange: (e: SelectValue): void =>
            onChanges({ projectTypeId: e as string }),
        }}
      />
      <RangePicker
        id="scopingCompleteForecasted"
        key="scopingCompleteForecasted"
        label="Scoping Complete – Forecasted"
        formItemProps={{ className: 'aligned-form-item' }}
        onChange={(date?: RangePickerValues): void =>
          onChanges({ scopingCompleteForecasted: date })
        }
        value={value.scopingCompleteForecasted}
      />
      <RangePicker
        id="scheduledSiteWalkDate"
        key="scheduledSiteWalkDate"
        label="Scheduled Site Walk"
        formItemProps={{ className: 'aligned-form-item' }}
        onChange={(date?: RangePickerValues): void =>
          onChanges({ scheduledSiteWalkDate: date })
        }
        value={value.scheduledSiteWalkDate}
      />
      <RangePicker
        id="siteAuditComplete"
        key="siteAuditComplete"
        label="Site Audit Complete"
        formItemProps={{ className: 'aligned-form-item' }}
        onChange={(date?: RangePickerValues): void =>
          onChanges({ siteAuditComplete: date })
        }
        value={value.siteAuditComplete}
      />
      <Autocomplete
        id="towerOwnerId"
        key="towerOwnerId"
        label="Tower Owner"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.TowerOwner,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.towerOwnerId,
          onChange: (e: SelectValue): void =>
            onChanges({ towerOwnerId: e as string }),
        }}
      />
      <Autocomplete
        id="stateId"
        key="stateId"
        label="State"
        options={arrsToSelectableObject<StateWithCounties>(
          states,
          'id',
          'name',
        )}
        elementProps={{
          allowClear: true,
          value: value.stateId,
          onChange: (e: SelectValue): void => {
            onChanges({ stateId: e as string, jurisdictionId: undefined });

            setStateId(e as string);
            formProps?.form?.resetFields(['jurisdictionId']);
          },
        }}
      />
      <Autocomplete
        id="jurisdictionId"
        key="jurisdictionId"
        label="Jurisdiction"
        title="You should first select a state"
        tooltip={!jurisdictionOptions?.length}
        options={jurisdictionOptions}
        elementProps={{
          disabled: !jurisdictionOptions.length,
          allowClear: true,
          value: value.jurisdictionId,
          onChange: (e: SelectValue): void =>
            onChanges({ jurisdictionId: e as string }),
        }}
      />
      <Autocomplete
        id="isFollowUpRequired"
        key="isFollowUpRequired"
        label="Follow Up Required"
        options={YES_NO_GROUP as OptionProps[]}
        elementProps={{
          allowClear: true,
          value: value.isFollowUpRequired as any,
          onChange: (e: SelectValue): void =>
            onChanges({ isFollowUpRequired: e as any }),
        }}
      />
    </div>
  );
};
