import React, { FC, PropsWithChildren } from 'react';
import { Checkbox } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import { FormItem } from '@shared/modules';
import { FormElement } from '../../models/interfaces';

export const CheckboxGroup: FC<FormElement<CheckboxGroupProps>> = ({
  id,
  elementProps,
  ...props
}: PropsWithChildren<FormElement<CheckboxGroupProps>>) => (
  <FormItem
    id={id}
    editingElement={<Checkbox.Group {...elementProps} />}
    readOnlyElement={<Checkbox.Group {...elementProps} />}
    {...props}
  />
);
