import React, { FC } from 'react';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { FileType } from '@models/types';
import { Import } from '@shared/modules/import';

export const ProjectImport: FC = () => (
  <Import
    template="ProjectTemplate"
    sendFile={(file: FileType): Promise<void> =>
      HttpService.getHttpRequests(ProjectHttpService).importProjects(file)
    }
  />
);
