import React, { Dispatch, FC, PropsWithChildren, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { ModalMainTypes } from '@models/enums';
import { OtherEquipmentData } from '@models/interfaces';
import { ScopingActions } from '@store/actions';
import { CustomTable } from '../../components/custom-table';
import { DeleteModal } from '../../components/delete-modal';
import { EquipmentModal } from '../../components/equipment-modal';
import { Panel } from '../../components/panel';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { getOtherEquipmentColumns } from './models/constants';
import { OtherEquipmentProps } from './models/types';

export const OtherEquipment: FC<OtherEquipmentProps> = ({
  className,
  isEditing,
  setOtherEquipments,
  setEquipmentItem,
  data,
}: PropsWithChildren<OtherEquipmentProps>) => {
  const dispatch = useDispatch();

  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [selectedItem, setSelectedItem] = useState<OtherEquipmentData>();

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    dispatch(ScopingActions.updateCurrentModalTypeAction.done(type));

    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem(undefined);
    }
  };

  const selectOtherEquipment = (
    otherEquipment: OtherEquipmentData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(otherEquipment);
    setter(!prevState);
  };

  const toggleDeleteModal = (): void => {
    setDeleteModalVisible(!deleteModalVisible);

    if (deleteModalVisible) {
      setSelectedItem(undefined);
    }
  };

  const onDelete = (): void => {
    if (setOtherEquipments) {
      setOtherEquipments((prevValue: OtherEquipmentData[]) => [
        ...prevValue.filter(
          (otherEquipment: OtherEquipmentData) =>
            otherEquipment.generatedId !== selectedItem?.generatedId,
        ),
      ]);
    }

    toggleDeleteModal();
  };

  return (
    <Panel
      header="Other Equipment"
      className={classNames('prov-other-equipment', className)}
      collapsible
    >
      <CustomTable
        columns={getOtherEquipmentColumns(
          isEditing,
          (equipment: OtherEquipmentData): void =>
            selectOtherEquipment(
              equipment,
              () => toggleAddAndEditModal(ModalMainTypes.Edit),
              isAddEditModalVisible,
            ),
          (equipment: OtherEquipmentData): void =>
            selectOtherEquipment(
              equipment,
              setDeleteModalVisible,
              deleteModalVisible,
            ),
        )}
        dataSource={data}
        pagination={false}
      />
      {setEquipmentItem && (
        <>
          <EquipmentModal
            visible={isAddEditModalVisible}
            modalType={currentModalType}
            toggleModal={toggleAddAndEditModal}
            itemValue={selectedItem}
            setEquipmentItem={setEquipmentItem}
          />
          <DeleteModal
            visible={deleteModalVisible}
            onOk={onDelete}
            onCancel={(): void => toggleDeleteModal()}
          />
        </>
      )}
      {isEditing && (
        <PrimaryButton
          title="Add Other Equipment"
          icon="+"
          onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
        />
      )}
    </Panel>
  );
};
