import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { UserManagementState } from '../reducers/user-management.reducer';

const userManagementState = (state: AppState): UserManagementState =>
  state.userManagement;

export namespace UserManagementSelectors {
  export const getUserManagementUsersWithTotal = createSelector(
    [userManagementState],
    ({ total, users }: UserManagementState) => ({ total, users }),
  );
}
