import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ContactsPlace } from '@models/enums';
import { Contacts } from '@shared/components';
import { SiteSelectors, UserSelectors } from '@store/selectors';

export const SiteContacts: FC = () => {
  const siteContacts = useSelector(SiteSelectors.getSiteContactsInfo);

  const { contactsAccess } = useSelector(
    UserSelectors.getSiteBrowsePermissions,
  );

  return (
    <div className="prov-site-contacts">
      <Contacts
        data={siteContacts}
        place={ContactsPlace.Site}
        editable={!!contactsAccess}
      />
    </div>
  );
};
