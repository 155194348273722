import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { Store } from 'rc-field-form/es/interface';
import { processScopingEquipmentInfo } from '@client/pages/engineering/helpers';
import { HttpService, ObjectComparatorService } from '@core/services';
import { ScopingHttpService } from '@core/services/http';
import {
  useCountingTotalEquipment,
  useDidUpdateEffect,
  useSaveChanged,
} from '@core/utils/hooks';
import {
  formDiffs,
  isValidForm,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  EQUIPMENT_DEFAULT_VALUE,
  SCOPING_SECTORS_VERSIONS,
  SCOPING_SECTORS_VERSIONS_DATA,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  EditableComponentProps,
  EquipmentModal,
  OtherEquipmentData,
  ScopingEquipmentInfo,
  ScopingNotes as IScopingNotes,
} from '@models/interfaces';
import { ScopingSectorsVersionData } from '@models/types';
import {
  ChooseSectorVersionModal,
  EquipmentTotals,
  NotificationsLoader,
  OtherEquipment,
  ScopingNotes,
  SectorsOfEquipment,
} from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions, LeasingActions, ProjectActions } from '@store/actions';
import {
  EquipmentSelectors,
  ProjectCategoriesSelectors,
  RADSelectors,
  ScopingSelectors,
} from '@store/selectors';
import { ScopingInformation } from './components';

import './styles.scss';

const { useForm } = Form;

const EquipmentContent: FC<EditableComponentProps> = ({
  isEditing,
  toggleEditing,
}: PropsWithChildren<EditableComponentProps>) => {
  const dispatch = useDispatch();

  const [scopingInfoForm] = useForm();
  const [scopingNotesForm] = useForm();

  const [equipmentForm, setEquipmentForm] = useState<Array<Store>>([]);
  const [initialForm, setInitialForm] = useState<Array<Store>>([]);

  const arraysCompare = ObjectComparatorService.arraysCompare(
    initialForm,
    equipmentForm,
    true,
  );

  const {
    currentProjectId,
    scopingInfo,
    RFDSPhase1,
    regulatoryCompliance,
    otherEquipments: scopingOtherEquipments,
    scopingSectorsVersions,
    scopingLeasingInfo,
  } = useSelector(ScopingSelectors.getScopingEquipmentData);

  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const equipmentTypes = useSelector(EquipmentSelectors.getEquipmentTypes);
  const currentModalType = useSelector(ScopingSelectors.getCurrentModalType);
  const scopingFetching = useSelector(ScopingSelectors.isFetching);
  const { RAD, originalRADInfo } = useSelector(RADSelectors.getRADInfo);

  const [otherEquipments, setOtherEquipments] = useState<OtherEquipmentData[]>(
    scopingOtherEquipments || [],
  );
  const [equipmentItem, setEquipmentItem] = useState<EquipmentModal>(
    EQUIPMENT_DEFAULT_VALUE,
  );
  const [sectorsDataVersions, setSectorsDataVersions] = useState<
    ScopingSectorsVersionData[]
  >(SCOPING_SECTORS_VERSIONS_DATA);

  const [isChooseVersionModalVisible, setChooseVersionModalVisible] =
    useState<boolean>(false);

  const totalEquipments = useCountingTotalEquipment(
    'scoping',
    otherEquipments,
    sectorsDataVersions,
    equipmentTypes,
  );

  useEffect(() => {
    const versions = scopingSectorsVersions ?? SCOPING_SECTORS_VERSIONS;

    setSectorsDataVersions(versions);
  }, [scopingSectorsVersions]);

  const toggleChooseVersionModalVisible = () =>
    setChooseVersionModalVisible(prevState => !prevState);

  const onSubmit = async (
    versions: ScopingSectorsVersionData[] = sectorsDataVersions,
  ): Promise<void> => {
    if (
      (await isValidForm(scopingInfoForm)) &&
      (await isValidForm(scopingNotesForm))
    ) {
      try {
        const [scopingInfoFormInitial, scopingNotesFormInitial] = initialForm;
        const [infoForm, notesForm] = equipmentForm;

        await HttpService.getHttpRequests(
          ScopingHttpService,
        ).updateScopingEquipment(
          currentProjectId,
          processScopingEquipmentInfo({
            scopingNotes: formDiffs(
              scopingNotesFormInitial as IScopingNotes,
              notesForm as IScopingNotes,
            ),
            scopingInformation: formDiffs<ScopingEquipmentInfo>(
              momentizeObjectDates(
                scopingInfoFormInitial,
                ['prelimRFDSReceivedDate'],
                true,
              ) as ScopingEquipmentInfo,
              momentizeObjectDates(
                infoForm,
                ['prelimRFDSReceivedDate'],
                true,
              ) as ScopingEquipmentInfo,
            ),
            otherEquipments,
            scopingSectorsVersions: versions,
            RAD: RAD.map(({ id, ...item }) => item),
          }),
        );

        setInitialForm(equipmentForm);

        toggleEditing?.();

        const { azimuthA, azimuthB, azimuthG, azimuthD, ...rest } =
          formDiffs<ScopingEquipmentInfo>(
            momentizeObjectDates(
              scopingInfoFormInitial,
              ['prelimRFDSReceivedDate'],
              true,
            ) as ScopingEquipmentInfo,
            momentizeObjectDates(
              infoForm,
              ['prelimRFDSReceivedDate'],
              true,
            ) as ScopingEquipmentInfo,
          );

        dispatch(
          LeasingActions.updateLeaseAuditAction.done({
            area: 'engineering',
            azimuthA,
            azimuthB,
            azimuthG,
            azimuthD,
          } as any),
        );

        dispatch(CommonActions.setHasUnsubmittedData.done(false));

        NotificationsLoader.notificationSuccess(
          `Information has been updated!`,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onSubmitWithChoosingVersion = () => {
    const dontNeedToAskChooseVersion = sectorsDataVersions.find(
      ({ isSelected, isCurrent }) => isSelected && isCurrent,
    );

    if (dontNeedToAskChooseVersion) {
      onSubmit();
    } else {
      toggleChooseVersionModalVisible();
    }
  };

  const onCancel = (): void => {
    const [infoForm, notesForm] = initialForm;

    scopingNotesForm.setFieldsValue(notesForm);
    scopingInfoForm.setFieldsValue(
      momentizeObjectDates(infoForm, ['prelimRFDSReceivedDate']),
    );

    setOtherEquipments(scopingOtherEquipments);
    setSectorsDataVersions(scopingSectorsVersions);

    setEquipmentForm(initialForm);

    dispatch(ProjectActions.updateProjectRADAction.done(originalRADInfo));

    dispatch(CommonActions.setHasUnsubmittedData.done(false));

    toggleEditing?.();
  };

  const onValuesChange = (): void => {
    if (Object.keys(scopingInfoForm.getFieldsValue()).length) {
      const data = [
        scopingInfoForm.getFieldsValue(),
        scopingNotesForm.getFieldsValue(),
      ];

      setEquipmentForm(data);

      if (!Object.keys(initialForm).length) {
        setInitialForm(data);
      }
    }
  };

  const onSaveVersion = (version: number) => {
    const versions = sectorsDataVersions.map(prevVersion => ({
      ...prevVersion,
      isSelected: prevVersion.version === version,
      isCurrent: prevVersion.version === version,
    }));

    setSectorsDataVersions(versions);

    toggleChooseVersionModalVisible();
    onSubmit(versions);
  };

  const onCancelChooseVersion = () => {
    toggleChooseVersionModalVisible();
    onSubmit();
  };

  // shouldn't react when user change selected version
  const isDataNotUpdated =
    ObjectComparatorService.objectsCompare(
      sectorsDataVersions.map(({ isSelected, ...version }) => version),
      scopingSectorsVersions.map(({ isSelected, ...version }) => version),
    ) &&
    ObjectComparatorService.arraysCompare(
      otherEquipments,
      scopingOtherEquipments,
    ) &&
    ObjectComparatorService.arraysCompare(originalRADInfo, RAD) &&
    arraysCompare;

  const buttonsDisabled = scopingFetching || isDataNotUpdated;

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isEditing]);

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!isDataNotUpdated));
  }, [
    sectorsDataVersions,
    scopingSectorsVersions,
    otherEquipments,
    scopingOtherEquipments,
    arraysCompare,
  ]);

  useDidUpdateEffect(() => {
    const newDataState =
      currentModalType === ModalMainTypes.Add
        ? [...otherEquipments, equipmentItem]
        : otherEquipments.map((other: OtherEquipmentData) =>
            other.generatedId === equipmentItem.generatedId
              ? { ...other, ...equipmentItem }
              : other,
          );

    setOtherEquipments(newDataState);
  }, [equipmentItem]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  const scopingNotesData = useMemo(() => {
    const {
      id: scopingId,
      demotionNote,
      promotionNote,
      scopingGroundNote,
      scopingTowerNote,
    } = scopingInfo;
    const { RFDSStatus } = RFDSPhase1;

    return {
      id: scopingId,
      demotionNote,
      promotionNote,
      scopingGroundNote,
      scopingTowerNote,
      RFDSStatus,
    };
  }, [scopingInfo, RFDSPhase1]);

  return (
    <div className="prov-scoping-equipment">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <ScopingInformation
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          form={scopingInfoForm}
          data={{
            ...(scopingLeasingInfo?.leaseAudit || {}),
            ...scopingInfo,
            ...RFDSPhase1,
            ...regulatoryCompliance,
          }}
          onValuesChange={onValuesChange}
        />
        <ScopingNotes
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          form={scopingNotesForm}
          data={scopingNotesData}
          onValuesChange={onValuesChange}
        />
        <SectorsOfEquipment
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          sectorsDataVersions={sectorsDataVersions}
          setSectorsDataVersions={setSectorsDataVersions}
        />
        <OtherEquipment
          className="prov-scoping-equipment__information"
          isEditing={isEditing}
          setOtherEquipments={setOtherEquipments}
          setEquipmentItem={setEquipmentItem}
          data={otherEquipments}
        />
        <EquipmentTotals
          className="prov-scoping-equipment__information"
          data={totalEquipments}
        />
        <ChooseSectorVersionModal
          versions={sectorsDataVersions}
          onSave={onSaveVersion}
          onCancel={onCancelChooseVersion}
          visible={isChooseVersionModalVisible}
        />
      </div>
      {isEditing && (
        <div className="prov-site-information__btn-wrap">
          <div>
            <PrimaryButton
              htmlType="submit"
              title="Submit"
              disabled={buttonsDisabled}
              onClick={onSubmitWithChoosingVersion}
            />
            <PrimaryButton
              title="Cancel"
              disabled={buttonsDisabled}
              type="default"
              onClick={onCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const Equipment: FC<EditableComponentProps> = (
  props: PropsWithChildren<EditableComponentProps>,
) => {
  // const [projectBundleID] = getProjectBundleIDParamFromUrl();

  // const scopingEquipment = HttpService.getHttpRequests(ScopingHttpService);

  // const getData = useCallback(async (): Promise<void> => {
  //   await scopingEquipment.getScopingEquipment(projectBundleID);
  // }, []);

  const isFetching = useSelector(ScopingSelectors.isFetching);

  // useEffect(() => {
  //   getData();
  // }, []);

  if (isFetching) {
    return null;
  }

  return <EquipmentContent {...props} />;
};
