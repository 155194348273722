import {
  JurisdictionDetailsPermitting,
  JurisdictionDetailsZoning,
} from '../interfaces';

export const DEFAULT_JURISDICTION_ZONING_PANEL_FIELDS: Omit<
  JurisdictionDetailsZoning,
  'id' | 'projectType' | 'projectSubType'
> = {
  applicationRequirement: null,
  describeOverallProcess: null,
  estimatedZoningApprovalTimeline: null,
  otherFee: null,
  separationRequirement: null,
  setback: null,
  specialLicensingOrBondingCondition: null,
  thirdPartyCostRequired: null,
  towerHeightRestriction: null,
  isWrittenDocumentationFromJurisdiction: null,
  jurisdictionZoningNote: null,
  zoningRequired: null,
  jurisdictionReviewDate: null,
};

export const DEFAULT_JURISDICTION_PERMITTING_PANEL_FIELDS: Omit<
  JurisdictionDetailsPermitting,
  'id' | 'projectType' | 'projectSubType'
> = {
  permittingRequired: null,
  expeditingOrResubmissionApprovalTimeline: null,
  specialLicensingOrBondingCondition: null,
  isWrittenDocumentationFromJurisdiction: null,
  jurisdictionPermittingNote: null,
  permittingTypes: [],
  jurisdictionReviewDate: null,
};
