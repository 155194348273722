import { Resolver } from '@wellyes/react-router-extended/dist/router/types';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { requestWithStoreWaiting } from '@core/utils/methods';
import store from '@store/index';

export class ProvidenceAgentsResolver implements Resolver {
  async resolve(): Promise<void> {
    await requestWithStoreWaiting(
      () =>
        HttpService.getHttpRequests(ProjectHttpService).getProvidenceAgents(),
      () => store.getState().project,
    );
  }
}
