import React, { FC, PropsWithChildren } from 'react';
import { PaceTaskHttpService } from '@core/services/http';
import { serverValidator } from '@core/utils/validators';
import { Input } from '@shared/modules';
import { TaskInformationProps } from './models/interfaces';

export const TaskInformation: FC<TaskInformationProps> = ({
  id,
}: PropsWithChildren<TaskInformationProps>) => (
  <>
    <Input
      id="paceTaskID"
      label="PACE Task Id"
      formItemProps={{
        rules: [
          serverValidator(
            {
              method: 'paceTaskValidation',
              errorMessage: 'PACE Task Id must be unique',
            },
            PaceTaskHttpService,
            id,
            'paceTaskID',
          ),
        ],
      }}
    />
    <Input
      id="taskName"
      label="Task Name"
      formItemProps={{
        rules: [
          { required: true, message: 'Task Name required' },
          serverValidator(
            {
              method: 'paceTaskValidation',
              errorMessage: 'Task name must be unique',
            },
            PaceTaskHttpService,
            id,
            'taskName',
          ),
        ],
      }}
    />
  </>
);
