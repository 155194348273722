import { FormInstance } from 'antd/lib/form';

export const getFormData = <T>(
  formValue: T,
  form: FormInstance,
  additionalFields?: Record<string, unknown>,
): T => ({
  ...formValue,
  ...form.getFieldsValue(),
  ...(additionalFields ?? {}),
});
