import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { HttpService, ObjectDifferencesService } from '@core/services';
import { PaceTasksBrowseHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { isValidForm, momentizeObjectDates } from '@core/utils/methods';
import { PACE_TASK_BROWSE_FIELDS_FOR_MOMENTIZE } from '@models/constants';
import { IUpdateProjectPACETask } from '@models/interfaces';
import { CustomTable, Panel } from '@shared/components';
import { Autocomplete, PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import { CommonSelectors, PACETasksBrowseSelectors } from '@store/selectors';
import { COLUMNS } from './models/columns.constant';

import './styles.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const onCancel = () => {};
const WRAPPER_COL = { span: 20 };

export const PaceTasksBrowse: FC = () => {
  const paceTasksBrowseHttpService = HttpService.getHttpRequests(
    PaceTasksBrowseHttpService,
  );
  const [filtersForm] = Form.useForm();
  const [projectPACETaskDataForm] = Form.useForm();
  const { projectBundleIDs, FALocationNumbers, siteNumbers } = useSelector(
    PACETasksBrowseSelectors.getProjectBundleIDsOptions,
  );
  const dispatch = useDispatch();
  const hasUnsubmittedData = useSelector(CommonSelectors.getHasUnsubmittedData);

  const data = useSelector(PACETasksBrowseSelectors.getProjectPACETasks);

  const [stateData, setData] = useState(data);
  const [stateProjectId, setProjectId] = useState<string>();
  const [stateSiteNumber, setSiteNumber] = useState<string>();
  const [stateFALocationNumber, setFALocationNumber] = useState<string>();
  const [isPaceTaskDisabled, setIsPaceTaskDisabled] = useState<boolean>(false);

  const isFetching = useSelector(PACETasksBrowseSelectors.isFetching);

  useEffect(() => {
    if (projectBundleIDs.length === 1) {
      filtersForm.setFieldsValue({ projectId: projectBundleIDs[0].value });
      setProjectId(projectBundleIDs[0].value as string);
    }

    if (!stateProjectId) {
      setIsPaceTaskDisabled(true);
    } else {
      setIsPaceTaskDisabled(false);
    }
  }, [projectBundleIDs, stateProjectId]);

  const onValuesProjectPACETasksDataFormChange = useCallback(
    (_, values) => {
      const diff = ObjectDifferencesService.getArraysDiff(
        data,
        Object.values(values),
      );
      const isChanged = !!diff.length;

      if (isChanged !== hasUnsubmittedData) {
        dispatch(CommonActions.setHasUnsubmittedData.done(isChanged));
      }
    },
    [hasUnsubmittedData, data],
  );

  useEffect(() => {
    projectPACETaskDataForm.resetFields();
    projectPACETaskDataForm.setFieldsValue(data);
    dispatch(CommonActions.setHasUnsubmittedData.done(false));
    setData(data);
  }, [data]);

  useDidUpdateEffect(() => {
    paceTasksBrowseHttpService.getPaceTasksBrowseFilterOptions({
      projectId: stateProjectId,
      siteNumber: stateSiteNumber,
      FALocationNumber: stateFALocationNumber,
    });

    if (stateProjectId || stateSiteNumber || stateFALocationNumber) {
      paceTasksBrowseHttpService.getProjectPACETasks({
        projectId: stateProjectId,
        siteNumber: stateSiteNumber,
        FALocationNumber: stateFALocationNumber,
      });
    } else {
      setData([]);
    }
  }, [stateProjectId, stateSiteNumber, stateFALocationNumber]);

  const onSubmit = useCallback(async () => {
    try {
      if (await isValidForm(projectPACETaskDataForm)) {
        const projectId = filtersForm.getFieldValue('projectId');
        const diff: IUpdateProjectPACETask[] =
          ObjectDifferencesService.getArraysDiff(
            data,
            Object.values(projectPACETaskDataForm.getFieldsValue()),
            ['id'],
          ).map(
            ({ id, ...otherValues }): IUpdateProjectPACETask => ({
              paceTaskId: id,
              ...momentizeObjectDates(
                otherValues,
                PACE_TASK_BROWSE_FIELDS_FOR_MOMENTIZE,
                true,
              ),
            }),
          );

        await paceTasksBrowseHttpService.updateProjectPACETasks(
          projectId,
          diff,
        );
      }
    } catch (e) {
      console.error(e);
    }
  }, [data]);

  useSaveChanged(true, onSubmit, onCancel);

  return (
    <Panel
      header="PACE Tasks Browse"
      collapseClassName="prov-admin-pace-tasks-browse"
    >
      <Row>
        <Col span={12}>
          <Form form={filtersForm} wrapperCol={WRAPPER_COL}>
            <Row className="prov-default-tower-agent__table-handler">
              <Col span="7">
                <Autocomplete
                  options={projectBundleIDs}
                  id="projectId"
                  label="Project Bundle ID"
                  elementProps={{
                    allowClear: true,
                    onChange: (value: SelectValue): void => {
                      setProjectId(value as string);
                    },
                  }}
                />
              </Col>
              <Col span="7">
                <Autocomplete
                  options={siteNumbers}
                  id="siteNumber"
                  label="Site Number"
                  elementProps={{
                    allowClear: true,
                    onChange: (siteNumber: SelectValue): void => {
                      setSiteNumber(siteNumber as string);
                    },
                  }}
                />
              </Col>
              <Col span="7">
                <Autocomplete
                  options={FALocationNumbers}
                  id="FALocationNumber"
                  label="FA Location Number"
                  elementProps={{
                    allowClear: true,
                    onChange: (FALocationNumber: SelectValue): void => {
                      setFALocationNumber(FALocationNumber as string);
                    },
                  }}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Form
        form={projectPACETaskDataForm}
        onValuesChange={onValuesProjectPACETasksDataFormChange}
      >
        <CustomTable
          dataSource={stateData}
          pagination={false}
          loading={isFetching}
          columns={COLUMNS(isPaceTaskDisabled)}
        />
        <PrimaryButton
          title="Submit"
          onClick={onSubmit}
          className="prov-admin-pace-tasks-browse__submit"
          disabled={!hasUnsubmittedData}
        />
      </Form>
    </Panel>
  );
};
