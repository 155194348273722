import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  AdminDistributionListResponse,
  AdminVendorDistributionListData,
  AdminVendorsData,
} from '@models/interfaces';
import { ValidationParams } from '@models/types';
import { VendorsActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class VendorHttpService extends CommonHttpService {
  @StoredRequest(VendorsActions.getVendorsAction)
  getVendors(): Promise<AdminVendorsData[]> {
    return this.http.callApi.get(`${this.apiUrl}/admin/vendors`);
  }

  @StoredRequest(VendorsActions.addVendorAction)
  async addVendor(
    body: Omit<AdminVendorsData, 'id'>,
  ): Promise<AdminVendorsData> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/admin/vendors`,
      body,
    );

    return { id, ...body };
  }

  @StoredRequest(VendorsActions.updateVendorAction)
  async editVendor({
    id,
    ...body
  }: AdminVendorsData): Promise<AdminVendorsData> {
    await this.http.callApi.patch(`${this.apiUrl}/admin/vendors`, body, {
      params: { vendorId: id },
    });

    return { id, ...body };
  }

  @StoredRequest(VendorsActions.deleteVendorAction)
  async deleteVendor(vendorId: string): Promise<string> {
    await this.http.callApi.delete(`${this.apiUrl}/admin/vendors`, null, {
      params: { vendorId },
    });

    return vendorId;
  }

  getVendorValidation(
    { id, field }: ValidationParams,
    value: string,
  ): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/vendors/validation`,
      null,
      {
        params: { id, field, value },
      },
    );
  }

  // VENDOR DISTRIBUTION LISTS
  @StoredRequest(VendorsActions.getVendorListsAction)
  getVendorDistributionLists(): Promise<AdminVendorDistributionListData[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/vendor-distribution-lists`,
    );
  }

  @StoredRequest(VendorsActions.addVendorListAction)
  async addVendorList(
    body: Pick<AdminVendorDistributionListData, 'name'>,
  ): Promise<AdminDistributionListResponse> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/admin/vendor-distribution-lists`,
      body,
    );

    return {
      id,
      name: body.name,
    };
  }

  @StoredRequest(VendorsActions.updateVendorListAction)
  async editVendorList({
    id,
    ...body
  }: Omit<AdminVendorsData, 'email'>): Promise<
    Omit<AdminVendorsData, 'email'>
  > {
    await this.http.callApi.patch(
      `${this.apiUrl}/admin/vendor-distribution-lists`,
      body,
      {
        params: { vendorListId: id },
      },
    );

    return { id, ...body };
  }

  @StoredRequest(VendorsActions.deleteVendorListAction)
  async deleteVendorList(vendorListId: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/vendor-distribution-lists`,
      null,
      {
        params: { vendorListId },
      },
    );

    return vendorListId;
  }

  getVendorListValidation(id: string, name: string): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/vendor-distribution-lists/validation`,
      null,
      {
        params: { id, name },
      },
    );
  }

  @StoredRequest(VendorsActions.assignManagementAction)
  updateVendorListAssignments({
    vendorId,
    vendorListId,
    method,
  }: {
    vendorId: string;
    vendorListId: string;
    method: string;
  }): Promise<AdminVendorsData> {
    return this.http.callApi.patch(
      `${this.apiUrl}/admin/vendor-distribution-lists/assign-management`,
      null,
      {
        params: { vendorId, vendorListId, method },
      },
    );
  }
}
