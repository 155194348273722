import React, {
  ChangeEvent,
  FC,
  PropsWithChildren,
  ReactElement,
  useState,
} from 'react';
import { Button } from 'antd';
import RemoveIcon from 'assets/icons/delete-icon.svg';
import DocIcon from 'assets/icons/doc.png';
import UploadIcon from 'assets/icons/upload-icon.svg';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { PrimaryButton } from '@shared/modules';
import { SOFToolUploadButtonProps } from './models';

import './styles.scss';

export const SOFUploadButton: FC<SOFToolUploadButtonProps> = ({
  updateAttachments,
  isDisabled,
}: PropsWithChildren<SOFToolUploadButtonProps>) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<File[]>([]);
  const [inputValue, setInputValue] = useState<string>('');

  useDidUpdateEffect(() => {
    updateAttachments(files);
  }, [files]);

  const addAttachment = (): void => {
    hiddenFileInput.current?.click();
  };

  const removeAttachment = (file: File): void => {
    const updatedFiles = files.filter(item => item !== file);

    setFiles(updatedFiles);
    setInputValue('');
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);

      setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
    }
  };

  const UploadIconElement = (): ReactElement => (
    <img src={UploadIcon} alt="upload icon" />
  );

  return (
    <div className="prov-sof-tool-upload-button">
      <div>
        <PrimaryButton
          title="Add attachment"
          icon={<UploadIconElement />}
          onClick={addAttachment}
          disabled={isDisabled}
        />
        <input
          type="file"
          ref={hiddenFileInput}
          value={inputValue}
          onChange={handleChange}
          multiple
          hidden
        />
      </div>

      {files.length > 0 && (
        <div className="prov-sof-tool-upload-button__files">
          {files.map(file => (
            <div
              key={file.name}
              className="prov-sof-tool-upload-button__file-info"
            >
              <img src={DocIcon} alt="doc icon" className="document-icon" />
              <span className="file-name">{file.name}</span>
              <Button
                onClick={() => removeAttachment(file)}
                disabled={isDisabled}
              >
                <img src={RemoveIcon} alt="remove icon" />
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
