import React, { FC, PropsWithChildren } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Details } from '@shared/modules/details';

import './styles.scss';

export const JurisdictionDetails: FC<RouteComponentProps> = (
  props: PropsWithChildren<RouteComponentProps>,
) => (
  <Details
    {...props}
    className="prov-permitting-jurisdiction-details"
    headerBar={false}
    destroyInactiveTabPane
    category
  />
);
