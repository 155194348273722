import { Undefinable } from '@symfa-inc/providence-types';
import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  ProjectCategoryHttpService,
  ProjectHttpService,
  SiteHttpService,
} from '@core/services/http';
import {
  getFALocationParamFromUrl,
  requestWithStoreWaiting,
} from '@core/utils/methods';
import store from '@store/index';
import { AppState } from '@store/reducers';

export class ProjectCreateResolver implements Resolver {
  private static get _state(): AppState {
    return store.getState();
  }

  private static get _isProjectCategoriesExist(): boolean {
    return !!Object.keys(
      ProjectCreateResolver._state.admin.projectCategory.projectCategories,
    ).length;
  }

  private static get _siteId(): Undefinable<string> {
    try {
      const [FALocationNumberFromUrl] = getFALocationParamFromUrl();

      return ProjectCreateResolver._state.site.FALocationNumberOptions.find(
        ({ viewValue }) => viewValue.toString() === FALocationNumberFromUrl,
      )?.value as string;
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      SiteHttpService,
    ).getFALocationNumberOptions();

    const siteId = ProjectCreateResolver._siteId;

    if (siteId) {
      await HttpService.getHttpRequests(ProjectHttpService).getProvidenceAgent(
        siteId,
      );
    }

    if (!ProjectCreateResolver._isProjectCategoriesExist) {
      await requestWithStoreWaiting(
        () =>
          HttpService.getHttpRequests(
            ProjectCategoryHttpService,
          ).getProjectCategories(),
        () => ProjectCreateResolver._state.admin.projectCategory,
      );
    }
  }
}
