import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { SiteHttpService } from '@core/services/http';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';

export class SiteBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(SiteHttpService).getSiteBrowse(
      BROWSE_DEFAULT_FILTER_VALUE,
    );
  }
}
