import { ReactNode } from 'react';
import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-types';
import { ColumnsType } from 'antd/lib/table';
import {
  getJurisdictionByIdOrUndefined,
  projectCategoryRender,
  projectPaceTaskDataRender,
  providenceAgentRender,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import { AdminDropdownValuesData, SiteBrowse, User } from '@models/interfaces';
import { Browse } from '@models/types';

export const PERMITTING_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.alternateSiteInfo,
  },
  {
    title: 'City',
    dataIndex: 'site',
    key: 'city',
    sorter: true,
    width: 100,
    render: (site: SiteBrowse): ReactNode => site?.city,
  },
  {
    title: 'County',
    dataIndex: 'site',
    key: 'county',
    sorter: true,
    width: 90,
    render: (site: SiteBrowse): ReactNode => site?.county?.name,
  },
  {
    title: 'FA Location Number',
    dataIndex: 'site',
    key: 'FALocationNumber',
    width: 190,
    sorter: true,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.FALocationNumber),
  },
  {
    title: 'Jurisdiction',
    dataIndex: 'site',
    key: 'jurisdiction',
    sorter: true,
    width: 165,
    render: (site: SiteBrowse): ReactNode =>
      getJurisdictionByIdOrUndefined(site?.jurisdiction?.id)?.jurisdictionName,
  },
  {
    title: 'Legacy Site ID',
    width: 150,
    key: 'legacySiteID',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.legacySiteID,
  },
  {
    title: 'Oracle Project',
    dataIndex: 'categories',
    key: 'oracleProjectNumber',
    width: 150,
    sorter: true,
    render: projectCategoryRender({ field: 'oracleProjectNumber' }),
  },
  {
    title: 'PACE Project',
    dataIndex: 'categories',
    key: 'PACEProjectNumber',
    width: 150,
    sorter: true,
    render: projectCategoryRender({ field: 'PACEProjectNumber' }),
  },
  {
    title: 'IWM Job Number',
    dataIndex: 'categories',
    key: 'IWMJobNumber',
    width: 170,
    sorter: true,
    render: projectCategoryRender({ field: 'IWMJobNumber' }),
  },
  {
    title: 'Project Category',
    dataIndex: 'categories',
    key: 'projectCategory',
    width: 200,
    sorter: true,
    render: projectCategoryRender({ field: 'projectCategory' }),
  },
  {
    title: 'Project Type',
    key: 'projectType',
    dataIndex: 'projectType',
    sorter: true,
    width: 165,
    render: (projectType?: AdminDropdownValuesData): ReactNode =>
      projectType?.value,
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgent',
    render: providenceAgentRender,
  },
  {
    title: 'RE005 Zoning Submitted - Actual',
    key: 'RE005ZoningSubmittedActual',
    dataIndex: 'projectPaceTaskData',
    sorter: true,
    width: 260,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.zoningSubmitted,
      field: 'actualDate',
    }),
  },
  {
    title: 'RE005 Zoning Submitted - Forecasted',
    key: 'RE005ZoningSubmittedForecasted',
    dataIndex: 'projectPaceTaskData',
    sorter: true,
    width: 290,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.zoningSubmitted,
      field: 'forecastedDate',
    }),
  },
  {
    title: 'RE020 Site Acq Complete - Forecasted',
    key: 'RE020SiteAcqCompleteForecasted',
    dataIndex: 'projectPaceTaskData',
    sorter: true,
    width: 290,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.siteAcqComplete,
      field: 'forecastedDate',
    }),
  },
  {
    title: 'Site',
    dataIndex: 'site',
    key: 'siteNumber',
    sorter: true,
    width: 100,
    render: (site: SiteBrowse): ReactNode => site?.siteNumber,
  },
  {
    title: 'State',
    dataIndex: 'site',
    key: 'state',
    sorter: true,
    width: 90,
    render: (site: SiteBrowse): ReactNode => site?.state?.name,
  },
  {
    title: 'Street Address',
    dataIndex: 'site',
    key: 'streetAddress',
    width: 170,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.streetAddress,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.submarket?.value,
  },
  {
    title: 'Tower Owner',
    dataIndex: 'site',
    key: 'towerOwner',
    width: 140,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.towerOwner?.value,
  },
  {
    title: 'USID',
    width: 150,
    key: 'USID',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.USID,
  },
  {
    title: 'Zip',
    dataIndex: 'site',
    key: 'zip',
    sorter: true,
    width: 90,
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.zip),
  },
  {
    title: 'Permitting Agent',
    dataIndex: ['site', 'jurisdiction', 'permitting', 0, 'permittingAgent'],
    key: 'permittingAgent',
    width: 140,
    sorter: true,
    render: (permittingAgent: User): ReactNode =>
      permittingAgent &&
      `${permittingAgent.firstName} ${permittingAgent.lastName}`,
  },
];
