import { browseReducer } from '@core/utils/methods/browse-sorter-reducer';
import { createReducer, on } from '@core/utils/store';
import {
  PaginatedResponse,
  PayloadAndState,
  SOFToolBrowse,
  SofToolBrowseFilterOptionsResponse,
  SOFToolDetails,
} from '@models/interfaces';
import { SOFToolActions } from '@store/actions';

export interface EngineeringSOFToolState {
  SOFToolBrowse: PaginatedResponse<SOFToolBrowse>;
  filtersOptions: SofToolBrowseFilterOptionsResponse | null;
  details: SOFToolDetails;
  previewSrc: string;
  isFetching: boolean;
  errors: boolean;
}

const initialState: EngineeringSOFToolState = {
  SOFToolBrowse: { items: [], total: 0 },
  filtersOptions: null,
  details: {} as SOFToolDetails,
  previewSrc: '',
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  // get data
  on(SOFToolActions.getSOFToolDataAction, browseReducer('SOFToolBrowse')),
  // get filters options
  on(
    SOFToolActions.getSOFToolBrowseFilters,
    ({
      payload: filtersOptions,
    }: PayloadAndState<
      SofToolBrowseFilterOptionsResponse,
      EngineeringSOFToolState
    >) => ({ filtersOptions }),
  ),
  // get details data
  on(
    SOFToolActions.getSOFToolDetails,
    ({
      payload: details,
    }: PayloadAndState<SOFToolDetails, EngineeringSOFToolState>) => ({
      details,
    }),
  ),
  // set preview pdf src
  on(
    SOFToolActions.setSOFToolPreviewSrcPDF,
    ({
      payload: previewSrc,
    }: PayloadAndState<string, EngineeringSOFToolState>) => ({ previewSrc }),
  ),
  // set preview pdf src
  on(
    SOFToolActions.sendSOFToolPDF,
    ({ state }: PayloadAndState<void, EngineeringSOFToolState>) => ({
      ...state,
    }),
  ),
);
