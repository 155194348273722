import { Undefinable } from '@symfa-inc/providence-types';
import { AdminCountyData } from '@models/interfaces';
import store from '@store/index';
import { AppState } from '@store/reducers';

export const getStateById = (id: string): Undefinable<AdminCountyData> => {
  const state: AppState = store.getState();
  const { states } = state.admin.countyValues;

  return states?.find((stateItem: AdminCountyData) => stateItem.id === id);
};

export const getStateByIdOrUndefined = (
  id?: string | null,
): Undefinable<AdminCountyData> => (id ? getStateById(id) : undefined);
