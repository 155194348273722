import { RuleObject } from 'rc-field-form/es/interface';

export const DECIMAL_VALIDATOR = [
  {
    validator: (_: RuleObject, inputValue: string): Promise<void> =>
      inputValue && !/^\d{1,3}(\.\d{1,2})?$/.test(inputValue)
        ? Promise.reject(
            new Error(
              'Invalid number format! The value can be up to ###.##% format.',
            ),
          )
        : Promise.resolve(),
  },
];
