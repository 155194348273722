import { RuleObject, StoreValue } from 'rc-field-form/es/interface';
import { dateWorker } from '@core/utils/date-worker';
import { RangePickerValues } from '@models/interfaces';

export const rangePickerValidator: RuleObject = {
  validator: (_: StoreValue, value?: RangePickerValues) =>
    value?.from && value?.to && dateWorker(value?.to).isBefore(value?.from)
      ? Promise.reject(new Error('Start Date must be before End Date!'))
      : Promise.resolve(),
};
