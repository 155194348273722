import React, { FC } from 'react';
import Img404 from 'assets/images/img-404.png';

import './styles.scss';

export const NotFound: FC = () => (
  <div className="prov-not-found-wrapper">
    <img src={Img404} alt="not found logo" />
    <span className="prov-not-found-wrapper__title">OOPS!</span>
    <span className="prov-not-found-wrapper__subtitle">
      The page you requested could not be found
    </span>
  </div>
);
