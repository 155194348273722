import React, { FC, PropsWithChildren } from 'react';
import { MenuFoldOutlined, MenuOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { MenuIconProps } from './models/interfaces';

export const MenuIcon: FC<MenuIconProps> = ({
  isOpened,
  onChange,
}: PropsWithChildren<MenuIconProps>) => {
  const icon = React.createElement(isOpened ? MenuFoldOutlined : MenuOutlined, {
    className: 'prov-layout__trigger',
    onClick: onChange,
  });

  return (
    <div
      className={classNames('prov-layout__sider-logo', {
        logo_open: isOpened,
        logo_close: !isOpened,
      })}
    >
      {icon}
    </div>
  );
};
