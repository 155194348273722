import {
  ProceccedSiteAuditRaycapsData,
  SiteAuditCablesData,
} from '@models/interfaces';

export const SITE_AUDIT_CABLES_DEFAULT_VALUE: SiteAuditCablesData = {
  id: undefined,
  equipment: {
    id: '',
    modelNumber: '',
    length: 0,
    width: 0,
    depth: 0,
    weight: 0,
    description: '',
  },
  quantity: 0,
};

export const SITE_AUDIT_RAYCAPS_DEFAULT_VALUE: ProceccedSiteAuditRaycapsData = {
  id: undefined,
  equipment: {
    id: '',
    modelNumber: '',
    length: 0,
    width: 0,
    depth: 0,
    weight: 0,
    description: '',
  },
  quantity: 0,
  projectCategories: [],
};
