import {
  EngineeringRegulatoryCompliance,
  EngineeringRFDSPhase1,
} from '@models/interfaces';

export const RFDS_INFO_DATES: Array<keyof EngineeringRFDSPhase1> = [
  'prelimRFDSReceivedDate',
  'revisedRFDSReceivedDate',
  'prelimRFDSPromotedDate',
  'finalRFDSReceived',
  'finalRFDSPromotedToCXDate',
  'successfulOfPromotionDate',
  'CI004',
  'CSSDataCompleteDate',
  'phase1OrderedDate',
  'phase1CompleteDate',
  'phase1UploadedDate',
];

export const REGULATORY_COMPLIANCE_INFO_DATES: Array<
  keyof EngineeringRegulatoryCompliance
> = [
  'SOWSubmitDate',
  'SOWRejectedDate',
  'CNTPReceivedDate',
  'FAAFileDate',
  'escalateDate',
  'determinationDate',
  'EHSSubmittedDate',
  'EHSApprovedDate',
  'RFSSROrdered',
  'RFSSRReceived',
  'PALOrNPAReceived',
];
