import { arraySort } from '@core/utils/methods';
import { createReducer, on } from '@core/utils/store/default-reducer';
import { PaceTaskTabScreens } from '@models/enums';
import {
  PaceTask,
  PaceTaskScreen,
  PaceTaskScreenResponse,
  PayloadAndState,
} from '@models/interfaces';
import { PaceTaskActions } from '@store/actions';

export type PaceTaskState = {
  [paceTaskKey in PaceTaskTabScreens]: PaceTaskScreen[];
} & {
  archive: PaceTaskScreen[];
  excluded: Omit<PaceTask, 'screens' | 'permissions'>[];
  allPaceTasks: PaceTask[];
  isFetching: boolean;
  errors: boolean;
};

const initialState: PaceTaskState = {
  archive: [],
  excluded: [],
  'Project Management': [],
  'Pace Tasks Browse': [],
  Engineering: [],
  Forecasting: [],
  Leasing: [],
  Permitting: [],
  Project: [],
  allPaceTasks: [],
  isFetching: false,
  errors: false,
};
const rewriteScreenPaceTasks = ({
  paceTasks,
  screen,
}: PaceTaskScreenResponse): Partial<PaceTaskState> => ({
  [screen]: [...paceTasks],
});

export const reducer = createReducer(
  initialState,
  // Get All Pace Tasks
  on(
    PaceTaskActions.getAllPaceTasks,
    ({
      payload: allPaceTasks,
    }: PayloadAndState<PaceTask[], PaceTaskState>) => ({
      allPaceTasks,
    }),
  ),
  // Add pace task on All Pace Task screen
  on(
    PaceTaskActions.addPaceTask,
    ({
      payload: paceTask,
      state: { allPaceTasks },
    }: PayloadAndState<PaceTask, PaceTaskState>) => ({
      allPaceTasks: arraySort([...allPaceTasks, paceTask], 'ASC', 'taskName'),
    }),
  ),
  // Update pace task on All Pace Task screen
  on(
    PaceTaskActions.updatePaceTask,
    ({
      payload: paceTask,
      state: { allPaceTasks },
    }: PayloadAndState<PaceTask, PaceTaskState>) => {
      const paceTasks = [...allPaceTasks];
      const paceTaskIndex: number = paceTasks.findIndex(
        (p: PaceTask): boolean => p.id === paceTask.id,
      );

      paceTasks.splice(paceTaskIndex, 1, paceTask);

      return {
        allPaceTasks: paceTasks,
      };
    },
  ),
  // Update pace tasks permissions
  on(
    PaceTaskActions.updatePermissions,
    ({
      payload: updatedPaceTasks,
      state: { allPaceTasks },
    }: PayloadAndState<PaceTask[], PaceTaskState>) => ({
      allPaceTasks: allPaceTasks.map((paceTask: PaceTask): PaceTask => {
        const updatedPaceTask = updatedPaceTasks.find(
          (p: PaceTask) => p.id === paceTask.id,
        );

        return updatedPaceTask ?? paceTask;
      }),
    }),
  ),
  // Get Pace Task screen for tab
  on(
    PaceTaskActions.getScreenPaceTasks,
    ({ payload }: PayloadAndState<PaceTaskScreenResponse, PaceTaskState>) =>
      rewriteScreenPaceTasks(payload),
  ),
  // Move to Archive Pace Task on tab
  on(
    PaceTaskActions.moveToArchive,
    ({ payload }: PayloadAndState<PaceTaskScreenResponse, PaceTaskState>) =>
      rewriteScreenPaceTasks(payload),
  ),
  // Get From Archive Pace Task on tab
  on(
    PaceTaskActions.moveFromArchive,
    ({ payload }: PayloadAndState<PaceTaskScreenResponse, PaceTaskState>) =>
      rewriteScreenPaceTasks(payload),
  ),
  // Add pace task screen
  on(
    PaceTaskActions.addPaceTaskScreen,
    ({ payload }: PayloadAndState<PaceTaskScreenResponse, PaceTaskState>) =>
      rewriteScreenPaceTasks(payload),
  ),
  // Update pace task screen
  on(
    PaceTaskActions.updatePaceTaskScreen,
    ({ payload }: PayloadAndState<PaceTaskScreenResponse, PaceTaskState>) =>
      rewriteScreenPaceTasks(payload),
  ),
  // Delete Pace Task from tab
  on(
    PaceTaskActions.deletePaceTask,
    ({
      payload: id,
      state: { allPaceTasks },
    }: PayloadAndState<string, PaceTaskState>) => ({
      allPaceTasks: allPaceTasks.filter(
        (paceTask: PaceTask): boolean => id !== paceTask.id,
      ),
    }),
  ),
  // Get Pace Task archive for modal
  on(
    PaceTaskActions.getArchivedPaceTasks,
    ({
      payload: { paceTasks: archive },
    }: PayloadAndState<PaceTaskScreenResponse, PaceTaskState>) => ({
      archive,
    }),
  ),
  // Get Pace Tasks for add modal
  on(
    PaceTaskActions.getExcludedPaceTasks,
    ({
      payload: excluded,
    }: PayloadAndState<
      Omit<PaceTask, 'screens' | 'permissions'>[],
      PaceTaskState
    >) => ({
      excluded,
    }),
  ),
);
