import { Rule } from 'antd/es/form';
import { RuleObject } from 'rc-field-form/lib/interface';

export const INPUT_NUMBER_VALIDATION_RULES: Rule[] = [
  {
    validator: (_: RuleObject, inputValue: number): Promise<void> =>
      inputValue >= 0
        ? Promise.resolve()
        : Promise.reject(
            new Error('Should be a number, that equal or greater than 0'),
          ),
  },
];
