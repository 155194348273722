import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { HttpService } from '@core/services';
import { EquipmentHttpService } from '@core/services/http';
import {
  arraySort,
  arrsToSelectableObject,
  isValidForm,
} from '@core/utils/methods';
import { ModalMainTypes, SectorEquipmentType } from '@models/enums';
import {
  EquipmentFullInfo,
  EquipmentTypeData,
  OptionProps,
  SectorEquipment,
  SectorEquipmentType as SectorEquipmentTypeInterface,
} from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { Autocomplete, InputNumber } from '@shared/modules';
import { EquipmentSelectors } from '@store/selectors';
import { EMPTY_POSITIONS, POSITIONS } from './models/constants';
import { AddEquipmentModalProps } from './models/types';

import './styles.scss';

const { useForm } = Form;

export const AddEquipmentModal: FC<AddEquipmentModalProps> = ({
  visible,
  toggleModal,
  setSectorsDataVersions,
  data,
  type,
}: PropsWithChildren<AddEquipmentModalProps>) => {
  const [form] = useForm();

  const { equipmentTypes, currentEquipments } = useSelector(
    EquipmentSelectors.getEquipmentTypesWithCurrentEquipments,
  );
  const sectorsEquipmentsByTypes = useSelector(
    EquipmentSelectors.getSectorsEquipmentsByTypes,
  );

  const [equipmentTypesOptions, setEquipmentOptions] = useState<OptionProps[]>(
    [],
  );
  const [modelNumberOptions, setModelNumberOptions] = useState<OptionProps[]>(
    [],
  );
  const [equipmentTypeValue, setEquipmentTypeValue] = useState<SelectValue>();

  useEffect(() => {
    const alreadyUsedEquipmentTypesIds = data.map(
      (sectorEquipmentType: SectorEquipmentTypeInterface) =>
        sectorEquipmentType.equipmentType,
    );

    setEquipmentOptions(
      arrsToSelectableObject<EquipmentTypeData>(
        equipmentTypes,
        'id',
        'name',
      ).filter(
        (option: OptionProps) =>
          !alreadyUsedEquipmentTypesIds.includes(option.value.toString()),
      ),
    );
  }, [equipmentTypes, data]);

  useEffect(() => {
    setModelNumberOptions(
      arrsToSelectableObject<EquipmentFullInfo>(
        currentEquipments,
        'id',
        'modelNumber',
      ),
    );
  }, [currentEquipments]);

  useEffect(() => {
    if (equipmentTypesOptions.length && equipmentTypeValue) {
      HttpService.getHttpRequests(EquipmentHttpService).getEquipmentsByTypes([
        equipmentTypesOptions.find(
          (option: OptionProps) => option.value === equipmentTypeValue,
        )!.viewValue as string,
      ]);
    }
  }, [equipmentTypeValue, equipmentTypesOptions]);

  const add = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const { equipmentType, equipment, position, quantity } =
        form.getFieldsValue();
      const currentModelNumber = modelNumberOptions.find(
        (model: OptionProps) => model.value === equipment,
      )?.viewValue;
      const manufacturer = sectorsEquipmentsByTypes[equipmentType].find(
        (sectorsEquipment: EquipmentFullInfo) =>
          sectorsEquipment.modelNumber === currentModelNumber,
      )?.manufacturer.id;

      setSectorsDataVersions(versions =>
        versions.map(version => {
          const { scopingSectors } = version;

          return version.isSelected
            ? {
                ...version,
                scopingSectors: {
                  ...scopingSectors,
                  [SectorEquipmentType[type]]: arraySort(
                    [
                      ...scopingSectors[type]!,
                      {
                        equipmentType,
                        equipments: [
                          ...(scopingSectors[type]!.find(
                            (
                              sectorEquipmentType: SectorEquipmentTypeInterface,
                            ) =>
                              sectorEquipmentType?.equipmentType ===
                              equipmentType,
                          )?.equipments || []),
                          ...EMPTY_POSITIONS.filter(
                            (sectorEquipment: SectorEquipment) =>
                              sectorEquipment.position !== position,
                          ),
                          {
                            equipment,
                            position,
                            quantity,
                            manufacturer,
                          },
                        ],
                      },
                    ],
                    'ASC',
                    'equipmentType',
                  ),
                },
              }
            : version;
        }),
      );

      setEquipmentTypeValue(undefined);

      toggleModal();
    }
  };

  return (
    <AddOrEditModal
      visible={visible}
      type={ModalMainTypes.Add}
      okText="Add"
      className="prov-add-equipment-modal"
      formProps={{
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
        form,
        initialValues: {
          quantity: 1,
        },
      }}
      title="Equipment"
      onCancel={(): void => {
        form.resetFields();
        toggleModal();
      }}
      onOk={add}
    >
      <Autocomplete
        id="equipmentType"
        label="Add Equipment"
        options={equipmentTypesOptions}
        formItemProps={{
          rules: [{ required: true, message: 'Equipment Type is required!' }],
        }}
        elementProps={{
          onChange: (value: SelectValue): void => {
            setEquipmentTypeValue(value);
            form.setFieldsValue({ equipment: undefined });
          },
        }}
      />
      <Autocomplete
        id="position"
        label="Position"
        options={POSITIONS}
        formItemProps={{
          rules: [{ required: true, message: 'Position is required!' }],
        }}
      />
      <Autocomplete
        id="equipment"
        label="Equipment Code"
        options={modelNumberOptions}
        formItemProps={{
          rules: [{ required: true, message: 'Model Number is required!' }],
        }}
      />
      <InputNumber id="quantity" label="Quantity" elementProps={{ min: 1 }} />
    </AddOrEditModal>
  );
};
