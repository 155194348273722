import { JurisdictionDetailsContacts } from '@models/interfaces';

export const PERMITTING_CONTACTS_DEFAULT_VALUE: JurisdictionDetailsContacts = {
  id: '',
  contactType: '',
  projectType: '',
  contactName: '',
  contactAddress: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  fax: '',
  email: '',
  contactNote: '',
};
