import React, { FC, PropsWithChildren } from 'react';
import { Card } from 'antd';
import { useClickOutsideListenerRef } from '@core/utils/hooks';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { ConfirmationPopupProps } from './models';

import './styles.scss';

export const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
  onOk,
  onCancel,
  text,
}: PropsWithChildren<ConfirmationPopupProps>) => {
  const ref = useClickOutsideListenerRef(onCancel);

  return (
    <div ref={ref}>
      <Card bordered={false} className="prov-confirmation-popup">
        <span className="prov-confirmation-popup__question">{text}</span>
        <div className="prov-confirmation-popup__footer">
          <div className="prov-confirmation-popup__footer-btns">
            <PrimaryButton title="Yes" onClick={onOk} />
            <PrimaryButton title="No" onClick={onCancel} type="default" />
          </div>
        </div>
      </Card>
    </div>
  );
};
