import { OptionProps } from '@models/interfaces';

export const stringEnumToSelectableObject = <T extends Record<string, any>>(
  someEnum: T,
): Array<OptionProps> => {
  const result = [];

  for (const [key, value] of Object.entries(someEnum)) {
    if (Number.isNaN(+key)) {
      result.push({ value, viewValue: value });
    }
  }

  return result;
};
