import { ProjectType } from '@symfa-inc/providence-types';
import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { AccountingHttpService } from '@core/services/http';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';

export class AccountingBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await Promise.all([
      HttpService.getHttpRequests(AccountingHttpService).getAccountingBrowse({
        ...BROWSE_DEFAULT_FILTER_VALUE,
        showCompletedProjects: false,
        projectTypeMode: ProjectType.LTE,
      }),

      HttpService.getHttpRequests(
        AccountingHttpService,
      ).getAccountingBrowseFiltersOptions(),
    ]);
  }
}
