import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration, { Duration } from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import {
  DateWorker,
  DateWorkerFormatSpecification,
  DateWorkerInput,
  UnitOfTime,
} from './models';

dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

export class DayjsAdapter implements DateWorker {
  static isDateWorker(m: any): m is DayjsAdapter {
    return m instanceof DayjsAdapter;
  }

  static duration(diff: number): Duration {
    return dayjs.duration(diff);
  }

  private static _asDayjs(item: DateWorkerInput): Dayjs {
    return DayjsAdapter.isDateWorker(item)
      ? item._dayjs
      : (item as unknown as Dayjs);
  }

  private readonly _dayjs: Dayjs;

  constructor(
    inp?: DateWorkerInput,
    format?: DateWorkerFormatSpecification,
    language?: string,
    strict?: boolean,
  ) {
    this._dayjs = dayjs(DayjsAdapter._asDayjs(inp), format, language, strict);
  }

  endOf(unitOfTime: UnitOfTime.StartOf): DateWorker {
    this._dayjs.endOf(unitOfTime);

    return this;
  }

  format(format?: string): string {
    return this._dayjs.format(format);
  }

  isSame(inp?: DateWorkerInput, granularity?: UnitOfTime.StartOf): boolean {
    return this._dayjs.isSame(DayjsAdapter._asDayjs(inp), granularity);
  }

  isBefore(inp?: DateWorkerInput, granularity?: UnitOfTime.Base): boolean {
    return this._dayjs.isBefore(DayjsAdapter._asDayjs(inp), granularity);
  }

  startOf(unitOfTime: UnitOfTime.StartOf): DayjsAdapter {
    this._dayjs.endOf(unitOfTime);

    return this;
  }

  isValid(): boolean {
    return this._dayjs.isValid();
  }

  diff(
    b: DateWorkerInput,
    unitOfTime?: UnitOfTime.Diff,
    precise?: boolean,
  ): number {
    return this._dayjs.diff(DayjsAdapter._asDayjs(b), unitOfTime, precise);
  }

  isBetween(
    a: DateWorkerInput,
    b: DateWorkerInput,
    granularity?: UnitOfTime.StartOf,
    inclusivity?: '()' | '[)' | '(]' | '[]',
  ): boolean {
    return this._dayjs.isBetween(
      DayjsAdapter._asDayjs(a),
      DayjsAdapter._asDayjs(b),
      granularity,
      inclusivity,
    );
  }

  local(keepLocalTime?: boolean): DateWorker {
    this._dayjs.utc(keepLocalTime);

    return this;
  }

  toString(): string {
    return this.format();
  }

  toJSON(): string {
    return this.toString();
  }
}
