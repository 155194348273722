import { actionCreatorFactory } from '../../core/utils/factories';
import { PaginatedResponse, UserManagementData } from '../../models/interfaces';

const actionCreator = actionCreatorFactory('USER_MANAGEMENT');

export namespace UserManagementActions {
  export const getAllUsersAction =
    actionCreator.createAsyncAction<PaginatedResponse<UserManagementData>>(
      'GET_ALL_USERS',
    );
  export const createUserAction =
    actionCreator.createAsyncAction<PaginatedResponse<UserManagementData>>(
      'ADD_USER',
    );
  export const updateUserAction =
    actionCreator.createAsyncAction<UserManagementData>('UPDATE_USER');
  export const deleteUserAction =
    actionCreator.createAsyncAction<PaginatedResponse<UserManagementData>>(
      'DELETE_USER',
    );
}
