import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { SOFToolHttpService } from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';

export class SOFToolDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await HttpService.getHttpRequests(SOFToolHttpService).getSOFToolDetails(
      projectBundleID,
    );
  }
}
