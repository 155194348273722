import { Guard } from '@wellyes/react-router-extended';
import { StorageItem } from '../../models/enums';
import { NotificationsLoader } from '../../shared/components';
import { LocalStorageService } from '../services/local-storage.service';

export class AuthGuard implements Guard {
  localStorage: LocalStorageService = new LocalStorageService();

  canActivate(): Promise<boolean> | boolean {
    const haveAccess = !!this.localStorage.getItem(StorageItem.ACCESS_TOKEN);

    if (!haveAccess) {
      NotificationsLoader.notificationError({
        message: 'Not Found',
        description: 'TOKEN EXPIRED OR NOT FOUND!',
      });
    }

    return haveAccess;
  }
}
