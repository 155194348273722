import { Dispatch } from 'react';
import { convertToDecimal } from '@core/utils/methods';
import { coordinateValueTest } from '../models/constants';

export const coordinateField = (
  dms: boolean,
  setDisabled: Dispatch<boolean>,
) => [
  {
    validator: ({ field }: any, inputValue?: string): Promise<void> => {
      if (inputValue) {
        const degree = field === 'latitude' ? 90 : 180;

        const formatValidation = dms
          ? coordinateValueTest(inputValue)
          : Number.isFinite(+inputValue);

        const valueValidation =
          Math.abs(
            coordinateValueTest(inputValue)
              ? convertToDecimal(inputValue)
              : +inputValue,
          ) <= degree;

        setDisabled(!(formatValidation && valueValidation));

        return formatValidation && valueValidation
          ? Promise.resolve()
          : Promise.reject(
              new Error(
                !formatValidation
                  ? `Wrong format! Coordinates must have ${
                      dms ? 'DMS' : 'decimal'
                    } format.`
                  : `The ${field} must be a number between -${degree} and ${degree}.`,
              ),
            );
      }

      setDisabled(false);

      return Promise.resolve();
    },
  },
];
