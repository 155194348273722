import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Undefinable } from '@symfa-inc/providence-types';
import { Breadcrumb } from 'antd';
import { showIfAuthorized } from '@core/utils/HOC';
import { replacePath } from '@core/utils/methods';
import { RouteGroup } from '@models/classes';
import { clientRouting } from '@modules/module.routing';
import { BreadcrumbsSelectors, CommonSelectors } from '@store/selectors';
import { BreadcrumbItem } from './components';

import './styles.scss';

const BreadcrumbMenuComponent: FC = () => {
  const { pathname } = useLocation();
  const replaceObject = useSelector(BreadcrumbsSelectors.getBreadcrumbs);

  const params = useSelector(CommonSelectors.getRouteParams);

  const breadcrumbs: Undefinable<RouteGroup[]> = useMemo(
    () => clientRouting.findRouteByPath(pathname)?.breadcrumbs,
    [pathname],
  );

  return (
    <div className="prov-breadcrumb-menu__wrapper">
      <Breadcrumb className="prov-breadcrumb-menu">
        {breadcrumbs?.map((breadcrumb: RouteGroup, index: number) => (
          <Breadcrumb.Item key={breadcrumb.breadcrumbName}>
            <BreadcrumbItem
              item={breadcrumb}
              path={breadcrumb.replacePath(params)}
              name={replacePath(breadcrumb.breadcrumbName, replaceObject)}
              isLastItem={index === breadcrumbs.length - 1}
            />
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export const BreadcrumbMenu: FC = showIfAuthorized(BreadcrumbMenuComponent);
