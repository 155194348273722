import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  MAIN_PACE_TASK_IDs,
  ProjectType,
  UserRole,
} from '@symfa-inc/providence-types';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { AccountingHttpService } from '@core/services/http';
import { momentizeObjectDates } from '@core/utils/methods';
import { ACTUAL_DATE } from '@core/utils/validators';
import { ACCOUNTING_PACE_TASKS } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { AccountingBrowseUpdateData } from '@models/interfaces';
import { ProjectPaceTaskDataType } from '@models/types';
import { AddOrEditModal, NotificationsLoader } from '@shared/components';
import { DatePicker, Input } from '@shared/modules';
import { UserSelectors } from '@store/selectors';
import {
  ACCOUNTING_MODAL_DATES_TO_MOMENTIZE,
  AccountingModalProps,
} from './models';

import './styles.scss';

const { useForm } = Form;

export const AccountingModal: FC<AccountingModalProps> = ({
  projectTypeMode,
  visible,
  toggleModal,
  itemValue,
}: PropsWithChildren<AccountingModalProps>) => {
  const [form] = useForm();

  const roles = useSelector(UserSelectors.getUserRoles);

  const getProcessedFormValues = (): AccountingBrowseUpdateData =>
    Object.assign(
      {},
      ...Object.entries(ACCOUNTING_PACE_TASKS).flatMap(
        ([paceTaskNumber, id]: string[]) => {
          const paceTask = itemValue?.projectPaceTaskData?.find(
            ({ paceTask: currentPaceTask }: ProjectPaceTaskDataType) =>
              currentPaceTask.id === id,
          );

          return [
            {
              [`${paceTaskNumber}InvoiceNumber`]: paceTask?.invoiceNumber,
            },
            { [`${paceTaskNumber}InvoiceDate`]: paceTask?.invoiceDate },
            { [`${paceTaskNumber}ActualDate`]: paceTask?.actualDate },
          ];
        },
      ),
    );

  const paceTaskValueExists = (taskId: string, field: string): boolean => {
    const paceTask = itemValue?.projectPaceTaskData?.find(
      ({ paceTask: { id } }: ProjectPaceTaskDataType) => id === taskId,
    );

    return paceTask
      ? !!paceTask[field as keyof ProjectPaceTaskDataType]
      : false;
  };

  const onClose = (): void => {
    toggleModal();
    form.resetFields();
  };

  const onOk = async (): Promise<void> => {
    try {
      await HttpService.getHttpRequests(
        AccountingHttpService,
      ).updateAccountingBrowse(
        itemValue?.id,
        momentizeObjectDates(
          form.getFieldsValue() as AccountingBrowseUpdateData,
          ACCOUNTING_MODAL_DATES_TO_MOMENTIZE,
          true,
        ),
      );

      onClose();

      NotificationsLoader.notificationSuccess('Information has been updated!');
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(
        momentizeObjectDates(
          getProcessedFormValues(),
          ACCOUNTING_MODAL_DATES_TO_MOMENTIZE,
        ),
      );
    }
  }, [visible]);

  return (
    <AddOrEditModal
      visible={visible}
      type={ModalMainTypes.Edit}
      onOk={onOk}
      onCancel={onClose}
      title="Accounting Record"
      formProps={{
        labelCol: { span: 9 },
        wrapperCol: { span: 14 },
        form,
      }}
      className="accounting-browse-edd-modal"
      cancelButtonProps={{
        onClick: (): void =>
          form.setFieldsValue({
            RE020InvoiceNumber: null,
            RE020InvoiceDate: null,
            RE015InvoiceNumber: null,
            RE015InvoiceDate: null,
            RE020ActualDate: null,
            RE066InvoiceNumber: null,
            RE066InvoiceDate: null,
            SS010InvoiceNumber: null,
            SS010InvoiceDate: null,
          }),
      }}
    >
      <Input
        id="RE015InvoiceNumber"
        label="RE015 Invoice Number"
        isEditing={paceTaskValueExists(
          MAIN_PACE_TASK_IDs.leasingComplete,
          'actualDate',
        )}
      />
      <DatePicker
        id="RE015InvoiceDate"
        label="RE015 Invoice Date"
        isEditing={paceTaskValueExists(
          MAIN_PACE_TASK_IDs.leasingComplete,
          'actualDate',
        )}
      />
      {projectTypeMode === ProjectType.LTE ? (
        <DatePicker
          id="RE020ActualDate"
          label="RE020 Actual Date"
          isEditing={
            !roles.every((role: UserRole): boolean =>
              [UserRole.Accounting, UserRole.ReadOnly].includes(role),
            )
          }
          formItemProps={{
            rules: ACTUAL_DATE(getProcessedFormValues()?.RE020ActualDate),
          }}
        />
      ) : null}
      <Input
        id="RE020InvoiceNumber"
        label="RE020 Invoice Number"
        isEditing={
          paceTaskValueExists(
            MAIN_PACE_TASK_IDs.finalSiteAcqAccp,
            'PACEActualDate',
          ) &&
          paceTaskValueExists(MAIN_PACE_TASK_IDs.OOMTruedUp, 'PACEActualDate')
        }
      />
      <DatePicker
        id="RE020InvoiceDate"
        label="RE020 Invoice Date"
        isEditing={
          paceTaskValueExists(
            MAIN_PACE_TASK_IDs.finalSiteAcqAccp,
            'PACEActualDate',
          ) &&
          paceTaskValueExists(MAIN_PACE_TASK_IDs.OOMTruedUp, 'PACEActualDate')
        }
      />
      {projectTypeMode === ProjectType.NSB ? (
        <>
          <Input
            id="SS010InvoiceNumber"
            label="SS010 Invoice Number"
            isEditing={paceTaskValueExists(
              MAIN_PACE_TASK_IDs.candidateApproved,
              'actualDate',
            )}
          />
          <DatePicker
            id="SS010InvoiceDate"
            label="SS010 Invoice Date"
            isEditing={paceTaskValueExists(
              MAIN_PACE_TASK_IDs.candidateApproved,
              'actualDate',
            )}
          />
          <Input
            id="RE066InvoiceNumber"
            label="RE066 Invoice Number"
            isEditing={paceTaskValueExists(
              MAIN_PACE_TASK_IDs.zoningAppDeemedComplete,
              'actualDate',
            )}
          />
          <DatePicker
            id="RE066InvoiceDate"
            label="RE066 Invoice Date"
            isEditing={paceTaskValueExists(
              MAIN_PACE_TASK_IDs.zoningAppDeemedComplete,
              'actualDate',
            )}
          />
        </>
      ) : null}
    </AddOrEditModal>
  );
};
