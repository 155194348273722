import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import classNames from 'classnames';
import { SocketIOService, ZohoService } from '@core/services';
import { UserSelectors } from '@store/selectors';
import { HasUnsubmittedData } from './components';

const { Content } = Layout;

export const ClientLayout: FC = ({ children }: PropsWithChildren<unknown>) => {
  const isAuthorized = useSelector(UserSelectors.isAuthorized);
  const isFetching = useSelector(UserSelectors.isFetching);

  useEffect(() => {
    if (!isFetching && isAuthorized) {
      ZohoService.get.inject();
      SocketIOService.get.init();
    }
  }, [isAuthorized]);

  return (
    <>
      <HasUnsubmittedData />
      <Content
        className={classNames('prov-layout__content', {
          'auth-page': !isFetching && !isAuthorized,
        })}
      >
        <div className="wrapper">{children}</div>
      </Content>
    </>
  );
};
