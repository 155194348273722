import { AcceptedTypesTransform } from '../interfaces';

export const ACCEPTED_TYPES_TRANSFORM: AcceptedTypesTransform = {
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
  xls: 'application/vnd.ms-excel',
  xlt: 'application/vnd.ms-excel',
  xml: ['application/xml', 'text/xml'],
  xlam: 'application/vnd.ms-excel.addin.macroEnabled.12',
  xla: 'application/vnd.ms-excel',
  xlw: [
    'application/excel',
    'application/vnd.ms-excel',
    'application/x-excel',
    'application/x-msexcel',
  ],
  xlr: [
    'application/vnd.ms-works',
    'application/x-msworks',
    'application/vnd.ms-excel',
  ],
};

export const transformTypesToAccept = (
  types?: ReadonlyArray<keyof AcceptedTypesTransform>,
): string =>
  types
    ?.map((type: keyof AcceptedTypesTransform, index: number) => {
      const acceptType: string | string[] =
        ACCEPTED_TYPES_TRANSFORM[type] ?? '';
      const resultType = Array.isArray(acceptType)
        ? acceptType.join(',')
        : acceptType;

      return index === 0 ? `.${type},${resultType}` : resultType;
    })
    .join(',') || '';
