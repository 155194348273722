import { Route } from '../../models/classes';
import { ForgotPassword, Login } from './components';

export const loginRoute = new Route({
  path: '/login',
  name: 'Login',
  component: Login,
});

export const forgotPasswordRoute = new Route({
  path: '/forgot-password',
  name: 'Forgot Password',
  component: ForgotPassword,
});
