import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { ExportsState } from '../reducers/exports.reducer';

const exportsState = (state: AppState): ExportsState => state.exports;

export namespace ExportsSelectors {
  export const getExportsBrowseData = createSelector(
    [exportsState],
    (store: ExportsState) => store.exportsBrowseData,
  );

  export const getExportsBrowseFilterOptions = createSelector(
    [exportsState],
    (store: ExportsState) => store.filtersOptions,
  );
}
