import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { PaceTasksBrowseState } from '../../reducers/admin/pace-tasks-browse.reducer';

const paceTasksBrowseState = (state: AppState) => state.admin.paceTasksBrowse;

export namespace PACETasksBrowseSelectors {
  export const getProjectBundleIDsOptions = createSelector(
    [paceTasksBrowseState],
    (state: PaceTasksBrowseState) => state.projectBundleIDsOptions,
  );

  export const getProjectPACETasks = createSelector(
    [paceTasksBrowseState],
    (state: PaceTasksBrowseState) => state.projectPACETasks,
  );
  export const isFetching = createSelector(
    [paceTasksBrowseState],
    (state: PaceTasksBrowseState) => state.isFetching,
  );
}
