import { OptionProps } from '@models/interfaces';

export const getCurrentTowerOwners = (
  towerOwnersDDV: OptionProps[],
  currentTowerOwners: string[] = [],
): string =>
  currentTowerOwners
    .map((tw: string) => {
      const found = towerOwnersDDV.find(
        (twDDV: OptionProps) => twDDV.value === tw,
      )?.viewValue as string;

      return found || '';
    })
    .filter((v: string) => !!v)
    .join(', ');
