import { Undefinable } from '@symfa-inc/providence-types';
import { ProjectDetailsWithoutCategories } from '@models/types';
import { actionCreatorFactory } from '../../core/utils/factories';
import {
  LeasingDetails,
  OptionProps,
  PaginatedResponse,
  ProjectBrowse,
  ProjectCategory,
  ProjectDetails,
  ProjectPaceTask,
  ProjectPaceTaskData,
  RadData,
  ReducerConfigPaceTaskData,
  User,
} from '../../models/interfaces';

const actionCreator = actionCreatorFactory('PROJECT');

export namespace ProjectActions {
  export const getProjectBrowseDataAction =
    actionCreator.createAsyncAction<PaginatedResponse<ProjectBrowse>>(
      'GET_BROWSE_DATA',
    );
  export const getProjectCategoryDataAction =
    actionCreator.createAsyncAction<ProjectCategory[]>('GET_CATEGORY_DATA');
  export const updateProjectAction =
    actionCreator.createAsyncAction<ProjectDetailsWithoutCategories>(
      'UPDATE_PROJECT',
    );
  export const updateProjectPaceTasksAction = actionCreator.createAsyncAction<
    ProjectPaceTask[]
  >('UPDATE_PROJECT_PACE_TASKS');
  export const updateProjectPaceTaskFromOOMTableAction =
    actionCreator.createAsyncAction<
      Pick<ProjectPaceTask, 'id'> & {
        actualDate: ProjectPaceTaskData['actualDate'];
      }
    >('UPDATE_PROJECT_PACE_TASK_FROM_OOM_TABLE');

  export const updateProjectPaceTaskFieldsByConfig =
    actionCreator.createAsyncAction<ReducerConfigPaceTaskData[]>(
      'UPDATE_PROJECT_PACE_TASK_FIELDS_BY_CONFIG',
    );
  export const updateProjectPaceTaskFields = actionCreator.createAsyncAction<
    ProjectPaceTask[]
  >('UPDATE_PROJECT_PACE_TASK_FIELDS');
  export const updateProjectPaceTaskFieldsFromSummaryPageTable =
    actionCreator.createAsyncAction<ProjectPaceTaskData[]>(
      'UPDATE_PROJECT_PACE_TASK_FIELDS_FROM_SUMMARY_PAGE_TABLE',
    );

  export const setArePACETasksChanged =
    actionCreator.createAsyncAction<boolean>('SET_ARE_PACE_TASKS_CHANGED');
  export const getProjectAction =
    actionCreator.createAsyncAction<ProjectDetailsWithoutCategories>(
      'GET_PROJECT',
    );
  export const updateProjectRADAction =
    actionCreator.createAsyncAction<RadData[]>('UPDATE_PROJECT_RAD');
  export const setRADAction =
    actionCreator.createAsyncAction<RadData[]>('SET_RAD');
  export const getProvidenceAgent = actionCreator.createAsyncAction<
    Undefinable<string>
  >('GET_PROVIDENCE_AGENT');

  export const getProvidenceAgents = actionCreator.createAsyncAction<
    OptionProps[]
  >('GET_PROVIDENCE_AGENTS');

  export const setCurrentProvidenceAgent =
    actionCreator.createAsyncAction<User>('SET_CURRENT_PROVIDENCE_AGENT');
  export const setCurrentProvidenceAgentAsString =
    actionCreator.createAsyncAction<string>(
      'SET_CURRENT_PROVIDENCE_AGENT_AS_STRING',
    );
  export const setHoldOptions =
    actionCreator.createAsyncAction<
      Pick<ProjectDetails, 'holdReason' | 'projectStatus'>
    >('SET_HOLD_OPTIONS');
  export const setHoldOptionsByLeasing = actionCreator.createAsyncAction<
    Pick<LeasingDetails, 'holdReason' | 'projectStatusId'>
  >('SET_HOLD_OPTIONS_BY_LEASING');
}
