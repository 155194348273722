import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownSelectorValues } from '@symfa-inc/providence-types';
import { useForm } from 'antd/lib/form/Form';
import { dateWorker } from '@core/utils/date-worker';
import { getFormData, isValidForm } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { JurisdictionDetailsPermittingType } from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { Autocomplete, DatePicker, Input } from '@shared/modules';
import { PermittingActions } from '@store/actions';
import { DDVSelectors } from '@store/selectors';
import { PermitTypeTableModalProps } from '../models';

export const PermitTypeTableModal: FC<PermitTypeTableModalProps> = ({
  visibleModal,
  toggleModal,
  formValue,
  permittingId,
}: PropsWithChildren<PermitTypeTableModalProps>) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const permitTypeDDV = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(DropDownSelectorValues.PermitType),
  );

  useEffect(() => {
    if (visibleModal) {
      form.setFieldsValue({
        ...formValue,
        expireDate: formValue.expireDate
          ? dateWorker(formValue.expireDate)
          : null,
      });
    }
  }, [visibleModal, formValue, form]);

  const addPermittingType = async (
    data: JurisdictionDetailsPermittingType,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        PermittingActions.permittingAddPermittingType.done({
          permittingId,
          data,
        }),
      );
      toggleModal();
    }
  };

  const editPermitType = async (
    data: JurisdictionDetailsPermittingType,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        PermittingActions.permittingUpdatePermittingType.done({
          permittingId,
          data,
        }),
      );
      toggleModal();
    }
  };

  return (
    <AddOrEditModal
      className="permit-type-table__modal"
      visible={visibleModal}
      type={formValue.id ? ModalMainTypes.Edit : ModalMainTypes.Add}
      cancelText="Cancel"
      onOk={
        formValue.id
          ? (): Promise<void> => editPermitType(getFormData(formValue, form))
          : (): Promise<void> => addPermittingType(getFormData(formValue, form))
      }
      onCancel={toggleModal}
      title={formValue.id ? 'Permit Type' : 'New Permit Type'}
      formProps={{
        labelCol: { span: 12 },
        wrapperCol: { span: 14 },
        form,
      }}
    >
      <Autocomplete
        id="permitType"
        label="Permit Type"
        options={permitTypeDDV}
        formItemProps={{
          rules: [
            {
              required: true,
              message: 'Permit Type is required!',
            },
          ],
        }}
      />
      <Input id="expectedPermitLeadTime" label="Expected Permit Lead Time" />
      <Input id="permitAndApplicationFee" label="Permit and Application Fees" />
      <Input
        id="requiredInfoForObtainingPermit"
        label="Required Info for Obtaining Permit"
      />
      <DatePicker id="expireDate" label="Permit Expire Date" />
    </AddOrEditModal>
  );
};
