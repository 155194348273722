import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { DriverManagementHttpService } from '@core/services/http';

export class DriverManagementResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      DriverManagementHttpService,
    ).getDriverManagementData(null);
  }
}
