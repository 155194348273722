import { ReactNode } from 'react';
import { ColumnsType } from 'antd/lib/table';
import {
  arrsToSelectableObject,
  readOnlySelectRender,
} from '@core/utils/methods';
import {
  EquipmentManufacturer,
  EquipmentTypeData,
  TotalsEquipmentType,
} from '@models/interfaces';
import store from '@store/index';

export const getEquipmentTotalsColumns = (): ColumnsType => {
  const {
    equipment: { equipmentTypes },
  } = store.getState();

  return [
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Equipment',
      key: 'equipmentType',
      dataIndex: 'equipmentType',
      render: (equipmentTypeId: string): ReactNode =>
        readOnlySelectRender(
          arrsToSelectableObject<EquipmentTypeData>(
            equipmentTypes,
            'id',
            'name',
          ),
          equipmentTypeId,
        ),
    },
    {
      title: 'Manufacturer',
      key: 'manufacturer',
      render: ({
        equipmentType: equipmentTypeId,
        manufacturer: manufacturerId,
      }: TotalsEquipmentType): ReactNode => {
        const currentEquipmentType = equipmentTypes?.find(
          (equipmentType: EquipmentTypeData) =>
            equipmentType.id === equipmentTypeId,
        );

        const currentManufacturer = currentEquipmentType?.manufacturers?.find(
          (manufacturer: EquipmentManufacturer) =>
            manufacturer.id === manufacturerId,
        );

        return currentManufacturer?.name || '';
      },
    },
  ];
};
