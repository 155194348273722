import { actionCreatorFactory } from '@core/utils/factories';
import {
  AdminDefaultTowerAgentData,
  AdminDefaultTowerAgentsOptions,
} from '@models/interfaces';

const actionCreator = actionCreatorFactory('DEFAULT_TOWER_AGENT');

export namespace DefaultTowerAgentActions {
  export const getDefaultTowerAgentDataAction = actionCreator.createAsyncAction<
    AdminDefaultTowerAgentData[]
  >('GET_DEFAULT_TOWER_AGENT_DATA');

  export const getDefaultTowerAgentOptionsAction =
    actionCreator.createAsyncAction<AdminDefaultTowerAgentsOptions>(
      'GET_DEFAULT_TOWER_AGENT_OPTIONS',
    );

  export const addDefaultTowerAgentDataAction =
    actionCreator.createAsyncAction<AdminDefaultTowerAgentData>(
      'ADD_DEFAULT_TOWER_AGENT_DATA',
    );

  export const updateDefaultTowerAgentDataAction =
    actionCreator.createAsyncAction<AdminDefaultTowerAgentData>(
      'UPDATE_DEFAULT_TOWER_AGENT_DATA',
    );

  export const deleteDefaultTowerAgentAction =
    actionCreator.createAsyncAction<string>('DELETE_DEFAULT_TOWER_AGENT');
}
