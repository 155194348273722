import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  EquipmentHttpService,
  ProjectCategoryHttpService,
  SiteAuditHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';
import { EquipmentType } from '@models/enums';

export class SiteAuditDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await Promise.all([
      HttpService.getHttpRequests(
        ProjectCategoryHttpService,
      ).getProjectCategories(),

      HttpService.getHttpRequests(EquipmentHttpService).getEquipmentTypes(),

      HttpService.getHttpRequests(EquipmentHttpService).getEquipmentsByTypes([
        EquipmentType.Cable,
      ]),

      HttpService.getHttpRequests(
        EquipmentHttpService,
      ).getEquipmentsByManufacturers(['Raycap']),

      HttpService.getHttpRequests(SiteAuditHttpService).getSiteAudit(
        projectBundleID,
      ),
    ]);
  }
}
