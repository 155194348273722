import {
  FiltersSetDataFilter,
  ProjectManagementBrowseFilter,
} from '@models/interfaces';
import { momentValue } from '../../filters/models';

export const projectManagementBrowseFilterTransformer = (
  filterSetFields: FiltersSetDataFilter,
): ProjectManagementBrowseFilter =>
  Object.keys(filterSetFields).reduce(
    (acc: ProjectManagementBrowseFilter, fieldKey: string) => {
      const splittedKey = fieldKey.split('/');
      const { operator, value, isVisible } = filterSetFields?.[fieldKey] ?? {};

      return {
        ...acc,
        ...(splittedKey.length === 1
          ? {
              filter: {
                ...acc.filter,
                [fieldKey]: {
                  ...filterSetFields[fieldKey],
                  isVisible: !!isVisible,
                },
              },
            }
          : {
              paceTaskFilter: [
                ...acc.paceTaskFilter!,
                {
                  id: splittedKey[0],
                  field: splittedKey[1],
                  operator,
                  value: momentValue(value, true),
                  isVisible: !!isVisible,
                },
              ],
            }),
      };
    },
    { filter: {}, paceTaskFilter: [] },
  );
