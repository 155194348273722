import React, { FC, PropsWithChildren } from 'react';
import { Row } from 'antd';
import classNames from 'classnames';
import { CustomTable } from '../../components/custom-table';
import { PaceTasksProps } from './models/interfaces';

import './styles.scss';

export const PaceTasks: FC<PaceTasksProps> = ({
  columns,
  data,
  infoRowLeftElement,
  infoRowRightElement,
  moveRow,
  className,
  rowSelection,
}: PropsWithChildren<PaceTasksProps>) => (
  <div className={classNames('prov-pace-tasks', className)}>
    <Row className="prov-pace-tasks__info-row" justify="space-between">
      <div className="prov-pace-tasks__info-row_left">{infoRowLeftElement}</div>
      <div className="prov-pace-tasks__info-row_right">
        {infoRowRightElement}
      </div>
    </Row>
    <Row className="prov-pace-tasks__table-row">
      <CustomTable
        columns={columns}
        dataSource={data}
        moveRow={moveRow}
        pagination={false}
        rowSelection={rowSelection}
      />
    </Row>
  </div>
);
