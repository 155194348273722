import React, { FC, PropsWithChildren, RefObject, useRef } from 'react';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import classNames from 'classnames';
import { DraggableRowProps } from '@models/interfaces';

const type = 'DraggableBodyRow';

export const DraggableRow: FC<DraggableRowProps> = ({
  index,
  moveRow,
  className,
  ...restProps
}: PropsWithChildren<DraggableRowProps>) => {
  const ref = useRef<HTMLTableRowElement>();

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: DropTargetMonitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index! ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: ({ index: fromIndex }: any) => {
      moveRow!(fromIndex, index!);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <tr
      ref={ref as RefObject<HTMLTableRowElement>}
      className={classNames(className, { [dropClassName!]: isOver })}
      {...restProps}
    />
  );
};
