import { Token, UserCredentials } from '@models/interfaces';
import { CommonHttpService } from './common-http.service';

export class AuthHttpService extends CommonHttpService {
  logIn(user: UserCredentials): Promise<Token> {
    return this.http.callApi.post(`${this.apiUrl}/auth/sign-in`, user);
  }

  resetPassword(email: string): Promise<void> {
    return this.http.callApi.get(`${this.apiUrl}/auth/reset-password`, null, {
      params: { email },
    });
  }
}
