import { createReducer, on } from '../../core/utils/store';
import { RAD_DATA } from '../../models/constants/rad-data';
import { PayloadAndState, RadData } from '../../models/interfaces';
import { ProjectActions } from '../actions/project.actions';

export interface RADState {
  originalRADInfo: RadData[];
  RAD: RadData[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: RADState = {
  originalRADInfo: RAD_DATA,
  RAD: RAD_DATA,
  isFetching: false,
  errors: false,
};

const processRADInfo = (data: RadData[], prevData: RadData[]): RadData[] =>
  prevData.map((prevItem: RadData) => {
    const currentItem = data.find(
      (item: RadData) => item.RADField === prevItem.RADField,
    );

    return {
      ...prevItem,
      ...currentItem,
    };
  });

export const reducer = createReducer(
  initialState,
  on(
    ProjectActions.setRADAction,
    ({ payload, state }: PayloadAndState<RadData[], RADState>) => ({
      RAD: processRADInfo(payload, state.originalRADInfo),
      originalRADInfo: processRADInfo(payload, state.originalRADInfo),
    }),
  ),
  on(
    ProjectActions.updateProjectRADAction,
    ({ payload, state }: PayloadAndState<RadData[], RADState>) => ({
      RAD: [
        ...payload.map((payloadValue: RadData) => {
          const stateValue = state.RAD.find(
            (v: RadData) => v.RADField === payloadValue.RADField,
          );

          return {
            ...stateValue,
            ...payloadValue,
          };
        }),
      ],
    }),
  ),
);
