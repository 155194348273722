import React, { FC } from 'react';
import { HttpService } from '@core/services';
import { DropdownHttpService } from '@core/services/http';
import { AdminDropdownValuesData } from '@models/interfaces';
import { FileType } from '@models/types';
import { Import } from '@shared/modules/import';

export const DropdownValuesImport: FC = () => (
  <Import<AdminDropdownValuesData[]>
    template="DropDownValuesTemplate"
    sendFile={(file: FileType): Promise<AdminDropdownValuesData[]> =>
      HttpService.getHttpRequests(DropdownHttpService).importDropDownValues(
        file,
      )
    }
  />
);
