import React, { FC, PropsWithChildren } from 'react';
import { priceCommaReplacer } from '@core/utils/methods';
import { ROInputNumber } from '../models';

export const ReadOnlyInputNumber: FC<ROInputNumber> = ({
  value,
  suffix,
  prefix,
}: PropsWithChildren<ROInputNumber>) => (
  <>
    {value && suffix ? suffix : ''}
    {value && suffix ? priceCommaReplacer(value) : value}
    {value && prefix ? prefix : ''}
  </>
);
