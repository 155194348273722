import { ReactNode } from 'react';
import { ColumnsType } from 'antd/es/table';
import { tableSorter } from '@core/utils/methods/table-sorter';
import {
  AccountingNotificationData,
  DashboardProjectData,
} from '@models/interfaces';
import { PROJECT_DATA_COLUMNS } from '../../../models';

export const ACCOUNTING_NOTIFICATIONS_COLUMNS: ColumnsType = [
  {
    title: 'Type',
    dataIndex: 'notificationData',
    key: 'type',
    sorter: tableSorter(['notificationData', 'type']),
    render: ({ type }: AccountingNotificationData): ReactNode => type,
  },
  {
    title: 'Legacy Site ID',
    dataIndex: 'projectData',
    key: 'legacySiteID',
    sorter: tableSorter(['projectData', 'legacySiteID']),
    render: ({ legacySiteID }: DashboardProjectData): ReactNode => legacySiteID,
  },
  ...PROJECT_DATA_COLUMNS,
] as ColumnsType;
