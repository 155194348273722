import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { SubPage, Tab } from '@symfa-inc/providence-types';
import { EditablePage, SOFInformation } from '@shared/components';
import { EngineeringSelectors, UserSelectors } from '@store/selectors';

export const EngineeringSOFTab: FC<RouterProps> = () => {
  const projectType = useSelector(EngineeringSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit('engineering', SubPage.EngineeringBrowse, Tab.SOF),
  );

  return (
    <EditablePage
      editableComponent={SOFInformation}
      canUserEdit={canEdit && projectTypePermission}
    />
  );
};
