import { actionCreatorFactory } from '@core/utils/factories';
import {
  AccountingDetails,
  JurisdictionDetails,
  ProjectDetails,
  ProjectManagementDetails,
  SiteBrowse,
  SOFToolDetails,
} from '../../models/interfaces';
import { ProjectDetailsWithoutCategories } from '../../models/types';

const action = actionCreatorFactory('BREADCRUMBS');

export namespace BreadcrumbsActions {
  export const site = action.createAsyncAction<SiteBrowse>('SITE');
  export const project =
    action.createAsyncAction<ProjectDetailsWithoutCategories>('PROJECT');

  export namespace Engineering {
    export const engineering =
      action.createAsyncAction<
        Omit<ProjectDetailsWithoutCategories, 'paceTasks'>
      >('ENGINEERING');
    export const SOFTool = action.createAsyncAction<SOFToolDetails>('SOF_TOOL');
    export const scoping =
      action.createAsyncAction<Omit<ProjectDetailsWithoutCategories, 'RAD'>>(
        'SCOPING',
      );
    export const siteAudit =
      action.createAsyncAction<ProjectDetailsWithoutCategories>('SITE_AUDIT');
  }

  export const leasing =
    action.createAsyncAction<
      Omit<ProjectDetailsWithoutCategories, 'paceTasks'>
    >('LEASING');

  export namespace Permitting {
    export const permitting =
      action.createAsyncAction<ProjectDetails>('PERMITTING');
    export const jurisdiction =
      action.createAsyncAction<JurisdictionDetails>('JURISDICTION');
  }

  export const projectManagement =
    action.createAsyncAction<
      Omit<ProjectManagementDetails, 'categories' | 'paceTasks'>
    >('PROJECT_MANAGEMENT');
  export const accounting =
    action.createAsyncAction<AccountingDetails>('ACCOUNTING');
}
