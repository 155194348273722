import React, {
  FC,
  PropsWithChildren,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  DndProvider,
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import {
  DownOutlined,
  SettingOutlined,
  UpOutlined,
} from '@ant-design/icons/lib';
import { Card, Checkbox, Popover } from 'antd';
import { ColumnType } from 'antd/es/table';
import classNames from 'classnames';
import { HttpService, ObjectComparatorService } from '@core/services';
import { UserHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { ColumnSettings as IColumnSettings } from '@models/interfaces';
import { Browse } from '@models/types';
import { UserSelectors } from '@store/selectors';
import { ColumnSettingsProps, DraggableLiProps } from './models';

import './styles.scss';

const DraggableLi: FC<DraggableLiProps> = ({
  children,
  moveRow,
  index,
  className,
}: PropsWithChildren<DraggableLiProps>) => {
  const ref = useRef<HTMLTableRowElement>();
  const type = 'DraggableLi';

  const [{ dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: DropTargetMonitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index! ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: ({ index: fromIndex }: any) => {
      moveRow!(fromIndex, index!);
    },
  });
  const [, drag] = useDrag({
    item: { type, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drop(drag(ref));

  return (
    <li
      ref={ref as unknown as RefObject<HTMLLIElement>}
      className={classNames(className, dropClassName)}
    >
      {children}
    </li>
  );
};

export const ColumnSettings: FC<ColumnSettingsProps> = ({
  columns,
  setColumns,
  module,
  subModule,
}: PropsWithChildren<ColumnSettingsProps>) => {
  const items = useSelector(
    UserSelectors.getColumnsSettings(module, subModule),
  );

  const getColumnsSettings = (): IColumnSettings[] => [
    // remove columns if they deleted
    ...items.filter((columnSetting: IColumnSettings) =>
      columns.find(
        (column: ColumnType<Browse>) => column.title === columnSetting.title,
      ),
    ),
    // add columns if they added
    ...columns
      .filter(
        (column: ColumnType<Browse>) =>
          column.title &&
          !items.find(
            (columnSetting: IColumnSettings) =>
              column.title === columnSetting.title,
          ),
      )
      .map((column: ColumnType<Browse>) => {
        const foundItem = items.find(
          (columnSetting: IColumnSettings) =>
            column.title === columnSetting.title,
        );

        return {
          title: column.title!.toString(),
          visible: foundItem?.visible || true,
          configured: foundItem?.configured,
        };
      }),
  ];

  const [columnsSettings, setColumnsSettings] = useState<IColumnSettings[]>(
    getColumnsSettings(),
  );

  const stateFilter = (state: IColumnSettings[]) => {
    const arr1 = state.filter(a => a.visible);
    const arr2 = state.filter(a => !a.visible);

    return [...arr1, ...arr2.sort((a, b) => (a.title > b.title ? 1 : -1) || 0)];
  };

  const toggleVisible = (index: number): void =>
    setColumnsSettings((prevState: IColumnSettings[]) => {
      const item = prevState[index];
      const state = [...prevState];

      state.splice(index, 1, { ...item, visible: !item.visible });

      return stateFilter(state);
    });

  const moveRow = (fromIndex: number, toIndex: number): void =>
    setColumnsSettings((prevState: IColumnSettings[]) => {
      const state = [...prevState];
      const [item] = state.splice(fromIndex, 1);

      state.splice(toIndex, 0, item);

      return stateFilter(state);
    });

  const changeColumns = (): void => {
    const unconcealedColumnsIndexes = columns
      .filter((column: ColumnType<Browse>) => !column.title)
      .map((column: ColumnType<Browse>) =>
        columns.findIndex(
          (columnIndexObj: ColumnType<Browse>) => columnIndexObj === column,
        ),
      );

    setColumns(() => {
      const orderedColumns = columnsSettings
        .filter((column: IColumnSettings) => column.visible)
        .map(
          (columnSetting: IColumnSettings) =>
            columns.find(
              (column: ColumnType<Browse>) =>
                column.title === columnSetting.title,
            )!,
        );

      // restore columns without title
      unconcealedColumnsIndexes.forEach((index: number) => {
        orderedColumns.splice(index, 0, columns[index]);
      });

      return orderedColumns;
    });
  };

  useDidUpdateEffect(() => {
    setColumnsSettings(getColumnsSettings());
  }, [module, subModule]);

  useDidUpdateEffect(() => {
    if (items.length) {
      setColumnsSettings(getColumnsSettings());
    }
  }, [items]);

  useDidUpdateEffect(() => {
    const effect = async (): Promise<void> => {
      try {
        if (!ObjectComparatorService.arraysCompare(columnsSettings, items)) {
          await HttpService.getHttpRequests(
            UserHttpService,
          ).changeColumnSettings({ module, subModule }, columnsSettings);
        }

        changeColumns();
      } catch (e) {
        console.error(e);
      }
    };

    effect();
  }, [columnsSettings]);

  useEffect(() => {
    changeColumns();
  }, []);

  return (
    <div className="prov-column-settings">
      <Popover
        trigger="click"
        content={
          <DndProvider backend={HTML5Backend}>
            <Card bordered={false} className="prov-column-settings-popup">
              <ul className="prov-column-settings-popup__list">
                {columnsSettings.map(
                  (column: IColumnSettings, index: number) => (
                    <DraggableLi
                      key={column.title}
                      className="prov-column-settings-popup__list__item"
                      index={index}
                      moveRow={moveRow}
                    >
                      <label htmlFor={`${index}-checkbox`}>
                        <div className="prov-column-settings-popup__list__item-checkbox">
                          <Checkbox
                            id={`${index}-checkbox`}
                            onChange={(): void => toggleVisible(index)}
                            checked={column.visible}
                          />
                        </div>
                        <div className="prov-column-settings-popup__list__item-title">
                          {column.title}
                        </div>
                      </label>
                      <div className="prov-column-settings-popup__list__item-actions">
                        <UpOutlined
                          onClick={(): void => {
                            if (index !== 0) {
                              moveRow(index, index - 1);
                            }
                          }}
                          disabled={index === 0}
                        />
                        <DownOutlined
                          onClick={(): void => {
                            if (index !== columnsSettings.length - 1) {
                              moveRow(index, index + 1);
                            }
                          }}
                          disabled={index === columnsSettings.length - 1}
                        />
                      </div>
                    </DraggableLi>
                  ),
                )}
              </ul>
            </Card>
          </DndProvider>
        }
        placement="bottomLeft"
      >
        <SettingOutlined />
      </Popover>
    </div>
  );
};
