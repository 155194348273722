import { actionCreatorFactory } from '@core/utils/factories';
import { AdminProjectCategoryData } from '@models/interfaces';

const actionCreator = actionCreatorFactory('PROJECT_CATEGORY');

export namespace ProjectCategoryActions {
  export const getProjectCategoryDataAction = actionCreator.createAsyncAction<
    AdminProjectCategoryData[]
  >('GET_PROJECT_CATEGORY_DATA');

  export const addProjectCategoryAction =
    actionCreator.createAsyncAction<AdminProjectCategoryData>(
      'ADD_PROJECT_CATEGORY',
    );

  export const updateProjectCategoryAction =
    actionCreator.createAsyncAction<AdminProjectCategoryData>(
      'UPDATE_PROJECT_CATEGORY',
    );

  export const deleteProjectCategoryAction =
    actionCreator.createAsyncAction<string>('DELETE_PROJECT_CATEGORY');
}
