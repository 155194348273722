import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { SelectValue } from 'antd/es/select';
import { YES_NO_GROUP } from '@models/constants';
import {
  ConfigureFilterProps,
  ExportsBrowseFilter,
  OptionProps,
} from '@models/interfaces';
import { Autocomplete, InputNumber } from '@shared/modules';
import { ExportsSelectors } from '@store/selectors';

export const Filters: FC<ConfigureFilterProps<ExportsBrowseFilter>> = ({
  value,
  onChanges,
}: PropsWithChildren<ConfigureFilterProps<ExportsBrowseFilter>>) => {
  const filtersOptions = useSelector(
    ExportsSelectors.getExportsBrowseFilterOptions,
  );

  const booleanOptions = YES_NO_GROUP as OptionProps[];

  return (
    <>
      <InputNumber
        id="projectBundleID"
        key="projectBundleID"
        label="Project Bundle ID"
        elementProps={{
          value: value.projectBundleID,
          onChange: (e?: number | string): void =>
            onChanges({ projectBundleID: e as number }),
        }}
      />
      <Autocomplete
        id="FALocationNumber"
        key="FALocationNumber"
        label="FA Location Number"
        options={filtersOptions?.FALocationNumbers || []}
        elementProps={{
          allowClear: true,
          value: value.FALocationNumber,
          onChange: (e: SelectValue): void =>
            onChanges({ FALocationNumber: e as string }),
        }}
      />
      <Autocomplete
        id="PACEProjectNumber"
        key="PACEProjectNumber"
        label="PACE Project Number"
        options={filtersOptions?.PACEProjectNumbers || []}
        elementProps={{
          allowClear: true,
          value: value.PACEProjectNumber,
          onChange: (e: SelectValue): void =>
            onChanges({ PACEProjectNumber: e as string }),
        }}
      />
      <Autocomplete
        id="IWMJobNumber"
        key="IWMJobNumber"
        label="IWM Job Number"
        options={filtersOptions?.IWMJobNumbers || []}
        elementProps={{
          allowClear: true,
          value: value.IWMJobNumber,
          onChange: (e: SelectValue): void =>
            onChanges({ IWMJobNumber: e as string }),
        }}
      />
      <Autocomplete
        id="paceTaskID"
        key="paceTaskID"
        label="PACE Task ID"
        options={filtersOptions?.PACETaskID || []}
        elementProps={{
          allowClear: true,
          value: value.paceTaskID,
          onChange: (e: SelectValue): void =>
            onChanges({ paceTaskID: e as string }),
        }}
      />
      <Autocomplete
        id="actualDateIsNA"
        key="actualDateIsNA"
        label="Actual Date is N/A"
        options={booleanOptions}
        elementProps={{
          allowClear: true,
          value: value.actualDateIsNA,
          onChange: (e: SelectValue): void =>
            onChanges({ actualDateIsNA: e as string }),
        }}
      />
      <Autocomplete
        id="PACEForecastedDateIsNA"
        key="PACEForecastedDateIsNA"
        label="PACE Forecasted Date is N/A"
        options={booleanOptions}
        elementProps={{
          allowClear: true,
          value: value.PACEForecastedDateIsNA,
          onChange: (e: SelectValue): void =>
            onChanges({ PACEForecastedDateIsNA: e as string }),
        }}
      />
    </>
  );
};
