import { ActionCreator } from '../store/models/interfaces';
import { ActionFactory, ActionFuncRes } from '../store/models/types';

export const actionCreatorFactory = (prefix: string): ActionFactory => {
  const actionTypes: Record<string, boolean> = {};

  const createAction = (type: string): ActionFuncRes => {
    if (actionTypes[type]) {
      throw new Error(`${type} action already exists!`);
    }

    actionTypes[type] = true;

    return Object.assign((payload: any) => ({ type, payload }), { type });
  };

  const createAsyncAction = (name: string): ActionCreator => {
    const type = `${prefix}_${name}`;

    return {
      type,
      start: createAction(`${type}_START`),
      done: createAction(`${type}_DONE`),
      fail: createAction(`${type}_FAIL`),
    };
  };

  return Object.assign(createAction, { createAsyncAction });
};
