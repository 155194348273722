import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { DashboardState } from '../reducers/dashboard.reducer';

const dashboardState = (state: AppState): DashboardState => state.dashboard;

export namespace DashboardSelectors {
  // other
  export const getAdminList = createSelector(
    [dashboardState],
    (state: DashboardState) => state.adminList,
  );

  export const getUserList = createSelector(
    [dashboardState],
    (state: DashboardState) => state.userList,
  );
  // ToDo
  export const getToDoItems = createSelector(
    [dashboardState],
    (state: DashboardState) => state.toDoItems,
  );

  export const isToDoItemsFetching = createSelector(
    [dashboardState],
    (state: DashboardState) => state.isToDoItemsFetching,
  );
  // RequiresAttention
  export const getRequiresAttentionItems = createSelector(
    [dashboardState],
    (state: DashboardState) => state.requiresAttentionItems,
  );

  export const isRequiresAttentionItemsFetching = createSelector(
    [dashboardState],
    (state: DashboardState) => state.isRequiresAttentionItemsFetching,
  );
  // Accounting
  export const getAccountingNotificationData = createSelector(
    [dashboardState],
    (state: DashboardState) => state.accountingItems,
  );

  export const isAccountingItemsFetching = createSelector(
    [dashboardState],
    (state: DashboardState) => state.isAccountingItemsFetching,
  );
  // Direct Messages
  export const getDirectMessagesData = createSelector(
    [dashboardState],
    (state: DashboardState) => state.directMessages,
  );

  export const isDirectMessagesFetching = createSelector(
    [dashboardState],
    (state: DashboardState) => state.isDirectMessagesFetching,
  );
}
