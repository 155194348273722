import { JurisdictionDetailsPermittingType } from '@models/interfaces';

export const DEFAULT_VALUES: JurisdictionDetailsPermittingType = {
  permitType: '',
  expectedPermitLeadTime: '',
  permitAndApplicationFee: '',
  requiredInfoForObtainingPermit: '',
  expireDate: '',
  generatedId: '',
};
