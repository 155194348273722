import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Form } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Input } from '@shared/modules';

const { useForm } = Form;

export const AzimuthInformation: FC<InformationProps> = ({
  className,
  data,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(data);
    }
  }, [data]);

  return (
    <Panel header="Azimuth Information" className={className}>
      <Form form={form}>
        <Input id="azimuthA" label="Azimuth A" isEditing={false} />
        <Input id="azimuthB" label="Azimuth B" isEditing={false} />
        <Input id="azimuthG" label="Azimuth G" isEditing={false} />
        <Input id="azimuthD" label="Azimuth D" isEditing={false} />
      </Form>
    </Panel>
  );
};
