import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { canBeEditable, momentizeObjectDates } from '@core/utils/methods';
import { ACTUAL_DATE } from '@core/utils/validators';
import { Panel } from '@shared/components';
import { Autocomplete, DatePicker, Input } from '@shared/modules';
import { CountySelectors } from '@store/selectors';
import { JurisdictionAuditSummarySectionProps } from '../../models';

export const JurisdictionAuditSummarySection: FC<
  JurisdictionAuditSummarySectionProps
> = ({
  isEditing,
  jurisdictionAuditSummaryFields: {
    jurisdictionAuditBy,
    jurisdictionAuditComplete,
  },
  onValuesChange,
  data,
  permittingUsers,
  form,
}: PropsWithChildren<JurisdictionAuditSummarySectionProps>) => {
  const states = useSelector(CountySelectors.getStateLikeSelectorOptions);

  useEffect(() => {
    form?.setFieldsValue(
      momentizeObjectDates(data, ['jurisdictionAuditComplete']),
    );
  }, [isEditing]);

  return (
    <Panel
      header="Summary"
      className="prov-permitting-jurisdiction-audit__section"
      collapsible
    >
      <Form
        className="prov-permitting-jurisdiction-audit__section__form"
        form={form}
        labelCol={{ span: 10 }}
        onValuesChange={onValuesChange}
      >
        <DatePicker
          id="jurisdictionAuditComplete"
          className="left"
          label="Jurisdiction Audit Complete"
          isEditing={canBeEditable(jurisdictionAuditComplete, isEditing)}
          formItemProps={{
            rules: ACTUAL_DATE(data?.jurisdictionAuditComplete),
          }}
        />
        <Input
          id="jurisdictionContactName"
          className="right"
          label="Jurisdiction Contact Name"
          isEditing={false}
        />
        <Input
          id="jurisdictionName"
          className="left"
          label="Jurisdiction Name"
          isEditing={false}
        />
        <Input
          id="jurisdictionContactPhone"
          className="right"
          label="Jurisdiction Contact Phone"
          isEditing={false}
        />
        <Autocomplete
          id="jurisdictionAuditBy"
          className="left"
          label="Audit By"
          options={permittingUsers || []}
          isEditing={canBeEditable(jurisdictionAuditBy, isEditing)}
        />
        <Input
          id="jurisdictionContactFax"
          className="right"
          label="Jurisdiction Contact Fax"
          isEditing={false}
        />
        <Input
          id="mailingStreetAddress"
          className="left"
          label="Mailing Street Address"
          isEditing={false}
        />
        <Input
          id="jurisdictionContactEmail"
          className="right"
          label="Jurisdiction Contact Email"
          isEditing={false}
        />
        <Input id="city" className="left" label="City" isEditing={false} />
        <Autocomplete
          id="state"
          className="left"
          label="State"
          options={states}
          isEditing={false}
        />
        <Input id="zip" className="left" label="Zip" isEditing={false} />
      </Form>
    </Panel>
  );
};
