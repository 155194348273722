import React, { ReactElement, ReactNode } from 'react';
import { actionButtons } from '@models/constants';
import { AdminDriverManagementData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';

export const getAdminDriverManagementColumns = (
  editFunc: ColumnsFunc<AdminDriverManagementData>,
  deleteFunc: ColumnsFunc<AdminDriverManagementData>,
): Array<VirtualTableColumn> => [
  {
    title: 'Driver ID',
    dataIndex: 'driverID',
    key: 'driverID',
  },
  {
    title: 'Driver Name',
    dataIndex: 'driverName',
    key: 'driverName',
  },
  {
    title: 'Driver Notes',
    dataIndex: 'driverNote',
    key: 'driverNote',
    render: (driverNote: string): ReactNode => (
      <span className="ellipsis-6">{driverNote}</span>
    ),
  },
  {
    title: 'Workflow',
    dataIndex: 'workflows',
    key: 'workflows',
    render: (workflows: string[]): ReactElement => (
      <div className="list">
        {workflows?.map(
          (workflow: string): ReactNode => (
            <span key={workflow} className="list__item">
              {workflow.replace('_', ' ')}
            </span>
          ),
        )}
      </div>
    ),
  },
  {
    title: 'Price to AT&T',
    dataIndex: 'priceToATandT',
    key: 'priceToATandT',
    render: (priceToATandT: number): ReactNode =>
      priceToATandT && `$${priceToATandT}`,
  },
  {
    title: 'Included in Bundle Pricing',
    dataIndex: 'isIncludedInBundlePricing',
    key: 'isIncludedInBundlePricing',
    render: (isIncludedInBundlePricing: boolean): ReactNode =>
      isIncludedInBundlePricing ? 'Yes' : 'No',
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];
