import React, { FC } from 'react';
import { TableLegendItem, TableLegendProps } from '../../models/interfaces';

import './styles.scss';

export const TableLegend: FC<TableLegendProps> = ({
  items,
  additionElement,
}: TableLegendProps) => (
  <>
    <div className="prov-table-legend">
      <span className="title">Legend</span>
      <ul className="list">
        {items.map(({ value, color }: TableLegendItem) => (
          <li key={`${value}_${color}`} className="list__item">
            <i className={`circle circle_${color}`} />
            <span className="text">{value}</span>
          </li>
        ))}
      </ul>
    </div>
    {additionElement}
  </>
);
