import React, { FC } from 'react';
import { InfoBoxProps } from '../models/interfaces';

export const InfoBox: FC<InfoBoxProps> = ({
  title,
  value,
  status,
}: InfoBoxProps) => (
  <div className={`info-box info-box--${status}`}>
    <i className="info-box__circle" />
    <div className="info-box__data">
      <span className="title">{title}</span>
      <span className="value">{value}</span>
    </div>
  </div>
);
