import React, { FC, memo, PropsWithChildren } from 'react';
import { Form } from 'antd';
import classNames from 'classnames';
import { TextArea } from '@shared/modules';
import { LeasingEquipmentNotesProps } from './models';

export const LeasingEquipmentNotes: FC<LeasingEquipmentNotesProps> = memo(
  ({
    form,
    isEditing,
    className,
    onChanges,
    ...props
  }: PropsWithChildren<LeasingEquipmentNotesProps>) => (
    <Form
      form={form}
      className={classNames(className, 'prov-leasing-equipment__notes')}
    >
      <TextArea
        {...props}
        isEditing={isEditing}
        className="prov-leasing-equipment__notes-textarea"
        elementProps={{
          autoSize: { minRows: 3 },
          onChange: () => onChanges?.(),
        }}
      />
    </Form>
  ),
);

LeasingEquipmentNotes.displayName = 'LeasingEquipmentNotes';
