import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { HttpService } from '@core/services';
import { DashboardHttpService } from '@core/services/http';
import { tableCount } from '@models/constants';
import { AccountingNotificationData, DashboardItem } from '@models/interfaces';
import { CustomTable, Search, TableContainer } from '@shared/components';
import { DashboardSelectors } from '@store/selectors';
import { accountingNotificationSearcher } from './helpers';
import { ACCOUNTING_NOTIFICATIONS_COLUMNS } from './models';

export const AccountingNotificationsTable: FC<RouterProps> = ({
  history,
}: RouterProps) => {
  const items = useSelector(DashboardSelectors.getAccountingNotificationData);
  const isAccountingItemsFetching = useSelector(
    DashboardSelectors.isAccountingItemsFetching,
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [tableData, setTableData] =
    useState<DashboardItem<AccountingNotificationData>[]>(items);

  useEffect(() => {
    HttpService.getHttpRequests(DashboardHttpService).getAccountingItems();
  }, []);

  useEffect(() => {
    setTableData(accountingNotificationSearcher(items, searchValue));
  }, [searchValue, items]);

  const onRowClick = useCallback(
    ({
      notificationData: { redirectLink },
    }: DashboardItem<AccountingNotificationData>): void => {
      history.push(redirectLink);
    },
    [history],
  );

  return (
    <TableContainer
      header="Accounting Notifications"
      className="prov-dashboard-panel"
    >
      <Search
        className="prov-dashboard-panel__search"
        onChange={setSearchValue}
        allowClear
      />
      <CustomTable
        dataSource={tableData}
        columns={ACCOUNTING_NOTIFICATIONS_COLUMNS}
        place={tableCount(true)}
        pagination={{
          total: tableData?.length,
        }}
        onRowClick={onRowClick}
        module="home"
        subModule="accounting"
        loading={isAccountingItemsFetching}
      />
    </TableContainer>
  );
};
