import { PaceTaskType } from '@symfa-inc/providence-types';
import { createSelector } from 'reselect';
import {
  LeasingDetailsLeaseAuditPACETaskKeys,
  LeasingDetailsWorkflowEngineeringPanelPACETaskKeys,
  LeasingDetailsWorkflowLeasingPanelPACETaskKeys,
  LeasingDetailsWorkflowStructuralPanelPACETaskKeys,
  PaceTaskDatesNarrowed,
} from '@models/types';
import { AppState } from '../reducers';
import { LeasingState } from '../reducers/leasing.reducer';
import { PaceTasksSelectors } from './pace-tasks.selectors';

const leasingState = (state: AppState): LeasingState => state.leasing;
const appState = (state: AppState): AppState => state;

export namespace LeasingSelectors {
  // common
  export const getProjectId = createSelector(
    [leasingState],
    ({ leasingDetailsData: { id } }: LeasingState) => id,
  );
  // specific
  export const getLeasingBrowseInfo = createSelector(
    [leasingState],
    (leasing: LeasingState) => leasing.leasingBrowseData,
  );

  export const getLeasingDetailsSummaryData = createSelector(
    [leasingState],
    ({
      leasingDetailsData: {
        id: projectId,
        projectBundleID,
        projectTypeId,
        progressStatusId,
        projectStatusId,
        civilVendorId,
        projectSubtypeId,
        projectNote,
        site: {
          id: siteId,
          FALocationNumber,
          siteNumber,
          legacySiteID,
          towerOwnerId,
          towerOwnerSiteID,
          streetAddress,
          city,
          stateName,
          zip,
          countyName,
          jurisdictionName,
          latitude,
          longitude,
          towerTypeId,
          towerHeight,
          leasedGroundSpace,
          equipmentTypeId,
          siteNote,
        },
        leasing: { structural, leasingNote },
      },
    }) => ({
      siteInfo: {
        siteId,
        FALocationNumber,
        siteNumber,
        legacySiteID,
        towerOwnerId,
        towerOwnerSiteID,
        streetAddress,
        city,
        stateName,
        zip,
        countyName,
        jurisdictionName,
        latitude,
        longitude,
        towerTypeId,
        towerHeight,
        leasedGroundSpace,
        equipmentTypeId,
        siteNote,
      },
      projectInfo: {
        projectId,
        projectBundleID,
        projectTypeId,
        progressStatusId,
        projectStatusId,
        civilVendorId,
        projectSubtypeId,
        projectNote,
      },
      leasingInfo: {
        projectBundleID,
        leasingNote,
        structuralResultId: structural?.structuralResultId,
      },
    }),
  );

  export const getLeasingDetailsWorkflowEngineeringPanel = createSelector(
    [
      leasingState,
      PaceTasksSelectors.getMainPaceTaskDates(
        PaceTaskType.ActualDate,
        // Engineering Panel
        'mountMappingOrdered',
        'mountMappingReceived',
        'mountAnalysisOrdered',
        'failingMountAnalysisReceived',
        'passingMountAnalysisReceived',
        'passingMountAnalysisReviewedLeasing',
        'prelimCDsOrdered',
        'prelimCDsReceived',
        'finalCDsReviewedLeasing',
        'finalCDsReceived',
        'prelimCDsApprovedLeasing',
      ),
    ],
    (
      {
        leasingDetailsData: {
          civilVendorId,
          scoping: {
            AEVendorId,
            AEMountVendorId,
            failingMountAnalysisReviewedDate,
            mountAnalysisDetailsId,
            mountModelReplacementsIds,
            CDsLandlordApprovalRequiredId,
            CDsSentToLandlordDate,
            CDsReceivedFromLandlordDate,
          },
        },
      },
      {
        mountMappingOrdered,
        mountMappingReceived,
        mountAnalysisOrdered,
        failingMountAnalysisReceived,
        passingMountAnalysisReviewedLeasing,
        passingMountAnalysisReceived,
        prelimCDsOrdered,
        prelimCDsReceived,
        finalCDsReceived,
        finalCDsReviewedLeasing,
        prelimCDsApprovedLeasing,
      }: PaceTaskDatesNarrowed<LeasingDetailsWorkflowEngineeringPanelPACETaskKeys>,
    ) => ({
      AEVendorId,
      AEMountVendorId,
      mountMappingOrdered,
      mountMappingReceived,
      mountAnalysisOrdered,
      failingMountAnalysisReceived,
      failingMountAnalysisReviewedDate,
      passingMountAnalysisReceived,
      mountAnalysisDetailsId,
      mountModelReplacementsIds,
      passingMountAnalysisReviewedLeasing,
      civilVendorId,
      prelimCDsOrdered,
      prelimCDsReceived,
      CDsLandlordApprovalRequiredId,
      CDsSentToLandlordDate,
      CDsReceivedFromLandlordDate,
      prelimCDsApprovedLeasing,
      finalCDsReceived,
      finalCDsReviewedLeasing,
    }),
  );

  export const getLeasingDetailsWorkflowLeasingPanel = createSelector(
    [
      leasingState,
      PaceTasksSelectors.getMainPaceTaskDates(
        PaceTaskType.ActualDate,
        'leaseAuditComplete',
        'SOFIssued',
        'coloAppDrafted',
        'coloAppApproved',
        'coloAppSubmitted',
        'PALOrNPAReceived',
        'leaseDraftReceived',
        'LMPSSubmitted',
        'LMPSApproved',
        'leaseOrAmendmentFullyExecuted',
        'NTPApproved',
        'NTPRequested',
      ),
    ],
    (
      {
        leasingDetailsData: {
          leasing: {
            landlordRequiresMAId,
            PALOrNPARequiredId,
            leaseDocumentName,
            leaseTypeId,
            leaseRevisionRequestedDate,
            legalSubmittedDate,
            legalAssignedDate,
            legalCompanyId,
            legalApprovedDate,
            outForSignatureDate,
            eSignatureId,
            leasingNote,
          },
          site: { NTPRequirement },
        },
      },
      {
        leaseAuditComplete,
        SOFIssued,
        coloAppDrafted,
        coloAppSubmitted,
        coloAppApproved,
        PALOrNPAReceived,
        leaseDraftReceived,
        LMPSSubmitted,
        LMPSApproved,
        leaseOrAmendmentFullyExecuted,
        NTPRequested,
        NTPApproved,
      }: PaceTaskDatesNarrowed<LeasingDetailsWorkflowLeasingPanelPACETaskKeys>,
    ) => ({
      leaseAuditComplete,
      SOFIssued,
      landlordRequiresMAId,
      coloAppDrafted,
      coloAppSubmitted,
      coloAppApproved,
      PALOrNPARequiredId,
      PALOrNPAReceived,
      leaseDraftReceived,
      leaseDocumentName,
      leaseTypeId,
      leaseRevisionRequestedDate,
      LMPSSubmitted,
      LMPSApproved,
      legalSubmittedDate,
      legalAssignedDate,
      legalCompanyId,
      legalApprovedDate,
      outForSignatureDate,
      eSignatureId,
      leaseOrAmendmentFullyExecuted,
      NTPRequirement,
      NTPRequested,
      NTPApproved,
      leasingNote,
    }),
  );

  export const getLeasingDetailsWorkflowStructuralPanel = createSelector(
    [
      leasingState,
      PaceTasksSelectors.getMainPaceTaskDates(
        PaceTaskType.ActualDate,
        'structuralOrdered',
        'passingStructuralReceived',
        'failingStructuralReceived',
        'modDesignComplete',
        'IBMApproved',
        'modConstructionStart',
        'modConstructionComplete',
      ),
    ],
    (
      {
        leasingDetailsData: {
          leasing: { structural },
        },
      },
      {
        structuralOrdered,
        passingStructuralReceived,
        failingStructuralReceived,
        modDesignComplete,
        IBMApproved,
        modConstructionStart,
        modConstructionComplete,
      }: PaceTaskDatesNarrowed<LeasingDetailsWorkflowStructuralPanelPACETaskKeys>,
    ) => ({
      isStructuralRequired: structural?.isStructuralRequired ?? null,
      structuralOrdered: structuralOrdered ?? null,
      structuralVendorId: structural?.structuralVendorId ?? null,
      structuralPOIssued: structural?.structuralPOIssued ?? null,
      structuralServiceId: structural?.structuralServiceId ?? null,
      structuralResultId: structural?.structuralResultId ?? null,
      passingStructuralReceived: passingStructuralReceived ?? null,
      structuralComment: structural?.structuralComment ?? null,
      structuralTowerPercent: structural?.structuralTowerPercent ?? null,
      isStructuralAdequate: structural?.isStructuralAdequate ?? false,
      structuralFoundationPercent:
        structural?.structuralFoundationPercent ?? null,
      structuralTIARevCodeId: structural?.structuralTIARevCodeId ?? null,
      failingStructuralReceived: failingStructuralReceived ?? null,
      modCost: structural?.modCost ?? null,
      orderModDesignDate: structural?.orderModDesignDate ?? null,
      modDesignComplete: modDesignComplete ?? null,
      PMSAReceivedDate: structural?.PMSAReceivedDate ?? null,
      PMSATowerPercent: structural?.PMSATowerPercent ?? null,
      PMSAFoundationPercent: structural?.PMSAFoundationPercent ?? null,
      ATTModApprovedDate: structural?.ATTModApprovedDate ?? null,
      IBMEligibleId: structural?.IBMEligibleId ?? null,
      IBMApproved: IBMApproved ?? null,
      modConstructionStart: modConstructionStart ?? null,
      modConstructionComplete: modConstructionComplete ?? null,
      PMIModTurnoverDate: structural?.PMIModTurnoverDate ?? null,
    }),
  );

  export const getLeaseAudiData = createSelector(
    [
      leasingState,
      PaceTasksSelectors.getMainPaceTaskDates(
        PaceTaskType.ActualDate,
        'leaseAuditComplete',
      ),
    ],
    (
      {
        leasingDetailsData: {
          leasing: { leasingNote, leaseAudit },
          leasingAgents,
          site: {
            towerOwnerId,
            towerOwnerSiteID,
            streetAddress,
            city,
            stateName,
            zip,
            countyName,
            jurisdictionName,
            latitude,
            longitude,
            towerTypeId,
            towerHeight,
            leasedGroundSpace,
            equipmentTypeId,
            sqInchesEquipmentAllowed,
            sqInchesEquipmentUsed,
            sqInchesCablesAllowed,
            sqInchesCablesUsed,
          },
        },
      },
      {
        leaseAuditComplete,
      }: PaceTaskDatesNarrowed<LeasingDetailsLeaseAuditPACETaskKeys>,
    ) => ({
      leaseAuditComplete,
      leaseAuditBy: leaseAudit?.leaseAuditBy ?? null,
      documentAudited: leaseAudit?.documentAudited ?? null,
      documentDate: leaseAudit?.documentDate ?? null,
      agreementTypeId: leaseAudit?.agreementTypeId ?? null,
      towerOwnerId,
      towerOwnerSiteID,
      streetAddress,
      city,
      stateName,
      zip,
      countyName,
      jurisdictionName,
      latitude,
      longitude,
      currentRentAmount: leaseAudit?.currentRentAmount ?? null,
      escalator: leaseAudit?.escalator ?? null,
      termLengthOrExtension: leaseAudit?.termLengthOrExtension ?? null,
      currentTermStartDate: leaseAudit?.currentTermStartDate ?? null,
      currentTermEndDate: leaseAudit?.currentTermEndDate ?? null,
      finalTermEndDate: leaseAudit?.finalTermEndDate ?? null,
      mostRecentSADate: leaseAudit?.mostRecentSADate ?? null,
      mostRecentTowerSAPercentage:
        leaseAudit?.mostRecentTowerSAPercentage ?? null,
      isMostRecentAdequate: leaseAudit?.isMostRecentAdequate ?? false,
      mostRecentFoundationSAPercentage:
        leaseAudit?.mostRecentFoundationSAPercentage ?? null,
      mostRecentSATIARevCodeId: leaseAudit?.mostRecentSATIARevCodeId ?? null,
      leasingNote,
      towerTypeId,
      towerHeight,
      leasedGroundSpace,
      equipmentTypeId,
      separateGeneratorSpace: leaseAudit?.separateGeneratorSpace ?? null,
      generatorDetails: leaseAudit?.generatorDetails ?? null,
      sqInchesEquipmentAllowed,
      sqInchesEquipmentUsed,
      sqInchesCablesAllowed,
      sqInchesCablesUsed,
      azimuthA: leaseAudit?.azimuthA ?? null,
      azimuthB: leaseAudit?.azimuthB ?? null,
      azimuthD: leaseAudit?.azimuthD ?? null,
      azimuthG: leaseAudit?.azimuthG ?? null,
      leasingAgents,
    }),
  );

  export const getLeasingSummary = createSelector(
    [leasingState],
    ({
      leasingInformation,
      leasingSite,
      currentProject,
      leasingAgents,
    }: LeasingState) => ({
      leasingInformation: {
        ...leasingInformation,
        leaseAudit: {
          ...leasingInformation?.leaseAudit,
          isMostRecentAdequate:
            leasingInformation?.leaseAudit?.isMostRecentAdequate ?? false,
        },
      },
      leasingSite,
      currentProject,
      leasingAgents,
    }),
  );

  export const getLeasingSite = createSelector(
    [leasingState],
    ({
      leasingDetailsData: {
        site: {
          towerOwnerId,
          siteNumber,
          FALocationNumber,
          isSiteOn,
          disablingNote,
          isHighRisk,
          riskCategoryId,
          legacySiteID,
        },
      },
    }: LeasingState) => ({
      siteNumber,
      FALocationNumber,
      isSiteOn,
      disablingNote,
      isHighRisk,
      towerOwner: towerOwnerId,
      riskCategory: riskCategoryId,
      legacySiteID,
    }),
  );

  export const getLeasingEquipmentSectionData = createSelector(
    [appState],
    ({
      equipment: { allUsedEquipments },
      leasing: {
        leasingEquipmentData: {
          curPriorTableData,
          scopingTableData,
          curFinalTableData,
        },
      },
    }: AppState) => ({
      allUsedEquipments,
      curPriorTableData,
      scopingTableData,
      curFinalTableData,
    }),
  );

  export const getLeasingEquipmentData = createSelector(
    [leasingState, getProjectId],
    (
      {
        equipmentTypeNames,
        leasingEquipmentData: {
          curPriorTableData,
          curFinalTableData,
          scopingTableData,
          priorTableData,
          finalTableData,
          scopingNotes,
          priorLeasingNote,
          finalLeasingNote,
        },
      }: LeasingState,
      projectId,
    ) => ({
      id: projectId,
      equipmentTypeNames,
      curPriorTableData,
      curFinalTableData,
      priorTableData,
      finalTableData,
      scopingTableData,
      scopingNotes,
      priorLeasingNote,
      finalLeasingNote,
    }),
  );

  export const getProjectType = createSelector(
    [leasingState],
    (state: LeasingState) => state.leasingDetailsData.projectTypeId,
  );

  export const isFetching = createSelector(
    [leasingState],
    (state: LeasingState) => state.isFetching,
  );
}
