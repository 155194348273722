import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import classNames from 'classnames';
import { RadioButtonProps } from '../../modules/ui-kit/models/interfaces';
import { RadioButtonGroupProps } from './models/interfaces';

import './styles.scss';

export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  buttons,
  onChange,
  className,
  buttonRender = (button: ReactElement): ReactElement => button,
  ...props
}: PropsWithChildren<RadioButtonGroupProps>) => (
  <Radio.Group
    defaultValue={className}
    onChange={(e: RadioChangeEvent): void => {
      onChange(e.target.value);
    }}
    className="mode-handler"
    {...props}
  >
    {buttons.map((buttonProps: RadioButtonProps) =>
      buttonRender(
        <Radio.Button
          {...buttonProps}
          key={buttonProps.value}
          className={classNames({ [`${className}__button`]: true })}
        >
          {buttonProps.viewValue}
        </Radio.Button>,
        buttonProps.value,
      ),
    )}
  </Radio.Group>
);
