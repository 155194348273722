import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { SubPage, Tab } from '@symfa-inc/providence-types';
import { SiteAuditComponent } from '@client/pages/engineering/components';
import { UserSelectors } from '@store/selectors';

import './styles.scss';

export const EngineeringDetailsSiteAuditTab: FC<RouteComponentProps> = () => {
  const permissions = useSelector(
    UserSelectors.getEngineeringSiteAuditPermissions,
  );
  const canEdit = useSelector(
    UserSelectors.canEdit(
      'engineering',
      SubPage.EngineeringBrowse,
      Tab.SiteAudit,
    ),
  );

  return (
    <div className="prov-engineering-site-audit-tab">
      <SiteAuditComponent
        canUserEdit={canEdit}
        defaultEditing={false}
        permissions={permissions}
      />
    </div>
  );
};
