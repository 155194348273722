import { AccountingBrowse, SiteBrowse } from '@models/interfaces';

export const ACCOUNTING_BROWSE_DEFAULT_VALUE: AccountingBrowse = {
  id: '',
  categories: [],
  site: {} as SiteBrowse,
  projectType: {
    id: '',
    value: '',
  },
  projectWorkType: {
    id: '',
    value: '',
  },
  projectPaceTaskData: [],
};
