import { Rule } from 'antd/es/form';
import { RuleObject } from 'rc-field-form/lib/interface';
import { DateWorker } from '@core/utils/date-worker';

export const DATE_INPUT_FORMAT_VALIDATOR: Rule[] = [
  {
    validator: (_: RuleObject, inputValue: DateWorker): Promise<void> =>
      inputValue == null || inputValue.isValid()
        ? Promise.resolve()
        : Promise.reject(new Error('Should be a date with correct format')),
  },
];
