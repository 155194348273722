import {
  CreateNotificationData,
  NotificationData,
  ProjectType,
} from '@symfa-inc/providence-types';
import { createReducer, on } from '../../core/utils/store';
import {
  BrowseColumnsSettings,
  ColumnSettingsResponse,
  PayloadAndState,
  User,
} from '../../models/interfaces';
import { UserActions } from '../actions/user.actions';

export interface UserState {
  activeUser: Omit<User, 'notifications'>;
  notifications: NotificationData[];
  settingsColumns: BrowseColumnsSettings;
  isFetching: boolean;
  errors: boolean;
}

const initialState: UserState = {
  activeUser: {} as User,
  notifications: [],
  settingsColumns: {} as BrowseColumnsSettings,
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  on(
    UserActions.getActiveUserAction,
    ({
      payload: { notifications, ...activeUser },
    }: PayloadAndState<User, UserState>) => ({
      activeUser,
      notifications,
      settingsColumns: activeUser?.settings?.columns,
    }),
  ),
  on(
    UserActions.changeUserPasswordAction,
    ({ state: { activeUser } }: PayloadAndState<null, UserState>) => ({
      activeUser: {
        ...activeUser,
        isTempPassword: false,
      },
    }),
  ),
  on(
    UserActions.changeColumnsSettings,
    ({
      state: { activeUser },
      payload: { module, subModule, columns },
    }: PayloadAndState<ColumnSettingsResponse, UserState>) => ({
      activeUser: {
        ...activeUser,
        settings: {
          ...activeUser.settings,
          columns: {
            ...activeUser.settings.columns,
            [module]: ['home', 'accounting'].includes(module)
              ? {
                  ...activeUser.settings.columns[module],
                  [subModule!]: columns,
                }
              : columns,
          },
        },
      },
    }),
  ),
  on(
    UserActions.changePMProjectType,
    ({
      state: { activeUser },
      payload: projectType,
    }: PayloadAndState<ProjectType, UserState>) => ({
      activeUser: {
        ...activeUser,
        settings: {
          ...activeUser.settings,
          defaultPMProjectType: projectType,
        },
      },
    }),
  ),
  on(
    UserActions.addNotification,
    ({
      payload,
      state,
    }: PayloadAndState<CreateNotificationData, UserState>) => ({
      ...state,
      notifications: [...state.notifications, payload],
    }),
  ),
  on(
    UserActions.changeSubscriptionStatus,
    ({
      payload: isSubscribed,
      state,
    }: PayloadAndState<boolean, UserState>) => ({
      ...state,
      activeUser: {
        ...state.activeUser,
        isSubscribed,
      },
    }),
  ),
  on(
    UserActions.clearCurrent,
    ({ payload: _id, state }: PayloadAndState<string, UserState>) => ({
      ...state,
      notifications: state.notifications.filter(
        ({ _id: entityId }: NotificationData) => entityId !== _id,
      ),
    }),
  ),
  on(UserActions.clearAll, ({ state }: PayloadAndState<void, UserState>) => ({
    ...state.notifications,
    notifications: [],
  })),
);
