import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { NotificationState } from '../../reducers/admin/notification.reducer';

const notificationState = (state: AppState): NotificationState =>
  state.admin.notification;

export namespace NotificationSelectors {
  export const getPaceTasksList = createSelector(
    [notificationState],
    (state: NotificationState) => state.paceTasksList,
  );

  export const getUserList = createSelector(
    [notificationState],
    (state: NotificationState) => state.userList,
  );

  export const getRules = createSelector(
    [notificationState],
    (state: NotificationState) => state.rules,
  );

  export const getSchedules = createSelector(
    [notificationState],
    (state: NotificationState) => state.schedules,
  );

  export const getEmailCertificationDate = createSelector(
    [notificationState],
    (state: NotificationState) => state.emailCertificationDate,
  );

  export const getDirectMessages = createSelector(
    [notificationState],
    (state: NotificationState) => state.directMessages,
  );
}
