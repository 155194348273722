import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { momentizeObjectDates } from '@core/utils/methods';
import { YES_NO_GROUP } from '@models/constants';
import { EditableInformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Autocomplete, DatePicker, Input, RadioGroup } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { TRANSFORM_SCOPING_DATES } from '../../models';

export const PreScopingAssessment: FC<EditableInformationProps> = ({
  className,
  isEditing,
  form,
  data,
  onValuesChange,
}: PropsWithChildren<EditableInformationProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  useEffect(() => {
    if (data) {
      form?.setFieldsValue({
        ...momentizeObjectDates(data, TRANSFORM_SCOPING_DATES),
      });
    }
  }, []);

  return (
    <Panel header="Pre-Scoping Assessment" className={className}>
      <Form
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span="12">
            <Autocomplete
              id="scopingMountAssessment"
              label="Scoping-Mount Assessment"
              isEditing={isEditing}
              options={dropdownsOptions?.ScopingMountAssessment}
            />
            <RadioGroup
              id="isPreviousMountAnalysisInFile"
              label="Previous Mount Analysis in File"
              buttons={YES_NO_GROUP}
              isEditing={isEditing}
            />
            <Input
              id="previousMountAnalysisResult"
              label="Previous Mount Analysis Result"
              isEditing={isEditing}
            />
            <RadioGroup
              id="isPreviousMountMappingInFile"
              label="Previous Mount Mapping in File"
              buttons={YES_NO_GROUP}
              isEditing={isEditing}
            />
          </Col>
          <Col span="12">
            <RadioGroup
              id="isPreviousAMSSPELetter"
              label="Previous AMSS/PE Letter in File"
              buttons={YES_NO_GROUP}
              isEditing={isEditing}
            />
            <Autocomplete
              id="previousMountModels"
              label="Previous Mount Models"
              isEditing={isEditing}
              elementProps={{
                mode: 'multiple',
              }}
              options={dropdownsOptions?.MountModel}
            />
            <DatePicker
              id="scheduledScopingDate"
              label="Scheduled Scoping Date"
              isEditing={isEditing}
            />
            <RadioGroup
              id="isSiteAuditRequired"
              label="Site Audit Required"
              buttons={YES_NO_GROUP}
              isEditing={isEditing}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
