import React, {
  FC,
  MouseEvent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Carousel, Empty } from 'antd';
import { HttpService } from '@core/services';
import { SitePicturesUploadHttpService } from '@core/services/http';
import { getStaticAPIPath, toggleModal } from '@core/utils/methods';
import { PrimaryButton } from '@shared/modules';
import { SitePicturesUploadActions } from '@store/actions';
import { SitePictureUploadSelectors } from '@store/selectors';
import { DeleteModal } from '../delete-modal';
import { NotificationsLoader } from '../notifications';
import { PreviewModal } from './components';
import { SiteImagesCarouselProps } from './models';

import './styles.scss';

export const SiteImagesCarousel: FC<SiteImagesCarouselProps> = ({
  FALocationNumber,
  isAdminPlacement = false,
}: PropsWithChildren<SiteImagesCarouselProps>) => {
  const siteImages = useSelector(SitePictureUploadSelectors.getSiteImages);

  const [imgModalVisible, setImgModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [selectedSlideIndex, setSelectedSlideIndex] = useState<number>(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (FALocationNumber) {
      HttpService.getHttpRequests(
        SitePicturesUploadHttpService,
      ).getSiteImagesByFALocationNumber(FALocationNumber);
    } else {
      dispatch(SitePicturesUploadActions.getSiteImages.done([]));
    }
  }, [FALocationNumber]);

  useEffect(() => {
    setSelectedSlideIndex(0);
  }, [FALocationNumber]);

  const settings = {
    customPaging(i: number) {
      return (
        <img
          src={getStaticAPIPath(siteImages[i].path)}
          alt="carousel paging item"
          style={{ maxWidth: '8vw', height: '8vh' }}
          crossOrigin="use-credentials"
        />
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <RightOutlined />,
    prevArrow: <LeftOutlined />,
    beforeChange: (_: number, current: number) =>
      setSelectedSlideIndex(current),
  };

  const onImageDelete = async () => {
    try {
      await HttpService.getHttpRequests(
        SitePicturesUploadHttpService,
      ).deleteSiteImage(siteImages[selectedSlideIndex].id);

      setSelectedSlideIndex(currentIndex =>
        currentIndex !== 0 ? currentIndex - 1 : currentIndex,
      );

      NotificationsLoader.notificationSuccess('Picture has been deleted!');

      setDeleteModalVisible(false);
    } catch (e) {
      console.error(e);
    }
  };

  const onCarouselWrapperClicked = ({ target }: MouseEvent<HTMLDivElement>) => {
    if ((target as HTMLElement).className === 'prov-carousel-slide-img') {
      setImgModalVisible(true);
    }
  };

  return FALocationNumber && siteImages.length ? (
    <div className="prov-carousel" onClick={onCarouselWrapperClicked}>
      <Carousel {...settings} arrows>
        {siteImages.map(({ id, path }) => (
          <img
            key={id}
            src={getStaticAPIPath(path)}
            alt="main carousel item"
            crossOrigin="use-credentials"
            className="prov-carousel-slide-img"
          />
        ))}
      </Carousel>
      {isAdminPlacement && (
        <div className="prov-carousel__delete-btn-wrapper">
          <PrimaryButton
            title="Delete Selected Picture"
            type="danger"
            onClick={toggleModal(setDeleteModalVisible)}
          />
          <DeleteModal
            onOk={onImageDelete}
            onCancel={toggleModal(setDeleteModalVisible)}
            visible={deleteModalVisible}
          />
        </div>
      )}
      <PreviewModal
        visible={imgModalVisible}
        toggleModal={toggleModal(setImgModalVisible)}
        imagePath={getStaticAPIPath(siteImages[selectedSlideIndex]?.path)}
      />
    </div>
  ) : (
    <Empty />
  );
};
