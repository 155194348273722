import { ReactNode } from 'react';
import { FormInstance } from 'antd/es/form';
import { FormElement } from '../interfaces';

export const getReadonlyElementFunction =
  <T,>(
    id: string,
    { formItemProps }: Partial<FormElement<T>>,
    renderValue: (value: any) => ReactNode = (value: any): ReactNode => value,
  ) =>
  (formInstance: FormInstance): ReactNode =>
    renderValue(formInstance.getFieldValue(formItemProps?.name ?? id));
