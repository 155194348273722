import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  EquipmentHttpService,
  ProjectCategoryHttpService,
  ScopingHttpService,
  SiteHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';
import { EquipmentType } from '@models/enums';
import store from '@store/index';
import { EngineeringScopingState } from '@store/reducers/engineering/scoping.reducer';

export class ScopingDetailsResolver implements Resolver {
  private static get _state(): EngineeringScopingState {
    return store.getState()?.engineering?.scoping || [];
  }

  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await Promise.all([
      HttpService.getHttpRequests(ScopingHttpService).getScopingDetails(
        projectBundleID,
      ),

      HttpService.getHttpRequests(ScopingHttpService).getScopingEquipment(
        projectBundleID,
      ),

      HttpService.getHttpRequests(
        ProjectCategoryHttpService,
      ).getProjectCategories(),

      HttpService.getHttpRequests(SiteHttpService).getFALocationNumberOptions(),

      HttpService.getHttpRequests(EquipmentHttpService).getEquipmentTypes(),
    ]);

    const equipmentTypeNames = new Set([
      ...(ScopingDetailsResolver._state?.equipmentTypeNames || []),
      EquipmentType.Cable,
    ]);

    await HttpService.getHttpRequests(
      EquipmentHttpService,
    ).getEquipmentsByTypes([...equipmentTypeNames]);
  }
}
