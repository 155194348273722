import { actionButtons } from '@models/constants';
import { EquipmentTypeData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';

export const equipmentTypeTableColumns = (
  editFunc: ColumnsFunc<EquipmentTypeData>,
  deleteFunc: ColumnsFunc<EquipmentTypeData>,
): Array<VirtualTableColumn> => [
  {
    title: 'Equipment Type',
    dataIndex: 'name',
    key: 'name',
  },
  actionButtons<EquipmentTypeData>({
    editFunc,
    deleteFunc,
  }) as VirtualTableColumn,
];
