import { createSelector } from 'reselect';
import { PaceTaskTabScreens } from '@models/enums';
import { AppState } from '../../reducers';
import { PaceTaskState } from '../../reducers/admin/pace-task.reducer';

const paceTaskState = (state: AppState): PaceTaskState => state.admin.paceTask;

export namespace PaceTaskSelectors {
  export const getExcludedPaceTsks = createSelector(
    [paceTaskState],
    (state: PaceTaskState) => state.excluded,
  );

  export const getArchiveData = createSelector(
    [paceTaskState],
    (state: PaceTaskState) => state.archive,
  );

  export const getAllPaceTasks = createSelector(
    [paceTaskState],
    (state: PaceTaskState) => state.allPaceTasks,
  );

  export const getScreenData = (screen: PaceTaskTabScreens) =>
    createSelector([paceTaskState], (state: PaceTaskState) => state[screen]);
}
