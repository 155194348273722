import { DeepPartial } from 'redux';
import { PaceTask } from '../interfaces';

export const PACE_TASK_PERMISSIONS: DeepPartial<PaceTask> = {
  permissions: {
    PACEActualDateEditors: [],
    PACEForecastedDateEditors: [],
    forecastedDateEditors: [],
    actualDateEditors: [],
    isCanBeDeleted: true,
  },
};
