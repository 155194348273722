import { actionCreatorFactory } from '@core/utils/factories';
import { PaceTask, PaceTaskScreenResponse } from '@models/interfaces';

const actionCreator = actionCreatorFactory('PACE_TASK');

export namespace PaceTaskActions {
  export const getAllPaceTasks =
    actionCreator.createAsyncAction<PaceTask[]>('GET_ALL_PACE_TASKS');

  export const getScreenPaceTasks =
    actionCreator.createAsyncAction<PaceTaskScreenResponse>(
      'GET_SCREEN_PACE_TASKS',
    );

  export const addPaceTask =
    actionCreator.createAsyncAction<PaceTask>('ADD_PACE_TASK');

  export const updatePaceTask =
    actionCreator.createAsyncAction<PaceTask>('UPDATE_PACE_TASK');

  export const updatePermissions =
    actionCreator.createAsyncAction<PaceTask[]>('UPDATE_PERMISSIONS');

  export const moveToArchive =
    actionCreator.createAsyncAction<PaceTaskScreenResponse>('MOVE_TO_ARCHIVE');

  export const deletePaceTask =
    actionCreator.createAsyncAction<string>('DELETE_PACE_TASK');

  export const moveFromArchive =
    actionCreator.createAsyncAction<PaceTaskScreenResponse>(
      'MOVE_FROM_ARCHIVE',
    );

  export const getArchivedPaceTasks =
    actionCreator.createAsyncAction<PaceTaskScreenResponse>(
      'GET_ARCHIVED_PACE_TASKS',
    );

  export const getExcludedPaceTasks = actionCreator.createAsyncAction<
    Omit<PaceTask, 'screens' | 'permissions'>[]
  >('GET_EXCLUDED_PACE_TASKS');

  export const addPaceTaskScreen =
    actionCreator.createAsyncAction<PaceTaskScreenResponse>(
      'ADD_PACE_TASK_SCREEN',
    );

  export const updatePaceTaskScreen =
    actionCreator.createAsyncAction<PaceTaskScreenResponse>(
      'UPDATE_PACE_TASK_SCREEN',
    );
}
