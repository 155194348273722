import React, { FC, PropsWithChildren } from 'react';
import { Col, Form, Row } from 'antd';
import { HttpService } from '@core/services';
import { PaceTaskHttpService } from '@core/services/http';
import { PACE_TASK_PERMISSIONS, USER_ROLE_OPTIONS } from '@models/constants';
import { PaceTaskPermissions } from '@models/interfaces';
import { Autocomplete, Modal } from '@shared/modules';
import { AccessPaceTaskModalProps } from './models/interfaces';

import './styles.scss';

const { useForm } = Form;

export const AccessPaceTaskModal: FC<AccessPaceTaskModalProps> = ({
  paceTaskId,
  onOk,
  ...props
}: PropsWithChildren<AccessPaceTaskModalProps>) => {
  const [form] = useForm();
  const save = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): Promise<void> => {
    const permissions = form.getFieldsValue() as Omit<
      PaceTaskPermissions,
      'id' | 'isCanBeDeleted'
    >;

    try {
      await HttpService.getHttpRequests(
        PaceTaskHttpService,
      ).updatePaceTasksPermissions(paceTaskId, permissions);
    } catch (ex) {
      console.error(ex);
    }

    if (onOk) {
      onOk(e);
      form.resetFields();
    }
  };

  return (
    <Modal
      title="Assign edit access"
      okText="Save"
      cancelText="Clear"
      className="prov-access-pace-task-modal"
      cancelButtonProps={{
        onClick: (): void => form.resetFields(),
      }}
      onOk={save}
      {...props}
    >
      <Form
        wrapperCol={{ span: 14 }}
        labelCol={{ span: 8 }}
        form={form}
        initialValues={PACE_TASK_PERMISSIONS}
      >
        <Row>
          <Col span="24">
            <Autocomplete
              id="forecastedDateEditors"
              elementProps={{ mode: 'multiple' }}
              label="Edit Forecasted Date"
              options={USER_ROLE_OPTIONS}
              formItemProps={{
                name: ['permissions', 'forecastedDateEditors'],
              }}
            />
            <Autocomplete
              id="actualDateEditors"
              elementProps={{ mode: 'multiple' }}
              label="Edit Actual Date"
              options={USER_ROLE_OPTIONS}
              formItemProps={{
                name: ['permissions', 'actualDateEditors'],
              }}
            />
            <Autocomplete
              id="PACEForecastedDateEditors"
              elementProps={{ mode: 'multiple' }}
              label="Edit PACE Forecasted Date"
              options={USER_ROLE_OPTIONS}
              formItemProps={{
                name: ['permissions', 'PACEForecastedDateEditors'],
              }}
            />
            <Autocomplete
              id="PACEActualDateEditors"
              elementProps={{ mode: 'multiple' }}
              label="Edit PACE Actual Date"
              options={USER_ROLE_OPTIONS}
              formItemProps={{
                name: ['permissions', 'PACEActualDateEditors'],
              }}
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
