import { Undefinable } from '@symfa-inc/providence-types';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from 'rc-field-form/es/interface';

export const isValidForm = (
  form: FormInstance,
  validationKeys: Undefinable<NamePath[]> = undefined,
): Promise<boolean> =>
  new Promise<boolean>((resolve: (result: boolean) => void) => {
    form
      .validateFields(validationKeys)
      .then(() => resolve(true))
      .catch((e: { errorFields: string[] }) => resolve(!e.errorFields.length));
  });
