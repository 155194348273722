import { actionCreatorFactory } from '../../core/utils/factories';
import {
  FinancialData,
  FinancialOOMInformation,
  FinancialServicesData,
  FinancialSiteFeesData,
} from '../../models/interfaces';

const actionCreator = actionCreatorFactory('FINANCIAL');

export namespace FinancialActions {
  export const getFinancialDataAction =
    actionCreator.createAsyncAction<FinancialData>('GET_FINANCIAL');
  export const addFinancialSiteFeeAction =
    actionCreator.createAsyncAction<FinancialSiteFeesData>(
      'ADD_FINANCIAL_SITE_FEE',
    );
  export const updateFinancialSiteFeeAction =
    actionCreator.createAsyncAction<FinancialSiteFeesData>(
      'UPDATE_FINANCIAL_SITE_FEE',
    );
  export const deleteFinancialSiteFeeAction =
    actionCreator.createAsyncAction<string>('DELETE_FINANCIAL_SITE_FEE');
  export const addFinancialServiceAction =
    actionCreator.createAsyncAction<FinancialServicesData>(
      'ADD_FINANCIAL_SERVICE',
    );
  export const updateFinancialServiceAction =
    actionCreator.createAsyncAction<FinancialServicesData>(
      'UPDATE_FINANCIAL_SERVICE',
    );
  export const deleteFinancialServiceAction =
    actionCreator.createAsyncAction<string>('DELETE_FINANCIAL_SERVICE');
  // OOM Information
  export const getOOMInformationDataAction = actionCreator.createAsyncAction<
    FinancialOOMInformation[]
  >('GET_OOM_INFORMATION');
  export const addOOMInformationDataAction =
    actionCreator.createAsyncAction<FinancialOOMInformation>(
      'ADD_OOM_INFORMATION',
    );
  export const updateOOMInformationDataAction =
    actionCreator.createAsyncAction<FinancialOOMInformation>(
      'UPDATE_OOM_INFORMATION',
    );
  export const deleteOOMInformationDataAction =
    actionCreator.createAsyncAction<string>('DELETE_OOM_INFORMATION');
}
