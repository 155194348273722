import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { canBeEditable, momentizeObjectDates } from '@core/utils/methods';
import { ACTUAL_DATE } from '@core/utils/validators';
import {
  FormProps,
  LeasingPermission,
  LeasingWorkflowUpdateData,
} from '@models/interfaces';
import { Panel } from '@shared/components';
import { Autocomplete, DatePicker } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { LEASING_ENGINEERING_INFO_DATES } from '../../models/constants';

interface Props extends FormProps {
  data: LeasingWorkflowUpdateData['engineering'];
  permissions: LeasingPermission.EngineeringFields;
}

export const EngineeringPanel: FC<Props> = ({
  form,
  isEditing,
  data,
  onValuesChange,
  permissions: {
    failingMountAnalysisReviewedDate,
    mountAnalysisDetailsId,
    passingMountAnalysisReviewedLeasing,
    CDsLandlordApprovalRequiredId,
    CDsSentToLandlordDate,
    CDsReceivedFromLandlordDate,
    prelimCDsApprovedLeasing,
    finalCDsReviewedLeasing,
  },
}: PropsWithChildren<Props>) => {
  const {
    AEVendorDDV,
    AEMountVendorDDV,
    MountAnalysisDetailsDDV,
    MountModelReplacementDDV,
    CivilVendorDDV,
    CDsLandlordApprovalRequiredDDV,
  } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'AEVendor',
      'AEMountVendor',
      'MountAnalysisDetails',
      'MountModelReplacement',
      'CivilVendor',
      'CDsLandlordApprovalRequired',
    ]),
  );

  useEffect(() => {
    if (data) {
      form?.setFieldsValue(
        momentizeObjectDates<LeasingWorkflowUpdateData['engineering']>(
          data,
          LEASING_ENGINEERING_INFO_DATES,
        ),
      );
    }
  }, [isEditing]);

  return (
    <Panel header="Engineering" className="engineering-panel" collapsible>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 14 }}
        onValuesChange={onValuesChange}
        form={form}
      >
        <Row>
          <Col span="11">
            <Autocomplete
              id="AEVendorId"
              label="A/E CD Vendor"
              options={AEVendorDDV}
              isEditing={false}
            />
            <Autocomplete
              id="AEMountVendorId"
              label="A/E Mount Vendor"
              options={AEMountVendorDDV}
              isEditing={false}
            />
            <DatePicker
              id="mountMappingOrdered"
              label="Mount Mapping Ordered"
              isEditing={false}
            />
            <DatePicker
              id="mountMappingReceived"
              label="Mount Mapping Received"
              isEditing={false}
            />
            <DatePicker
              id="mountAnalysisOrdered"
              label="Mount Analysis Ordered"
              isEditing={false}
            />
            <DatePicker
              id="failingMountAnalysisReceived"
              label="Failing Mount Analysis Received"
              isEditing={false}
            />
            <DatePicker
              id="failingMountAnalysisReviewedDate"
              label="Failing Mount Analysis Reviewed"
              isEditing={canBeEditable(
                failingMountAnalysisReviewedDate,
                isEditing,
              )}
            />
            <DatePicker
              id="passingMountAnalysisReceived"
              label="Passing Mount Analysis Received"
              isEditing={false}
            />
            <Autocomplete
              id="mountAnalysisDetailsId"
              label="Mount Analysis Details"
              options={MountAnalysisDetailsDDV}
              isEditing={canBeEditable(mountAnalysisDetailsId, isEditing)}
            />
            <Autocomplete
              id="mountModelReplacementsIds"
              label="Mount Model Replacements"
              options={MountModelReplacementDDV}
              isEditing={false}
            />
          </Col>
          <Col span="11">
            <DatePicker
              id="passingMountAnalysisReviewedLeasing"
              label="Passing Mount Analysis Reviewed - Leasing"
              isEditing={canBeEditable(
                passingMountAnalysisReviewedLeasing,
                isEditing,
              )}
              formItemProps={{
                rules: ACTUAL_DATE(data?.passingMountAnalysisReviewedLeasing),
              }}
            />
            <Autocomplete
              id="civilVendorId"
              label="Civil Vendor"
              options={CivilVendorDDV}
              isEditing={false}
            />
            <DatePicker
              id="prelimCDsOrdered"
              label="Prelim CDs Ordered"
              isEditing={false}
            />
            <DatePicker
              id="prelimCDsReceived"
              label="Prelim CDs Received"
              isEditing={false}
            />
            <Autocomplete
              id="CDsLandlordApprovalRequiredId"
              label="CDs Landlord Approval Required"
              options={CDsLandlordApprovalRequiredDDV}
              isEditing={canBeEditable(
                CDsLandlordApprovalRequiredId,
                isEditing,
              )}
            />
            <DatePicker
              id="CDsSentToLandlordDate"
              label="CDs Sent to Landlord"
              isEditing={canBeEditable(CDsSentToLandlordDate, isEditing)}
            />
            <DatePicker
              id="CDsReceivedFromLandlordDate"
              label="CDs Received from Landlord"
              isEditing={canBeEditable(CDsReceivedFromLandlordDate, isEditing)}
            />
            <DatePicker
              id="prelimCDsApprovedLeasing"
              label="Prelim CDs Approved - Leasing"
              isEditing={canBeEditable(prelimCDsApprovedLeasing, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.prelimCDsApprovedLeasing),
              }}
            />
            <DatePicker
              id="finalCDsReceived"
              label="Final CDs Received"
              isEditing={false}
            />
            <DatePicker
              id="finalCDsReviewedLeasing"
              label="Final CDs Reviewed - Leasing"
              isEditing={canBeEditable(finalCDsReviewedLeasing, isEditing)}
              formItemProps={{
                rules: ACTUAL_DATE(data?.finalCDsReviewedLeasing),
              }}
            />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
