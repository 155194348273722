import { ProjectType, Undefinable } from '@symfa-inc/providence-types';
import { SiteInformationType } from '@models/types';

export const getLTEAdditionalInfo = (
  formValues: Omit<SiteInformationType, 'site'>,
  projectType: ProjectType,
  unknownSwitchState: Undefinable<boolean>,
) =>
  projectType === ProjectType.LTE
    ? {
        powerPlantType: !unknownSwitchState ? formValues?.powerPlantType : null,
        unknownPowerPlantType: !unknownSwitchState
          ? null
          : formValues?.unknownPowerPlantType,
        isUnknownPowerPlantType: !!unknownSwitchState,
      }
    : {};
