import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-types';
import { RadioChangeEvent } from 'antd/lib/radio';
import { SelectValue } from 'antd/lib/select';
import { useFindCountyAndJurisdictions } from '@core/utils/hooks';
import { arrsToSelectableObject } from '@core/utils/methods';
import { ON_OFF_GROUP } from '@models/constants';
import {
  AdminDropdownValuesData,
  ConfigureFilterProps,
  OptionProps,
  SiteBrowseFilter,
  StateWithCounties,
} from '@models/interfaces';
import { Autocomplete, RadioGroup } from '@shared/modules';
import { SharedSelectors } from '@store/selectors';

import './styles.scss';

export const Filters: FC<ConfigureFilterProps<SiteBrowseFilter>> = ({
  value,
  onChanges,
  formProps,
}: PropsWithChildren<ConfigureFilterProps<SiteBrowseFilter>>) => {
  const { dropdownValues, states } = useSelector(
    SharedSelectors.getDropdownValuesAndStates,
  );

  const [stateId, setStateId] = useState<Undefinable<string>>();
  const [countyOptions, setCountyOptions] = useState<OptionProps[]>([]);
  const [jurisdictionOptions, setJurisdictionOptions] = useState<OptionProps[]>(
    [],
  );

  useFindCountyAndJurisdictions({
    stateId,
    setCountyOptions,
    setJurisdictionOptions,
    effectDepArray: [stateId, states],
  });

  useEffect(() => {
    if (value.stateId !== stateId) {
      setStateId(value.stateId);
    }
  }, [value]);

  return (
    <div className="prov-site-browse-filters">
      <RadioGroup
        id="isSiteOn"
        key="isSiteOn"
        label="Site On/Off"
        buttons={ON_OFF_GROUP}
        elementProps={{
          value: value.isSiteOn,
          onChange: (e: RadioChangeEvent): void =>
            onChanges({ isSiteOn: e.target.value }),
        }}
      />
      <Autocomplete
        id="towerOwnerId"
        key="towerOwnerId"
        label="Tower Owner"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.TowerOwner,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.towerOwnerId,
          onChange: (e: SelectValue): void =>
            onChanges({ towerOwnerId: e as string }),
        }}
      />
      <Autocomplete
        id="towerTypeId"
        key="towerTypeId"
        label="Tower Type"
        options={arrsToSelectableObject<AdminDropdownValuesData>(
          dropdownValues.TowerType,
          'id',
          'value',
        )}
        elementProps={{
          allowClear: true,
          value: value.towerTypeId,
          onChange: (e: SelectValue): void =>
            onChanges({ towerTypeId: e as string }),
        }}
      />
      <Autocomplete
        id="stateId"
        key="stateId"
        label="State"
        options={arrsToSelectableObject<StateWithCounties>(
          states,
          'id',
          'name',
        )}
        elementProps={{
          allowClear: true,
          value: value.stateId,
          onChange: (e: SelectValue): void => {
            formProps?.form?.resetFields(['countyId', 'jurisdictionId']);
            onChanges({ countyId: undefined, jurisdictionId: undefined });

            setStateId(e as string);
            onChanges({ stateId: e as string });
          },
        }}
      />
      <Autocomplete
        id="countyId"
        key="countyId"
        label="County"
        title="You should first select a state"
        tooltip={!countyOptions?.length}
        options={countyOptions}
        elementProps={{
          allowClear: true,
          value: value.countyId,
          onChange: (e: SelectValue): void => {
            onChanges({ countyId: e as string });
          },
          disabled: !countyOptions?.length,
        }}
      />
      <Autocomplete
        id="jurisdictionId"
        key="jurisdictionId"
        label="Jurisdiction"
        title="You should first select a state"
        tooltip={!countyOptions?.length}
        options={jurisdictionOptions}
        elementProps={{
          allowClear: true,
          value: value.jurisdictionId,
          onChange: (e: SelectValue): void =>
            onChanges({ jurisdictionId: e as string }),
          disabled: !jurisdictionOptions.length,
        }}
      />
    </div>
  );
};
