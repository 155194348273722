import React, { FC } from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { ExtendedRouter } from '@wellyes/react-router-extended';
import { MainLayout } from '@layout/main-layout';
import { routeMapper } from './core/utils/methods';
import { Route } from './models/classes';
import { promptCallback } from './models/constants';
import { APP_ROUTES } from './modules/module.routing';

export const Router: FC = () => (
  <BrowserRouter getUserConfirmation={promptCallback}>
    <MainLayout>
      <Switch>
        {APP_ROUTES.map((route: Route) => (
          <ExtendedRouter key={route.path} {...routeMapper(route)} />
        ))}
        <Redirect to="/404" />
      </Switch>
    </MainLayout>
  </BrowserRouter>
);
