import { arraySort } from '../../core/utils/methods';
import { createReducer, on } from '../../core/utils/store';
import { EquipmentType } from '../../models/enums';
import {
  CreateEquipmentManufacturerResponse,
  EquipmentFullInfo,
  EquipmentManufacturerData,
  EquipmentManufacturerResponse,
  EquipmentsBrowseResponse,
  EquipmentTabData,
  EquipmentTypeData,
  PayloadAndState,
  RemoveEquipmentManufacturerResponse,
} from '../../models/interfaces';
import { SectorsByTypes } from '../../models/types';
import { EquipmentActions } from '../actions/equipment.actions';

export interface EquipmentState {
  equipmentTypes: EquipmentTypeData[];
  equipmentManufacturers: EquipmentManufacturerData[];
  equipments: EquipmentTabData[];
  equipmentsBrowse: EquipmentsBrowseResponse;
  allUsedEquipments: EquipmentFullInfo[];
  sectorsEquipmentsByTypes: SectorsByTypes;
  currentEquipments: EquipmentFullInfo[];
  cableEquipments: EquipmentFullInfo[];
  raycapEquipments: EquipmentFullInfo[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: EquipmentState = {
  equipmentTypes: [],
  equipmentManufacturers: [],
  equipments: [],
  equipmentsBrowse: {
    items: [],
    total: 0,
  },
  sectorsEquipmentsByTypes: {},
  allUsedEquipments: [],
  currentEquipments: [],
  cableEquipments: [],
  raycapEquipments: [],
  isFetching: false,
  errors: false,
};

export const equipmentReducer = createReducer(
  initialState,
  // Equipment Management - Equipment Types
  // get all
  on(
    EquipmentActions.getEquipmentTypes,
    ({
      payload: equipmentTypes,
    }: PayloadAndState<EquipmentTypeData[], EquipmentState>) => ({
      equipmentTypes,
    }),
  ),
  // add new
  on(
    EquipmentActions.addEquipmentType,
    ({
      payload,
      state: { equipmentTypes },
    }: PayloadAndState<EquipmentTypeData, EquipmentState>) => ({
      equipmentTypes: arraySort<EquipmentTypeData>(
        [...equipmentTypes, payload],
        'ASC',
        'name',
      ),
    }),
  ),
  // edit
  on(
    EquipmentActions.editEquipmentType,
    ({
      payload,
      state: { equipmentTypes },
    }: PayloadAndState<EquipmentTypeData, EquipmentState>) => ({
      equipmentTypes: arraySort<EquipmentTypeData>(
        [
          ...equipmentTypes.filter(
            (equipmentType: EquipmentTypeData) =>
              equipmentType.id !== payload.id,
          ),
          payload,
        ],
        'ASC',
        'name',
      ),
    }),
  ),
  // remove
  on(
    EquipmentActions.removeEquipmentType,
    ({
      payload: id,
      state: { equipmentTypes },
    }: PayloadAndState<string, EquipmentState>) => ({
      equipmentTypes: equipmentTypes.filter(
        (equipmentType: EquipmentTypeData) => equipmentType.id !== id,
      ),
    }),
  ),
  // Equipment Management - Equipment Manufacturers
  // get all
  on(
    EquipmentActions.getEquipmentManufacturers,
    ({
      payload: equipmentManufacturers,
    }: PayloadAndState<EquipmentManufacturerData[], EquipmentState>) => ({
      equipmentManufacturers,
    }),
  ),
  // add new
  on(
    EquipmentActions.addEquipmentManufacturer,
    ({
      payload,
      state: { equipmentManufacturers, equipmentTypes },
    }: PayloadAndState<
      CreateEquipmentManufacturerResponse,
      EquipmentState
    >) => ({
      equipmentManufacturers: arraySort<EquipmentManufacturerData>(
        [...equipmentManufacturers, payload.equipmentManufacturer],
        'ASC',
        'name',
      ),
      equipmentTypes: arraySort<EquipmentTypeData>(
        [
          ...equipmentTypes.filter(
            (equipmentType: EquipmentTypeData) =>
              equipmentType.id !== payload.equipmentType.id,
          ),
          payload.equipmentType,
        ],
        'ASC',
        'name',
      ),
    }),
  ),
  // edit
  on(
    EquipmentActions.editEquipmentManufacturer,
    ({
      payload,
      state: { equipmentManufacturers, equipmentTypes },
    }: PayloadAndState<EquipmentManufacturerResponse, EquipmentState>) => ({
      equipmentManufacturers: arraySort<EquipmentManufacturerData>(
        [
          ...equipmentManufacturers.filter(
            (equipmentManufacturer: EquipmentManufacturerData) =>
              equipmentManufacturer.id !==
              (payload.reassignedId ?? payload.equipmentManufacturer.id),
          ),
          payload.equipmentManufacturer,
        ],
        'ASC',
        'name',
      ),
      equipmentTypes: arraySort<EquipmentTypeData>(
        [
          ...equipmentTypes.filter(
            (equipmentType: EquipmentTypeData) =>
              equipmentType.id !== payload.equipmentType.id,
          ),
          payload.equipmentType,
        ],
        'ASC',
        'name',
      ),
    }),
  ),
  // remove
  on(
    EquipmentActions.removeEquipmentManufacturer,
    ({
      payload,
      state: { equipmentManufacturers, equipmentTypes },
    }: PayloadAndState<
      RemoveEquipmentManufacturerResponse,
      EquipmentState
    >) => ({
      equipmentManufacturers: equipmentManufacturers.filter(
        (equipmentManufacturer: EquipmentManufacturerData) =>
          equipmentManufacturer.id !== payload.id,
      ),
      equipmentTypes: arraySort<EquipmentTypeData>(
        [
          ...equipmentTypes.filter(
            (equipmentType: EquipmentTypeData) =>
              equipmentType.id !== payload.equipmentType.id,
          ),
          payload.equipmentType,
        ],
        'ASC',
        'name',
      ),
    }),
  ),
  // Equipment Management - Equipments
  // get all
  on(
    EquipmentActions.getEquipments,
    ({
      payload: equipments,
    }: PayloadAndState<EquipmentTabData[], EquipmentState>) => ({ equipments }),
  ),
  // add new
  on(
    EquipmentActions.addEquipment,
    ({
      payload,
      state: { equipments },
    }: PayloadAndState<EquipmentTabData, EquipmentState>) => ({
      equipments: arraySort<EquipmentTabData>(
        [...equipments, payload],
        'ASC',
        'modelNumber',
      ),
    }),
  ),
  // edit
  on(
    EquipmentActions.editEquipment,
    ({
      payload,
      state: { equipments },
    }: PayloadAndState<EquipmentTabData, EquipmentState>) => ({
      equipments: arraySort<EquipmentTabData>(
        [
          ...equipments.filter(
            (equipment: EquipmentTabData) => equipment.id !== payload.id,
          ),
          payload,
        ],
        'ASC',
        'modelNumber',
      ),
    }),
  ),
  // remove
  on(
    EquipmentActions.removeEquipment,
    ({
      payload: id,
      state: { equipments },
    }: PayloadAndState<string, EquipmentState>) => ({
      equipments: equipments.filter(
        (equipment: EquipmentTabData) => equipment.id !== id,
      ),
    }),
  ),
  // Equipment Browse - Equipments
  on(
    EquipmentActions.getEquipmentsByFilter,
    ({
      payload: equipmentsBrowse,
    }: PayloadAndState<EquipmentsBrowseResponse, EquipmentState>) => ({
      equipmentsBrowse,
    }),
  ),
  // Equipment - Equipments By Types
  on(
    EquipmentActions.getEquipmentsByTypes,
    ({
      payload,
      state: { allUsedEquipments, sectorsEquipmentsByTypes },
    }: PayloadAndState<EquipmentFullInfo[], EquipmentState>) => ({
      allUsedEquipments: [...allUsedEquipments, ...payload],
      currentEquipments: payload,
      cableEquipments: payload.filter(
        (equipmentType: EquipmentFullInfo) =>
          equipmentType?.equipmentType?.name === EquipmentType.Cable,
      ),
      sectorsEquipmentsByTypes: {
        ...sectorsEquipmentsByTypes,
        ...payload.reduce(
          (acc: SectorsByTypes, curItem: EquipmentFullInfo) => ({
            ...acc,
            [curItem.equipmentType.id]: acc[curItem.equipmentType.id]
              ? [...acc[curItem.equipmentType.id], curItem]
              : [curItem],
          }),
          {},
        ),
      },
    }),
  ),
  // Equipment - Equipments By Manufacturers
  on(
    EquipmentActions.getEquipmentsByManufacturers,
    ({
      payload,
      state: { allUsedEquipments },
    }: PayloadAndState<EquipmentFullInfo[], EquipmentState>) => ({
      allUsedEquipments: [...allUsedEquipments, ...payload],
      raycapEquipments: payload.filter(
        (equipmentType: EquipmentFullInfo) =>
          equipmentType?.manufacturer?.name === 'Raycap',
      ),
    }),
  ),
);
