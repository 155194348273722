import React, { FC, PropsWithChildren } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { FormItem } from '@shared/modules';
import { getReadonlyElementFunction } from '../../models/constants';
import { FormElement } from '../../models/interfaces';

export const TextArea: FC<FormElement<TextAreaProps>> = ({
  id,
  elementProps,
  readOnlyElementRender,
  ...props
}: PropsWithChildren<FormElement<TextAreaProps>>) => (
  <FormItem
    id={id}
    editingElement={<Input.TextArea id={id} {...elementProps} />}
    readOnlyElement={getReadonlyElementFunction<TextAreaProps>(
      id,
      props,
      readOnlyElementRender,
    )}
    {...props}
  />
);
