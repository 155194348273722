import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Form } from 'antd';
import {
  arraySort,
  canBeEditable,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  JURISDICTION_DATES_FOR_MOMENTIZE,
  YES_NO_GROUP,
} from '@models/constants';
import { Panel, PermitTypeTable } from '@shared/components';
import {
  Autocomplete,
  Checkbox,
  DatePicker,
  Input,
  RadioGroup,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { JurisdictionAuditPermittingSectionProps } from '../../models';

export const JurisdictionAuditPermittingSection: FC<
  JurisdictionAuditPermittingSectionProps
> = ({
  isEditing,
  jurisdictionAuditPermittingFields: {
    permittingRequired,
    isPermittingReqBasedOnProjectScope,
    reasonForPermittingReqChange,
    tableAccess,
  },
  onValuesChange,
  data,
  form,
  hasPermitting,
}: PropsWithChildren<JurisdictionAuditPermittingSectionProps>) => {
  const { PermittingRequiredDDV, ExpeditingResubmissionApprovalTimelineDDV } =
    useSelector(
      DDVSelectors.getDDVValuesAsOptionsByTypes([
        'PermittingRequired',
        'ExpeditingResubmissionApprovalTimeline',
      ]),
    );

  useEffect(() => {
    form?.setFieldsValue(
      momentizeObjectDates(data, JURISDICTION_DATES_FOR_MOMENTIZE),
    );
  }, [isEditing]);

  return (
    <Panel
      header="Permitting"
      className="prov-permitting-jurisdiction-audit__section"
      collapsible={hasPermitting}
      restrictionText="Jurisdiction does not have Permitting for Project's Type and Subtype"
    >
      <Form
        className="prov-permitting-jurisdiction-audit__section__form"
        form={form}
        labelCol={{ span: 10 }}
        onValuesChange={onValuesChange}
      >
        <Autocomplete
          id="permittingRequired"
          className="left"
          label="Permitting Required"
          options={arraySort(PermittingRequiredDDV, 'DESC', 'viewValue')}
          isEditing={canBeEditable(permittingRequired, isEditing)}
        />
        <Autocomplete
          id="expeditingOrResubmissionApprovalTimeline"
          className="right"
          label="Expediting/Resubmission Approval Timeline"
          options={ExpeditingResubmissionApprovalTimelineDDV}
          isEditing={false}
        />
        <Checkbox
          id="isPermittingReqBasedOnProjectScope"
          className="left"
          label="Permitting Req Based On Project Scope"
          isEditing={canBeEditable(
            isPermittingReqBasedOnProjectScope,
            isEditing,
          )}
        />
        <Input
          id="specialLicensingOrBondingCondition"
          className="right"
          label="Special Licensing/Bonding Conditions"
          isEditing={false}
        />
        <Input
          id="reasonForPermittingReqChange"
          className="left"
          label="Reason For Permitting Req Change"
          isEditing={canBeEditable(reasonForPermittingReqChange, isEditing)}
        />
        <Input
          id="permittingNotes"
          className="right"
          label="Permitting Notes"
          isEditing={false}
        />
        <RadioGroup
          id="isWrittenDocumentationFromJurisdiction"
          className="left"
          label="Written Documentation from Jurisdiction"
          isEditing={false}
          buttons={YES_NO_GROUP}
        />
        <DatePicker
          id="jurisdictionReviewDate"
          className="right"
          label="Jurisdiction Review Date"
          isEditing={false}
        />
      </Form>
      <Divider />
      <PermitTypeTable
        isEditing={isEditing}
        tableAccess={tableAccess}
        permittingId={data.id}
        showPermitExpireDate
      />
    </Panel>
  );
};
