import React from 'react';
import { Marker, Popup, Tooltip } from 'react-leaflet';
import PushpinImg from 'assets/icons/pushpin.png';
import { Icon } from 'leaflet';
import { Candidate } from '@models/interfaces';

const icon = new Icon({
  iconUrl: PushpinImg,
  iconSize: [16, 16],
  iconAnchor: [8, 8],
});

export const getMapMarkers = (candidates: Candidate[]) =>
  candidates.map(
    ({ longitude, latitude, FALocationNumber, siteNumber, USID }) => (
      <Marker
        position={[latitude, longitude]}
        key={FALocationNumber}
        icon={icon}
      >
        <Tooltip direction="bottom" opacity={1} permanent>
          {FALocationNumber}
        </Tooltip>
        <Popup>
          <div>
            <div className="leaflet-popup-title">{FALocationNumber}</div>
            <div className="leaflet-popup-body">
              {siteNumber ? <span>Site Number: {siteNumber}</span> : null}
              {USID ? <span>USID: {USID}</span> : null}
            </div>
          </div>
        </Popup>
      </Marker>
    ),
  );
