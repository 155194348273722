import { ContactsData } from '@models/interfaces';

export const CONTACTS_DEFAULT_VALUE: ContactsData = {
  id: '',
  contactType: {
    id: '',
    value: '',
  },
  projectType: {
    id: '',
    value: '',
  },
  contactName: '',
  state: {
    id: '',
    name: '',
  },
};
