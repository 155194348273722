import React, {
  Dispatch,
  FC,
  ReactText,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'antd/lib/form/util';
import { PaginationConfig } from 'antd/lib/pagination';
import { SorterResult } from 'antd/lib/table/interface';
import Filter from 'assets/icons/filter.png';
import { HttpService } from '@core/services';
import { UserManagementHttpService } from '@core/services/http';
import { toggleModal } from '@core/utils/methods';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  BrowsePageOptions,
  BrowseSortOptions,
  UserManagementData,
  UserManagementFilterOptions,
} from '@models/interfaces';
import { CustomTable, DeleteModal } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { FiltersModal } from '@shared/modules/browse-page/components/filters-modal';
import { UserManagementSelectors } from '@store/selectors';
import {
  getUserManagementColumns,
  USER_MANAGEMENT_DEFAULT_VALUE,
} from './models/constants';
import { UserManagementFilters, UserModal } from './components';

import './styles.scss';

export const UserManagement: FC = () => {
  const [form] = useForm();

  const userManagementService = useMemo(
    () => HttpService.getHttpRequests(UserManagementHttpService),
    [],
  );

  const { total, users } = useSelector(
    UserManagementSelectors.getUserManagementUsersWithTotal,
  );

  const [isUserModalVisible, setUserModalVisible] = useState<boolean>(false);
  const [isFiltersModalVisible, setFiltersModalVisible] =
    useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );

  const [selectedItem, setSelectedItem] = useState<UserManagementData>(
    USER_MANAGEMENT_DEFAULT_VALUE,
  );

  const toggleUserModal = (type: ModalMainTypes): void => {
    setModalType(type);
    setUserModalVisible(!isUserModalVisible);

    if (isUserModalVisible) {
      setSelectedItem(USER_MANAGEMENT_DEFAULT_VALUE);
    }
  };

  const toggleFiltersModal = (): void => {
    setFiltersModalVisible(!isFiltersModalVisible);
  };

  const selectUser = (
    user: UserManagementData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(user);
    setter(!prevState);
  };

  const [pageOptions, setPageOptions] = useState<BrowsePageOptions>(
    BROWSE_DEFAULT_FILTER_VALUE,
  );
  const [sortOptions, setSortOptions] = useState<BrowseSortOptions>({
    sortBy: 'firstName',
    sortOrder: 'ascend',
  });
  const [filterOptions, setFilterOptions] =
    useState<UserManagementFilterOptions>({
      isActive: undefined,
      roles: [],
      responsibleForProjectType: undefined,
    });
  const [selectValueArray, setSelectValueArray] = useState<string[]>([]);

  useEffect(() => {
    userManagementService.getUsers({
      ...pageOptions,
      ...sortOptions,
      ...filterOptions,
    });
  }, [pageOptions, sortOptions, filterOptions]);

  const handlePaginationChange = (
    { pageSize, current }: PaginationConfig,
    filters: Record<string, ReactText[] | null>,
    sorter:
      | SorterResult<UserManagementData>
      | SorterResult<UserManagementData>[],
  ): void => {
    const { field: sortBy, order: sortOrder } =
      sorter as SorterResult<UserManagementData>;

    setPageOptions({ page: current, size: pageSize });
    setSortOptions({
      sortBy: sortOrder ? sortBy : undefined,
      sortOrder,
    });
  };

  const onSaveFilters = (filters: UserManagementFilterOptions): void => {
    setFilterOptions(filters);
    toggleFiltersModal();
  };

  const onDelete = async (): Promise<void> => {
    await userManagementService.deleteUser(selectedItem.id, {
      ...pageOptions,
      ...sortOptions,
      ...filterOptions,
    });
    setDeleteModalVisible(false);
    setSelectedItem(USER_MANAGEMENT_DEFAULT_VALUE);
  };

  return (
    <>
      <div className="prov-user-management">
        <div className="prov-user-management__handler">
          <PrimaryButton
            icon="+"
            title="Create User"
            className="create-user-btn"
            onClick={(): void => toggleUserModal(ModalMainTypes.Add)}
          />
          <UserModal
            visible={isUserModalVisible}
            modalType={modalType}
            toggleModal={toggleUserModal}
            formValues={selectedItem}
            currentOptions={{
              ...pageOptions,
              ...sortOptions,
              ...filterOptions,
            }}
          />
          <PrimaryButton
            className="configure-btn"
            icon={<img src={Filter} alt="filter icon" />}
            title="Configure filter"
            onClick={toggleFiltersModal}
          />
          <FiltersModal
            className="prov-user-management__filters"
            visible={isFiltersModalVisible}
            onCancel={toggleFiltersModal}
            onOk={(): void =>
              onSaveFilters(
                form.getFieldsValue() as UserManagementFilterOptions,
              )
            }
            formProps={{ form }}
            cancelButtonProps={{
              onClick: (): void => {
                setSelectValueArray([]);

                return form?.resetFields();
              },
            }}
          >
            <UserManagementFilters
              selectValueArray={selectValueArray}
              setSelectValueArray={setSelectValueArray}
            />
          </FiltersModal>
        </div>
      </div>
      <div className="prov-user-management__table-wrapper">
        <CustomTable
          columns={getUserManagementColumns(
            (userManagementDataItem: UserManagementData): void =>
              selectUser(
                userManagementDataItem,
                () => toggleUserModal(ModalMainTypes.Edit),
                isUserModalVisible,
              ),
            (userManagementDataItem: UserManagementData): void =>
              selectUser(
                userManagementDataItem,
                setDeleteModalVisible,
                deleteModalVisible,
              ),
          )}
          dataSource={users}
          onChange={handlePaginationChange}
          pagination={{ total }}
          hoverable={false}
        />
        <DeleteModal
          onOk={onDelete}
          onCancel={toggleModal(setDeleteModalVisible)}
          visible={deleteModalVisible}
        />
      </div>
    </>
  );
};
