import React, { FC, PropsWithChildren } from 'react';
import { Row } from 'antd';
import { ColumnType } from 'antd/lib/table';
import classNames from 'classnames';
import { Browse } from '@models/types';
import { getProjectManagementBrowseColumns } from '@modules/client/pages/project-management/components/browse/models';
import { Checkbox, FormItem, Select } from '@shared/modules';
import { ItemWithOperatorProps } from '../models';

import './styles.scss';

const PrivateItemWithOperator: FC<ItemWithOperatorProps> = ({
  id,
  label,
  mainItemProps,
  selectProps,
  component,
  checkbox,
}: PropsWithChildren<ItemWithOperatorProps>) => {
  const filterOption = (inputValue: string, option: any): boolean =>
    !!option &&
    String(option.label)
      .toUpperCase()
      .indexOf(String(inputValue).toUpperCase()) !== -1;

  const checkboxDisabled = getProjectManagementBrowseColumns([]) // if filters are added to other browse pages,  you will need to create method
    .map((column: ColumnType<Browse>) => column.title)
    .includes(label);

  return (
    <Row>
      {React.createElement(component, {
        ...mainItemProps,
        id,
        formItemProps: {
          name: [id, 'value'],
        },
        key: id,
      })}
      <Select
        className="prov-item-with-operator__operator"
        id={`${id}Operator`}
        formItemProps={{
          name: [id, 'operator'],
        }}
        elementProps={{
          filterOption,
          showSearch: true,
          ...selectProps.elementProps,
        }}
        {...selectProps}
      />
      {checkbox && (
        <Checkbox
          id={id}
          label=""
          tooltipMessage={
            checkboxDisabled ? 'Default display' : 'Display on Browse'
          }
          elementProps={{
            disabled: checkboxDisabled,
          }}
          formItemProps={{ name: [id, 'visible'] }}
          className="prov-item-with-operator__checkbox"
        />
      )}
    </Row>
  );
};

export const ItemWithOperator: FC<ItemWithOperatorProps> = ({
  id,
  label,
  mainItemProps,
  selectProps,
  className,
  component,
  checkbox,
  ...props
}: PropsWithChildren<ItemWithOperatorProps>) => (
  <FormItem
    id={id}
    label={label}
    className={classNames('prov-item-with-operator', className)}
    editingElement={
      <PrivateItemWithOperator
        id={id}
        label={label}
        mainItemProps={mainItemProps}
        selectProps={selectProps}
        component={component}
        checkbox={checkbox}
      />
    }
    {...props}
  />
);
