import React, { FC, ReactText, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { IReceivedDirectMessageForArea } from '@symfa-inc/providence-types';
import { CustomTable } from '@shared/components';
import { PrimaryButton, SwitchBox } from '@shared/modules';
import { DirectMessageActions } from '@store/actions';
import { DirectMessageSelectors } from '@store/selectors';
import { DirectMessageViewer } from './modals';
import {
  RECEIVED_DIRECT_MESSAGE_MANAGER_COLUMNS,
  ReceivedDirectMessageProps,
} from './models';

export const ReceivedDirectMessages: FC<ReceivedDirectMessageProps> = ({
  isFetching,
  userList,
  service,
  visible,
}: ReceivedDirectMessageProps) => {
  const dispatch = useDispatch();

  const data = useSelector(
    DirectMessageSelectors.getReceivedDirectMessagesForArea,
  );

  const [tableData, setTableData] =
    useState<IReceivedDirectMessageForArea[]>(data);
  const [currentItem, setCurrentItem] =
    useState<IReceivedDirectMessageForArea | null>(null);
  const [switchValue, setSwitchValue] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<ReactText[]>([]);
  const [visibleMessage, setVisibleMessage] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setSwitchValue(false);
      setSelectedRows([]);
      dispatch(DirectMessageActions.getReceivedDirectMessages.done([]));
    };
  }, [visible]);

  useEffect(() => {
    setTableData(
      switchValue
        ? data
        : data.filter(
            (directMessage: IReceivedDirectMessageForArea) =>
              !directMessage.isCompleted,
          ),
    );
  }, [data, switchValue]);

  const showMessageHandler = useCallback(
    (directMessage: IReceivedDirectMessageForArea): void => {
      setSelectedRows([]);
      setCurrentItem(directMessage);
      setVisibleMessage(true);
    },
    [],
  );

  const closeMessage = useCallback((): void => {
    setVisibleMessage(false);
  }, []);

  const changeSwitchValue = useCallback(
    (checked: boolean) => setSwitchValue(checked),
    [],
  );

  const changeRowSelected = useCallback(
    (rows: ReactText[]): void => setSelectedRows(rows),
    [],
  );

  const setCheckboxDisabled = useCallback(
    (directMessage: IReceivedDirectMessageForArea) => ({
      disabled: directMessage.isCompleted,
    }),
    [],
  );

  const setCompleted = useCallback(
    async (_id: string): Promise<void> => {
      await service.setCompletedDirectMessage(_id);

      closeMessage();
    },
    [service],
  );

  const multiSetCompleted = useCallback(async (): Promise<void> => {
    await service.multiSetCompletedDirectMessage(selectedRows as string[]);

    setSelectedRows([]);
  }, [selectedRows, service]);

  return (
    <>
      <CustomTable
        dataSource={tableData}
        columns={RECEIVED_DIRECT_MESSAGE_MANAGER_COLUMNS(
          userList,
          showMessageHandler,
          setCompleted,
        )}
        loading={isFetching}
        pagination={false}
        hoverable={false}
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: changeRowSelected,
          getCheckboxProps: setCheckboxDisabled,
        }}
        legendProps={{
          items: [
            { value: 'Completed', color: 'gray' },
            { value: 'Not completed', color: 'green' },
          ],
          additionElement: (
            <div className="legend-addition-elements">
              <PrimaryButton
                title="Set Completed"
                onClick={multiSetCompleted}
                disabled={!selectedRows.length}
              />
              <SwitchBox
                tooltip="Show Completed"
                checked={switchValue}
                onChange={changeSwitchValue}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </div>
          ),
        }}
      />
      <DirectMessageViewer
        usersList={userList}
        visible={visibleMessage}
        item={currentItem}
        onCancel={closeMessage}
        onCompleted={setCompleted}
      />
    </>
  );
};
