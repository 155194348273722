import { Rule } from 'antd/lib/form';

export const emailValidator = (
  required: boolean,
  {
    requiredMessage = 'Email is required!',
    errorMessage = 'Email must be an email!',
  }: Partial<Record<'requiredMessage' | 'errorMessage', string>> = {},
): Rule[] => [
  { type: 'email', message: errorMessage },
  ...(required ? [{ required: true, message: requiredMessage }] : []),
];
