import { actionCreatorFactory } from '@core/utils/factories';
import {
  AdminDistributionListResponse,
  AdminVendorDistributionListData,
  AdminVendorsData,
} from '@models/interfaces';

const actionCreator = actionCreatorFactory('VENDORS');

export namespace VendorsActions {
  export const getVendorsAction =
    actionCreator.createAsyncAction<AdminVendorsData[]>('GET_VENDORS');

  export const addVendorAction =
    actionCreator.createAsyncAction<AdminVendorsData>('ADD_VENDOR');

  export const updateVendorAction =
    actionCreator.createAsyncAction<AdminVendorsData>('UPDATE_VENDOR');

  export const deleteVendorAction =
    actionCreator.createAsyncAction<string>('DELETE_VENDOR');

  export const getVendorListsAction =
    actionCreator.createAsyncAction<AdminVendorDistributionListData[]>(
      'GET_VENDORS_LIST',
    );

  export const addVendorListAction =
    actionCreator.createAsyncAction<AdminDistributionListResponse>(
      'ADD_VENDOR_TO_LIST',
    );

  export const updateVendorListAction =
    actionCreator.createAsyncAction<AdminDistributionListResponse>(
      'UPDATE_VENDORS_LIST',
    );

  export const deleteVendorListAction = actionCreator.createAsyncAction<string>(
    'REMOVE_VENDOR_FROM_LIST',
  );

  export const assignManagementAction =
    actionCreator.createAsyncAction<AdminVendorDistributionListData>(
      'ASSIGN_MANAGEMENT',
    );
}
