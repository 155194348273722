import React, { FC, PropsWithChildren } from 'react';
import { getStaticAPIPath } from '@core/utils/methods';
import { Modal } from '@shared/modules';
import { PreviewModalProps } from '../models';

import './styles.scss';

export const PreviewModal: FC<PreviewModalProps> = ({
  visible,
  src,
  onClose,
}: PropsWithChildren<PreviewModalProps>) => (
  <Modal
    className="prov-sof-preview"
    visible={visible}
    onCancel={onClose}
    title="SOF Tool PDF preview"
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={{ style: { display: 'none' } }}
    width="70%"
    closable
  >
    <div className="prov-sof-preview__content">
      {visible && (
        <iframe className="iframe" src={getStaticAPIPath(src)} title="SOF" />
      )}
    </div>
  </Modal>
);
