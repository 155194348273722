import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Form, Slider } from 'antd';
import { HttpService } from '@core/services';
import { SitePicturesUploadHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { FormItem, Modal, PrimaryButton } from '@shared/modules';
import {
  Dimensions,
  INIT_ROTATION,
  MAX_ROTATION,
  ROTATION_STEP,
  UploadEditImgModalProps,
} from './models';

import './styles.scss';

export const UploadEditImgModal: FC<UploadEditImgModalProps> = ({
  onSubmit,
  visible,
  onClose,
  previewFile,
}: PropsWithChildren<UploadEditImgModalProps>) => {
  const [form] = Form.useForm();
  const containerRef = useRef<HTMLDivElement>(null);

  const siteImagesService = HttpService.getHttpRequests(
    SitePicturesUploadHttpService,
  );

  const [rotation, setRotation] = useState<number>(INIT_ROTATION);
  const [fileString, setFileString] = useState<string>();
  const [editedFile, setEditedFile] = useState<File>();
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (previewFile) {
      const file = previewFile.originFileObj as File;
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (event: ProgressEvent<FileReader>) =>
        setFileString(event?.target?.result as string);
    }
  }, [previewFile]);

  const onReset = () => {
    setEditedFile(undefined);
    setRotation(INIT_ROTATION);
  };

  const onApply = async () => {
    if (previewFile) {
      const resp = await siteImagesService.editImage(
        previewFile.originFileObj as File,
        {
          ...form.getFieldsValue(),
          angle: rotation,
        },
      );

      setEditedFile(
        new File([resp], previewFile.name, { type: previewFile.type }),
      );
    }
  };

  const onCloseModal = () => {
    onClose();
    setFileString(undefined);
    onReset();
  };

  const onModalOkBtn = () => {
    if (editedFile) {
      onSubmit(editedFile);
    }

    onCloseModal();
  };

  const getImageDimensions = (file: string) =>
    new Promise<void>((resolve: VoidFunction) => {
      const image = new Image();

      image.onload = () => {
        setDimensions({ width: image.width, height: image.height });
        resolve();
      };

      image.src = file;
    });

  useDidUpdateEffect(() => {
    if (fileString) {
      getImageDimensions(fileString);
    }
  }, [fileString]);

  const scaleValue = useMemo(() => {
    if (
      !containerRef.current ||
      rotation % 180 === 0 ||
      (dimensions.width &&
        dimensions.height &&
        dimensions.width === dimensions.height)
    ) {
      return 1;
    }

    const { clientWidth: containerWidth, clientHeight: containerHeight } =
      containerRef.current;
    const [{ clientWidth: imgWidth, clientHeight: imgHeight }] =
      containerRef.current.children;

    return imgWidth > imgHeight
      ? containerHeight / imgWidth
      : containerWidth / imgHeight;
  }, [rotation]);

  const rotate = (value: number) =>
    setRotation((prevValue: number) => (prevValue + value) % MAX_ROTATION);

  const onRotateLeft = useCallback(() => rotate(-ROTATION_STEP), []);
  const onRotateRight = useCallback(() => rotate(ROTATION_STEP), []);

  return (
    <Modal
      className="prov-site-upload__edit-modal"
      title="Edit image"
      visible={visible}
      onCancel={onCloseModal}
      width="fit-content"
    >
      <div className="prov-site-upload__edit-modal-img-wrapper">
        <div
          className="prov-site-upload__edit-modal-img-wrapper__container"
          ref={containerRef}
        >
          {fileString && (
            <img
              src={fileString}
              className="prov-site-upload__edit-modal-img"
              alt="preview"
              style={{
                transform: `rotate(${rotation}deg) scale(${scaleValue})`,
              }}
            />
          )}
        </div>
      </div>
      <Form
        form={form}
        className="prov-site-upload__edit-modal-handler"
        initialValues={{ quality: 80 }}
        labelCol={{ span: 4 }}
      >
        <FormItem
          id="quality"
          editingElement={
            <Slider
              id="quality"
              min={75}
              marks={{
                75: '75',
                100: '100',
              }}
            />
          }
          label="Quality"
        />
        <div className="rotation-row">
          <Button type="primary" onClick={onRotateLeft}>
            ↺
          </Button>
          <Button type="primary" onClick={onRotateRight}>
            ↻
          </Button>
        </div>
        <div className="handle-row">
          <PrimaryButton title="Apply" onClick={onApply} />
          <PrimaryButton title="Reset" type="default" onClick={onReset} />
        </div>
        <div className="save-btn-row">
          <PrimaryButton
            title="Save"
            onClick={onModalOkBtn}
            disabled={!editedFile}
          />
        </div>
      </Form>
    </Modal>
  );
};
