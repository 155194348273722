import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import Info from 'assets/icons/info.png';
import { SiteImagesCarousel } from '@shared/components';
import { Autocomplete, PrimaryButton } from '@shared/modules';
import { SitePictureUploadSelectors } from '@store/selectors';
import { UploadModal } from './components';

import './styles.scss';

export const SitePictures = () => {
  const selectorOptions = useSelector(
    SitePictureUploadSelectors.getFALocationOptions,
  );

  const [selectedFALocation, setSelectedFALocation] = useState<number>();
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);

  return (
    <div className="prov-admin-site-pictures">
      <Row className="prov-admin-site-pictures__handler">
        <Col span="7">
          <div className="label">
            <img className="label__image" src={Info} alt="edit row" />
            <span className="label__text">
              Please, select Site for which you need to upload images
            </span>
          </div>
          <Autocomplete
            id="FALocationNumber"
            label=""
            options={selectorOptions}
            elementProps={{
              onChange: (value: SelectValue): void => {
                setSelectedFALocation(value as number);
              },
              allowClear: true,
            }}
          />
        </Col>
        <PrimaryButton
          title="Upload"
          disabled={!selectedFALocation}
          onClick={() => setUploadModalVisible(true)}
        />
      </Row>
      <SiteImagesCarousel
        FALocationNumber={selectedFALocation}
        isAdminPlacement
      />
      <UploadModal
        visible={uploadModalVisible}
        toggleModal={(): void => setUploadModalVisible(false)}
        FALocationNumber={selectedFALocation!}
      />
    </div>
  );
};
