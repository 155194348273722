import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { canBeEditable, momentizeObjectDates } from '@core/utils/methods';
import { YES_NO_GROUP } from '@models/constants';
import { EditableInformationProps } from '@models/interfaces';
import { Panel, RADTable } from '@shared/components';
import {
  Autocomplete,
  DatePicker,
  InputNumber,
  RadioGroup,
} from '@shared/modules';
import { DDVSelectors, ScopingSelectors } from '@store/selectors';

export const ScopingInformation: FC<EditableInformationProps> = ({
  className,
  isEditing,
  form,
  data,
  onValuesChange,
}: PropsWithChildren<EditableInformationProps>) => {
  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  const permissions = useSelector(ScopingSelectors.getRADScopingPermissions);
  const azimuthFieldsPermissions = useSelector(
    ScopingSelectors.getAzimuthScopingPermissions,
  );

  useEffect(() => {
    if (data) {
      form?.setFieldsValue({
        ...momentizeObjectDates(data, ['prelimRFDSReceivedDate']),
      });
    }
  }, []);

  return (
    <Panel header="Scoping Information" className={className} collapsible>
      <Form
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span="12">
            <Row>
              <Col span="12">
                <DatePicker
                  id="prelimRFDSReceivedDate"
                  label="Prelim RFDS Received"
                  isEditing={isEditing}
                />
                <RadioGroup
                  id="isMicrowaveToBeRemoved"
                  label="Microwave to Be Removed"
                  buttons={YES_NO_GROUP}
                  isEditing={isEditing}
                />
                <RadioGroup
                  id="isCraneRequired"
                  label="Crane Required"
                  buttons={YES_NO_GROUP}
                  isEditing={isEditing}
                />
                <InputNumber
                  id="craneHeight"
                  label="Crane Height"
                  isEditing={isEditing}
                />
                <Autocomplete
                  id="mountModelReplacements"
                  label="Mount Model Replacements"
                  isEditing={isEditing}
                  elementProps={{
                    mode: 'multiple',
                  }}
                  options={dropdownsOptions?.MountModelReplacement}
                />
              </Col>
              <Col span="12">
                <InputNumber
                  id="azimuthA"
                  label="Azimuth A"
                  isEditing={canBeEditable(
                    azimuthFieldsPermissions.azimuthA,
                    isEditing,
                  )}
                />
                <InputNumber
                  id="azimuthB"
                  label="Azimuth B"
                  isEditing={canBeEditable(
                    azimuthFieldsPermissions.azimuthB,
                    isEditing,
                  )}
                />
                <InputNumber
                  id="azimuthG"
                  label="Azimuth G"
                  isEditing={canBeEditable(
                    azimuthFieldsPermissions.azimuthG,
                    isEditing,
                  )}
                />
                <InputNumber
                  id="azimuthD"
                  label="Azimuth D"
                  isEditing={canBeEditable(
                    azimuthFieldsPermissions.azimuthD,
                    isEditing,
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col span="11">
            <RADTable isEditing={isEditing} permissions={permissions} />
          </Col>
        </Row>
      </Form>
    </Panel>
  );
};
