import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ContactsPlace } from '@models/enums';
import { Contacts } from '@shared/components';
import { PermittingSelectors, UserSelectors } from '@store/selectors';

export const PermittingJurisdictionContacts: FC = () => {
  const { contacts } = useSelector(
    PermittingSelectors.getJurisdictionDetailsData(['contacts']),
  );
  const { contactsAccess } = useSelector(
    UserSelectors.getPermittingJurisdictionPermissions,
  );

  return (
    <div className="prov-permitting-contacts">
      <Contacts
        place={ContactsPlace.Permitting}
        data={contacts}
        editable={!!contactsAccess}
        hoverable={false}
      />
    </div>
  );
};
