import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { SitePicturesUploadState } from '../../reducers/admin/site-pictures-upload.reducer';

const sitePicturesUploadState = (state: AppState): SitePicturesUploadState =>
  state.admin.sitePicturesUpload;

export namespace SitePictureUploadSelectors {
  export const getFALocationOptions = createSelector(
    [sitePicturesUploadState],
    ({ FALocationNumberOptions }: SitePicturesUploadState) =>
      FALocationNumberOptions,
  );

  export const getSiteImages = createSelector(
    [sitePicturesUploadState],
    ({ currentSiteImages }: SitePicturesUploadState) => currentSiteImages,
  );

  export const isFetching = createSelector(
    [sitePicturesUploadState],
    (state: SitePicturesUploadState) => state.isFetching,
  );
}
