import React, { ReactNode } from 'react';
import { ColumnsType } from 'antd/lib/table';
import {
  arrsToSelectableObject,
  readOnlyInputNumberRender,
  readOnlySelectRender,
} from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import {
  EquipmentFullInfo,
  EquipmentModal,
  EquipmentTypeData,
} from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import store from '@store/index';

export const getLeasingEquipmentSectionColumns = ({
  editFunc,
  deleteFunc,
  isEditing,
  allEquipments,
  rowColors,
  isScopingTable,
}: {
  editFunc: ColumnsFunc<EquipmentModal>;
  deleteFunc: ColumnsFunc<EquipmentModal>;
  isEditing: boolean;
  allEquipments: EquipmentFullInfo[];
  rowColors: string[];
  isScopingTable: boolean;
}): ColumnsType<EquipmentModal> => {
  const {
    equipment: { equipmentTypes },
  } = store.getState();

  const getColoredRow = (child: ReactNode, index: number): ReactNode => (
    <div className={rowColors?.[index]}>{child}</div>
  );

  return [
    {
      title: 'Equipment Type',
      key: 'equipmentTypeName',
      dataIndex: 'equipmentType',
      sorter: true,
      render: (equipmentTypeId: string, _: unknown, index: number): ReactNode =>
        getColoredRow(
          readOnlySelectRender(
            arrsToSelectableObject<EquipmentTypeData>(
              equipmentTypes,
              'id',
              'name',
            ),
            equipmentTypeId,
          ),
          index,
        ),
    },
    {
      title: 'Manufacturer',
      key: 'manufacturer',
      dataIndex: 'equipment',
      render: (equipmentId: string, _: unknown, index: number): ReactNode =>
        getColoredRow(
          allEquipments?.find(
            (equipment: EquipmentFullInfo) => equipment.id === equipmentId,
          )?.manufacturer.name,
          index,
        ),
    },
    {
      title: 'Equipment Model',
      key: 'modelNumber',
      dataIndex: 'equipment',
      sorter: true,
      render: (equipment: string, _: unknown, index: number): ReactNode =>
        getColoredRow(
          readOnlySelectRender(
            arrsToSelectableObject(allEquipments, 'id', 'modelNumber'),
            equipment,
          ),
          index,
        ),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      sorter: true,
      render: (quantity: number, _: unknown, index: number): ReactNode =>
        getColoredRow(readOnlyInputNumberRender(quantity), index),
    },
    ...(!isScopingTable
      ? [
          {
            title: 'RAD',
            key: 'RAD',
            dataIndex: 'RAD',
            sorter: true,
            render: (RAD: number, _: unknown, index: number): ReactNode =>
              getColoredRow(readOnlyInputNumberRender(RAD), index),
          },
        ]
      : []),
    actionButtons({ editFunc, deleteFunc, isEditing }),
  ];
};
