import { DateWorkerString, MomentizeRangePickerData } from '@models/types';
import { DateWorker, dateWorker } from '../date-worker';

export const formatData = <T extends Record<string, any>>(
  data: T,
  item: keyof T,
  revert?: boolean,
): DateWorkerString =>
  revert ? dateWorker(data[item]).format() : dateWorker(data[item]);

const canBeMomentize = (date: DateWorker, revert?: boolean): boolean =>
  revert ? date && dateWorker.isMomento(date) && date.isValid() : !!date;

export const momentizeObjectDates = <T extends Record<string, any>>(
  data: T,
  fields: Array<keyof T>,
  revert?: boolean,
  revertWithNulls?: boolean,
): T => ({
  ...data,
  ...fields.reduce((acc: Record<string, DateWorkerString>, field: keyof T) => {
    const emptyItem = revertWithNulls ? { [field]: null } : {};

    return {
      ...acc,
      ...(data?.[field] &&
      canBeMomentize(data[field] as unknown as DateWorker, revert)
        ? { [field]: formatData<T>(data, field, revert) }
        : emptyItem),
    };
  }, {}),
});

export const momentizeRangePickerValues = <T extends Record<string, any>>(
  data: T,
  fields: Array<keyof T>,
  revert?: boolean,
  revertWithNulls?: boolean,
): T & MomentizeRangePickerData => ({
  ...data,
  ...fields.reduce(
    (acc: MomentizeRangePickerData, field: keyof T) => ({
      ...acc,
      ...{
        [field]: momentizeObjectDates(
          data[field],
          ['from', 'to'],
          revert,
          revertWithNulls,
        ),
      },
    }),
    {},
  ),
});
