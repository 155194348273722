import { arraySort } from '@core/utils/methods';
import { createReducer, on } from '@core/utils/store';
import {
  AdminCountyData,
  PayloadAndState,
  StateWithCounties,
  UpdateCountyResponse,
} from '@models/interfaces';
import { CountyActions } from '@store/actions';

export interface CountyValuesState {
  states: StateWithCounties[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: CountyValuesState = {
  states: [],
  isFetching: false,
  errors: false,
};

const getFilteredStates = (
  { id }: AdminCountyData,
  state: CountyValuesState,
): StateWithCounties[] =>
  state.states.filter((stateItem: StateWithCounties) => stateItem.id !== id);

const mainSorter = <T>(data: T[]): T[] =>
  arraySort(data, 'ASC', 'name' as keyof T);

export const reducer = createReducer(
  initialState,
  // ADD STATE
  on(
    CountyActions.addAdminCountyStateDataAction,
    ({
      payload,
      state: { states },
    }: PayloadAndState<AdminCountyData, CountyValuesState>) => ({
      states: mainSorter([...states, { ...payload, counties: [] }]),
    }),
  ),
  // EDIT STATE
  on(
    CountyActions.updateAdminCountyStateDataAction,
    ({
      payload: { id, name },
      state: { states },
    }: PayloadAndState<AdminCountyData, CountyValuesState>) => ({
      states: mainSorter(
        states.map((state: StateWithCounties) =>
          state.id === id ? { ...state, name } : state,
        ),
      ),
    }),
  ),
  // DELETE STATE
  on(
    CountyActions.deleteAdminCountyStateDataAction,
    ({ payload: id, state }: PayloadAndState<string, CountyValuesState>) => ({
      states: [
        ...state.states.filter(
          (stateItem: StateWithCounties) => stateItem.id !== id,
        ),
      ],
    }),
  ),
  // ADD COUNTY
  on(
    CountyActions.addAdminCountiesDataAction,
    ({
      payload: { state: editedState, county },
      state,
    }: PayloadAndState<UpdateCountyResponse, CountyValuesState>) => ({
      states: mainSorter([
        ...getFilteredStates(editedState, state),
        {
          ...editedState,
          counties: mainSorter([...editedState.counties, county]),
        },
      ]),
    }),
  ),
  // EDIT COUNTY
  on(
    CountyActions.updateAdminCountiesDataAction,
    ({
      payload: {
        state: editedState,
        county: { id, name },
      },
      state,
    }: PayloadAndState<UpdateCountyResponse, CountyValuesState>) => ({
      states: mainSorter([
        ...getFilteredStates(editedState, state),
        {
          ...editedState,
          counties: mainSorter(
            editedState.counties.map((county: AdminCountyData) =>
              county.id === id ? { ...county, name } : county,
            ),
          ),
        },
      ]),
    }),
  ),
  // DELETE COUNTY
  on(
    CountyActions.deleteAdminCountiesDataAction,
    ({
      payload: id,
      state: { states },
    }: PayloadAndState<string, CountyValuesState>) => ({
      states: states.map((item: StateWithCounties) => ({
        ...item,
        counties: item.counties.filter(
          (county: AdminCountyData) => county.id !== id,
        ),
      })),
    }),
  ),
  // GET STATES WITH COUNTIES
  on(
    CountyActions.getStatesAction,
    ({
      payload: states,
    }: PayloadAndState<StateWithCounties[], CountyValuesState>) => ({
      states,
    }),
  ),
);
