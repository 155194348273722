import { IScopingTowerNote } from '@symfa-inc/providence-types';
import { actionCreatorFactory } from '@core/utils/factories';
import {
  EngineeringBrowse,
  EngineeringDetails,
  EngineeringWorkflowUpdateData,
  PaginatedResponse,
  UpdateEngineeringSOFData,
} from '@models/interfaces';

const actionCreator = actionCreatorFactory('ENGINEERING');

export namespace EngineeringActions {
  export const getEngineeringBrowseDataAction =
    actionCreator.createAsyncAction<PaginatedResponse<EngineeringBrowse>>(
      'GET_BROWSE_DATA',
    );
  export const getEngineeringAction =
    actionCreator.createAsyncAction<EngineeringDetails>('GET_ENGINEERING');
  export const updateEngineeringWorkflowAction =
    actionCreator.createAsyncAction<EngineeringWorkflowUpdateData>(
      'UPDATE_ENGINEERING_WORKFLOW',
    );
  export const updateEngineeringSOFAction =
    actionCreator.createAsyncAction<UpdateEngineeringSOFData>(
      'UPDATE_ENGINEERING_SOF',
    );
  export const updateScopingTowerNoteAction =
    actionCreator.createAsyncAction<IScopingTowerNote>(
      'UPDATE_SCOPING_TOWER_NOTE_SOF',
    );
}
