import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { findFromSelectorOptions, mainDateFormat } from '@core/utils/methods';
import { Modal, PrimaryButton } from '@shared/modules';
import { DirectMessageViewerProps } from '../../models';

import './styles.scss';

export const DirectMessageViewer: FC<DirectMessageViewerProps> = ({
  visible,
  onCancel,
  onCompleted,
  usersList,
  item,
}: DirectMessageViewerProps) => {
  const setCompleted = useCallback((): void => {
    if (item) {
      onCompleted(item._id);
    }
  }, [item, onCompleted]);

  return (
    <Modal
      title={`Direct Message from${
        item ? ` ${findFromSelectorOptions(item.senderId, usersList)}` : ''
      }`}
      className="prov-area-direct-message-viewer"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <PrimaryButton
          key="cancel"
          type="default"
          title="Cancel"
          onClick={onCancel}
        />,
        <PrimaryButton
          key="set completed"
          title="Set Completed"
          onClick={setCompleted}
          disabled={item?.isCompleted}
        />,
      ]}
    >
      <div className="prov-area-direct-message-viewer__content">
        <div className="item">
          <span className="item__label">Status</span>
          <span
            className={classNames(
              'item__value',
              item?.isCompleted ? 'completed' : 'not-completed',
            )}
          >
            {item?.isCompleted ? 'Completed' : 'Not Completed'}
          </span>
        </div>
        <div className="item">
          <span className="item__label">Created</span>
          <span className="item__value">{mainDateFormat(item?.createdAt)}</span>
        </div>
        <span className="message">{item?.message}</span>
      </div>
    </Modal>
  );
};
