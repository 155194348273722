import { ReactNode } from 'react';
import { Nullable } from '@symfa-inc/providence-types';
import { ColumnsType } from 'antd/lib/table/interface';
import { leasingProjectCategoryRender } from '@core/utils/methods';
import { LeasingBrowseResponseItem, SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';

export const LEASING_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.alternateSiteInfo,
  },
  {
    title: 'City',
    key: 'city',
    dataIndex: 'site',
    width: 160,
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode => site?.city,
  },
  {
    title: 'County',
    key: 'county',
    dataIndex: 'site',
    width: 140,
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.countyName,
  },
  {
    title: 'FA Location',
    dataIndex: 'site',
    key: 'FALocationNumber',
    width: 140,
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site.FALocationNumber,
  },
  {
    title: 'Jurisdiction',
    key: 'jurisdiction',
    width: 180,
    dataIndex: 'site',
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.jurisdictionName,
  },
  {
    title: 'Legacy Site ID',
    width: 150,
    key: 'legacySiteID',
    sorter: true,
    dataIndex: 'site',
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.legacySiteID,
  },
  {
    title: 'Oracle Project',
    dataIndex: 'categories',
    key: 'oracleProjectNumber',
    width: 150,
    sorter: true,
    render: leasingProjectCategoryRender({ field: 'oracleProjectNumber' }),
  },
  {
    title: 'PACE Project',
    dataIndex: 'categories',
    key: 'PACEProjectNumber',
    width: 150,
    sorter: true,
    render: leasingProjectCategoryRender({ field: 'PACEProjectNumber' }),
  },
  {
    title: 'IWM Job Number',
    dataIndex: 'categories',
    key: 'IWMJobNumber',
    width: 170,
    sorter: true,
    render: leasingProjectCategoryRender({ field: 'IWMJobNumber' }),
  },
  {
    title: 'Project Type',
    key: 'projectType',
    width: 140,
    dataIndex: 'projectTypeValue',
    sorter: true,
    render: (projectTypeValue: string): ReactNode => projectTypeValue,
  },
  {
    title: 'Project Work Type',
    key: 'projectWorkType',
    width: 180,
    dataIndex: 'projectWorkTypeValue',
    sorter: true,
    render: (projectWorkTypeValue: string): ReactNode => projectWorkTypeValue,
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgentName',
    render: (providenceAgentName: Nullable<string>): ReactNode =>
      providenceAgentName,
  },
  {
    title: 'Site',
    dataIndex: 'site',
    key: 'siteNumber',
    width: 100,
    sorter: true,
    render: (site: SiteBrowse): ReactNode => site?.siteNumber,
  },
  {
    title: 'State',
    dataIndex: 'site',
    width: 100,
    key: 'state',
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.stateName,
  },
  {
    title: 'Street Address',
    key: 'streetAddress',
    dataIndex: 'site',
    width: 170,
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.streetAddress,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.submarketValue,
  },

  {
    title: 'Tower Owner',
    dataIndex: 'site',
    width: 155,
    key: 'towerOwner',
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode =>
      site?.towerOwnerValue,
  },
  {
    title: 'USID',
    width: 150,
    key: 'USID',
    sorter: true,
    dataIndex: 'site',
    render: (site: LeasingBrowseResponseItem['site']): ReactNode => site?.USID,
  },
  {
    title: 'Zip',
    key: 'zip',
    dataIndex: 'site',
    width: 100,
    sorter: true,
    render: (site: LeasingBrowseResponseItem['site']): ReactNode => site?.zip,
  },
];
