import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { SubPage, Tab } from '@symfa-inc/providence-types';
import { canUserEditByProjectStatusAndRoles } from '@core/utils/methods';
import { EditablePage } from '@shared/components';
import { ProjectSelectors, UserSelectors } from '@store/selectors';
import { ProjectSummary } from './components';

export const ProjectSummaryTab: FC<RouterProps> = ({
  history,
}: PropsWithChildren<RouterProps>) => {
  const browse = useSelector(UserSelectors.getProjectBrowsePermissions);
  const projectType = useSelector(ProjectSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit('projects', SubPage.ProjectsBrowse, Tab.Summary),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <EditablePage
      editableComponent={ProjectSummary}
      history={history}
      permissions={browse}
      canUserEdit={
        projectTypePermission &&
        canEdit &&
        canUserEditByProjectStatusAndRoles(projectStatusId, userRoles)
      }
    />
  );
};
