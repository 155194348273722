import { ColumnsType } from 'antd/lib/table/interface';
import { Browse } from '@models/types';

export const EQUIPMENT_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 270,
  },
  {
    title: 'Dimensions',
    dataIndex: 'dimensions',
    key: 'dimensions',
    width: 200,
  },
  {
    title: 'Equipment Type',
    dataIndex: 'equipmentType',
    key: 'equipmentType',
    width: 200,
    sorter: true,
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer',
    key: 'manufacturer',
    width: 240,
    sorter: true,
  },
  {
    title: 'Model Number',
    dataIndex: 'modelNumber',
    key: 'modelNumber',
    width: 240,
    sorter: true,
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight',
    width: 200,
  },
];
