import React, { FC, PropsWithChildren } from 'react';
import { ModalProps } from '@models/interfaces';
import { Modal } from '../../modules/ui-kit/modal';

import './styles.scss';

export const UnsavedDataModal: FC<ModalProps> = ({
  ...props
}: PropsWithChildren<any>) => (
  <Modal
    okText="Ok"
    cancelText="Cancel"
    className="prov-unsaved-data-modal"
    cancelType="danger-outline"
    centered
    title='Are you sure you want to exit "edit mode" ?'
    {...props}
  >
    <span className="prov-unsaved-data-modal__content">
      All unsubmitted changes will be cancel !
    </span>
  </Modal>
);
