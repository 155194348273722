import React, { FC, PropsWithChildren } from 'react';
import { InputNumber as AntInputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import { FormItem } from '@shared/modules';
import { getReadonlyElementFunction } from '../../models/constants';
import { FormElement } from '../../models/interfaces';

export const InputNumber: FC<FormElement<InputNumberProps>> = ({
  id,
  elementProps,
  readOnlyElementRender,
  ...props
}: PropsWithChildren<FormElement<InputNumberProps>>) => (
  <FormItem
    id={id}
    editingElement={<AntInputNumber id={id} {...elementProps} />}
    readOnlyElement={getReadonlyElementFunction<InputNumberProps>(
      id,
      props,
      readOnlyElementRender,
    )}
    {...props}
  />
);
