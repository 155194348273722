import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Form } from 'antd';
import { isValidForm } from '@core/utils/methods';
import { Modal, TextArea } from '@shared/modules';
import { HoldReasonModalProps } from './models';

const { useForm } = Form;

export const HoldReasonModal: FC<HoldReasonModalProps> = ({
  onOk,
  visible,
  ...props
}: PropsWithChildren<HoldReasonModalProps>) => {
  const [form] = useForm();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  const onOkHandle = async (): Promise<void> => {
    if (await isValidForm(form)) {
      onOk(form.getFieldValue('holdReason'));
    }
  };

  return (
    <Modal title="Hold Reason" onOk={onOkHandle} visible={visible} {...props}>
      <Form form={form}>
        <TextArea
          id="holdReason"
          label="Hold Reason"
          formItemProps={{
            rules: [
              {
                required: true,
                transform: (value: string): string => value.trim(),
                message: 'Hold reason should be not empty.',
              },
            ],
          }}
        />
      </Form>
    </Modal>
  );
};
