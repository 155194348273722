import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import {
  arraySort,
  canBeEditable,
  momentizeObjectDates,
} from '@core/utils/methods';
import {
  JURISDICTION_DATES_FOR_MOMENTIZE,
  YES_NO_GROUP,
} from '@models/constants';
import {
  Autocomplete,
  DatePicker,
  Input,
  InputNumber,
  RadioGroup,
  TextArea,
} from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { ZoningLTEFormProps } from '../../models';

export const ZoningLTEForm: FC<ZoningLTEFormProps> = ({
  isEditing,
  permissions: {
    applicationRequirement,
    describeOverallProcess,
    estimatedZoningApprovalTimeline,
    otherFee,
    specialLicensingOrBondingCondition,
    thirdPartyCostRequired,
    isWrittenDocumentationFromJurisdiction,
    jurisdictionZoningNote,
    zoningRequired,
    jurisdictionReviewDate,
  },
  formValue,
  formUpdating,
}: PropsWithChildren<ZoningLTEFormProps>) => {
  const [form] = Form.useForm();

  const { ZoningRequiredDDV, EstimatedZoningApprovalTimelineDDV } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'ZoningRequired',
      'EstimatedZoningApprovalTimeline',
    ]),
  );

  useEffect(() => {
    form.setFieldsValue(
      momentizeObjectDates(formValue, JURISDICTION_DATES_FOR_MOMENTIZE),
    );
  }, [isEditing]);

  const update = (): void => {
    if (formUpdating) {
      formUpdating(formValue.id, form.getFieldsValue());
    }
  };

  return (
    <Form
      className="prov-jurisdiction-configurable-panel__form"
      labelCol={{ span: 10 }}
      form={form}
      onValuesChange={update}
    >
      <Autocomplete
        id="zoningRequired"
        className="left"
        label="Zoning Required"
        options={arraySort(ZoningRequiredDDV, 'DESC', 'viewValue')}
        isEditing={canBeEditable(zoningRequired, isEditing)}
      />
      <Input
        id="specialLicensingOrBondingCondition"
        className="right"
        label="Special Licensing/Bonding Conditions"
        isEditing={canBeEditable(specialLicensingOrBondingCondition, isEditing)}
      />
      <RadioGroup
        id="isWrittenDocumentationFromJurisdiction"
        className="left"
        label="Written Documentation from Jurisdiction"
        isEditing={canBeEditable(
          isWrittenDocumentationFromJurisdiction,
          isEditing,
        )}
        buttons={YES_NO_GROUP}
      />
      <InputNumber
        id="thirdPartyCostRequired"
        className="right"
        label="Third-Party Cost Required"
        elementProps={{ min: 1 }}
        isEditing={canBeEditable(thirdPartyCostRequired, isEditing)}
      />
      <Input
        id="describeOverallProcess"
        className="left"
        label="Describe Overall Process"
        isEditing={canBeEditable(describeOverallProcess, isEditing)}
      />
      <InputNumber
        id="otherFee"
        className="right"
        label="Other Fees"
        elementProps={{ min: 1 }}
        isEditing={canBeEditable(otherFee, isEditing)}
      />
      <Autocomplete
        id="estimatedZoningApprovalTimeline"
        className="left"
        label="Estimated Zoning Approval Timeline"
        options={EstimatedZoningApprovalTimelineDDV}
        isEditing={canBeEditable(estimatedZoningApprovalTimeline, isEditing)}
      />
      <TextArea
        id="jurisdictionZoningNote"
        className="right"
        label="Zoning Notes"
        isEditing={canBeEditable(jurisdictionZoningNote, isEditing)}
      />
      <Input
        id="applicationRequirement"
        className="left"
        label="Application Requirements"
        isEditing={canBeEditable(applicationRequirement, isEditing)}
      />
      <DatePicker
        id="jurisdictionReviewDate"
        className="right"
        label="Jurisdiction Review Date"
        isEditing={canBeEditable(jurisdictionReviewDate, isEditing)}
      />
    </Form>
  );
};
