const getDeepDataByKeys = <RecordType>(obj: RecordType, keys: string[]) =>
  keys.reduce((acc: any, key: string) => acc[key], obj);

export const tableSorter =
  (keysToData: string[], dateComparing: boolean = false) =>
  <RecordType>(a: RecordType, b: RecordType) => {
    const currentValueA = getDeepDataByKeys(a, keysToData);
    const currentValueB = getDeepDataByKeys(b, keysToData);

    const itemA = dateComparing ? Date.parse(currentValueA) : currentValueA;
    const itemB = dateComparing ? Date.parse(currentValueB) : currentValueB;

    if (Number.isNaN(itemA) || Number.isNaN(itemB)) {
      throw new Error('Compared items are not Date!');
    }

    if (itemA < itemB) return -1;

    if (itemB < itemA) return 1;

    return 0;
  };
