import React, { FC } from 'react';
import Logo from 'assets/images/main-logo.png';

// TODO fix paddings (for Max)
export const EmptyPage: FC = () => (
  <div className="prov-empty-page">
    <div className="prov-empty-page__img-box">
      <img src={Logo} alt="main logo" className="prov-empty-page__logo" />
    </div>
  </div>
);
