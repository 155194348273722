import React, { FC, memo, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { HttpService } from '@core/services';
import { LeasingHttpService } from '@core/services/http';
import { AssociatedProjects, NotificationsLoader } from '@shared/components';
import { Modal, PrimaryButton } from '@shared/modules';
import { LeasingActions } from '@store/actions';
import { AssociatedProjectsModalProps } from './models';

import './styles.scss';

export const AssociatedProjectsModal: FC<AssociatedProjectsModalProps> = memo(
  ({
    toggleModal,
    projectId,
    ...props
  }: PropsWithChildren<AssociatedProjectsModalProps>) => {
    // const leasingSite = useSelector(LeasingSelectors.getLeasingSite);

    const history = useHistory();

    const dispatch = useDispatch();

    const onRowClick = async (id: string): Promise<void> => {
      try {
        await HttpService.getHttpRequests(
          LeasingHttpService,
        ).getLeasingEquipmentPriorProject(id);

        NotificationsLoader.notificationSuccess(
          'Prior project has been fetched!',
        );

        toggleModal();
      } catch (e) {
        console.error(e);
      }
    };

    return (
      <Modal
        title="Set prior project"
        onCancel={toggleModal}
        className="prov-leasing-equipment__associated-projects-modal"
        width={1800}
        {...props}
      >
        <PrimaryButton
          title="Empty a prior table"
          className="empty-btn"
          onClick={(): void => {
            dispatch(LeasingActions.emptyPriorTableAction.done());
            dispatch(LeasingActions.generateFinalLoadingAction.done());
            toggleModal();
          }}
        />
        <AssociatedProjects
          history={history}
          // TODO: need rework by Leasing Equipment problem (maybe for Max)
          // data={(leasingSite?.projects || []).filter(
          //   (project: AssociatedProject) => project.id !== projectId,
          // )}
          data={[]}
          isSiteOn={false}
          onRowClick={onRowClick}
        />
      </Modal>
    );
  },
);

AssociatedProjectsModal.displayName = 'AssociatedProjectsModal';
