import React, { Dispatch, FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { HttpService } from '@core/services';
import { DriverManagementHttpService } from '@core/services/http';
import { toggleModal } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { AdminDriverManagementData } from '@models/interfaces';
import { DeleteModal, Search, VirtualTable } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { DriverManagementSelectors } from '@store/selectors';
import {
  DRIVER_MANAGEMENT_DEFAULT_VALUE,
  getAdminDriverManagementColumns,
} from './models/constants';
import { DriverManagementModal } from './components';

import './styles.scss';

export const DriverManagement: FC = () => {
  const driverManagementService = useMemo(
    () => HttpService.getHttpRequests(DriverManagementHttpService),
    [],
  );

  const drivers = useSelector(DriverManagementSelectors.getDrivers);

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [selectedItem, setSelectedItem] = useState<AdminDriverManagementData>(
    DRIVER_MANAGEMENT_DEFAULT_VALUE,
  );

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem(DRIVER_MANAGEMENT_DEFAULT_VALUE);
    }
  };

  const selectDriver = (
    driver: AdminDriverManagementData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(driver);
    setter(!prevState);
  };

  const onDelete = async (): Promise<void> => {
    await driverManagementService.deleteDriver(selectedItem.id!);
    setDeleteModalVisible(false);
    setSelectedItem(DRIVER_MANAGEMENT_DEFAULT_VALUE);
  };

  return (
    <>
      <div className="prov-admin-driver-management">
        <div className="prov-admin-driver-management__search-box">
          <Search
            onChange={(value: string): Promise<AdminDriverManagementData[]> =>
              driverManagementService.getDriverManagementData(value)
            }
          />
        </div>
        <VirtualTable
          columns={getAdminDriverManagementColumns(
            (driver: AdminDriverManagementData): void =>
              selectDriver(
                driver,
                () => toggleAddAndEditModal(ModalMainTypes.Edit),
                isAddEditModalVisible,
              ),
            (driver: AdminDriverManagementData): void =>
              selectDriver(driver, setDeleteModalVisible, deleteModalVisible),
          )}
          dataSource={drivers}
          maxViewRow={5}
          rowHeight={110}
        />
        <PrimaryButton
          icon="+"
          title="Add Driver"
          className="prov-admin-driver-management__add-btn"
          onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
        />
      </div>
      <DriverManagementModal
        visible={isAddEditModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditModal}
        formValue={selectedItem}
      />
      <DeleteModal
        onOk={onDelete}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
      />
    </>
  );
};
