import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UploadFile } from 'antd/lib/upload/interface';
import { HttpService } from '@core/services';
import { SitePicturesUploadHttpService } from '@core/services/http';
import { NotificationsLoader } from '@shared/components';
import { Modal } from '@shared/modules';
import { SitePictureUploadSelectors } from '@store/selectors';
import { SitePicturesUpload } from '../site-pictures-upload';
import { UploadModalProps } from './models';

import './styles.scss';

export const UploadModal = ({
  visible,
  toggleModal,
  FALocationNumber,
}: UploadModalProps) => {
  const isFetching = useSelector(SitePictureUploadSelectors.isFetching);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onClose = () => {
    toggleModal();
    setFileList([]);
  };

  const onOk = async (): Promise<void> => {
    try {
      await HttpService.getHttpRequests(
        SitePicturesUploadHttpService,
      ).uploadSiteImages(
        FALocationNumber,
        // TODO: remove 'as File' when version of antd will be updated
        // https://ant.design/components/upload/#Why-does-onChange-sometimes-return-File-object-and-other-times-return-%7B-originFileObj:-File-%7D
        fileList.map(({ originFileObj }) => originFileObj as File),
      );

      onClose();

      NotificationsLoader.notificationSuccess('Pictures have been uploaded!');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      title="Upload images"
      visible={visible}
      onCancel={onClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={isFetching ? 'Loading' : 'Upload'}
      className="prov-admin-site-pictures__upload-modal"
      okButtonProps={{ loading: isFetching, disabled: !fileList.length }}
      onOk={onOk}
    >
      <SitePicturesUpload fileList={fileList} setFileList={setFileList} />
    </Modal>
  );
};
