import { ElementType } from 'react';
import { Undefinable } from '@symfa-inc/providence-types';
import {
  Guard,
  Resolver,
} from '@wellyes/react-router-extended/dist/router/types';
import { replaceLocationPathName, replacePath } from '../../core/utils/methods';
import { ProcessEnvMap } from '../interfaces';
import { MenuItems } from '../types/menu-items';
import { RouteGroupProps } from './models';
import { Route } from './route';
import { Tab } from './tab';

type InheritanceResolver = { resolver: Resolver; inheritance: boolean };

export class RouteGroup {
  #path: string;
  #resolvers: InheritanceResolver[] = [];
  readonly #name: string;
  #guards: Guard[] = [];
  #breadcrumbs: RouteGroup[] = [];
  #routes: RouteGroup[] = [];
  #isBreadcrumb: boolean = false;
  #menu?: string;
  #group?: RouteGroup;
  #redirectURL?: string;
  #icon?: ElementType;
  #breadcrumbName?: string;

  constructor({ path, name, breadcrumbName }: RouteGroupProps) {
    this.#name = name;
    this.#path = path;
    this.#breadcrumbName = breadcrumbName;
  }

  // GETTERS & SETTERS
  get resolvers(): Resolver[] {
    return this.#resolvers.map(
      (inheritanceResolver: InheritanceResolver) =>
        inheritanceResolver.resolver,
    );
  }

  protected get onlyInheritanceResolvers(): Resolver[] {
    return this.#resolvers
      .filter((resolver: InheritanceResolver) => resolver.inheritance)
      .map((resolver: InheritanceResolver) => resolver.resolver);
  }

  get guards(): Guard[] {
    return this.#guards;
  }

  get path(): string {
    return this.#path;
  }

  get isBreadcrumb(): boolean {
    return this.#isBreadcrumb;
  }

  get breadcrumbs(): RouteGroup[] {
    return this.#breadcrumbs;
  }

  get menu(): Undefinable<string> {
    return this.#menu;
  }

  get group(): Undefinable<RouteGroup> {
    return this.#group;
  }

  get name(): string {
    return this.#name;
  }

  get redirectURL(): Undefinable<string> {
    return this.#redirectURL;
  }

  get icon(): Undefinable<ElementType> {
    return this.#icon;
  }

  get breadcrumbName(): string {
    return this.#breadcrumbName ?? this.#name;
  }

  get regexpPath(): RegExp {
    const regexp = this.path
      .replaceAll('/', '\\/')
      .replaceAll(/(:[\w\d]+)/g, '[^\\/]+');

    return new RegExp(`^${regexp}$`);
  }

  // METHODS
  setRedirectURL(redirectURL: string): this {
    this.#redirectURL = redirectURL;

    return this;
  }

  addResolver(resolver: Resolver, inheritance: boolean = true): this {
    this.#resolvers.unshift({ resolver, inheritance });

    return this;
  }

  addResolvers(resolvers: Resolver[], inheritance: boolean = true): this {
    resolvers.forEach((resolver: Resolver) => {
      this.addResolver(resolver, inheritance);
    });

    return this;
  }

  setPath(path: string): this {
    this.#path = path;

    return this;
  }

  addRoute(route: RouteGroup): this {
    this.#routes.push(route);

    return this;
  }

  addRoutes(routes: RouteGroup[]): this {
    this.#routes.push(...routes);

    return this;
  }

  breadcrumb(): this {
    this.#isBreadcrumb = true;
    this.#breadcrumbs = [this];

    return this;
  }

  protected changeRoute(route: RouteGroup): void {
    route.setPath(RouteGroup.fixPath(`${this.#path}/${route.path}`));

    route.addBreadcrumbs(this.breadcrumbs);

    route.addResolvers(this.onlyInheritanceResolvers);

    if (this.guards.length) {
      route.addGuards(this.guards, this.redirectURL!);
    }

    route.setGroup(this);
  }

  setMenu(menu: string, icon?: ElementType): this {
    this.#menu = menu;
    this.#icon = icon;

    return this;
  }

  addGuard(guard: Guard, redirectURL: string): this {
    this.#guards.push(guard);
    this.#redirectURL = redirectURL;

    return this;
  }

  addGuards(guards: Guard[], redirectURL: string): this {
    this.#guards.push(...guards);
    this.#redirectURL = redirectURL;

    return this;
  }

  addBreadcrumbs(breadcrumbs: RouteGroup[]): this {
    this.#breadcrumbs.unshift(...breadcrumbs);

    return this;
  }

  setGroup(group: RouteGroup): this {
    this.#group = group;

    return this;
  }

  formatRoutes(): this {
    this.#routes.forEach((route: RouteGroup) => {
      this.changeRoute(route);
      route.formatRoutes();
    });

    return this;
  }

  getChildrenRoutes(): Route[] {
    const accumulator: Route[] = [];

    return [
      ...this.#routes.reduce(
        (acc: Route[], route: RouteGroup) => [
          ...acc,
          ...(route instanceof Route
            ? [...route.tabs.filter((tab: Tab) => tab.isLink), route]
            : []),
          ...route.getChildrenRoutes(),
        ],
        accumulator,
      ),
    ];
  }

  async getMenuItems(menu: string): Promise<MenuItems<RouteGroup>> {
    const accumulator: Promise<MenuItems<RouteGroup>> = Promise.resolve(
      new Map(),
    );
    const filteredRoutes: RouteGroup[] = await this.filterRoutes();

    return filteredRoutes.reduce(
      async (routes: Promise<MenuItems<RouteGroup>>, route: RouteGroup) => {
        const routesVal = await routes;

        if (route.menu) {
          if (route.menu === menu) {
            routesVal.set(route, await route.getMenuItems(menu));
          }
        } else {
          const deepMenu = await route.getMenuItems(menu);

          deepMenu.forEach((value: MenuItems<RouteGroup>, key: RouteGroup) => {
            routesVal.set(key, value);
          });
        }

        return routesVal;
      },
      accumulator,
    );
  }

  private async filterRoutes(): Promise<RouteGroup[]> {
    const accumulator: Promise<RouteGroup[]> = Promise.resolve([]);

    return this.#routes.reduce(
      async (routes: Promise<RouteGroup[]>, route: RouteGroup) => [
        ...(await routes),
        ...((await RouteGroup.canActivateGuards(route.guards)) ? [route] : []),
      ],
      accumulator,
    );
  }

  replacePath(replacement: ProcessEnvMap = {}): string {
    return replacePath(this.#path, replacement);
  }

  replaceLocationPathName(replacement: ProcessEnvMap = {}): string {
    return replaceLocationPathName(this.#path, replacement);
  }

  findRouteByPath(path: string): Undefinable<RouteGroup> {
    return this.regexpPath.test(path)
      ? this
      : this.#routes
          .map((route: RouteGroup) => route.findRouteByPath(path))
          .find((route?: RouteGroup) => route);
  }

  private static canActivateGuards(guards: Guard[]): Promise<boolean> {
    return guards.reduce(async (acc: Promise<boolean>, guard: Guard) => {
      const canActivate = await guard.canActivate();
      const generalCanActivate = await acc;

      return generalCanActivate && canActivate;
    }, Promise.resolve(true));
  }

  static fixPath(path: string): string {
    return `/${path
      .split('/')
      .filter((p: string) => p)
      .join('/')}`;
  }
}
