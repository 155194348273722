import { combineReducers } from 'redux';
import * as browse from './browse.reducer';
import * as scoping from './scoping.reducer';
import * as siteAudit from './site-audit.reducer';
import * as SOFTool from './sof-tool.reducer';

export interface EngineeringState {
  browse: browse.EngineeringBrowseState;
  scoping: scoping.EngineeringScopingState;
  SOFTool: SOFTool.EngineeringSOFToolState;
  siteAudit: siteAudit.EngineeringSiteAuditState;
}

export const reducers = combineReducers<EngineeringState>({
  browse: browse.reducer,
  scoping: scoping.reducer,
  SOFTool: SOFTool.reducer,
  siteAudit: siteAudit.reducer,
});
