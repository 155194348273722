import { Nullable } from '@symfa-inc/providence-types';
import {
  AccountingNotificationData,
  DashboardItem,
  DashboardProjectData,
} from '@models/interfaces';

export const accountingNotificationSearcher = (
  data: DashboardItem<AccountingNotificationData>[],
  searchValue: string,
): DashboardItem<AccountingNotificationData>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: { type },
          projectData: {
            projectBundleID,
            projectType,
            siteNumber,
            PACEProjectNumbers,
            oracleProjectNumbers,
          },
        }: DashboardItem<AccountingNotificationData>) =>
          [
            type,
            projectBundleID.toString(),
            siteNumber,
            projectType,
            ...PACEProjectNumbers.map(
              ({ value }: DashboardProjectData['PACEProjectNumbers'][number]) =>
                value,
            ),
            ...oracleProjectNumbers.map(
              ({
                value,
              }: DashboardProjectData['oracleProjectNumbers'][number]) => value,
            ),
          ].some((item: Nullable<string>) => item && regExp.test(item)),
      );
};
