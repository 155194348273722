import React, { ReactNode } from 'react';
import { ToTopOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnType } from 'antd/es/table';
import deleteIcon from 'assets/icons/delete-icon.svg';
import editIcon from 'assets/icons/edit-icon.svg';
import classnames from 'classnames';
import { ColumnsFunc } from '@models/types';

interface ActionButtonsArgs<T> {
  editFunc?: ColumnsFunc<T>;
  deleteFunc?: ColumnsFunc<T>;
  exportFunc?: ColumnsFunc<T>;
  width?: number;
  isEditing?: boolean;
  getDeleteFuncAccess?: (item: T) => boolean;
  getEditFuncAccess?: (item: T) => boolean;
}

export const actionButtons = <T,>({
  editFunc,
  deleteFunc,
  exportFunc,
  width = 130,
  isEditing = true,
  getDeleteFuncAccess,
  getEditFuncAccess,
}: ActionButtonsArgs<T>): ColumnType<any> => ({
  key: 'action-buttons',
  width,
  render: (item: T): ReactNode => {
    const deleteAccess = getDeleteFuncAccess?.(item) ?? true;
    const editAccess = getEditFuncAccess?.(item) ?? true;

    return (
      isEditing && (
        <div
          className={classnames('controls', {
            'controls-edit-mode': !deleteFunc,
          })}
        >
          {exportFunc && (
            <Button
              className="controls__btn"
              onClick={(): void => exportFunc(item)}
            >
              <ToTopOutlined className="export-img" />
            </Button>
          )}
          {editFunc && editAccess && (
            <Button
              className="controls__btn"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
                e.stopPropagation();

                editFunc(item);
              }}
            >
              <img src={editIcon} alt="Edit row" />
            </Button>
          )}
          {deleteFunc && deleteAccess && (
            <Button
              className="controls__btn"
              onClick={(): void => deleteFunc(item)}
            >
              <img src={deleteIcon} alt="Delete row" />
            </Button>
          )}
        </div>
      )
    );
  },
});
