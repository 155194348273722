import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { BreadcrumbsState } from '../reducers/breadcrumbs.reducer';

const breadcrumbsState = (state: AppState): BreadcrumbsState =>
  state.breadcrumbs;

export namespace BreadcrumbsSelectors {
  export const getBreadcrumbs = createSelector(
    [breadcrumbsState],
    ({ breadcrumbs }: BreadcrumbsState) => breadcrumbs,
  );
}
