import React, { ReactNode } from 'react';
import { actionButtons } from '@models/constants';
import { EquipmentTabData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';

export const equipmentTabColumns = (
  editFunc: ColumnsFunc<EquipmentTabData>,
  deleteFunc: ColumnsFunc<EquipmentTabData>,
  selectorNotEmpty: boolean,
): Array<VirtualTableColumn> => [
  {
    title: 'Model Number',
    dataIndex: 'modelNumber',
    key: 'modelNumber',
  },
  {
    title: 'Length',
    dataIndex: 'length',
    key: 'length',
  },
  {
    title: 'Width',
    dataIndex: 'width',
    key: 'width',
  },
  {
    title: 'Depth',
    dataIndex: 'depth',
    key: 'depth',
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    key: 'weight',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (value: string): ReactNode => (
      <span className="ellipsis-3">{value}</span>
    ),
  },
  actionButtons<EquipmentTabData>({
    editFunc,
    deleteFunc,
    isEditing: selectorNotEmpty,
  }) as VirtualTableColumn,
];
