import store from '@store/index';

export const requestWithStoreWaiting = async <T>(
  request: () => Promise<T>,
  state: () => { isFetching: boolean },
): Promise<void> => {
  const waiter = new Promise<void>((resolve: () => void) => {
    const unsubscribe = store.subscribe(() => {
      if (!state().isFetching) {
        resolve();
        unsubscribe();
      }
    });
  });

  await request();
  await waiter;
};
