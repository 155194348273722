import {
  FinancialData,
  FinancialServicesData,
  FinancialSiteFeesData,
} from '@models/interfaces';

export const getFinancialSiteFees = (
  financialData: FinancialData,
): FinancialSiteFeesData[] => financialData.siteFees;

export const getFinancialServicesRequired = (
  financialData: FinancialData,
): FinancialServicesData[] => financialData.servicesRequired;
