import React from 'react';
import ReactDOM from 'react-dom';
import { SaveChangesModal } from '../../shared/components';
import store from '../../store';

type AnswerType = 'save' | 'discard';

export const promptCallback = (
  _: unknown,
  callback: (answer: boolean) => void,
): void => {
  const node = document.getElementById('custom-prompt');

  const cleanUp = (answer?: AnswerType): void => {
    if (node) {
      ReactDOM.unmountComponentAtNode(node);
    }

    if (answer) {
      window.dispatchEvent(new CustomEvent(`${answer}-changes`));
    }
  };

  const onButtonClick = (cleanUpAnswer?: AnswerType): void => {
    cleanUp(cleanUpAnswer);
    const unsubscribe = store.subscribe(() => {
      if (!store.getState().common.hasUnsubmittedData) {
        callback(true);
        unsubscribe();
      }
    });
  };

  ReactDOM.render(
    <SaveChangesModal
      visible
      cancelButtonProps={{
        onClick: (): void => onButtonClick('discard'),
      }}
      onCancel={(): void => {
        cleanUp();
        callback(false);
      }}
      onOk={(): void => onButtonClick('save')}
    />,
    node,
  );
};
