import React, {
  ChangeEvent,
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Input as AntInput, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ReadOnlyInputNumber } from '../../read-only-elements';
import { SwitchBox } from '../../switch-box';
import { FormItem } from '../form-item';
import { convertValue } from './models/constants';
import { CoordinateFieldProps, CoordinateProps } from './models/interfaces';
import { coordinateField } from './validators';

import './styles.scss';

export const CoordinateField: FC<CoordinateFieldProps> = ({
  id,
  value,
  converted,
  onChange,
}: PropsWithChildren<CoordinateFieldProps>) => {
  useEffect(() => {
    onChange?.(convertValue(converted, value));
  }, [converted]);

  return (
    <AntInput.TextArea
      className="model-input"
      id={id}
      value={value}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(e.currentTarget.value);
      }}
    />
  );
};

export const Coordinate: FC<CoordinateProps> = ({
  id,
  label,
  elementProps,
  value,
  className,
  formItemProps,
  isEditing = true,
}: PropsWithChildren<CoordinateProps>) => {
  const [converted, setConverted] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  const coordinateLabel = [
    label,
    <Tooltip
      key={`tooltip${id}`}
      title={`Convert to ${converted ? 'decimal' : 'DMS'} format`}
    >
      <SwitchBox
        key={id}
        className="convert-button"
        onChange={(checked: boolean) => {
          setConverted(checked);
        }}
        disabled={disabled}
        checked={converted}
        size="small"
      />
    </Tooltip>,
  ];

  return (
    <FormItem
      id={id}
      className={className}
      editingElement={
        <CoordinateField id={id} converted={converted} {...elementProps} />
      }
      readOnlyElement={(formInstance: FormInstance): ReactNode => (
        <ReadOnlyInputNumber
          value={convertValue(
            converted,
            formInstance.getFieldValue(id) || value,
            true,
          )}
        />
      )}
      formItemProps={{
        rules: [
          ...coordinateField(converted, setDisabled),
          ...(formItemProps?.rules || []),
        ],
      }}
      label={coordinateLabel}
      isEditing={isEditing}
    />
  );
};
