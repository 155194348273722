import React, { forwardRef, PropsWithChildren, Ref } from 'react';
import { Row } from 'antd';
import classNames from 'classnames';
import { SwitchBox } from '@shared/modules';
import { SiteOnSwitchProps } from './models';

import './styles.scss';

export const SiteOnSwitch = forwardRef(
  (
    {
      onChange,
      switchState,
      disabled = false,
    }: PropsWithChildren<SiteOnSwitchProps>,
    ref: Ref<HTMLElement>,
  ) => (
    <Row className="switch-wrapper">
      <span
        className={classNames('switch-wrapper__text', {
          'switch-wrapper__text-dangerous': !switchState,
        })}
      >
        Off
      </span>
      <SwitchBox
        ref={ref}
        tooltip={
          disabled
            ? 'Site cannot be turned off when there are active associated projects'
            : 'Site On/Off'
        }
        className={classNames({
          'switch-box-dangerous': !switchState,
        })}
        title={
          <span
            className={classNames('switch-wrapper__text', {
              'switch-wrapper__text-primary': switchState,
            })}
          >
            On
          </span>
        }
        checked={switchState}
        onChange={onChange}
        disabled={disabled}
      />
    </Row>
  ),
);
