import { NotificationsLoader } from '@shared/components';
import store from '@store/index';
import { ActionCreator } from './models/interfaces';

// TODO: try to make decorator without getter
export function StoredRequest(...actions: [ActionCreator, ...ActionCreator[]]) {
  return (
    _: unknown,
    memberName: string,
    propertyDescriptor: PropertyDescriptor,
  ) => {
    return {
      get() {
        const { dispatch } = store;
        const notification = new NotificationsLoader({
          message: 'Loading',
          description: 'Please wait',
        });

        const wrapperFn = async (...args: any[]) => {
          actions.forEach(({ start }: ActionCreator) => dispatch(start()));
          try {
            const res = await propertyDescriptor.value.apply(this, args);

            actions.forEach(({ done }: ActionCreator) => dispatch(done(res)));

            notification.close();

            return res;
          } catch (error) {
            console.error(error);
            actions.forEach(({ fail }: ActionCreator) => dispatch(fail()));
            notification.close();
            throw error;
          }
        };

        Object.defineProperty(this, memberName, {
          value: wrapperFn,
          configurable: true,
          writable: true,
        });

        return wrapperFn;
      },
    };
  };
}
