import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import {
  ALLOWED_SYMBOLS_PATTERN,
  EquipmentTabsModalProps,
} from '@equipment-tabs/models';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { EquipmentHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { EquipmentTypeData } from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { Input } from '@shared/modules';
import { equipmentTypeDefaultValue } from '../../models';

import './styles.scss';

const { useForm } = Form;

export const EquipmentTypeModal: FC<
  EquipmentTabsModalProps<EquipmentTypeData>
> = ({
  value = equipmentTypeDefaultValue,
  visible,
  modalType,
  toggleModal,
}: PropsWithChildren<EquipmentTabsModalProps<EquipmentTypeData>>) => {
  const [form] = useForm();

  const equipmentService = useMemo(
    () => HttpService.getHttpRequests(EquipmentHttpService),
    [],
  );

  const getEquipmentTypesData = (): EquipmentTypeData => ({
    ...value,
    ...form.getFieldsValue(),
  });

  const add = async ({ name }: EquipmentTypeData): Promise<void> => {
    if (await isValidForm(form)) {
      await equipmentService.addEquipmentType(name);
      toggleModal(modalType);
    }
  };

  const edit = async (equipmentType: EquipmentTypeData): Promise<void> => {
    if (await isValidForm(form)) {
      await equipmentService.editEquipmentType(equipmentType);
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(value);
    }
  }, [visible, value, form]);

  return (
    <AddOrEditModal
      visible={visible}
      type={modalType}
      okText="Save"
      cancelText="Cancel"
      className="prov-equipment-type-modal"
      formProps={{
        labelCol: { span: 6 },
        wrapperCol: { span: 15 },
        form,
        initialValues: value,
      }}
      title={`${modalType === ModalMainTypes.Add ? 'New' : ''} Item`}
      onCancel={(): void => toggleModal(modalType)}
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getEquipmentTypesData())
          : (): Promise<void> => edit(getEquipmentTypesData())
      }
    >
      <Input
        id="name"
        label="Equipment Type"
        elementProps={{
          autoFocus: true,
        }}
        formItemProps={{
          rules: [
            serverValidator(
              {
                method: 'getEquipmentTypeValidation',
                errorMessage: 'Equipment Type must be unique',
              },
              EquipmentHttpService,
              modalType === ModalMainTypes.Add ? undefined : value.id,
            ),
            { required: true, message: 'Equipment Type is required!' },
            { min: 2, message: 'Equipment Type must be at least 2 characters' },
            ALLOWED_SYMBOLS_PATTERN,
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
    </AddOrEditModal>
  );
};
