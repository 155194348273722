import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import archiveIcon from 'assets/icons/archive-icon.svg';
import { PaceTask, PaceTaskScreen } from '@models/interfaces';

interface PaceTaskColumnsParams {
  isArchived?: boolean;
  archivePaceTask?: (paceTaskScreen: PaceTaskScreen) => void;
}
export const paceTaskColumns = ({
  isArchived = false,
  archivePaceTask,
}: PaceTaskColumnsParams = {}): ColumnsType<PaceTaskScreen> => [
  {
    title: 'PACE Task Id',
    key: 'paceTaskID',
    dataIndex: 'paceTask',
    width: 300,
    render: (paceTask: PaceTask): ReactNode => paceTask.paceTaskID,
  },
  {
    title: 'Task Name',
    key: 'taskName',
    dataIndex: 'paceTask',
    width: 400,
    render: (paceTask: PaceTask): ReactNode => paceTask.taskName,
  },
  {
    title: 'Order',
    key: 'order',
    dataIndex: 'order',
    width: 400,
  },
  {
    ...(!isArchived && {
      key: 'action_buttons',
      dataIndex: 'paceTask',
      width: 150,
      render: (_: PaceTask, screen: PaceTaskScreen): ReactNode => {
        const paceTaskScreen: PaceTaskScreen = screen;

        return (
          <div className="controls">
            <Button
              className="controls__btn"
              onClick={(): void => archivePaceTask!(paceTaskScreen)}
            >
              <img src={archiveIcon} alt="Archive task" />
            </Button>
          </div>
        );
      },
    }),
  },
];
