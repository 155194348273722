import { FilterOperator } from '@symfa-inc/providence-types';

export const getFilterOperator = (value: FilterOperator): string => {
  switch (value) {
    case FilterOperator.Equal:
      return '='; // Equal
    case FilterOperator.NotEqual:
      return '≠'; // Not equal
    case FilterOperator.GreaterThan:
      return '>'; // Greater than
    case FilterOperator.LessThan:
      return '<'; // Less than
    case FilterOperator.Between:
      return '∈'; // Between
    default:
      throw new Error('Incorrect value');
  }
};
