import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { SOFToolHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import {
  createOnChangeModalVisibility,
  momentizeRangePickerValues,
  removeEmptyFields,
} from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
} from '@models/constants';
import { SOFToolBrowseFilter } from '@models/interfaces';
import { MainSOFToolBrowseFilter } from '@models/types';
import { BrowsePage } from '@shared/modules/browse-page';
import { SOFToolSelectors } from '@store/selectors';
import { SOFFilters } from './modals';
import {
  SOF_TOOL_BROWSE_COLUMNS,
  SOF_TOOL_BROWSE_DEFAULT_CONFIGURE_FILTER,
} from './models';

import './styles.scss';

const { useForm } = Form;

export const SOFToolBrowse: FC = () => {
  const [form] = useForm();

  const SOFToolData = useSelector(SOFToolSelectors.browseData);

  const [filters, setFilters] = useState<MainSOFToolBrowseFilter>(
    BROWSE_DEFAULT_FILTER_VALUE,
  );

  const [configureFilter, setConfigureFilter] = useState<SOFToolBrowseFilter>(
    SOF_TOOL_BROWSE_DEFAULT_CONFIGURE_FILTER,
  );

  useDidUpdateEffect(() => {
    HttpService.getHttpRequests(SOFToolHttpService).getSOFTool(
      momentizeRangePickerValues(
        removeEmptyFields({ ...filters }),
        ['SS014ActualDate'],
        true,
      ),
    );
  }, [filters]);

  const onSearchChanges = (search: string): void => {
    setFilters((prevState: MainSOFToolBrowseFilter) => ({
      ...prevState,
      search,
    }));
  };

  const onChangeConfigureFilter = (filter: SOFToolBrowseFilter): void => {
    setConfigureFilter((prevState: SOFToolBrowseFilter) => ({
      ...prevState,
      ...filter,
    }));
  };

  const onApplyConfigureFilter = (): void => {
    setFilters((prevState: MainSOFToolBrowseFilter) => ({
      ...prevState,
      ...configureFilter,
    }));
  };

  return (
    <div className="prov-sof_tool-browse">
      <BrowsePage
        columns={SOF_TOOL_BROWSE_COLUMNS}
        data={SOFToolData?.items}
        filters={
          <SOFFilters
            formProps={{ form }}
            value={configureFilter}
            onChanges={onChangeConfigureFilter}
          />
        }
        onChangeFilterModalVisibility={createOnChangeModalVisibility(
          form,
          configureFilter,
          filters,
          setConfigureFilter,
        )}
        validation
        filterFormProps={{ labelCol: { span: 10 }, form }}
        className="prov-sof_tool-browse"
        onSearchChanges={onSearchChanges}
        onChange={browseFiltersChange(setFilters)}
        onApplyFilter={onApplyConfigureFilter}
        cancelButtonProps={{
          onClick: (): void => {
            form?.resetFields();
            setConfigureFilter(SOF_TOOL_BROWSE_DEFAULT_CONFIGURE_FILTER);
          },
        }}
        tableProps={{
          pagination: {
            total: SOFToolData?.total,
          },
          module: 'SOFTool',
        }}
      />
    </div>
  );
};
