import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaceTaskType, SubPage, Tab } from '@symfa-inc/providence-types';
import { Form } from 'antd';
import { Store } from 'antd/es/form/interface';
import classNames from 'classnames';
import {
  HttpService,
  ObjectComparatorService,
  ObjectDifferencesService,
} from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  canUserEditByProjectStatusAndRoles,
  isValidForm,
  momentizeObjectDates,
} from '@core/utils/methods';
import { EditablePage, NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import {
  PaceTasksSelectors,
  PermittingSelectors,
  ProjectCategoriesSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import {
  JurisdictionInformation,
  LeasingAndEngineeringInformation,
  WorkflowPermittingSection,
  WorkflowZoningSection,
} from './components';
import {
  dataToPaceTasksMapper,
  updatePermittingPaceTaskFields,
} from './helpers';
import {
  PERMITTING_INFO_DATES,
  PERMITTING_SECTION_MAPPER_OPTIONS,
  PermittingSection,
  WorkflowProps,
  ZONING_INFO_DATES,
  ZONING_SECTION_MAPPER_OPTIONS,
  ZoningSection,
} from './models';

import './styles.scss';

const { useForm } = Form;

const Workflow: FC<WorkflowProps> = ({
  isEditing,
  permissions,
  toggleEditing,
}: PropsWithChildren<WorkflowProps>) => {
  const dispatch = useDispatch();

  const [permittingForm] = useForm();
  const [zoningForm] = useForm();

  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const { site, project, leasing, scoping } = useSelector(
    PermittingSelectors.getPermittingDetailsData([
      'site',
      'scoping',
      'leasing',
      'project',
    ]),
  );

  const paceTasks = useSelector(
    PaceTasksSelectors.getMainPaceTaskDates(
      PaceTaskType.ActualDate,
      // Permitting Panel
      'E911AddressValidation',
      'jurisdictionAuditComplete',
      'prelimCDsReviewedPermitting',
      // Zoning Panel
      'BPReceived',
      'BPSubmitted',
      'zoningAppDeemedComplete',
      'zoningApproved',
      'zoningSubmitted',
      // Leasing & Engineering Panel
      'prelimCDsReceived',
      'passingStructuralReceived',
      'SOFIssued',
      'structuralOrdered',
    ),
  );

  const [workflowForm, setWorkflowForm] = useState<Array<Store>>([]);
  const [initialForm, setInitialForm] = useState<Array<Store>>([]);

  const getPermittingPanelData = (): Store => ({
    e911AddressValidation: paceTasks.E911AddressValidation,
    jurisdictionAuditComplete: paceTasks.jurisdictionAuditComplete,
    prelimCDsReviewedPermitting: paceTasks.prelimCDsReviewedPermitting,
    projectPermittingNote: project?.projectPermittingNote,
    permittingTypes: site?.jurisdiction?.permitting?.permittingTypes,
    permittingAgent: project?.permittingAgent,
  });
  const getZoningPanelData = (): Store => ({
    BPReceived: paceTasks.BPReceived,
    BPSubmitted: paceTasks.BPSubmitted,
    zoningAppDeemedComplete: paceTasks.zoningAppDeemedComplete,
    zoningApproved: paceTasks.zoningApproved,
    zoningSubmitted: paceTasks.zoningSubmitted,
  });

  const [permittingPanelData, setPermittingPanelData] = useState(
    getPermittingPanelData(),
  );
  const [zoningPanelData, setZoningPanelData] = useState(getZoningPanelData());
  const [duringReqDisabled, setDuringReqDisabled] = useState<boolean>(false);

  const arraysCompare = ObjectComparatorService.arraysCompare(
    initialForm,
    workflowForm,
    true,
  );

  const buttonsDisabled = duringReqDisabled || arraysCompare;

  useEffect(() => {
    setPermittingPanelData(getPermittingPanelData());
  }, [
    paceTasks.E911AddressValidation,
    paceTasks.jurisdictionAuditComplete,
    paceTasks.prelimCDsReviewedPermitting,
    project?.projectPermittingNote,
    project?.permittingAgent,
  ]);

  useEffect(() => {
    setZoningPanelData(getZoningPanelData());
  }, [
    paceTasks.BPReceived,
    paceTasks.BPSubmitted,
    paceTasks.zoningAppDeemedComplete,
    paceTasks.zoningApproved,
    paceTasks.zoningSubmitted,
  ]);

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!arraysCompare));
  }, [arraysCompare]);

  const onSubmit = async (): Promise<void> => {
    if (
      (await isValidForm(permittingForm)) &&
      (await isValidForm(zoningForm))
    ) {
      try {
        const [permittingSectionData, zoningSectionData] = workflowForm;
        const [initialPermitting, initialZoning] = initialForm;

        setDuringReqDisabled(true);

        const permittingSection = momentizeObjectDates(
          ObjectDifferencesService.getObjectsDiff(
            initialPermitting,
            permittingSectionData,
          ),
          PERMITTING_INFO_DATES,
          true,
        );

        const zoningSection = momentizeObjectDates(
          ObjectDifferencesService.getObjectsDiff(
            initialZoning,
            zoningSectionData,
          ),
          ZONING_INFO_DATES,
          true,
        );

        const {
          projectPermittingNote,
          permittingAgent,
          ...restPermittingSectionFields
        } = permittingSection;

        await HttpService.getHttpRequests(
          PermittingHttpService,
        ).updatePermittingWorkflowData(project!.id, {
          ...dataToPaceTasksMapper(
            {
              permittingSection: restPermittingSectionFields,
              zoningSection,
              projectPermittingNote,
              permittingAgent,
            },
            PERMITTING_SECTION_MAPPER_OPTIONS,
            ZONING_SECTION_MAPPER_OPTIONS,
          ),
        });

        updatePermittingPaceTaskFields(
          momentizeObjectDates(
            permittingForm.getFieldsValue(),
            PERMITTING_INFO_DATES,
            true,
          ),
          momentizeObjectDates(
            zoningForm.getFieldsValue(),
            ZONING_INFO_DATES,
            true,
          ),
        );

        setInitialForm(workflowForm);

        toggleEditing?.();

        NotificationsLoader.notificationSuccess(
          'Information has been updated!',
        );
      } catch (e) {
        console.error(e);
      }

      setDuringReqDisabled(false);
    }
  };

  const onCancel = (): void => {
    const [permitting, zoning] = initialForm;

    permittingForm?.setFieldsValue(
      momentizeObjectDates<PermittingSection>(
        (permitting as PermittingSection) || [],
        PERMITTING_INFO_DATES,
      ),
    );

    zoningForm?.setFieldsValue(
      momentizeObjectDates<ZoningSection>(
        (zoning as ZoningSection) || [],
        ZONING_INFO_DATES,
      ),
    );

    setWorkflowForm(initialForm);

    if (isEditing) {
      toggleEditing?.();
    }
  };

  const onValuesChange = (): void => {
    if (Object.keys(permittingForm.getFieldsValue()).length) {
      const data = [
        permittingForm.getFieldsValue(),
        zoningForm.getFieldsValue(),
      ];

      setWorkflowForm(data);

      if (!Object.keys(initialForm).length) {
        setInitialForm(data);
      }
    }
  };

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isEditing]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <main className="prov-permitting-workflow">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <WorkflowPermittingSection
          permissions={permissions!.workflowPermittingFields}
          isEditing={isEditing}
          onValuesChange={onValuesChange}
          data={permittingPanelData}
          form={permittingForm}
          permitting={site?.jurisdiction?.permitting}
        />
        <WorkflowZoningSection
          permissions={permissions!.workflowZoningFields}
          isEditing={isEditing}
          onValuesChange={onValuesChange}
          data={zoningPanelData}
          form={zoningForm}
        />
        <section className="prov-permitting-workflow__info-section">
          <JurisdictionInformation data={site?.jurisdiction} />
          <LeasingAndEngineeringInformation
            projectBundleID={project?.projectBundleID}
            projectCivilVendor={project?.civilVendor}
            zip={site?.zip}
            leasing={leasing}
            scoping={scoping}
            paceTasks={paceTasks}
          />
        </section>
      </div>
      {isEditing && (
        <section className="prov-permitting-workflow__actions">
          <PrimaryButton
            title="Submit"
            disabled={buttonsDisabled}
            onClick={onSubmit}
          />
          <PrimaryButton
            title="Cancel"
            disabled={buttonsDisabled}
            type="default"
            onClick={onCancel}
          />
        </section>
      )}
    </main>
  );
};

export const PermittingWorkflow: FC = () => {
  const permissions = useSelector(UserSelectors.getPermittingBrowsePermissions);
  const projectType = useSelector(PermittingSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit('permitting', SubPage.PermittingBrowse, Tab.Workflow),
  );
  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <EditablePage
      editableComponent={Workflow}
      permissions={permissions}
      canUserEdit={
        canEdit &&
        projectTypePermission &&
        canUserEditByProjectStatusAndRoles(projectStatusId, userRoles)
      }
    />
  );
};
