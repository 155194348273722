import { Nullable } from '@symfa-inc/providence-types';
import { projectCategoryModifier } from '@models/constants';
import { OptionProps } from '@models/interfaces';

export const getRankingValue = (
  projectRankingId: Nullable<string>,
  options: Record<string, OptionProps[]>,
): Nullable<string> =>
  (projectRankingId &&
    (options.ProjectRanking.find(
      (o: OptionProps) => o.value === projectRankingId,
    )?.viewValue as string)) ||
  null;

export const getRankingValueAsKeyModifier = (
  projectRankingId: Nullable<string>,
  options: Record<string, OptionProps[]>,
): keyof typeof projectCategoryModifier =>
  ((projectRankingId &&
    options.ProjectRanking.find(
      (option: OptionProps) => option.value === projectRankingId,
    )?.viewValue) as keyof typeof projectCategoryModifier) || 'Other';
