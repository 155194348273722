import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export const useClickOutsideListenerRef = (
  onClose: () => void,
): MutableRefObject<null> => {
  const ref = useRef(null);
  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, []);
  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (
        !(e.target as HTMLElement).className.includes('ant-switch') &&
        !(ref.current! as any).contains(e.target)
      ) {
        onClose?.();
      }
    },

    [ref.current],
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    document.addEventListener('keyup', escapeListener);

    return (): void => {
      document.removeEventListener('click', clickListener);
      document.removeEventListener('keyup', escapeListener);
    };
  }, []);

  return ref;
};
