import React, { FC, PropsWithChildren, useRef } from 'react';
import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { SearchProps } from './models';

import './styles.scss';

export const Search: FC<SearchProps> = ({
  onChange,
  debounceMs = 1000,
  className = '',
  allowClear,
}: PropsWithChildren<SearchProps>) => {
  const ref = useRef<Input>(null);

  const [debouncedCallback] = useDebouncedCallback(
    (onChangeCallback: (value: string) => void, value: string) =>
      onChangeCallback(value),
    debounceMs,
  );

  return (
    <div className={classNames('prov-search', className)}>
      <SearchOutlined className="prov-search__search-icon" />
      {allowClear && !!ref.current?.input.value && (
        <CloseCircleOutlined
          className="prov-search__clear-icon"
          onClick={(): void => {
            if (ref.current && onChange) {
              ref.current.setValue('', () => onChange(''));
            }
          }}
        />
      )}
      <Input
        ref={ref}
        className={classNames('prov-search__input', {
          allowClear,
        })}
        placeholder="Search"
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          onChange && debouncedCallback(onChange, e.target.value)
        }
      />
    </div>
  );
};
