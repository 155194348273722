import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { Store } from 'rc-field-form/es/interface';
import {
  HttpService,
  ObjectComparatorService,
  ObjectDifferencesService,
} from '@core/services';
import { LeasingHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import { isValidForm, momentizeObjectDates } from '@core/utils/methods';
import {
  EditableComponentProps,
  LeasingWorkflowUpdateData,
} from '@models/interfaces';
import { NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions } from '@store/actions';
import { LeasingSelectors, ProjectCategoriesSelectors } from '@store/selectors';
import {
  LEASING_ENGINEERING_INFO_DATES,
  LEASING_INFO_DATES,
  STRUCTURAL_INFO_DATES,
} from './models/constants';
import { EngineeringPanel, LeasingPanel, StructuralPanel } from './components';
import { isAllObjectsEmpty, updateLeasingPaceTaskFields } from './helpers';

import './styles.scss';

export const Workflow: FC<EditableComponentProps> = ({
  isEditing,
  toggleEditing,
  permissions: { engineeringFields, leasingFields, structuralFields },
}: PropsWithChildren<EditableComponentProps>) => {
  const dispatch = useDispatch();

  const [engineeringForm] = Form.useForm();
  const [leasingForm] = Form.useForm();
  const [structuralForm] = Form.useForm();

  const projectId = useSelector(LeasingSelectors.getProjectId);
  const engineeringPanel = useSelector(
    LeasingSelectors.getLeasingDetailsWorkflowEngineeringPanel,
  );
  const leasingPanel = useSelector(
    LeasingSelectors.getLeasingDetailsWorkflowLeasingPanel,
  );
  const structuralPanel = useSelector(
    LeasingSelectors.getLeasingDetailsWorkflowStructuralPanel,
  );
  const leasingFetching = useSelector(LeasingSelectors.isFetching);
  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );

  const [initialForm, setInitialForm] = useState<Array<Store>>([]);
  const [workflowForm, setWorkflowForm] = useState<Array<Store>>([]);

  const arraysCompare = ObjectComparatorService.arraysCompare(
    initialForm,
    workflowForm,
    true,
  );

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(!arraysCompare));
  }, [arraysCompare]);

  const onSubmit = async (): Promise<void> => {
    if ((await isValidForm(leasingForm)) && (await isValidForm(structuralForm)))
      try {
        const [initialEngineering, initialLeasing, initialStructural] =
          initialForm;
        const [engineering, leasing, structural] = workflowForm;

        await HttpService.getHttpRequests(
          LeasingHttpService,
        ).updateLeasingWorkflow(projectId, {
          engineering: momentizeObjectDates<
            LeasingWorkflowUpdateData['engineering']
          >(
            ObjectDifferencesService.getObjectsDiff(
              initialEngineering,
              engineering,
            ),
            LEASING_ENGINEERING_INFO_DATES,
            true,
          ),
          leasing: momentizeObjectDates<LeasingWorkflowUpdateData['leasing']>(
            ObjectDifferencesService.getObjectsDiff(initialLeasing, leasing),
            LEASING_INFO_DATES,
            true,
          ),
          structural: momentizeObjectDates<
            LeasingWorkflowUpdateData['structural']
          >(
            ObjectDifferencesService.getObjectsDiff(
              initialStructural,
              structural,
            ),
            STRUCTURAL_INFO_DATES,
            true,
          ),
        });

        updateLeasingPaceTaskFields({
          engineeringSection: momentizeObjectDates(
            engineering as LeasingWorkflowUpdateData['engineering'],
            [
              'passingMountAnalysisReviewedLeasing',
              'finalCDsReviewedLeasing',
              'prelimCDsApprovedLeasing',
            ],
            true,
          ),
          leasingSection: momentizeObjectDates(
            leasing as LeasingWorkflowUpdateData['leasing'],
            [
              'leaseAuditComplete',
              'SOFIssued',
              'coloAppApproved',
              'coloAppDrafted',
              'coloAppSubmitted',
              'PALOrNPAReceived',
              'leaseDraftReceived',
              'LMPSApproved',
              'LMPSSubmitted',
              'leaseOrAmendmentFullyExecuted',
              'NTPApproved',
              'NTPRequested',
            ],
            true,
          ),
          structuralSection: momentizeObjectDates(
            structural as LeasingWorkflowUpdateData['structural'],
            [
              'structuralOrdered',
              'passingStructuralReceived',
              'failingStructuralReceived',
              'modDesignComplete',
              'IBMApproved',
              'modConstructionStart',
              'modConstructionComplete',
            ],
            true,
          ),
        });

        setInitialForm(workflowForm);
        toggleEditing?.();
        NotificationsLoader.notificationSuccess(
          `Information has been updated!`,
        );
      } catch (e) {
        console.error(e);
      }
  };

  const onCancel = (): void => {
    const [engineering, leasing, structural] = initialForm;

    engineeringForm.setFieldsValue(
      momentizeObjectDates(engineering || [], LEASING_ENGINEERING_INFO_DATES),
    );
    leasingForm.setFieldsValue(
      momentizeObjectDates(leasing || [], LEASING_INFO_DATES),
    );
    structuralForm.setFieldsValue(
      momentizeObjectDates(structural || [], STRUCTURAL_INFO_DATES),
    );

    setWorkflowForm(initialForm);

    toggleEditing?.();
  };

  const onValuesChange = (): void => {
    const data = [
      engineeringForm.getFieldsValue(),
      leasingForm.getFieldsValue(),
      structuralForm.getFieldsValue(),
    ];

    setWorkflowForm(data);

    const isInitialFormEmpty = isAllObjectsEmpty(initialForm);

    if (isInitialFormEmpty) {
      setInitialForm(data);
    }
  };

  const buttonsDisabled = leasingFetching || arraysCompare;

  useDidUpdateEffect(() => {
    onValuesChange();
  }, [isEditing]);

  useDidUpdateEffect(() => {
    if (!isEditing) {
      setInitialForm(workflowForm);
    }
  }, [isEditing, workflowForm]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <div className="prov-leasing-workflow">
      <div
        className={classNames('tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <EngineeringPanel
          isEditing={isEditing}
          permissions={engineeringFields}
          form={engineeringForm}
          data={engineeringPanel}
          onValuesChange={onValuesChange}
        />
        <LeasingPanel
          isEditing={isEditing}
          permissions={leasingFields}
          form={leasingForm}
          data={leasingPanel}
          onValuesChange={onValuesChange}
        />
        <StructuralPanel
          isEditing={isEditing}
          permissions={structuralFields}
          form={structuralForm}
          data={structuralPanel}
          onValuesChange={onValuesChange}
        />
      </div>
      {isEditing && (
        <div className="prov-leasing-handle__btns">
          <div className="action-wrap">
            <PrimaryButton
              title="Submit"
              disabled={buttonsDisabled}
              onClick={onSubmit}
            />
            <PrimaryButton
              title="Cancel"
              type="default"
              disabled={buttonsDisabled}
              onClick={onCancel}
            />
          </div>
        </div>
      )}
    </div>
  );
};
