import { DayjsAdapter } from './dayjs-adapter';
import {
  DateWorker,
  DateWorkerFormatSpecification,
  DateWorkerInput,
} from './models';

export type { DateWorker } from './models';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function dateWorker(inp?: DateWorkerInput, strict?: boolean): DateWorker;
export function dateWorker(
  inp?: DateWorkerInput,
  format?: DateWorkerFormatSpecification,
  strict?: boolean,
): DateWorker;

export function dateWorker(
  inp?: DateWorkerInput,
  format?: DateWorkerFormatSpecification,
  language?: string,
  strict?: boolean,
): DateWorker {
  return new DayjsAdapter(inp, format, language, strict);
}

dateWorker.isMomento = DayjsAdapter.isDateWorker;
dateWorker.duration = DayjsAdapter.duration;
