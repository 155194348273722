import React, { FC, PropsWithChildren } from 'react';
import { Button } from 'antd';
import editIcon from 'assets/icons/edit-icon.svg';

import './styles.scss';

export const RADColumnButtons: FC<any> = ({
  editFunc,
  title,
  isEditing = false,
}: PropsWithChildren<any>) => {
  const columnTitle = `${title[0].toUpperCase()}${title.substring(1)}`;

  return (
    <div className="prov-rad-columns-btn">
      {columnTitle}
      {isEditing && (
        <Button
          className="edit-icon"
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
            e.stopPropagation();

            editFunc(title);
          }}
        >
          <img src={editIcon} alt="Edit row" />
        </Button>
      )}
    </div>
  );
};
