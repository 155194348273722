import { createSelector } from 'reselect';
import { arrsToSelectableObject } from '@core/utils/methods';
import { AppState } from '../../reducers';
import { CountyValuesState } from '../../reducers/admin/county.reducer';

const countyState = (state: AppState): CountyValuesState =>
  state.admin.countyValues;

export namespace CountySelectors {
  export const getStatesWithCounties = createSelector(
    [countyState],
    (state: CountyValuesState) => state.states,
  );

  export const getStateLikeSelectorOptions = createSelector(
    [countyState],
    (state: CountyValuesState) =>
      arrsToSelectableObject(state.states, 'id', 'name'),
  );
}
