import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { TableContainerProps } from './models';

import './styles.scss';

export const TableContainer: FC<TableContainerProps> = ({
  header = '',
  className = '',
  children,
}: PropsWithChildren<TableContainerProps>) => (
  <div className={classNames('prov-table-container', className)}>
    {header && <div className="prov-table-container__header">{header}</div>}
    <div className="prov-table-container__content">{children}</div>
  </div>
);
