import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { SubPage, Tab } from '@symfa-inc/providence-types';
import { canUserEditByProjectStatusAndRoles } from '@core/utils/methods';
import { EditablePage, LeasingEquipment } from '@shared/components';
import {
  ProjectSelectors,
  ScopingSelectors,
  UserSelectors,
} from '@store/selectors';

export const ScopingLeasingEquipmentTab: FC<RouterProps> = () => {
  const canEdit = useSelector(
    UserSelectors.canEdit(
      'engineering',
      SubPage.EngineeringScoping,
      Tab.LeaseEquipment,
    ),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <EditablePage
      editableComponent={LeasingEquipment}
      canUserEdit={
        canEdit &&
        canUserEditByProjectStatusAndRoles(projectStatusId, userRoles)
      }
      data={useSelector(ScopingSelectors.getScopingLeaseEquipmentData)}
    />
  );
};
