import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import { findFromSelectorOptions, mainDateFormat } from '@core/utils/methods';
import { Modal, PrimaryButton } from '@shared/modules';
import { DirectMessageViewerProps } from '../../models';

import './styles.scss';

export const DirectMessageViewer: FC<DirectMessageViewerProps> = ({
  visible,
  onCancel,
  usersList,
  item,
}: DirectMessageViewerProps) => {
  const onRemove = useCallback(async (): Promise<void> => {
    await HttpService.getHttpRequests(
      NotificationsHttpService,
    ).removeDirectMessages(item!._id);

    onCancel();
  }, [item, onCancel]);

  return (
    <Modal
      title={`Direct Message${
        item
          ? ` - ${item.projectData.siteNumber}(${item.projectData.projectBundleID})`
          : ''
      }`}
      className="prov-admin-direct-message-viewer"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <PrimaryButton
          key="cancel"
          type="default"
          title="Cancel"
          onClick={onCancel}
        />,
        <PrimaryButton key="remove" title="Remove" onClick={onRemove} />,
      ]}
    >
      <div className="prov-admin-direct-message-viewer__content">
        <div className="item">
          <span className="item__label">Status</span>
          <span
            className={classNames(
              'item__value',
              item?.directMessageData.isCompleted
                ? 'completed'
                : 'not-completed',
            )}
          >
            {item?.directMessageData.isCompleted
              ? 'Completed'
              : 'Not Completed'}
          </span>
        </div>
        <div className="item">
          <span className="item__label">Sender</span>
          <span className="item__value">
            {findFromSelectorOptions(
              item?.directMessageData.senderId || '',
              usersList,
            )}
          </span>
        </div>
        <div className="item">
          <span className="item__label">Area</span>
          <span className="item__value">{item?.directMessageData.areaKey}</span>
        </div>
        <div className="item">
          <span className="item__label">Receivers</span>
          <span className="item__value">
            {item?.directMessageData.receivers
              .map((receiver: string) =>
                findFromSelectorOptions(receiver, usersList),
              )
              .join(', ')}
          </span>
        </div>
        <div className="item">
          <span className="item__label">Created</span>
          <span className="item__value">
            {mainDateFormat(item?.directMessageData.createdAt)}
          </span>
        </div>
        <span className="message">{item?.directMessageData.message}</span>
      </div>
    </Modal>
  );
};
