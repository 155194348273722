export const convertToDMS = (field: number): string => {
  const num = Math.abs(field);

  const deg = Math.floor(num);
  const min = Math.floor((num - deg) * 60);
  const sec = +((num - deg - min / 60) * 3600).toFixed(2);

  return `${field < 0 ? -deg : deg}° ${min}' ${sec}"`;
};

export const convertToDecimal = (field: string): number => {
  const dms = field
    .match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)
    ?.map((value: string) => +value);

  if (dms?.length) {
    const [deg, min = 0, sec = 0] = dms;

    const result =
      Math.round((Math.abs(deg) + min / 60 + sec / 3600) * 1e7) / 1e7;
    const condition = deg < 0 ? -result : result;

    return result ? condition : +field;
  }

  return +field;
};
