import React, { FC, PropsWithChildren, useState } from 'react';
import { Row } from 'antd';
import classNames from 'classnames';
import {
  ProcessEnvMap as KeyString,
  SectorEquipment as SectorEquipmentInterface,
  SectorEquipmentType as SectorEquipmentTypeInterface,
} from '@models/interfaces';
import { CustomTable, Panel } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { getSectorEquipmentsColumns } from './models/constants';
import { SectorEquipmentProps } from './models/interfaces';
import { AddEquipmentModal, DuplicateSectorModal } from './components';

export const SectorEquipment: FC<SectorEquipmentProps> = ({
  className,
  isEditing,
  type,
  sectorsForDuplicate,
  deleteSector,
  data,
  setSectorsDataVersions,
}: PropsWithChildren<SectorEquipmentProps>) => {
  const [isAddEquipmentModalVisible, setAddEquipmentModalVisible] =
    useState<boolean>(false);
  const [isDuplicateModalVisible, setDuplicateModalVisible] =
    useState<boolean>(false);

  const toggleEquipmentModal = (): void =>
    setAddEquipmentModalVisible(!isAddEquipmentModalVisible);
  const toggleDuplicateModal = (): void =>
    setDuplicateModalVisible(!isDuplicateModalVisible);

  return (
    <Panel
      header={`Sector Equipment - ${type.toUpperCase()}`}
      className={classNames('prov-sector-equipment', className)}
      collapsible
    >
      {isEditing && (
        <Row justify="space-between" className="action-buttons-container">
          <div className="action-buttons">
            <PrimaryButton
              onClick={toggleEquipmentModal}
              title="Add Equipment"
              icon="+"
            />
            <PrimaryButton
              onClick={toggleDuplicateModal}
              title="Duplicate Sector"
              disabled={!sectorsForDuplicate.length}
            />
          </div>
          <PrimaryButton
            title="Delete Sector"
            type="danger"
            onClick={(): void => deleteSector(type)}
          />
        </Row>
      )}
      <CustomTable
        columns={getSectorEquipmentsColumns(
          data,
          data?.[type],
          type,
          setSectorsDataVersions,
          isEditing,
        )}
        dataSource={
          data?.[type].length
            ? Array.from({ length: 5 }, (_: unknown, index: number) => ({
                ...data?.[type].reduce(
                  (
                    acc: {
                      position: number;
                    },
                    dataItem: SectorEquipmentTypeInterface,
                  ) => ({
                    ...acc,
                    [dataItem.equipmentType]: dataItem?.equipments?.find(
                      (e: SectorEquipmentInterface) =>
                        e?.position === index + 1,
                    )?.equipment,
                    position: index + 1,
                  }),
                  {},
                ),
              }))
            : []
        }
        hoverable={!isEditing}
        pagination={false}
        rowKey={(record: KeyString & { position: number }): string => {
          const [key] = Object.keys(record);

          return `${type}-${key}-${record[key]}-${record.position}`;
        }}
      />
      <AddEquipmentModal
        visible={isAddEquipmentModalVisible}
        toggleModal={toggleEquipmentModal}
        setSectorsDataVersions={setSectorsDataVersions}
        data={data?.[type]}
        type={type}
      />
      <DuplicateSectorModal
        visible={isDuplicateModalVisible}
        onCancel={toggleDuplicateModal}
        setSectorsDataVersions={setSectorsDataVersions}
        sectorsForDuplicate={sectorsForDuplicate}
        sectorType={type}
      />
    </Panel>
  );
};
