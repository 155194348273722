import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Select as AntSelect, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { OptionProps } from '@models/interfaces';
import { FormItem, ReadOnlySelect } from '@shared/modules';
import { FormElementSelect } from '../../models/interfaces';
import { getPopupContainer } from './models';

export const Select: FC<FormElementSelect> = ({
  id,
  title = '',
  tooltip = false,
  elementProps,
  options = [],
  readOnlyElementRender,
  ...props
}: PropsWithChildren<FormElementSelect>) => (
  <FormItem
    id={id}
    editingElement={
      tooltip ? (
        <Tooltip title={title}>
          <AntSelect
            showArrow
            id={id}
            getPopupContainer={getPopupContainer}
            {...elementProps}
          />
        </Tooltip>
      ) : (
        <AntSelect
          showArrow
          id={id}
          getPopupContainer={getPopupContainer}
          {...elementProps}
        >
          {options?.map(({ viewValue, value, ...option }: OptionProps) => (
            <AntSelect.Option key={value} value={value} {...option}>
              {viewValue}
            </AntSelect.Option>
          ))}
        </AntSelect>
      )
    }
    readOnlyElement={(formInstance: FormInstance): ReactNode => {
      const value = formInstance.getFieldValue(props.formItemProps?.name ?? id);

      return (
        <ReadOnlySelect
          value={value}
          options={options}
          render={readOnlyElementRender}
        />
      );
    }}
    {...props}
  />
);
