import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { isValidForm } from '@core/utils/methods';
import { Autocomplete, Modal } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';
import { JurisdictionModalProps } from '../models';

export const JurisdictionModal: FC<JurisdictionModalProps> = ({
  onOk,
  initialFormValues,
  visible,
  ...props
}: PropsWithChildren<JurisdictionModalProps>) => {
  const [form] = Form.useForm();

  const { ProjectTypeDDV, ProjectSubtypeDDV } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'ProjectType',
      'ProjectSubtype',
    ]),
  );

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialFormValues);
    }
  }, [form, initialFormValues, visible]);

  const onApply = async (): Promise<void> => {
    if (await isValidForm(form)) {
      onOk({
        ...initialFormValues,
        ...form.getFieldsValue(),
      });

      form.resetFields();
    }
  };

  return (
    <Modal visible={visible} onOk={onApply} {...props}>
      <Form form={form} labelCol={{ span: 6 }}>
        <Autocomplete
          id="projectType"
          key="projectType"
          label="Project Type"
          options={ProjectTypeDDV}
          formItemProps={{
            rules: [{ required: true, message: 'Project Type is required!' }],
          }}
        />
        <Autocomplete
          id="projectSubType"
          key="projectSubType"
          label="Project Subtype"
          options={ProjectSubtypeDDV}
          formItemProps={{
            rules: [
              { required: true, message: 'Project Subtype is required!' },
            ],
          }}
        />
      </Form>
    </Modal>
  );
};
