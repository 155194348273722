import React from 'react';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import downloadIcon from 'assets/icons/download-icon.svg';
import { AxiosError } from 'axios';
import { Config } from '@core/config/config';
import {
  DRAG_AND_DROP_ACCEPTED_TYPES,
  UPLOADER_PROPS,
} from '@models/constants';
import { ConnectionConfig, ImportFormProps } from '@models/interfaces';
import { DragAndDropUploader, NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';

import './styles.scss';

export const ImportForm = <T extends void | Record<keyof T, unknown>>({
  template,
  sendFile,
  onError,
  acceptedTypes = DRAG_AND_DROP_ACCEPTED_TYPES,
}: ImportFormProps<T>) => {
  const { staticApiUrl }: ConnectionConfig = Config.get().connectionConfig;

  const link: string = `${staticApiUrl}/templates/${template}.xlsx`;
  const customRequest = async ({
    file,
    onSuccess,
  }: RcCustomRequestOptions): Promise<void> => {
    const notification = new NotificationsLoader({
      description: 'File is uploading',
      message: 'Please wait',
    });

    try {
      onError([]);

      await sendFile(file);

      onSuccess({}, file);
    } catch (e) {
      if (e instanceof AxiosError && e.response) {
        onError(e.response.data.message);
      }
    }

    notification.close();
  };

  return (
    <div className="prov-import">
      <div className="prov-import__header">
        <h1 className="title">File To Import</h1>
        <PrimaryButton
          className="download-button"
          title="Download Template"
          href={link}
          target="_blank"
          icon={
            <img
              src={downloadIcon}
              alt="Download Icon"
              className="header__download-icon"
            />
          }
        />
      </div>
      <div className="prov-import__body">
        <DragAndDropUploader
          acceptedTypes={acceptedTypes}
          customRequest={customRequest}
          {...UPLOADER_PROPS}
        />
      </div>
    </div>
  );
};
