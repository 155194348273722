import { DetailsResolver } from '@core/resolvers';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';

export class ProjectDetailsResolver extends DetailsResolver {
  async resolve(): Promise<void> {
    await super.resolve();

    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await HttpService.getHttpRequests(ProjectHttpService).getProject(
      projectBundleID,
    );
  }
}
