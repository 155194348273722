import {
  ScopingSectorsVersion,
  ScopingSectorsVersionData,
  ScopingSectorsVersionResp,
  SectorDataState,
} from '../types';

export const SCOPING_SECTORS: SectorDataState = {
  Alpha: [],
  Beta: [],
  Gamma: [],
  Delta: [],
};
export const SCOPING_SECTORS_DATA: SectorDataState = {
  Alpha: [],
};

export const SCOPING_SECTORS_VERSIONS: ScopingSectorsVersion[] = [
  {
    version: 1,
    isCurrent: true,
    scopingSectors: { Alpha: SCOPING_SECTORS.Alpha },
  },
];
export const SCOPING_SECTORS_VERSIONS_RESPONSE: ScopingSectorsVersionResp[] = [
  {
    version: 1,
    isCurrent: true,
    scopingSectors: [],
  },
];
export const SCOPING_SECTORS_VERSIONS_DATA: ScopingSectorsVersionData[] =
  SCOPING_SECTORS_VERSIONS.map(scopingDataVersion => ({
    ...scopingDataVersion,
    isSelected: scopingDataVersion.isCurrent,
  }));
