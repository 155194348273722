import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { LeasingHttpService, ProjectHttpService } from '@core/services/http';
import { requestWithStoreWaiting } from '@core/utils/methods';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';
import store from '@store/index';

export class LeasingBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(LeasingHttpService).getLeasingBrowse(
      BROWSE_DEFAULT_FILTER_VALUE,
    );
    await requestWithStoreWaiting(
      () =>
        HttpService.getHttpRequests(ProjectHttpService).getProvidenceAgents(),
      () => store.getState().project,
    );
  }
}
