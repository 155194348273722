import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-types';
import { transformFields } from '../../core/utils/methods';
import { createReducer, on } from '../../core/utils/store';
import { FINANCIAL_SITE_FEES_TRANSFORM_FIELDS } from '../../models/constants/transform-fields';
import {
  AccountingDetails,
  FinancialData,
  FinancialOOMInformation,
  FinancialServicesData,
  FinancialSiteFeesData,
  PayloadAndState,
} from '../../models/interfaces';
import { AccountingActions } from '../actions/accounting.actions';
import { FinancialActions } from '../actions/financial.actions';

export interface FinancialState {
  financialData: FinancialData;
  OOMInformation: FinancialOOMInformation[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: FinancialState = {
  financialData: {
    id: '',
    siteFees: [],
    servicesRequired: [],
  },
  OOMInformation: [],
  isFetching: false,
  errors: false,
};

const PACETaskMap: Readonly<
  Record<string, { paceTaskID: string; taskName: string }>
> = {
  [MAIN_PACE_TASK_IDs.siteAcqComplete]: {
    paceTaskID: 'RE020',
    taskName: 'Site Acq Complete',
  },
  [MAIN_PACE_TASK_IDs.leasingComplete]: {
    paceTaskID: 'RE015',
    taskName: 'Leasing Complete',
  },
};

export const reducer = createReducer(
  initialState,
  // GET FINANCIAL DATA
  on(
    FinancialActions.getFinancialDataAction,
    ({
      payload: siteFeesPayload,
    }: PayloadAndState<FinancialData, FinancialState>) => {
      const siteFeesData = siteFeesPayload ?? {};
      const { siteFees, ...financial } = siteFeesData;

      return {
        financialData: {
          ...financial,
          siteFees: siteFees?.map((siteFee: FinancialSiteFeesData) =>
            transformFields<FinancialSiteFeesData>(
              siteFee,
              FINANCIAL_SITE_FEES_TRANSFORM_FIELDS,
            ),
          ),
        },
      };
    },
  ),
  // ADD FINANCIAL SITE FEE
  on(
    FinancialActions.addFinancialSiteFeeAction,
    ({
      payload,
      state: {
        financialData: { siteFees, ...financial },
      },
    }: PayloadAndState<FinancialSiteFeesData, FinancialState>) => ({
      financialData: {
        ...financial,
        siteFees: [
          ...(siteFees || []),
          transformFields<FinancialSiteFeesData>(
            payload,
            FINANCIAL_SITE_FEES_TRANSFORM_FIELDS,
          ),
        ],
      },
    }),
  ),
  // UPDATE FINANCIAL SITE FEE
  on(
    FinancialActions.updateFinancialSiteFeeAction,
    ({
      payload,
      state: {
        financialData: { siteFees, ...financial },
      },
    }: PayloadAndState<FinancialSiteFeesData, FinancialState>) => ({
      financialData: {
        ...financial,
        siteFees: siteFees.map((siteFee: FinancialSiteFeesData) =>
          siteFee.id === payload.id
            ? {
                ...transformFields<FinancialSiteFeesData>(
                  payload,
                  FINANCIAL_SITE_FEES_TRANSFORM_FIELDS,
                ),
                creator: siteFee.creator,
              }
            : siteFee,
        ),
      },
    }),
  ),
  // DELETE FINANCIAL SITE FEE
  on(
    FinancialActions.deleteFinancialSiteFeeAction,
    ({
      payload: id,
      state: {
        financialData: { siteFees, ...financial },
      },
    }: PayloadAndState<string, FinancialState>) => ({
      financialData: {
        ...financial,
        siteFees: siteFees.filter(
          (siteFee: FinancialSiteFeesData) => siteFee.id !== id,
        ),
      },
    }),
  ),
  // ADD FINANCIAL SERVICE
  on(
    FinancialActions.addFinancialServiceAction,
    ({
      payload,
      state: {
        financialData: { servicesRequired, ...financial },
      },
    }: PayloadAndState<FinancialServicesData, FinancialState>) => ({
      financialData: {
        ...financial,
        servicesRequired: [...(servicesRequired || []), payload],
      },
    }),
  ),
  // UPDATE FINANCIAL SERVICE
  on(
    FinancialActions.updateFinancialServiceAction,
    ({
      payload: { id, ...payload },
      state: {
        financialData: { servicesRequired, ...financial },
      },
    }: PayloadAndState<FinancialServicesData, FinancialState>) => ({
      financialData: {
        ...financial,
        servicesRequired: servicesRequired.map(
          (service: FinancialServicesData) =>
            service.id === id
              ? {
                  ...service,
                  ...payload,
                }
              : service,
        ),
      },
    }),
  ),
  // DELETE FINANCIAL SERVICE
  on(
    FinancialActions.deleteFinancialServiceAction,
    ({
      payload: id,
      state: {
        financialData: { servicesRequired, ...financial },
      },
    }: PayloadAndState<string, FinancialState>) => ({
      financialData: {
        ...financial,
        servicesRequired: servicesRequired.filter(
          (service: FinancialServicesData) => service.id !== id,
        ),
      },
    }),
  ),
  // GET ACCOUNTING DATA
  on(
    AccountingActions.getAccountingDetailsAction,
    ({
      payload: { financialData, OOMInformation },
    }: PayloadAndState<AccountingDetails, FinancialState>) => ({
      financialData: {
        ...financialData,
        siteFees: financialData?.siteFees?.map(
          (siteFee: FinancialSiteFeesData) =>
            transformFields<FinancialSiteFeesData>(
              siteFee,
              FINANCIAL_SITE_FEES_TRANSFORM_FIELDS,
            ),
        ),
      },
      OOMInformation,
    }),
  ),
  // GET OOM INFORMATION DATA
  on(
    FinancialActions.getOOMInformationDataAction,
    ({
      payload,
    }: PayloadAndState<FinancialOOMInformation[], FinancialState>) => ({
      OOMInformation: payload,
    }),
  ),
  // ADD OOM INFORMATION
  on(
    FinancialActions.addOOMInformationDataAction,
    ({
      payload: {
        OOMDate,
        actualDate,
        OOMAmount,
        PACETaskIdentification,
        OOMTotalAmount,
        id,
      },
      state: { OOMInformation },
    }: PayloadAndState<FinancialOOMInformation, FinancialState>) => {
      const { taskName, paceTaskID } = PACETaskMap[PACETaskIdentification!];

      return {
        OOMInformation: [
          ...OOMInformation,
          {
            id,
            OOMDate,
            actualDate,
            OOMAmount,
            OOMTotalAmount,
            paceTaskID,
            taskName,
          },
        ],
      };
    },
  ),
  // UPDATE OOM INFORMATION
  on(
    FinancialActions.updateOOMInformationDataAction,
    ({
      payload: {
        id,
        OOMDate,
        actualDate,
        OOMAmount,
        PACETaskIdentification,
        OOMTotalAmount,
      },
      state: { OOMInformation },
    }: PayloadAndState<FinancialOOMInformation, FinancialState>) => {
      const { taskName, paceTaskID } = PACETaskMap[PACETaskIdentification!];

      return {
        OOMInformation: [
          ...OOMInformation.map((currentValue: FinancialOOMInformation) =>
            currentValue.id === id
              ? {
                  ...currentValue,
                  OOMDate,
                  actualDate,
                  OOMAmount,
                  OOMTotalAmount,
                  paceTaskID,
                  taskName,
                }
              : currentValue,
          ),
        ],
      };
    },
  ),
  // DELETE OOM INFORMATION
  on(
    FinancialActions.deleteOOMInformationDataAction,
    ({
      payload: id,
      state: { OOMInformation },
    }: PayloadAndState<string, FinancialState>) => ({
      OOMInformation: [
        ...OOMInformation.filter(
          (currentValue: FinancialOOMInformation) => currentValue.id !== id,
        ),
      ],
    }),
  ),
);
