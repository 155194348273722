import {
  DirectMessageAreaKey,
  IReceivedDirectMessageForArea,
  ISentDirectMessage,
} from '@symfa-inc/providence-types';
import { DirectMessageActions } from '@store/actions';
import { StoredRequest } from '../../utils/store/stored-request-decorator';
import { CommonHttpService } from './common-http.service';

export class DirectMessageHttpService extends CommonHttpService {
  @StoredRequest(DirectMessageActions.getDirectMessages)
  getDirectMessages(
    areaKey: DirectMessageAreaKey,
    projectId: string,
  ): Promise<ISentDirectMessage[]> {
    return this.http.callApi.get(`${this.apiUrl}/direct-messages`, null, {
      params: { areaKey, projectId },
    });
  }

  @StoredRequest(DirectMessageActions.getReceivedDirectMessages)
  getReceivedDirectMessages(
    areaKey: DirectMessageAreaKey,
    projectId: string,
  ): Promise<IReceivedDirectMessageForArea[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/direct-messages/received`,
      null,
      {
        params: { areaKey, projectId },
      },
    );
  }

  @StoredRequest(DirectMessageActions.getUsersByArea)
  getUsersByArea(
    areaKey: DirectMessageAreaKey,
    projectId: string,
  ): Promise<ISentDirectMessage[]> {
    return this.http.callApi.get(`${this.apiUrl}/direct-messages/users`, null, {
      params: { areaKey, projectId },
    });
  }

  @StoredRequest(DirectMessageActions.createDirectMessage)
  async createDirectMessages(
    body: Pick<ISentDirectMessage, 'receivers' | 'message'>,
    areaKey: DirectMessageAreaKey,
    projectId: string,
  ): Promise<ISentDirectMessage> {
    const _id = await this.http.callApi.post(`${this.apiUrl}/direct-messages`, {
      ...body,
      areaKey,
      projectId,
    });

    return {
      _id,
      ...body,
      isCompleted: false,
    };
  }

  @StoredRequest(DirectMessageActions.updateDirectMessage)
  async updateDirectMessages(
    { isCompleted, _id, ...body }: ISentDirectMessage,
    areaKey: string,
    projectId: string,
  ): Promise<Omit<ISentDirectMessage, 'isCompleted'>> {
    await this.http.callApi.patch(
      `${this.apiUrl}/direct-messages`,
      { ...body, areaKey, projectId },
      {
        params: { directMessageId: _id },
      },
    );

    return { _id, ...body };
  }

  @StoredRequest(DirectMessageActions.setCompletedDirectMessage)
  async setCompletedDirectMessage(directMessageId: string): Promise<string> {
    await this.http.callApi.patch(
      `${this.apiUrl}/direct-messages/completed`,
      null,
      { params: { directMessageId } },
    );

    return directMessageId;
  }

  @StoredRequest(DirectMessageActions.multiSetCompletedDirectMessage)
  async multiSetCompletedDirectMessage(recordIds: string[]): Promise<string[]> {
    await this.http.callApi.patch(
      `${this.apiUrl}/direct-messages/multi-completed`,
      null,
      { params: { recordIds } },
    );

    return recordIds;
  }

  @StoredRequest(DirectMessageActions.removeDirectMessage)
  async removeDirectMessages(directMessageId: string): Promise<string> {
    await this.http.callApi.delete(`${this.apiUrl}/direct-messages`, null, {
      params: { directMessageId },
    });

    return directMessageId;
  }
}
