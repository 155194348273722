import React, { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input } from 'antd';
import { Store } from 'antd/es/form/interface';
import { HttpService } from '@core/services';
import { AuthHttpService } from '@core/services/http';
import { emailValidator } from '@core/utils/validators';
import { AuthLayout } from '@layout/components';
import { GoBackButton, NotificationsLoader } from '@shared/components';
import { PrimaryButton } from '@shared/modules';

export const ForgotPassword: FC = () => {
  const history = useHistory();
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false);

  const onForgotPassword = useCallback(
    async ({ email }: Store): Promise<void> => {
      try {
        setBtnDisabled((prevState: boolean) => !prevState);
        const httpService: AuthHttpService =
          HttpService.getHttpRequests(AuthHttpService);

        await httpService.resetPassword(email);
        history.goBack();
        NotificationsLoader.notificationSuccess(
          'New password was send your email!',
        );
      } catch (e) {
        setBtnDisabled((prevState: boolean) => !prevState);
        console.error(e);
      }
    },
    [],
  );

  return (
    <AuthLayout header="Forgot Password">
      <Form onFinish={onForgotPassword}>
        <Form.Item name="email" rules={emailValidator(true)}>
          <Input placeholder="Email" className="wrap__input" />
        </Form.Item>
        <Form.Item>
          <PrimaryButton
            className="wrap__button"
            title="Reset"
            htmlType="submit"
            disabled={btnDisabled}
          />
          <GoBackButton className="wrap__button" title="Back" />
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};
