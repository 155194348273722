import React, {
  forwardRef,
  PropsWithChildren,
  Ref,
  useImperativeHandle,
  useState,
} from 'react';
import { NotifyType, Nullable, UserRole } from '@symfa-inc/providence-types';
import { SelectValue } from 'antd/lib/select';
import { getCurrentOptions, getEnumKeyByEnumValue } from '@core/utils/methods';
import {
  RESPONSIBLE_FOR_PROJECT_TYPE_OPTIONS,
  USER_ROLE_OPTIONS,
} from '@models/constants';
import { Autocomplete, Select } from '@shared/modules';
import { actualizeControl, getCurrentNotifyType } from '../helpers';
import { NotifyTypeControlsProps, NotifyTypeControlsRefType } from '../models';

type ChangeNotifyTypeArgument = Parameters<
  NotifyTypeControlsRefType['changeNotifyType']
>[number];
type ChangeRoles = Parameters<NotifyTypeControlsRefType['changeRoles']>[number];

export const NotifyTypeControls = forwardRef(
  (
    {
      form,
      userList,
      towerOwners,
      setAdminRolePresence,
    }: PropsWithChildren<NotifyTypeControlsProps>,
    forwardedRef: Ref<NotifyTypeControlsRefType>,
  ) => {
    const [notifyType, setNotifyType] =
      useState<ChangeNotifyTypeArgument>(null);
    const [roles, setRoles] = useState<ChangeRoles>(null);

    const isRoleType =
      notifyType === getEnumKeyByEnumValue(NotifyType, NotifyType.Role);

    useImperativeHandle(forwardedRef, () => ({
      changeNotifyType(value: ChangeNotifyTypeArgument): void {
        setNotifyType(value);
      },
      changeRoles(value: ChangeRoles): void {
        setRoles(value);
      },
    }));

    const hasAdminOrEMRole = (currentRoles?: Nullable<string[]>): boolean =>
      currentRoles && currentRoles.length
        ? [UserRole.Admin, UserRole.EngineeringManager].some((role: UserRole) =>
            currentRoles.includes(role),
          )
        : false;

    const hasLeasingAgentRole = (currentRoles?: Nullable<string[]>): boolean =>
      currentRoles && currentRoles.length
        ? currentRoles.includes(UserRole.LeasingAgent)
        : false;

    return (
      <>
        <Select
          id="notifyType"
          label="Notify Type"
          formItemProps={{
            rules: [{ required: true, message: 'Notify Type is required!' }],
          }}
          elementProps={{
            onChange: (value: SelectValue): void => {
              actualizeControl(
                setNotifyType,
                form,
                NotifyType,
                value as string,
                [
                  'roles',
                  'userIds',
                  'responsibleForProjectType',
                  'towerOwners',
                ],
              );

              if (value === NotifyType.User) {
                setRoles(null);
              }
            },
          }}
          options={getCurrentOptions(NotifyType, getCurrentNotifyType)}
        />
        {notifyType &&
          (isRoleType ? (
            <Select
              id="roles"
              label="Roles"
              formItemProps={{
                rules: [{ required: true, message: 'Roles are required!' }],
              }}
              elementProps={{
                mode: 'multiple',
                onChange: (value: SelectValue): void => {
                  const selectedValues = value as string[];

                  setRoles((prevState: ChangeRoles) => {
                    if (
                      hasAdminOrEMRole(prevState) &&
                      !hasAdminOrEMRole(selectedValues)
                    ) {
                      form.resetFields(['responsibleForProjectType']);
                    }

                    if (
                      hasLeasingAgentRole(prevState) &&
                      !hasLeasingAgentRole(selectedValues)
                    ) {
                      form.resetFields(['towerOwners']);
                    }

                    return selectedValues;
                  });

                  if (setAdminRolePresence) {
                    setAdminRolePresence(
                      selectedValues.includes(UserRole.Admin),
                    );
                  }
                },
              }}
              options={USER_ROLE_OPTIONS}
            />
          ) : (
            <Select
              id="userIds"
              label="Users"
              formItemProps={{
                rules: [{ required: true, message: 'Users are required!' }],
              }}
              elementProps={{
                mode: 'multiple',
              }}
              options={userList}
            />
          ))}
        {notifyType && isRoleType && hasAdminOrEMRole(roles) && (
          <Autocomplete
            id="responsibleForProjectType"
            label="Responsible For Project Type"
            options={RESPONSIBLE_FOR_PROJECT_TYPE_OPTIONS}
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: 'Responsible For Project Type is required!',
                },
              ],
            }}
          />
        )}
        {notifyType && isRoleType && hasLeasingAgentRole(roles) && (
          <Autocomplete
            id="towerOwners"
            label="Tower Owners"
            elementProps={{
              mode: 'multiple',
            }}
            options={towerOwners}
          />
        )}
      </>
    );
  },
);
