import { SiteBrowseFilter } from '@models/interfaces';

export const SITE_BROWSE_DEFAULT_CONFIGURE_FILTER: SiteBrowseFilter = {
  isSiteOn: undefined,
  towerOwnerId: undefined,
  towerTypeId: undefined,
  stateId: undefined,
  countyId: undefined,
  jurisdictionId: undefined,
  onlyActive: undefined,
};
