import React, { FC, PropsWithChildren, useState } from 'react';
import { Form } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { SectorEquipmentType } from '@models/enums';
import { Modal, RadioGroup } from '@shared/modules';
import { RadioButtonProps } from '@shared/modules/ui-kit/models/interfaces';
import { SectorSetupModalProps } from './models/interfaces';

import './styles.scss';

export const SectorSetupModal: FC<SectorSetupModalProps> = ({
  className,
  onOk,
  sectorsForCreate,
  ...props
}: PropsWithChildren<SectorSetupModalProps>) => {
  const [currentValue, setCurrentValue] = useState<SectorEquipmentType>();

  const buttons: RadioButtonProps[] = sectorsForCreate.map(
    (sector: SectorEquipmentType) => ({
      value: sector,
      viewValue: sector,
      className: 'radio-button',
    }),
  );

  return (
    <Modal
      title="Sector Setup"
      okText="Save"
      className={className}
      onOk={(): void => onOk(currentValue!)}
      {...props}
    >
      <Form labelCol={{ span: 7 }} wrapperCol={{ span: 16 }}>
        <RadioGroup
          id="sectorForCreate"
          label="Select Sector(s)"
          elementProps={{
            onChange: (e: RadioChangeEvent): void =>
              setCurrentValue(e.target.value),
          }}
          buttons={buttons}
        />
      </Form>
    </Modal>
  );
};
