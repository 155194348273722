export const getEnumKeyByEnumValue = <TEnum extends Record<string, unknown>>(
  someEnum: TEnum,
  enumValue: string | number,
): keyof TEnum => {
  const [key] = Object.entries(someEnum).find(
    ([, value]: [string, unknown]) => value === enumValue,
  ) ?? [undefined];

  if (!key) {
    throw new Error(`${enumValue} is not exist in current Enum!`);
  }

  return key;
};
