import { arraySort } from '@core/utils/methods';
import { createReducer, on } from '@core/utils/store';
import { AdminProjectCategoryData, PayloadAndState } from '@models/interfaces';
import { ProjectCategoryActions } from '@store/actions';

export interface ProjectCategoryState {
  projectCategories: AdminProjectCategoryData[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: ProjectCategoryState = {
  projectCategories: [],
  isFetching: false,
  errors: false,
};

const mainSorter = (
  data: AdminProjectCategoryData[],
): AdminProjectCategoryData[] => arraySort(data, 'ASC', 'value');

export const reducer = createReducer(
  initialState,
  // GET ALL PROJECT CATEGORIES
  on(
    ProjectCategoryActions.getProjectCategoryDataAction,
    ({
      payload: projectCategories,
    }: PayloadAndState<AdminProjectCategoryData[], ProjectCategoryState>) => ({
      projectCategories,
    }),
  ),
  // ADD PROJECT CATEGORY
  on(
    ProjectCategoryActions.addProjectCategoryAction,
    ({
      payload,
      state: { projectCategories },
    }: PayloadAndState<AdminProjectCategoryData, ProjectCategoryState>) => ({
      projectCategories: mainSorter([...projectCategories, payload]),
    }),
  ),
  // EDIT PROJECT CATEGORY
  on(
    ProjectCategoryActions.updateProjectCategoryAction,
    ({
      payload,
      state: { projectCategories },
    }: PayloadAndState<AdminProjectCategoryData, ProjectCategoryState>) => ({
      projectCategories: mainSorter([
        ...projectCategories.filter(
          (projectCategory: AdminProjectCategoryData) =>
            projectCategory.id !== payload.id,
        ),
        payload,
      ]),
    }),
  ),
  // DELETE PROJECT CATEGORY
  on(
    ProjectCategoryActions.deleteProjectCategoryAction,
    ({
      payload: id,
      state: { projectCategories },
    }: PayloadAndState<string, ProjectCategoryState>) => ({
      projectCategories: projectCategories.filter(
        (projectCategory: AdminProjectCategoryData) =>
          projectCategory.id !== id,
      ),
    }),
  ),
);
