import { BACKEND_DATE_FORMAT, UserRole } from '@symfa-inc/providence-types';
import { Store } from 'antd/es/form/interface';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from 'rc-field-form/es/interface';
import { ObjectDifferencesService } from '@core/services';
import { dateWorker } from '@core/utils/date-worker';
import { momentizeObjectDates } from '@core/utils/methods';
import { ProjectPaceTask, ProjectPaceTaskData } from '@models/interfaces';
import { AccessPaceTaskFieldDepsType } from './pace-task-table.types';

const PACE_TASK_PERMISSION: AccessPaceTaskFieldDepsType = {
  forecastedDate: 'forecastedDateEditors',
  actualDate: 'actualDateEditors',
  PACEForecastedDate: 'PACEForecastedDateEditors',
  PACEActualDate: 'PACEActualDateEditors',
};

const REQ_KEYS = ['id', 'paceTask'];

export const getDate = (
  dateType: keyof AccessPaceTaskFieldDepsType,
  tableForm: FormInstance,
  index: number,
): string | null =>
  tableForm.getFieldsValue()[index].projectPaceTaskData[dateType]
    ? dateWorker(
        tableForm.getFieldsValue()[index].projectPaceTaskData[dateType],
      ).format(BACKEND_DATE_FORMAT)
    : null;

const getPermissionByRoles = (
  allowedRoles: UserRole[],
  roles: UserRole[],
): boolean =>
  roles?.some((role: UserRole): boolean => allowedRoles.includes(role));

export const paceTaskMapper = (data: ProjectPaceTask[]): Store =>
  data.map((paceTask: ProjectPaceTask) => ({
    ...paceTask,
    projectPaceTaskData: {
      forecastedDate: paceTask?.projectPaceTaskData?.forecastedDate
        ? dateWorker(paceTask?.projectPaceTaskData?.forecastedDate)
        : undefined,
      actualDate: paceTask?.projectPaceTaskData?.actualDate
        ? dateWorker(paceTask?.projectPaceTaskData?.actualDate)
        : undefined,
      PACEForecastedDate: paceTask?.projectPaceTaskData?.PACEForecastedDate
        ? dateWorker(paceTask?.projectPaceTaskData?.PACEForecastedDate)
        : undefined,
      PACEActualDate: paceTask?.projectPaceTaskData?.PACEActualDate
        ? dateWorker(paceTask?.projectPaceTaskData?.PACEActualDate)
        : undefined,
    },
  }));

export const getPaceTaskDifference = (
  data: ProjectPaceTask[],
  tableForm: FormInstance,
  roles: UserRole[],
): [ProjectPaceTaskData[], NamePath[]] => {
  const diff = ObjectDifferencesService.getArraysDiff(
    data.map((paceTask: ProjectPaceTask) => {
      const paceTaskItem: Record<string, any> = { ...paceTask };

      const { projectPaceTaskData } = paceTaskItem;

      delete projectPaceTaskData.invoiceDate;
      delete projectPaceTaskData.invoiceNumber;

      return {
        id: projectPaceTaskData.id,
        paceTask: paceTaskItem.id,
        ...momentizeObjectDates(
          projectPaceTaskData,
          [
            'PACEActualDate',
            'PACEForecastedDate',
            'actualDate',
            'forecastedDate',
          ],
          true,
        ),
      };
    }),
    data.map((paceTask: ProjectPaceTask, index: number) => ({
      id: paceTask.projectPaceTaskData.id,
      paceTask: paceTask.id,
      ...Object.keys(PACE_TASK_PERMISSION).reduce(
        (acc: Store, rawKey: string) => {
          const key = rawKey as keyof AccessPaceTaskFieldDepsType;

          return {
            ...acc,
            ...(getPermissionByRoles(
              paceTask.permissions[PACE_TASK_PERMISSION[key]],
              roles,
            )
              ? {
                  [key]: getDate(key, tableForm, index),
                }
              : {}),
          };
        },
        {} as Store,
      ),
    })),
    REQ_KEYS,
  ) as ProjectPaceTaskData[];

  const keys = diff.reduce((acc: NamePath[], value) => {
    const index = data.findIndex(
      ({ id }: ProjectPaceTask) => value.paceTask === id,
    );

    return [
      ...acc,
      ...Object.keys(value)
        .filter((key: string) => !REQ_KEYS.includes(key))
        .map((key: string): NamePath => [index, 'projectPaceTaskData', key]),
    ];
  }, []);

  return [diff, keys];
};
