import { GlobalOutlined } from '@ant-design/icons';
import { SubPage } from '@symfa-inc/providence-types';
import { RedirectGuard } from '@core/guards/redirect.guard';
import { tabPropsToTabs } from '@core/utils/methods';
import { Route, RouteGroup } from '@models/classes';
import { SITE_TABS_PROPS } from '@models/constants';
import { MenuPlacement } from '@models/enums';
import { SiteBrowseResolver } from './components/browse/site-browse.resolver';
import {
  SiteBrowse,
  SiteCreate,
  SiteDetails,
  SiteImport,
  SiteImportUpdate,
} from './components';

export const siteTabs = tabPropsToTabs(SITE_TABS_PROPS);

export const siteRoutes = new RouteGroup({
  path: '/site',
  name: 'Site',
})
  .setMenu(MenuPlacement.Main, GlobalOutlined)
  .breadcrumb()
  .addGuard(new RedirectGuard('site'), '/')
  .addRoutes([
    new Route({
      path: '/browse',
      name: 'Browse',
      component: SiteBrowse,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('site', SubPage.SiteBrowse), '/')
      .breadcrumb()
      .addResolver(new SiteBrowseResolver(), false)
      .addRoute(
        new Route({
          path: '/:FALocationNumber/details',
          name: ':siteNumber',
          component: SiteDetails,
          extendPath: false,
        })
          .breadcrumb()
          .addTabs(siteTabs),
      ),
    new Route({
      path: '/create',
      name: 'Create',
      component: SiteCreate,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('site', SubPage.SiteCreate), '/')
      .breadcrumb(),
    new Route({
      path: '/import',
      name: 'Import',
      component: SiteImport,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('site', SubPage.SiteImport), '/')
      .breadcrumb(),
    new Route({
      path: '/import-update',
      name: 'Import Update',
      component: SiteImportUpdate,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('site', SubPage.SiteImportUpdate), '/')
      .breadcrumb(),
  ]);
