import { Candidate } from '@models/interfaces';

const SITE_NUMBER_TEMPLATE = `
        <div class="item">
            <span class="label">Site Number:</span>
            <span class="value">{siteNumber}</span>
        </div>`;

const USID_TEMPLATE = `
        <div class="item">
            <span class="label">USID:</span>
            <span class="value">{USID}</span>
        </div>`;

export const getDescriptionContent = (
  candidate: Candidate,
  needTitle?: boolean,
) =>
  `
    <div>
    ${needTitle ? `<div'>${candidate?.FALocationNumber.toString()}</div>` : ''}
        ${SITE_NUMBER_TEMPLATE}
        ${USID_TEMPLATE}
    </div>
    `
    .replace(
      candidate?.siteNumber ? '{siteNumber}' : SITE_NUMBER_TEMPLATE,
      candidate?.siteNumber ?? '',
    )
    .replace(
      candidate?.USID ? '{USID}' : USID_TEMPLATE,
      candidate?.USID?.toString() ?? '',
    );
