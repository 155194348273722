import { DownloadOutlined } from '@ant-design/icons/lib';
import { SubPage } from '@symfa-inc/providence-types';
import { RedirectGuard } from '@core/guards';
import { Route, RouteGroup } from '@models/classes';
import { MenuPlacement } from '@models/enums';
import { ExportsBrowse } from './components';
import { ExportsBrowseResolver } from './resolvers';

export const exportsRoutes = new RouteGroup({
  name: 'Exports',
  path: '/exports',
})
  .setMenu(MenuPlacement.Main, DownloadOutlined)
  .addResolver(new ExportsBrowseResolver())
  .breadcrumb()
  .addGuard(new RedirectGuard('exports'), '/')
  .addRoutes([
    new Route({
      name: 'PACE Update Export',
      path: '/pace-update-export',
      component: ExportsBrowse,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('exports', SubPage.ExportsBrowse), '/')
      .breadcrumb(),
    new Route({
      name: 'PM Update Export',
      path: '/pm-update-export',
      component: ExportsBrowse,
    })
      .setMenu(MenuPlacement.Main)
      .addGuard(new RedirectGuard('exports', SubPage.ExportsPMUpdate), '/')
      .breadcrumb(),
  ]);
