import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { DashboardHttpService } from '@core/services/http';
import store from '@store/index';
import { UserSelectors } from '@store/selectors';
import { isNotOnlyReadOnlyUser } from '../helpers';

export class HomeResolver implements Resolver {
  async resolve(): Promise<void> {
    const roles = UserSelectors.getUserRoles(store.getState());

    if (isNotOnlyReadOnlyUser(roles)) {
      const DashboardService =
        HttpService.getHttpRequests(DashboardHttpService);

      await Promise.all([
        DashboardService.getAdminList(),
        DashboardService.getUserList(),
      ]);
    }
  }
}
