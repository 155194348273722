import { ApiMethods } from '@models/enums';
import { ConnectionConfig } from '@models/interfaces';
import { FileType, VoidString } from '@models/types';
import { Config } from '../../config/config';
import { HttpClient } from '../../utils/http-client';
import { AuthInterceptorService } from '../interceptors';

export class CommonHttpService {
  private authInterceptor: AuthInterceptorService;
  private config: ConnectionConfig = Config.get().connectionConfig;

  protected http: HttpClient = new HttpClient();

  constructor() {
    this.authInterceptor = AuthInterceptorService.getInstance();
  }

  protected get apiUrl(): string {
    return this.config.apiUrl;
  }

  protected uploadFile<P = VoidString>(
    method: ApiMethods,
    url: string,
    file: FileType,
  ): Promise<P> {
    const formData = new FormData();

    formData.append('file', file);

    return this.http.callApi[method](url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  protected uploadMultipleFiles<TResponse>(
    method: ApiMethods.Post | ApiMethods.Patch,
    url: string,
    params: Record<string, unknown> | null,
    files: File[],
    filesKey: string,
  ): Promise<TResponse> {
    const formData = new FormData();

    files.forEach((file: File) => formData.append(filesKey, file));

    return this.http.callApi[method](url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...(params ? { params } : {}),
    });
  }

  protected async downloadFile(
    method: ApiMethods,
    url: string,
    options: any = {},
  ): Promise<void> {
    const resp = await this.http.callApi[method](url, null, {
      responseType: 'blob',
      needHeaders: true,
      ...options,
    });

    const [, fileName] = resp.headers['content-disposition'].match(
      /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
    );

    const link = document.createElement('a');

    link.classList.add('invisible');
    link.href = window.URL.createObjectURL(resp.data);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  protected async previewFile<T>(
    method: ApiMethods,
    url: string,
    body: T | null = null,
    options: any = {},
  ): Promise<string> {
    const resp = await this.http.callApi[method](url, body, {
      responseType: 'blob',
      needHeaders: true,
      ...options,
    });

    return window.URL.createObjectURL(resp.data);
  }
}
