import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { FinancialHttpService } from '@core/services/http';
import { dateWorker } from '@core/utils/date-worker';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { isValidForm, momentizeObjectDates } from '@core/utils/methods';
import { PACE_ACTUAL_DATE } from '@core/utils/validators';
import {
  ALLOW_OOM_INFORMATION_PACE_TASK_OPTIONS,
  DOLLAR_PREFIX,
  INPUT_POSITIVE_NUMBER_VALIDATOR,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  AddAndEditModalProps,
  FinancialOOMInformation,
  OptionProps,
  ProjectPaceTask,
} from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { DatePicker, InputNumber, Select } from '@shared/modules';
import { ProjectActions } from '@store/actions';
import { FinancialSelectors, PaceTasksSelectors } from '@store/selectors';

import './styles.scss';

const { useForm } = Form;

export const AddAndEditOOM: FC<
  AddAndEditModalProps<FinancialOOMInformation>
> = ({
  className,
  visible,
  formValue,
  toggleModal,
  modalType,
  entity,
  projectId,
  permissions,
  ...props
}: PropsWithChildren<AddAndEditModalProps<FinancialOOMInformation>>) => {
  const dispatch = useDispatch();

  const [form] = useForm();

  const financialService = useMemo(
    () => HttpService.getHttpRequests(FinancialHttpService),
    [],
  );

  const disabled = useSelector(FinancialSelectors.isFetching);
  const PACETasks = useSelector(PaceTasksSelectors.getPaceTasks);
  const PACETasksFields = useSelector(PaceTasksSelectors.getPaceTaskFields);
  const existsPACETasksInOOMInformation = useSelector(
    FinancialSelectors.getOMMInformationPACETaskIds,
  );

  const [selectedPACETask, setSelectedPACETask] = useState<string | null>(null);
  const [allowPACETaskOptions, setAllowPACETaskOptions] = useState<
    OptionProps[]
  >([]);

  const updatePACETask = ({
    PACETaskIdentification,
    actualDate,
  }: FinancialOOMInformation): void => {
    const exists = PACETasks.find(
      ({ id }: ProjectPaceTask) => id === PACETaskIdentification,
    );

    if (exists) {
      dispatch(
        ProjectActions.updateProjectPaceTaskFromOOMTableAction.done({
          id: exists.id,
          actualDate,
        }),
      );
    }
  };

  const add = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const formValues = form.getFieldsValue();

      await financialService.addFinancialOOM(
        projectId!,
        momentizeObjectDates(
          formValues as FinancialOOMInformation,
          ['OOMDate', 'actualDate'],
          true,
        ),
        entity,
      );

      updatePACETask(formValues as FinancialOOMInformation);

      toggleModal(modalType);
    }
  };

  const edit = async (): Promise<void> => {
    if (await isValidForm(form)) {
      const { OOMAmount, OOMTotalAmount, ...formValues } =
        form.getFieldsValue();

      await financialService.updateFinancialOOM(
        projectId!,
        {
          id: formValue.id,
          OOMAmount: +OOMAmount,
          OOMTotalAmount: +OOMTotalAmount,
          ...momentizeObjectDates(formValues, ['OOMDate', 'actualDate'], true),
        } as FinancialOOMInformation,
        entity,
      );

      updatePACETask(formValues as FinancialOOMInformation);

      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(
        momentizeObjectDates(formValue, ['OOMDate', 'actualDate']),
      );

      const allowPACETasks = ALLOW_OOM_INFORMATION_PACE_TASK_OPTIONS.filter(
        ({ value }: OptionProps) =>
          !existsPACETasksInOOMInformation.includes(value as string),
      );

      if (modalType === ModalMainTypes.Edit) {
        const currentOption = ALLOW_OOM_INFORMATION_PACE_TASK_OPTIONS.find(
          ({ value }: OptionProps) =>
            value === formValue.PACETaskIdentification,
        );

        if (currentOption) {
          allowPACETasks.push(currentOption);
        }
      }

      setAllowPACETaskOptions(allowPACETasks);
    }

    if (!visible) {
      setSelectedPACETask(null);
      setAllowPACETaskOptions([]);
    }
  }, [visible, formValue, form]);

  useDidUpdateEffect(() => {
    if (visible) {
      const arrayToFind = PACETasks.length > 0 ? PACETasks : PACETasksFields;

      const currentPACETask = arrayToFind.find(
        (v: ProjectPaceTask) => v.id === selectedPACETask,
      );

      const actualDate =
        currentPACETask &&
        currentPACETask.projectPaceTaskData &&
        currentPACETask.projectPaceTaskData.actualDate
          ? dateWorker(currentPACETask.projectPaceTaskData.actualDate)
          : null;

      form.setFieldsValue({ actualDate });
    }
  }, [selectedPACETask]);

  return (
    <AddOrEditModal
      title={modalType === ModalMainTypes.Add ? 'Add OOM' : 'Edit OOM'}
      type={modalType}
      visible={visible}
      className={classNames(className, 'financial-oom-modal')}
      onOk={modalType === ModalMainTypes.Add ? add : edit}
      okText={modalType === ModalMainTypes.Add ? 'Add' : 'Save'}
      onCancel={(): void => {
        toggleModal(modalType);
      }}
      formProps={{
        labelCol: { span: 8 },
        form,
      }}
      okButtonProps={{ disabled }}
      maskClosable={!disabled}
      {...props}
    >
      <div>
        <Select
          id="PACETaskIdentification"
          label="PACE Task"
          options={allowPACETaskOptions}
          formItemProps={{
            rules: [{ required: true, message: 'PACE Task is required!' }],
          }}
          elementProps={{
            onChange: (v: SelectValue): void =>
              setSelectedPACETask(v as string),
            disabled: modalType === ModalMainTypes.Edit,
          }}
        />
        <DatePicker
          id="actualDate"
          label="Actual Date"
          formItemProps={{
            rules: PACE_ACTUAL_DATE(formValue?.actualDate),
          }}
        />
        <InputNumber
          id="OOMAmount"
          label="OOM Amount"
          formItemProps={{
            rules: [
              { required: true, message: 'OOM Amount is required!' },
              INPUT_POSITIVE_NUMBER_VALIDATOR,
            ],
          }}
          elementProps={{ formatter: DOLLAR_PREFIX }}
        />
        <DatePicker
          id="OOMDate"
          label="OOM Date"
          formItemProps={{
            rules: [{ required: true, message: 'OOM Date is required!' }],
          }}
        />
        <InputNumber
          id="OOMTotalAmount"
          label="OOM Total Amount"
          formItemProps={{
            rules: [
              { required: true, message: 'OOM Total Amount is required!' },
              INPUT_POSITIVE_NUMBER_VALIDATOR,
            ],
          }}
          elementProps={{ formatter: DOLLAR_PREFIX }}
        />
      </div>
    </AddOrEditModal>
  );
};
