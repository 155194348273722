import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { PaceTaskScreen, SubPage, Tab } from '@symfa-inc/providence-types';
import { Form } from 'antd';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  canUserEditByProjectStatusAndRoles,
  getProjectBundleIDParamFromUrl,
  isValidForm,
} from '@core/utils/methods';
import { EditableComponentProps } from '@models/interfaces';
import {
  EditablePage,
  getPaceTaskDifference,
  NotificationsLoader,
  paceTaskMapper,
  PaceTaskTable,
} from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { CommonActions, ProjectActions } from '@store/actions';
import {
  PaceTasksSelectors,
  PermittingSelectors,
  ProjectCategoriesSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import {
  LeasingInformation,
  ProjectInformation,
  SiteInformation,
} from './components';

import './styles.scss';

const { useForm } = Form;

const Summary: FC<EditableComponentProps> = ({
  isEditing,
  toggleEditing,
}: PropsWithChildren<EditableComponentProps>) => {
  const dispatch = useDispatch();

  const [projectBundleID] = getProjectBundleIDParamFromUrl();

  const [paceTaskForm] = useForm();

  const {
    site: siteData,
    leasing: leasingData,
    project: projectData,
    scoping: scopingData,
  } = useSelector(
    PermittingSelectors.getPermittingDetailsData([
      'site',
      'leasing',
      'project',
      'scoping',
    ]),
  );
  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const arePaceTasksChanged = useSelector(
    PaceTasksSelectors.arePaceTasksChanged,
  );
  const currentPaceTasks = useSelector(PaceTasksSelectors.getPaceTasks);
  const roles = useSelector(UserSelectors.getUserRoles);

  const [duringReqDisabled, setDuringReqDisabled] = useState<boolean>(false);

  const buttonsDisabled = duringReqDisabled || !arePaceTasksChanged;

  const onSubmit = async (): Promise<void> => {
    const [paceTasksData, keys] = getPaceTaskDifference(
      currentPaceTasks,
      paceTaskForm,
      roles,
    );

    if (await isValidForm(paceTaskForm, keys)) {
      try {
        setDuringReqDisabled(true);

        await HttpService.getHttpRequests(
          ProjectHttpService,
        ).updateProjectPaceTasks(
          projectData!.id,
          PaceTaskScreen.Permitting,
          paceTasksData,
        );

        toggleEditing?.();

        dispatch(
          ProjectActions.updateProjectPaceTaskFieldsFromSummaryPageTable.done(
            paceTasksData,
          ),
        );

        dispatch(ProjectActions.setArePACETasksChanged.done(false));

        NotificationsLoader.notificationSuccess(
          'PACE tasks have been updated!',
        );
      } catch (e) {
        console.error(e);
      }

      setDuringReqDisabled(false);
    }
  };

  const onCancel = (): void => {
    paceTaskForm.setFieldsValue(paceTaskMapper(currentPaceTasks));

    dispatch(ProjectActions.setArePACETasksChanged.done(false));

    toggleEditing?.();
  };

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(arePaceTasksChanged));
  }, [arePaceTasksChanged]);

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <main className="prov-permitting-summary">
      <section
        className={classNames('prov-permitting-summary__content', 'tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <div className="left">
          <SiteInformation siteData={siteData!} />
          <ProjectInformation
            projectData={projectData!}
            scopingData={scopingData!}
          />
          <LeasingInformation
            leasingData={leasingData!}
            projectBundleID={projectBundleID}
          />
        </div>
        <PaceTaskTable
          className="right"
          hoverable={false}
          isEditing={isEditing}
          data={currentPaceTasks || []}
          form={paceTaskForm}
        />
      </section>
      {isEditing && (
        <section className="prov-permitting-summary__actions">
          <PrimaryButton
            title="Submit"
            disabled={buttonsDisabled}
            onClick={onSubmit}
          />
          <PrimaryButton
            title="Cancel"
            disabled={buttonsDisabled}
            type="default"
            onClick={onCancel}
          />
        </section>
      )}
    </main>
  );
};

export const PermittingSummary: FC<RouterProps> = () => {
  const projectType = useSelector(PermittingSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit('permitting', SubPage.PermittingBrowse, Tab.Summary),
  );
  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <EditablePage
      editableComponent={Summary}
      canUserEdit={
        canEdit &&
        projectTypePermission &&
        canUserEditByProjectStatusAndRoles(projectStatusId, userRoles)
      }
    />
  );
};
