import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { VendorsState } from '../../reducers/admin/vendors.reducer';

const vendorsState = (state: AppState): VendorsState => state.admin.vendors;

export namespace VendorsSelectors {
  export const getVendorsMainData = createSelector(
    [vendorsState],
    ({ vendors, vendorsLists, vendorsListsOptions }: VendorsState) => ({
      vendors,
      vendorsLists,
      vendorsListsOptions,
    }),
  );

  export const getVendorsLists = createSelector(
    [vendorsState],
    (state: VendorsState) => state.vendorsLists,
  );
}
