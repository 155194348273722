import React, { FC, memo, PropsWithChildren } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { PrimaryButtonProps } from './models/interfaces';

import './styles.scss';

export const PrimaryButton: FC<PrimaryButtonProps> = memo(
  ({
    icon,
    title,
    className,
    type = 'primary',
    htmlType = 'button',
    ...props
  }: PropsWithChildren<PrimaryButtonProps>) => (
    <div className={classNames(className, 'prov-primary-button')}>
      <Button
        className={classNames(
          'prov-primary-button__wrap',
          `primary-btn_${type}`,
        )}
        htmlType={htmlType}
        {...props}
      >
        {icon && <div className="prov-primary-button__icon">{icon}</div>}
        <span>{title}</span>
      </Button>
    </div>
  ),
);

PrimaryButton.displayName = 'PrimaryButton';
