import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { EquipmentState } from '../reducers/equipment.reducer';

const equipmentState = (state: AppState): EquipmentState => state.equipment;

export namespace EquipmentSelectors {
  export const getEquipmentBrowseData = createSelector(
    [equipmentState],
    (state: EquipmentState) => state.equipmentsBrowse,
  );

  export const getEquipmentTypes = createSelector(
    [equipmentState],
    (state: EquipmentState) => state.equipmentTypes,
  );

  export const getEquipmentTypesWithCurrentEquipments = createSelector(
    [equipmentState],
    (state: EquipmentState) => ({
      equipmentTypes: state.equipmentTypes,
      currentEquipments: state.currentEquipments,
    }),
  );

  export const getEquipmentTypesWithEquipments = createSelector(
    [equipmentState],
    (state: EquipmentState) => ({
      equipmentTypes: state.equipmentTypes,
      equipments: state.equipments,
    }),
  );

  export const getEquipmentTypesWithManufacturers = createSelector(
    [equipmentState],
    (state: EquipmentState) => ({
      equipmentTypes: state.equipmentTypes,
      equipmentManufacturers: state.equipmentManufacturers,
    }),
  );

  export const getSectorsEquipmentsByTypes = createSelector(
    [equipmentState],
    ({ sectorsEquipmentsByTypes }: EquipmentState) => sectorsEquipmentsByTypes,
  );
}
