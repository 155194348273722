import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { SOFToolHttpService } from '@core/services/http';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';
import { SOFToolBrowseFilter } from '@models/interfaces';

export class SofToolResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(SOFToolHttpService).getSOFTool(
      BROWSE_DEFAULT_FILTER_VALUE as SOFToolBrowseFilter,
    );

    await HttpService.getHttpRequests(
      SOFToolHttpService,
    ).getSOFToolBrowseFiltersOptions();
  }
}
