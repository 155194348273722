import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { showIfAuthorized } from '@core/utils/HOC';
import { CommonSelectors } from '@store/selectors';

const HasUnsubmittedDataComponent: FC = () => {
  const hasUnsubmittedData = useSelector(CommonSelectors.getHasUnsubmittedData);

  return (
    <>
      <div id="custom-prompt" />
      <Prompt when={hasUnsubmittedData} message="" />
    </>
  );
};

export const HasUnsubmittedData: FC = showIfAuthorized(
  HasUnsubmittedDataComponent,
);
