import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { AdminDriverManagementData, Driver } from '@models/interfaces';
import { ValidationParams } from '@models/types';
import { DriverManagementActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class DriverManagementHttpService extends CommonHttpService {
  @StoredRequest(DriverManagementActions.getDriverManagementDataAction)
  getDriverManagementData(
    search: string | null,
  ): Promise<AdminDriverManagementData[]> {
    return this.http.callApi.get(`${this.apiUrl}/admin/drivers`, null, {
      params: { search },
    });
  }

  @StoredRequest(DriverManagementActions.addDriverAction)
  async addDriver(
    body: AdminDriverManagementData,
  ): Promise<AdminDriverManagementData> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/admin/drivers`,
      body,
    );

    return {
      id,
      ...body,
    };
  }

  @StoredRequest(DriverManagementActions.updateDriverAction)
  async editDriver({
    id,
    ...body
  }: AdminDriverManagementData): Promise<AdminDriverManagementData> {
    await this.http.callApi.patch(`${this.apiUrl}/admin/drivers`, body, {
      params: { driverId: id },
    });

    return {
      id,
      ...body,
    };
  }

  @StoredRequest(DriverManagementActions.deleteDriverAction)
  async deleteDriver(driverId: string): Promise<string> {
    await this.http.callApi.delete(`${this.apiUrl}/admin/drivers`, null, {
      params: { driverId },
    });

    return driverId;
  }

  getDriverValidation(
    { id, field }: ValidationParams,
    value: string,
  ): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/drivers/validation`,
      null,
      {
        params: { id, field, value },
      },
    );
  }

  @StoredRequest(DriverManagementActions.getDriversListByWorkflowAction)
  getDriversListByWorkflow(workflow?: string): Promise<Driver[]> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/drivers/drivers-list-by-workflow`,
      null,
      {
        params: { workflow },
      },
    );
  }
}
