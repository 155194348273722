import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SubPage, Tab } from '@symfa-inc/providence-types';
import { SiteAuditComponent } from '@client/pages/engineering/components';
import { canUserEditByProjectStatusAndRoles } from '@core/utils/methods';
import {
  ProjectSelectors,
  SiteAuditSelectors,
  UserSelectors,
} from '@store/selectors';

import './styles.scss';

export const SiteAuditTab: FC = () => {
  const permissions = useSelector(
    UserSelectors.getEngineeringSiteAuditPermissions,
  );
  const { projectType } = useSelector(
    SiteAuditSelectors.getProjectInfo('projectType'),
  );
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType as string),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit(
      'engineering',
      SubPage.EngineeringScoping,
      Tab.SiteAudit,
    ),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <div className="prov-site-audit-tab">
      <SiteAuditComponent
        defaultEditing={false}
        permissions={permissions}
        canUserEdit={
          canEdit &&
          projectTypePermission &&
          canUserEditByProjectStatusAndRoles(projectStatusId, userRoles)
        }
      />
    </div>
  );
};
