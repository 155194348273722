import { Candidate, PayloadAndState } from '@models/interfaces';
import { CandidatesActions } from '@store/actions';
import { createReducer, on } from '../../core/utils/store';

export interface CandidatesState {
  candidates: Candidate[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: CandidatesState = {
  candidates: [],
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  on(
    CandidatesActions.getCandidatesDataAction,
    ({
      payload: candidates,
    }: PayloadAndState<Candidate[], CandidatesState>) => ({
      candidates,
    }),
  ),
);
