import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { YES_NO_GROUP } from '@models/constants';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { DatePicker, Input, PrimaryButton, RadioGroup } from '@shared/modules';

const { useForm } = Form;

export const LeasingInformation: FC<InformationProps> = ({
  className,
  data,
  expectedId,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  useEffect(() => {
    if (data) {
      const { structural } = data;

      form.setFieldsValue({
        ...structural,
        structuralResult: structural?.structuralResult?.value,
      });
    }
  }, [data]);

  return (
    <Panel header="Leasing Information" className={className}>
      <Form labelCol={{ span: 14 }} wrapperCol={{ span: 10 }} form={form}>
        <Row>
          <Col span="12">
            <RadioGroup
              id="isStructuralRequired"
              label="Structural Required"
              isEditing={false}
              buttons={YES_NO_GROUP}
            />
            <DatePicker
              id="structuralOrdered"
              label="Structural Ordered"
              isEditing={false}
            />
            <Input
              id="structuralResult"
              label="Structural Result"
              isEditing={false}
            />
          </Col>
          <Col span="12">
            <DatePicker
              id="passingStructuralReceived"
              label="Passing Structural Received"
              isEditing={false}
            />
            <Input
              id="structuralComment"
              label="Structural Comments"
              isEditing={false}
            />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View Details"
            target="_blank"
            href={`/leasing/${expectedId}/details`}
          />
          <PrimaryButton
            title="View Equipment"
            target="_blank"
            href={`/leasing/${expectedId}/details/equipment`}
          />
        </Row>
      </Form>
    </Panel>
  );
};
