import {
  DateStringOrNull,
  IScopingTowerNote,
  MAIN_PACE_TASK_IDs,
} from '@symfa-inc/providence-types';
import { transformFields } from '@core/utils/methods';
import { browseReducer } from '@core/utils/methods/browse-sorter-reducer';
import { createReducer, on } from '@core/utils/store';
import {
  SCOPING_INFO_TRANSFORM_FIELDS,
  SCOPING_PROJECT_INFO_TRANSFORM_FIELDS,
  SCOPING_SECTORS_VERSIONS,
  SCOPING_SECTORS_VERSIONS_DATA,
} from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import {
  EngineeringWorkflowUpdateData,
  EquipmentModal,
  IdAndValue,
  LeasingInformation,
  OtherEquipmentData,
  PaginatedResponse,
  PayloadAndState,
  ProjectDetails,
  ProjectPaceTask,
  ScopingBrowse,
  ScopingDetails,
  ScopingInfo,
  ScopingSummaryData,
  SectorEquipment,
  SiteAuditInformation,
  SiteResponse,
  UpdateScopingEquipmentData,
} from '@models/interfaces';
import {
  LeasingEquipmentData,
  ScopingSectorsVersion,
  ScopingSectorsVersionData,
} from '@models/types';
import { ScopingActions } from '@store/actions';
import {
  equipmentDuplicatesCombiner,
  getEquipmentTotalsFromScoping,
  getOtherEquipmentInfo,
  getProcessedEquipments,
  getScopingSectorsFromUpdateData,
  getScopingSectorsInfo,
  sortEquipments,
} from '../helpers';

type ScopingProjectInfo = Omit<
  ProjectDetails,
  'id' | 'site' | 'paceTasks' | 'categories'
>;

// TODO: move to selectors
export interface EngineeringScopingState {
  scopingBrowseData: PaginatedResponse<ScopingBrowse>;
  currentProjectId: string;
  currentModalType: ModalMainTypes;
  scopingSiteInfo: SiteResponse;
  projectType: IdAndValue;
  scopingProjectInfo: ScopingProjectInfo;
  scopingLeasingInfo: LeasingInformation;
  scopingInfo: Omit<ScopingInfo, 'otherEquipments' | 'scopingSectorsVersions'>;
  scopingSiteAuditData: SiteAuditInformation;
  scopingEngineeringData: EngineeringWorkflowUpdateData;
  scheduledScopingDate?: DateStringOrNull;
  mountMappingOrdered?: DateStringOrNull;
  mountMappingReceived?: DateStringOrNull;
  mountAnalysisOrdered?: DateStringOrNull;
  failingMountAnalysisReceived?: DateStringOrNull;
  passingMountAnalysisReceived?: DateStringOrNull;
  prelimCDsOrdered?: DateStringOrNull;
  prelimCDsReceived?: DateStringOrNull;
  finalCDsReceived?: DateStringOrNull;
  otherEquipments: OtherEquipmentData[];
  scopingSectorsVersions: ScopingSectorsVersionData[];
  isFetching: boolean;
  errors: boolean;
  equipmentTypeNames: string[];
  leasingEquipmentData: LeasingEquipmentData;
}

const initialState: EngineeringScopingState = {
  scopingBrowseData: { items: [], total: 0 },
  currentProjectId: '',
  currentModalType: ModalMainTypes.Add,
  scopingSiteInfo: {} as SiteResponse,
  scopingProjectInfo: {} as ScopingProjectInfo,
  projectType: {} as IdAndValue,
  scopingLeasingInfo: {} as LeasingInformation,
  scopingInfo: {} as Omit<
    ScopingInfo,
    'otherEquipments' | 'scopingSectorsVersions'
  >,
  scopingSiteAuditData: {} as SiteAuditInformation,
  scopingEngineeringData: {
    id: '',
    regulatoryCompliance: {},
    RFDSPhase1: {},
  } as EngineeringWorkflowUpdateData,
  scheduledScopingDate: undefined,
  mountMappingOrdered: undefined,
  mountMappingReceived: undefined,
  mountAnalysisOrdered: undefined,
  failingMountAnalysisReceived: undefined,
  passingMountAnalysisReceived: undefined,
  prelimCDsOrdered: undefined,
  prelimCDsReceived: undefined,
  finalCDsReceived: undefined,
  otherEquipments: [],
  scopingSectorsVersions: SCOPING_SECTORS_VERSIONS_DATA,
  isFetching: false,
  errors: false,
  equipmentTypeNames: [],
  leasingEquipmentData: {
    priorTableData: [],
    curPriorTableData: [],
    scopingTableData: [],
    finalTableData: [],
    curFinalTableData: [],
    scopingNotes: null,
    priorLeasingNote: null,
    finalLeasingNote: null,
  },
};

const equipmentTypeNames = new Set<string>();

export const reducer = createReducer(
  initialState,
  // GET SCOPING BROWSE DATA
  on(
    ScopingActions.getScopingBrowseDataAction,
    browseReducer('scopingBrowseData'),
  ),
  // UPDATE CURRENT MODAL TYPE
  on(
    ScopingActions.updateCurrentModalTypeAction,
    ({
      payload: currentModalType,
    }: PayloadAndState<ModalMainTypes, EngineeringScopingState>) => ({
      currentModalType,
    }),
  ),
  // UPDATE SCOPING BROWSE DATA
  on(
    ScopingActions.updateScopingBrowseDataAction,
    ({
      payload: scopingBrowseData,
    }: PayloadAndState<
      PaginatedResponse<ScopingBrowse>,
      EngineeringScopingState
    >) => ({
      scopingBrowseData,
    }),
  ),
  // GET SCOPING DETAILS
  on(
    ScopingActions.getScopingDetailsAction,
    ({
      payload: {
        site,
        id,
        leasing: scopingLeasingInfo,
        siteAudit: scopingSiteAuditData,
        paceTasks,
        scoping: scopingPayload,
        engineering: engineeringPayload,
        ...projectData
      },
    }: PayloadAndState<
      Omit<ScopingDetails, 'categories' | 'RAD'>,
      EngineeringScopingState
    >) => {
      const engineeringData = engineeringPayload ?? {};
      const { RFDSPhase1, ...restEngineeringData } = engineeringData;

      return {
        scopingSiteInfo: site,
        scopingProjectInfo: transformFields<ScopingProjectInfo>(
          projectData,
          SCOPING_PROJECT_INFO_TRANSFORM_FIELDS,
        ),
        projectType: projectData.projectType,
        scheduledScopingDate: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MAIN_PACE_TASK_IDs.scopingComplete,
        )?.projectPaceTaskData?.forecastedDate,
        mountMappingOrdered: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MAIN_PACE_TASK_IDs.mountMappingOrdered,
        )?.projectPaceTaskData?.actualDate,
        mountMappingReceived: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MAIN_PACE_TASK_IDs.mountMappingReceived,
        )?.projectPaceTaskData?.actualDate,
        mountAnalysisOrdered: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MAIN_PACE_TASK_IDs.mountAnalysisOrdered,
        )?.projectPaceTaskData?.actualDate,
        failingMountAnalysisReceived: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MAIN_PACE_TASK_IDs.failingMountAnalysisReceived,
        )?.projectPaceTaskData?.actualDate,
        passingMountAnalysisReceived: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MAIN_PACE_TASK_IDs.passingMountAnalysisReceived,
        )?.projectPaceTaskData?.actualDate,
        prelimCDsOrdered: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MAIN_PACE_TASK_IDs.prelimCDsOrdered,
        )?.projectPaceTaskData?.actualDate,
        prelimCDsReceived: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MAIN_PACE_TASK_IDs.prelimCDsReceived,
        )?.projectPaceTaskData?.actualDate,
        finalCDsReceived: paceTasks.find(
          (paceTask: ProjectPaceTask) =>
            paceTask?.id === MAIN_PACE_TASK_IDs.finalCDsReceived,
        )?.projectPaceTaskData?.actualDate,
        scopingEngineeringData: {
          ...restEngineeringData,
          RFDSPhase1: transformFields(RFDSPhase1, ['RFDSStatus']),
        },
        scopingSiteAuditData,
        scopingLeasingInfo,
        currentProjectId: id,
      };
    },
  ),
  on(
    ScopingActions.getScopingEquipmentAction,
    ({
      payload: { leasing: scopingLeasingInfo, scoping: scopingPayload },
    }: PayloadAndState<
      Omit<ScopingDetails, 'categories' | 'RAD'>,
      EngineeringScopingState
    >) => {
      const leasing = scopingLeasingInfo ?? {};
      const scopingData = scopingPayload ?? {};
      const { otherEquipments, scopingSectorsVersions, ...restScopingData } =
        scopingData;

      const otherEquipmentInfo = getOtherEquipmentInfo(otherEquipments ?? []);

      const leasingEquipmentData = {
        priorTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasing?.leasingEquipment,
          'PriorEquipment',
        ),
        curPriorTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasing?.leasingEquipment,
          'PriorEquipment',
        ),
        finalTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasing?.leasingEquipmentFinal,
          'FinalEquipment',
        ),
        curFinalTableData: getProcessedEquipments(
          equipmentTypeNames,
          leasing?.leasingEquipmentFinal,
          'FinalEquipment',
        ),
        scopingTableData: sortEquipments(
          getEquipmentTotalsFromScoping(
            equipmentTypeNames,
            scopingData,
            'ScopingEquipment',
          ),
        ),
        scopingNotes: scopingData?.scopingTowerNote,
        priorLeasingNote: leasing?.priorLeasingNote,
        finalLeasingNote: leasing?.finalLeasingNote,
      };

      // if coming empty array from DB
      const scopingSectorsInfo = scopingSectorsVersions?.length
        ? getScopingSectorsInfo(scopingSectorsVersions)
        : {
            versions: SCOPING_SECTORS_VERSIONS,
            equipmentTypeNames: [],
          };

      return {
        scopingInfo: transformFields<
          Omit<ScopingInfo, 'otherEquipments' | 'scopingSectorsVersions'>
        >(restScopingData, SCOPING_INFO_TRANSFORM_FIELDS),
        otherEquipments: otherEquipmentInfo?.otherEquipments || [],
        equipmentTypeNames: [
          ...(otherEquipmentInfo?.equipmentTypeNames || []),
          ...(scopingSectorsInfo?.equipmentTypeNames || []),
          ...equipmentTypeNames,
        ],
        scopingSectorsVersions: scopingSectorsInfo?.versions?.map(
          (
            scopingSectorsVersion: ScopingSectorsVersion,
          ): ScopingSectorsVersionData => ({
            ...scopingSectorsVersion,
            isSelected: scopingSectorsVersion.isCurrent,
          }),
        ),
        leasingEquipmentData,
        scopingNotes: restScopingData?.scopingTowerNote,
        priorLeasingNote: leasing?.priorLeasingNote,
        finalLeasingNote: leasing?.finalLeasingNote,
      };
    },
  ),
  // GET SCOPING ASSOCIATED PROJECTS
  on(
    ScopingActions.getScopingAssociatedProjectsAction,
    ({
      payload: { site },
    }: PayloadAndState<
      Omit<ScopingDetails, 'categories' | 'RAD'>,
      EngineeringScopingState
    >) => ({
      scopingSiteInfo: site,
    }),
  ),
  // UPDATE SCOPING SUMMARY
  on(
    ScopingActions.updateScopingSummaryAction,
    ({
      payload: {
        projectInformation: projectInfoPayload,
        preScopingAssessment: preScopingPayload,
      },
      state,
    }: PayloadAndState<ScopingSummaryData, EngineeringScopingState>) => {
      const projectData = projectInfoPayload ?? {};
      const scopingData = preScopingPayload ?? {};
      const { AEVendor, AEMountVendor, ...restProjectData } = projectData;
      const {
        scheduledScopingDate,
        mountMappingOrdered,
        mountMappingReceived,
        mountAnalysisOrdered,
        failingMountAnalysisReceived,
        passingMountAnalysisReceived,
        prelimCDsOrdered,
        prelimCDsReceived,
        finalCDsReceived,
        ...restScopingData
      } = scopingData;

      return {
        scopingProjectInfo: {
          ...state.scopingProjectInfo,
          ...restProjectData,
        },
        scopingInfo: {
          ...state.scopingInfo,
          ...restScopingData,
          AEVendor,
          AEMountVendor,
        },
        scheduledScopingDate,
        mountMappingOrdered,
        mountMappingReceived,
        mountAnalysisOrdered,
        failingMountAnalysisReceived,
        passingMountAnalysisReceived,
        prelimCDsOrdered,
        prelimCDsReceived,
        finalCDsReceived,
      };
    },
  ),
  // UPDATE SCOPING EQUIPMENT
  on(
    ScopingActions.updateScopingEquipmentAction,
    ({
      payload: {
        scopingNotes: scopingNotesPayload,
        scopingInformation: scopingInfoPayload,
        otherEquipments,
        versions,
      },
      state,
    }: PayloadAndState<
      UpdateScopingEquipmentData,
      EngineeringScopingState
    >) => {
      const scopingInfoData = scopingInfoPayload ?? {};
      const scopingNotesInfo = scopingNotesPayload ?? {};
      const {
        prelimRFDSReceivedDate,
        isMicrowaveToBeRemoved,
        isCraneRequired,
        craneHeight,
        mountModelReplacements,
        ...restInfo
      } = scopingInfoData;
      const { RFDSStatus, ...scopingNotes } = scopingNotesInfo;

      const sectorsEquipments = versions
        .find(version => version.isCurrent)!
        .sectorsWithManufacturers.flatMap(sector =>
          sector.sectorEquipmentTypes.flatMap(sectorEquipmentType =>
            sectorEquipmentType.equipments
              .filter(
                (equipmentItem: SectorEquipment) => !!equipmentItem.equipment,
              )
              .map((equipmentItem: SectorEquipment) => {
                const { position, ...equipment } = equipmentItem;

                return {
                  ...equipment,
                  equipmentType: sectorEquipmentType.equipmentType,
                } as EquipmentModal;
              }),
          ),
        );

      return {
        scopingInfo: {
          ...state.scopingInfo,
          ...restInfo,
          ...scopingNotes,
          ...(isMicrowaveToBeRemoved !== undefined
            ? { isMicrowaveToBeRemoved }
            : {}),
          ...(mountModelReplacements?.length ? { mountModelReplacements } : {}),
        },
        scopingEngineeringData: {
          RFDSPhase1: {
            ...state.scopingEngineeringData.RFDSPhase1,
            ...(prelimRFDSReceivedDate !== undefined
              ? { prelimRFDSReceivedDate }
              : {}),
            ...(RFDSStatus ? { RFDSStatus } : {}),
          },
          regulatoryCompliance: {
            ...state.scopingEngineeringData.regulatoryCompliance,
            ...(isCraneRequired !== undefined ? { isCraneRequired } : {}),
            ...(craneHeight ? { craneHeight } : {}),
          },
        },
        leasingEquipmentData: {
          ...state.leasingEquipmentData,
          scopingTableData: equipmentDuplicatesCombiner([
            ...sectorsEquipments,
            ...(otherEquipments as EquipmentModal[]),
          ]),
        },
        otherEquipments,
        scopingSectorsVersions: getScopingSectorsFromUpdateData(versions).map(
          (
            scopingSectorsVersion: ScopingSectorsVersion,
          ): ScopingSectorsVersionData => ({
            ...scopingSectorsVersion,
            isSelected: scopingSectorsVersion.isCurrent,
          }),
        ),
      };
    },
  ),
  // UPDATE SCOPING TOWER NOTE
  on(
    ScopingActions.updateScopingTowerNoteAction,
    ({
      payload: scopingTowerNote,
      state,
    }: PayloadAndState<IScopingTowerNote, EngineeringScopingState>) => {
      return {
        ...state,
        scopingInfo: { ...state.scopingInfo, scopingTowerNote },
        leasingEquipmentData: {
          ...state.leasingEquipmentData,
          scopingNotes: scopingTowerNote,
        },
      };
    },
  ),
);
