import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  IDirectMessageReceivers,
  ISentDirectMessage,
} from '@symfa-inc/providence-types';
import { Form, Tooltip } from 'antd';
import { HttpService } from '@core/services';
import { DirectMessageHttpService } from '@core/services/http';
import { arraySort, isValidForm } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { Autocomplete, Modal, TextArea } from '@shared/modules';
import { DirectMessageSelectors, UserSelectors } from '@store/selectors';
import { AddAndEditDirectMessageProps } from '../../models';

import './styles.scss';

export const AddAndEditDirectMessage: FC<AddAndEditDirectMessageProps> = ({
  visible,
  modalType,
  currentItem,
  areaKey,
  onClose,
  userList,
}: AddAndEditDirectMessageProps) => {
  const directMessageService = HttpService.getHttpRequests(
    DirectMessageHttpService,
  );

  const [form] = Form.useForm();

  const userId = useSelector(UserSelectors.getUserId);
  const projectId = useSelector(DirectMessageSelectors.getProjectId(areaKey));

  useEffect(() => {
    if (visible && currentItem !== null) {
      form.setFieldsValue(currentItem);
    }
  }, [visible, currentItem]);

  const onCancel = useCallback((): void => {
    onClose();

    form.resetFields();
  }, [onClose]);

  const add = useCallback(async (): Promise<void> => {
    if (await isValidForm(form)) {
      await directMessageService.createDirectMessages(
        form.getFieldsValue() as Pick<
          ISentDirectMessage,
          'receivers' | 'message'
        >,
        areaKey!,
        projectId!,
      );

      onCancel();
    }
  }, [areaKey]);

  const edit = useCallback(async (): Promise<void> => {
    if (await isValidForm(form)) {
      await directMessageService.updateDirectMessages(
        {
          ...currentItem,
          ...form.getFieldsValue(),
        } as ISentDirectMessage,
        areaKey!,
        projectId!,
      );

      onCancel();
    }
  }, [currentItem, areaKey]);

  const onOk = useCallback(async (): Promise<void> => {
    await (modalType === ModalMainTypes.Add ? add() : edit());
  }, [modalType]);

  return (
    <Modal
      className="prov-add-edit-direct-message-modal"
      title={`${modalType} Direct Message`}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
        <div className="receiver-wrapper">
          <Autocomplete
            id="receivers"
            key="receivers"
            label="Receivers"
            options={arraySort(
              userList.map(
                ({ value, viewValue, hasAccess }: IDirectMessageReceivers) => ({
                  value,
                  viewValue,
                  disabled: !hasAccess || value === userId,
                }),
              ),
              'ASC',
              'viewValue',
            )}
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: 'Please select one or some receivers!',
                },
              ],
            }}
            elementProps={{
              mode: 'multiple',
            }}
          />
          <Tooltip title="User(s) that are gray and disabled cannot receive messages as they do not have access to the current screen or Project Type.">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <TextArea
          id="message"
          label="Message"
          formItemProps={{
            rules: [
              {
                required: true,
                message: 'Please write some message!',
              },
            ],
          }}
          elementProps={{ autoSize: { minRows: 5, maxRows: 15 } }}
        />
      </Form>
    </Modal>
  );
};
