import { EquipmentTabData } from '@models/interfaces';

export const EQUIPMENT_DEFAULT_VALUE: EquipmentTabData = {
  id: '',
  modelNumber: '',
  depth: 0,
  length: 0,
  weight: 0,
  width: 0,
  description: '',
};
