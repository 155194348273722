import debounce from 'debounce-promise';
import { RuleObject } from 'rc-field-form/lib/interface';
import { CommonHttpService } from '../../services/http';
import { HttpService } from '../../services/http.service';

interface ValidatorSettings<D> {
  method: keyof D;
  errorMessage: string;
  required?: boolean;
  requestDelayMs?: number;
}

export const serverValidator = <D extends CommonHttpService>(
  {
    method,
    errorMessage,
    required = false,
    requestDelayMs = 500,
  }: ValidatorSettings<D>,
  type: new () => D,
  ...args: any[]
): RuleObject => ({
  validator: debounce(async (rules: RuleObject, value: any) => {
    if (!required && !value) {
      return;
    }

    const httpService: D = HttpService.getHttpRequests(type);

    const valid = await (httpService[method] as any).apply(httpService, [
      ...args,
      value,
    ]);

    if (!valid) {
      throw new Error(errorMessage);
    }
  }, requestDelayMs),
});
