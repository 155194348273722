import { CommonHttpService } from './http';

export class HttpService {
  private static readonly _cache: Map<any, any> = new Map();

  static getHttpRequests<D extends CommonHttpService>(Type: new () => D): D {
    if (!HttpService._cache.get(Type)) {
      HttpService._cache.set(Type, new Type());
    }

    return HttpService._cache.get(Type);
  }
}
