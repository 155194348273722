import { ReactNode } from 'react';
import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-types';
import { ColumnsType } from 'antd/lib/table/interface';
import {
  getJurisdictionByIdOrUndefined,
  projectCategoryRender,
  projectPaceTaskDataRender,
  providenceAgentRender,
  readOnlyInputNumberRender,
} from '@core/utils/methods';
import { IdAndValue, SiteBrowse } from '@models/interfaces';
import { Browse } from '@models/types';

export const SCOPING_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'Alternate Site Info',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.alternateSiteInfo,
  },
  {
    title: 'Civil Vendor',
    key: 'civilVendor',
    dataIndex: 'civilVendor',
    sorter: true,
    width: 130,
    render: (civilVendor: IdAndValue): ReactNode => civilVendor?.value,
  },
  {
    title: 'FA Location Number',
    dataIndex: 'site',
    key: 'FALocationNumber',
    render: (site: SiteBrowse): ReactNode =>
      readOnlyInputNumberRender(site?.FALocationNumber),
    sorter: true,
    width: 190,
  },
  {
    title: 'Jurisdiction',
    key: 'jurisdiction',
    dataIndex: 'site',
    sorter: true,
    width: 180,
    render: (site: SiteBrowse): ReactNode =>
      getJurisdictionByIdOrUndefined(site?.jurisdiction?.id)?.jurisdictionName,
  },
  {
    title: 'Legacy Site ID',
    width: 150,
    key: 'legacySiteID',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.legacySiteID,
  },
  {
    title: 'Oracle Project Number',
    dataIndex: 'categories',
    key: 'oracleProjectNumber',
    render: projectCategoryRender({
      field: 'oracleProjectNumber',
    }),
    sorter: true,
    width: 210,
  },
  {
    title: 'PACE Project Number',
    dataIndex: 'categories',
    key: 'PACEProjectNumber',
    render: projectCategoryRender({
      field: 'PACEProjectNumber',
    }),
    sorter: true,
    width: 200,
  },
  {
    title: 'IWM Job Number',
    key: 'IWMJobNumber',
    dataIndex: 'categories',
    render: projectCategoryRender({
      field: 'IWMJobNumber',
    }),
    sorter: true,
    width: 200,
  },
  {
    title: 'Project Category',
    dataIndex: 'categories',
    key: 'projectCategory',
    render: projectCategoryRender({ field: 'projectCategory' }),
    sorter: true,
    width: 170,
  },
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    width: 180,
    sorter: true,
    dataIndex: 'providenceAgent',
    render: providenceAgentRender,
  },
  {
    title: 'Scheduled Scoping',
    key: 'scheduledScoping',
    dataIndex: 'projectPaceTaskData',
    width: 180,
    defaultSortOrder: 'ascend',
    sorter: true,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.scopingComplete,
      field: 'forecastedDate',
    }),
  },
  {
    title: 'Site',
    dataIndex: 'site',
    key: 'siteNumber',
    sorter: true,
    width: 120,
    render: (site: SiteBrowse): ReactNode => site?.siteNumber,
  },
  {
    title: 'State',
    key: 'state',
    dataIndex: 'site',
    sorter: true,
    width: 85,
    render: (site: SiteBrowse): ReactNode => site?.state?.name,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.submarket?.value,
  },
  {
    title: 'Tower Owner',
    key: 'towerOwner',
    dataIndex: 'site',
    sorter: true,
    width: 140,
    render: (site: SiteBrowse): ReactNode => site?.towerOwner?.value,
  },
  {
    title: 'USID',
    width: 150,
    key: 'USID',
    sorter: true,
    dataIndex: 'site',
    render: (site: SiteBrowse): ReactNode => site.USID,
  },
  {
    title: 'SS099 Mount Analysis Ordered',
    dataIndex: 'projectPaceTaskData',
    key: 'SS099MountAnalysisOrdered',
    width: 225,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.mountAnalysisOrdered,
      field: 'actualDate',
    }),
  },
  {
    title: 'SS100 Mount Analysis Complete',
    dataIndex: 'projectPaceTaskData',
    key: 'SS100MountAnalysisComplete',
    width: 225,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.mountAnalysisComplete,
      field: 'actualDate',
    }),
  },
  {
    title: 'SS071 Passing Structural Received',
    dataIndex: 'projectPaceTaskData',
    key: 'SS071PassingStructuralReceived',
    width: 225,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.passingStructuralReceived,
      field: 'actualDate',
    }),
  },
  {
    title: 'SS103 Failing Structural Received',
    dataIndex: 'projectPaceTaskData',
    key: 'SS103FailingStructuralReceived',
    width: 225,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.failingStructuralReceived,
      field: 'actualDate',
    }),
  },
  {
    title: 'SS107 Final CDs Received',
    dataIndex: 'projectPaceTaskData',
    key: 'SS107FinalCDsReceived',
    width: 225,
    render: projectPaceTaskDataRender({
      id: MAIN_PACE_TASK_IDs.finalCDsReceived,
      field: 'actualDate',
    }),
  },
];
