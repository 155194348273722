import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Steps as AntSteps } from 'antd';
import classNames from 'classnames';
import { Step } from '@models/interfaces';
import { StepsProps } from './models/interfaces';

import './styles.scss';

const { Step: AntStep } = AntSteps;

export const Steps: FC<StepsProps> = ({
  steps,
  disabledNextArrow = false,
  disabledPrevArrow = false,
  nextArrowClick = (): Promise<boolean> => Promise.resolve(true),
  prevArrowClick = (): Promise<boolean> => Promise.resolve(true),
  resetCount,
  ...props
}: PropsWithChildren<StepsProps>) => {
  const [current, setCurrent] = useState<number>(0);
  const changeCurrent = (changedCurrent: number): void => {
    if (changedCurrent >= 0 && changedCurrent < steps.length) {
      setCurrent(changedCurrent);
    }
  };

  useEffect(() => {
    if (resetCount) {
      setCurrent(0);
    }
  }, [resetCount]);

  const canGoNext = !disabledNextArrow && current + 1 < steps.length;
  const canGoPrev = !disabledPrevArrow && current - 1 >= 0;

  return (
    <div className="prov-steps">
      <AntSteps {...props} current={current}>
        {steps.map(({ name }: Step) => (
          <AntStep title={name} key={name} />
        ))}
      </AntSteps>
      <div className="prov-steps__content">
        <div
          className={classNames('prov-steps__content__arrow-prev', {
            disabled: !canGoPrev,
          })}
          onClick={(): void => {
            prevArrowClick().then((canClick: boolean) => {
              if (canClick && canGoPrev) {
                changeCurrent(current - 1);
              }
            });
          }}
        >
          <LeftOutlined />
        </div>
        <div className="prov-steps__content__box">
          {steps.map(
            (
              { component, name, props: componentProps }: Step,
              index: number,
            ) => (
              <div
                className={classNames('prov-steps__content__box__item', {
                  active: index === current,
                })}
                key={name}
              >
                {React.createElement(component, componentProps)}
              </div>
            ),
          )}
        </div>
        <div
          className={classNames('prov-steps__content__arrow-next', {
            disabled: !canGoNext,
          })}
          onClick={(): void => {
            nextArrowClick().then((canClick: boolean) => {
              if (canClick && canGoNext) {
                changeCurrent(current + 1);
              }
            });
          }}
        >
          <RightOutlined />
        </div>
      </div>
    </div>
  );
};
