import { browseReducer } from '../../core/utils/methods/browse-sorter-reducer';
import { transformFields } from '../../core/utils/methods/transform-fields';
import { createReducer, on } from '../../core/utils/store';
import { ACCOUNTING_PACE_TASKS } from '../../models/constants/accounting-pace-tasks';
import {
  AccountingBrowse,
  AccountingBrowseUpdateData,
  AccountingDetails,
  AccountingNotes,
  AccountingProjectData,
  PaginatedResponse,
  PayloadAndState,
  SiteBrowse,
} from '../../models/interfaces';
import { ProjectPaceTaskDataType } from '../../models/types';
import { AccountingActions } from '../actions/accounting.actions';

type PaceTaskChildData = keyof Omit<AccountingBrowseUpdateData, 'id'>;

export interface AccountingState {
  accountingBrowseData: PaginatedResponse<AccountingBrowse>;
  accountingSite: SiteBrowse;
  projectData: AccountingProjectData;
  notes: AccountingNotes;
  isFetching: boolean;
  errors: boolean;
}

const initialState: AccountingState = {
  accountingBrowseData: {
    items: [],
    total: 0,
  },
  accountingSite: {} as SiteBrowse,
  projectData: {} as AccountingProjectData,
  notes: {} as AccountingNotes,
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  on(
    AccountingActions.getAccountingBrowseDataAction,
    browseReducer('accountingBrowseData'),
  ),
  on(
    AccountingActions.updateAccountingBrowseAction,
    ({
      payload: { id, ...restData },
      state: {
        accountingBrowseData: { items, total },
      },
    }: PayloadAndState<AccountingBrowseUpdateData, AccountingState>) => {
      const editedRowIndex = items.findIndex(
        (item: AccountingBrowse) => item.id === id,
      );

      const editedItem = items[editedRowIndex] ?? {};

      const paceTasks = Object.keys(ACCOUNTING_PACE_TASKS);

      const updatedItemPaceTasks = editedItem?.projectPaceTaskData.length
        ? editedItem?.projectPaceTaskData.map(
            (projectPaceTask: ProjectPaceTaskDataType) => {
              const indexOfPaceTask = projectPaceTask.paceTask.paceTaskID
                ? paceTasks.indexOf(projectPaceTask.paceTask.paceTaskID)
                : -1;

              if (
                projectPaceTask.paceTask.paceTaskID &&
                indexOfPaceTask !== -1
              ) {
                return {
                  ...projectPaceTask,
                  actualDate:
                    restData[
                      `${paceTasks[indexOfPaceTask]}ActualDate` as PaceTaskChildData
                    ],
                  invoiceDate:
                    restData[
                      `${paceTasks[indexOfPaceTask]}InvoiceDate` as PaceTaskChildData
                    ],
                  invoiceNumber: restData[
                    `${paceTasks[indexOfPaceTask]}InvoiceNumber` as PaceTaskChildData
                  ] as string,
                };
              }

              return projectPaceTask;
            },
          )
        : (paceTasks.map((paceTask: string) => ({
            invoiceDate:
              restData[`${paceTask}InvoiceDate` as PaceTaskChildData],
            invoiceNumber:
              restData[`${paceTask}InvoiceNumber` as PaceTaskChildData],
            ...(paceTask === 'RE020'
              ? {
                  actualDate:
                    restData[`${paceTask}ActualDate` as PaceTaskChildData],
                }
              : {}),
            paceTask: {
              paceTaskID: paceTask,
              id: ACCOUNTING_PACE_TASKS[paceTask],
            },
          })) as ProjectPaceTaskDataType[]);

      const accountingBrowseItems = [...items];

      accountingBrowseItems.splice(editedRowIndex, 1, {
        ...editedItem,
        projectPaceTaskData: updatedItemPaceTasks,
      });

      return {
        accountingBrowseData: {
          items: accountingBrowseItems,
          total,
        },
      };
    },
  ),
  on(
    AccountingActions.getAccountingDetailsAction,
    ({
      payload: {
        projectData: {
          site: { jurisdiction, siteNote: siteNotes, ...site },
          leasing,
          engineering,
          categories,
          ...projectData
        },
      },
    }: PayloadAndState<AccountingDetails, AccountingState>) => ({
      accountingSite: transformFields<SiteBrowse>(site, [
        'towerOwner',
        'riskCategory',
      ]),
      projectData: {
        ...projectData,
        FALocationNumber: site.FALocationNumber,
        legacySiteID: site.legacySiteID,
      },
      notes: {
        siteNotes,
        engineeringNote: engineering?.RFDSPhase1?.engineeringNote,
        leasingNote: leasing?.leasingNote,
        permittingNotes: jurisdiction?.jurisdictionNote,
      },
    }),
  ),
);
