import { createReducer, on } from '../../core/utils/store';
import {
  ExportsBrowse,
  ExportsBrowseFilterOptionsResponse,
  PaginatedResponse,
  PayloadAndState,
} from '../../models/interfaces';
import { ExportsActions } from '../actions/exports.actions';

export interface ExportsState {
  exportsBrowseData: PaginatedResponse<ExportsBrowse>;
  filtersOptions: ExportsBrowseFilterOptionsResponse | null;
  projectBundleID: number;
  isFetching: boolean;
  errors: boolean;
}

const initialState: ExportsState = {
  exportsBrowseData: {
    items: [],
    total: 0,
  },
  filtersOptions: null,
  projectBundleID: 0,
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  // get data
  on(
    ExportsActions.getExportsBrowseDataAction,
    ({
      payload: exportsBrowseData,
    }: PayloadAndState<PaginatedResponse<ExportsBrowse>, ExportsState>) => ({
      exportsBrowseData,
    }),
  ),
  // get filters options
  on(
    ExportsActions.getExportsBrowseFilters,
    ({
      payload: filtersOptions,
    }: PayloadAndState<ExportsBrowseFilterOptionsResponse, ExportsState>) => ({
      filtersOptions,
    }),
  ),
);
