import React, { FC } from 'react';
import { USER_ROLE_OPTIONS, YES_NO_GROUP } from '@models/constants';
import { OptionProps } from '@models/interfaces';
import { Autocomplete } from '@shared/modules';

export const Permissions: FC = () => (
  <>
    <Autocomplete
      id="isCanBeDeleted"
      label="Can Be Deleted"
      options={YES_NO_GROUP as OptionProps[]}
      formItemProps={{
        name: ['permissions', 'isCanBeDeleted'],
      }}
    />
    <Autocomplete
      id="forecastedDateEditors"
      elementProps={{ mode: 'multiple' }}
      label="Edit Forecasted Date"
      options={USER_ROLE_OPTIONS}
      formItemProps={{
        name: ['permissions', 'forecastedDateEditors'],
      }}
    />
    <Autocomplete
      id="actualDateEditors"
      elementProps={{ mode: 'multiple' }}
      label="Edit Actual Date"
      options={USER_ROLE_OPTIONS}
      formItemProps={{
        name: ['permissions', 'actualDateEditors'],
      }}
    />
    <Autocomplete
      id="PACEForecastedDateEditors"
      elementProps={{ mode: 'multiple' }}
      label="Edit PACE Forecasted Date"
      options={USER_ROLE_OPTIONS}
      formItemProps={{
        name: ['permissions', 'PACEForecastedDateEditors'],
      }}
    />
    <Autocomplete
      id="PACEActualDateEditors"
      elementProps={{ mode: 'multiple' }}
      label="Edit PACE Actual Date"
      options={USER_ROLE_OPTIONS}
      formItemProps={{
        name: ['permissions', 'PACEActualDateEditors'],
      }}
    />
  </>
);
