import { ISelectorOption } from '@symfa-inc/providence-types';
import { actionCreatorFactory } from '@core/utils/factories';
import {
  AddDropdownValueResponse,
  DropdownValues,
  UpdateDropdownValueResponse,
} from '@models/types';

const actionCreator = actionCreatorFactory('DROPDOWN_VALUES');

export namespace DropdownValuesActions {
  export const getAdminDropdownValuesDataAction =
    actionCreator.createAsyncAction<DropdownValues>('GET_DROPDOWN_VALUES_DATA');

  export const getAdminDropdownValuesOptionsAction =
    actionCreator.createAsyncAction<ISelectorOption<string>[]>(
      'GET_DROPDOWN_VALUES_OPTIONS_DATA',
    );

  export const addAdminDropdownValuesDataAction =
    actionCreator.createAsyncAction<AddDropdownValueResponse>(
      'ADD_DROPDOWN_VALUES_DATA',
    );

  export const updateAdminDropdownValuesDataAction =
    actionCreator.createAsyncAction<UpdateDropdownValueResponse>(
      'UPDATE_DROPDOWN_VALUES_DATA',
    );

  export const deleteAdminDropdownValuesDataAction =
    actionCreator.createAsyncAction<string>('DELETE_DROPDOWN_VALUES_DATA');
}
