import React, { FC, PropsWithChildren, useState } from 'react';
import nextId from 'react-id-generator';
import { Collapse } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import classNames from 'classnames';
import { PanelProps } from './models/interfaces';

export const Panel: FC<PanelProps> = ({
  collapseClassName,
  collapsible = false,
  restrictionText = '',
  ...props
}: PropsWithChildren<PanelProps>) => {
  const [key] = useState(nextId());
  const [activeKey, setActiveKey] = useState(key);
  const toggleKey = (): void => {
    if (collapsible) {
      setActiveKey((prevState: string) => (prevState === key ? '' : key));
    }
  };

  const condition = !collapsible && !!restrictionText;

  return (
    <Collapse
      activeKey={!condition ? activeKey : ''}
      className={classNames(collapseClassName, { disabled: condition })}
      onChange={toggleKey}
      expandIconPosition="right"
    >
      <CollapsePanel
        key={key}
        showArrow={collapsible}
        {...props}
        extra={condition && <span>{restrictionText}</span>}
      />
    </Collapse>
  );
};
