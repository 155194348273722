import { useCallback, useEffect } from 'react';
import { Config } from '@core/config/config';
import { Candidate } from '@models/interfaces';
import { DEFAULT_MAP_COORDS, DEFAULT_ZOOM_LVL } from '../../../models';
import { createMapScript } from './create-map-script';
import { createPushpin } from './create-pushpin';

export const useCreateMap = ({
  map,
  setMap,
  mapRef,
  candidates,
}: {
  map: Microsoft.Maps.Map | undefined;
  setMap: React.Dispatch<React.SetStateAction<Microsoft.Maps.Map | undefined>>;
  mapRef: React.RefObject<HTMLDivElement>;
  candidates: Candidate[];
}) => {
  const createMap = useCallback(() => {
    const { Maps } = window.Microsoft;

    if (!map && mapRef?.current) {
      const currentMap = new Maps.Map(mapRef.current, {
        credentials: Config.get().BingMapsKey,
        zoom: DEFAULT_ZOOM_LVL,
        supportedMapTypes: [
          Microsoft.Maps.MapTypeId.road,
          Microsoft.Maps.MapTypeId.aerial,
        ],
        showLocateMeButton: false,
        showTrafficButton: false,
        navigationBarMode: Microsoft.Maps.NavigationBarMode.compact,
        disableStreetside: true,
        center: new Microsoft.Maps.Location(...DEFAULT_MAP_COORDS),
      });

      setMap(currentMap);

      const infoBox = new Maps.Infobox(currentMap.getCenter(), {
        visible: false,
      });

      infoBox.setMap(currentMap);

      currentMap.entities.push(createPushpin(candidates, infoBox));
    }
  }, [map]);

  useEffect(() => {
    window?.Microsoft?.Maps ? createMap() : createMapScript(createMap);
  }, [createMap]);
};
