import { ISelectorOption } from '@symfa-inc/providence-types';
import { actionCreatorFactory } from '@core/utils/factories';
import { IProjectPACETask, IUpdateProjectPACETask } from '@models/interfaces';

const actionCreator = actionCreatorFactory('PACE_TASKS_BROWSE');

export namespace PACETasksBrowse {
  export const getProjectBundleIDsOptions = actionCreator.createAsyncAction<{
    [key: string]: ISelectorOption<string | number, string | number>[];
  }>('GET_PROJECT_BUNDLE_IDs');

  export const getProjectPACETasks = actionCreator.createAsyncAction<
    IProjectPACETask[]
  >('GET_PROJECT_PACE_TASKS');
  export const updateProjectPACETasks = actionCreator.createAsyncAction<
    IUpdateProjectPACETask[]
  >('UPDATE_PROJECT_PACE_TASKS');
}
