import { ReactText } from 'react';
import { Undefinable } from '@symfa-inc/providence-types';
import { convertToDecimal, convertToDMS } from '@core/utils/methods';

export const convertValue = (
  converted: boolean,
  value: Undefinable<ReactText>,
  readOnlyField?: boolean,
): Undefinable<ReactText> => {
  if (value) {
    const decimal = readOnlyField
      ? Math.round(convertToDecimal(`${value}`) * 1e5) / 1e5
      : convertToDecimal(`${value}`);

    return converted ? convertToDMS(+value) : decimal;
  }
};
