import { DriverWorkflow, UserRole } from '@symfa-inc/providence-types';
import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  DriverManagementHttpService,
  FinancialHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';
import store from '@store/index';

export class FinancialResolver implements Resolver {
  private readonly _entity: string;
  private readonly _workflow?: string;

  constructor(entity: string, workflow?: DriverWorkflow) {
    this._entity = entity;

    if (workflow) {
      this._workflow = workflow;
    }
  }

  async resolve(): Promise<void> {
    const {
      user: {
        activeUser: {
          roles: [firstRole, ...roles],
        },
      },
    } = store.getState();

    const readOnly = roles.length === 0 && firstRole === UserRole.ReadOnly;

    if (!readOnly) {
      const [projectBundleID] = getProjectBundleIDParamFromUrl();

      await HttpService.getHttpRequests(FinancialHttpService).getFinancial(
        projectBundleID,
        this._entity,
      );

      if (this._workflow) {
        await HttpService.getHttpRequests(
          DriverManagementHttpService,
        ).getDriversListByWorkflow(this._workflow);
      }
    }
  }
}
