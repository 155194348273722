import store from '@store/index';
import { ActionCreator } from '../store/models/interfaces';

export const distructAndDispatch =
  <T extends Record<string, any>, F>(
    field: keyof T,
    action: ActionCreator<F>,
    fn: (obj: F) => F = (obj: F): F => obj,
  ) =>
  (data: T): T => {
    const { ...rest } = data;

    if (Object.prototype.hasOwnProperty.call(rest, field)) {
      const obj = data[field];

      delete rest[field];

      store.dispatch(action.done(fn(obj)));
    }

    return rest;
  };
