import { ScopingAdditionFilterType } from '@symfa-inc/providence-types';
import { AdditionalFilter } from '@models/interfaces';

export const ADDITIONAL_FILTERS: AdditionalFilter[] = [
  {
    key: ScopingAdditionFilterType.FinalCDsNotComplete,
    name: 'Final CDs Not Complete',
  },
  {
    key: ScopingAdditionFilterType.MountAnalysisNotComplete,
    name: 'Mount Analysis Not Complete',
  },
  {
    key: ScopingAdditionFilterType.ScopingScheduledToday,
    name: 'Scoping Scheduled Today',
  },
];
