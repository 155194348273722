import {
  JurisdictionDetailsSummary,
  JurisdictionDetailsZoning,
  JurisdictionZoningData,
} from '@models/interfaces';

export const getDefaultZoningData = (
  summary?: Omit<JurisdictionDetailsSummary, 'id'>,
  zoning?: JurisdictionDetailsZoning[],
): JurisdictionZoningData => ({
  summarySection: summary || ({} as JurisdictionDetailsSummary),
  zoningSection: zoning || [],
});
