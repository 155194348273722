import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { Candidate } from '@models/interfaces';
import { CandidatesActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class CandidatesHttpService extends CommonHttpService {
  @StoredRequest(CandidatesActions.getCandidatesDataAction)
  getCandidates(): Promise<Candidate[]> {
    return this.http.callApi.get(`${this.apiUrl}/candidates`);
  }
}
