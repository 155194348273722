import { actionCreatorFactory } from '@core/utils/factories';
import {
  AdminCountyData,
  StateWithCounties,
  UpdateCountyResponse,
} from '@models/interfaces';

const actionCreator = actionCreatorFactory('COUNTY');

export namespace CountyActions {
  export const addAdminCountyStateDataAction =
    actionCreator.createAsyncAction<AdminCountyData>('ADD_COUNTY_STATE_DATA');

  export const updateAdminCountyStateDataAction =
    actionCreator.createAsyncAction<AdminCountyData>(
      'UPDATE_COUNTY_STATE_DATA',
    );

  export const deleteAdminCountyStateDataAction =
    actionCreator.createAsyncAction<string>('DELETE_COUNTY_STATE_DATA');

  export const addAdminCountiesDataAction =
    actionCreator.createAsyncAction<UpdateCountyResponse>('ADD_COUNTIES_DATA');

  export const updateAdminCountiesDataAction =
    actionCreator.createAsyncAction<UpdateCountyResponse>(
      'UPDATE_COUNTIES_DATA',
    );

  export const deleteAdminCountiesDataAction =
    actionCreator.createAsyncAction<string>('DELETE_COUNTIES_DATA');

  export const getStatesAction =
    actionCreator.createAsyncAction<StateWithCounties[]>('GET_STATES');
}
