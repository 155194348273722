import { LeasingWorkflowUpdateData } from '@models/interfaces';

export const LEASING_ENGINEERING_INFO_DATES: Array<
  keyof LeasingWorkflowUpdateData['engineering']
> = [
  'failingMountAnalysisReviewedDate',
  'passingMountAnalysisReviewedLeasing',
  'CDsSentToLandlordDate',
  'CDsReceivedFromLandlordDate',
  'prelimCDsApprovedLeasing',
  'finalCDsReviewedLeasing',
];

export const LEASING_INFO_DATES: Array<
  keyof LeasingWorkflowUpdateData['leasing']
> = [
  'leaseAuditComplete',
  'SOFIssued',
  'coloAppApproved',
  'coloAppDrafted',
  'coloAppSubmitted',
  'PALOrNPAReceived',
  'leaseDraftReceived',
  'leaseRevisionRequestedDate',
  'LMPSApproved',
  'LMPSSubmitted',
  'legalApprovedDate',
  'legalAssignedDate',
  'legalSubmittedDate',
  'outForSignatureDate',
  'leaseOrAmendmentFullyExecuted',
  'NTPApproved',
  'NTPRequested',
];

export const STRUCTURAL_INFO_DATES: Array<
  keyof LeasingWorkflowUpdateData['structural']
> = [
  'structuralOrdered',
  'passingStructuralReceived',
  'failingStructuralReceived',
  'orderModDesignDate',
  'modDesignComplete',
  'PMSAReceivedDate',
  'ATTModApprovedDate',
  'IBMApproved',
  'modConstructionStart',
  'modConstructionComplete',
  'PMIModTurnoverDate',
];
