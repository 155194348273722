import React, { FC, PropsWithChildren } from 'react';
import { DownloadOutlined } from '@ant-design/icons/lib';
import { Upload } from 'antd';
import { DragAndDropUploaderProps, transformTypesToAccept } from './models';

import './styles.scss';

const { Dragger } = Upload;

export const DragAndDropUploader: FC<DragAndDropUploaderProps> = ({
  acceptedTypes,
  ...props
}: PropsWithChildren<DragAndDropUploaderProps>) => (
  <div className="prov-drag-and-drop-uploader">
    <Dragger
      showUploadList={false}
      accept={transformTypesToAccept(acceptedTypes)}
      {...props}
    >
      <DownloadOutlined />
      <div className="prov-drag-and-drop-uploader__text">
        Drag and Drop here or
        <span className="prov-drag-and-drop-uploader__text_primary">
          Browse File
        </span>
      </div>
    </Dragger>
    {acceptedTypes?.length && (
      <div className="prov-drag-and-drop-uploader__accepted-types">
        {`Accepted File Types: ${acceptedTypes?.join(', ').toUpperCase()}`}
      </div>
    )}
  </div>
);
