import { DollarOutlined } from '@ant-design/icons/lib';
import { SubPage } from '@symfa-inc/providence-types';
import { RedirectGuard } from '@core/guards';
import { Route, RouteGroup, Tab } from '@models/classes';
import { MenuPlacement } from '@models/enums';
import { Financial } from '@shared/components/financial';
import { AccountingSelectors } from '@store/selectors/accounting.selectors';
import { AccountingSummary } from './components/details/tabs/summary';
import { AccountingBrowse, AccountingDetails } from './components';
import {
  AccountingBrowseResolver,
  AccountingDetailsResolver,
} from './resolvers';

export const accountingRoutes = new RouteGroup({
  name: 'Accounting',
  path: '/accounting',
})
  .breadcrumb()
  .addGuard(new RedirectGuard('accounting'), '/')
  .setMenu(MenuPlacement.Main, DollarOutlined)
  .addRoutes([
    new Route({
      name: 'Browse',
      path: '/browse',
      component: AccountingBrowse,
    })
      .addResolver(new AccountingBrowseResolver(), false)
      .breadcrumb()
      .addGuard(new RedirectGuard('accounting', SubPage.AccountingBrowse), '/')
      .setMenu(MenuPlacement.Main)
      .addRoute(
        new Route({
          name: 'Details',
          breadcrumbName: ':siteNumber(:projectBundleID)',
          path: '/:accountingId/details',
          component: AccountingDetails,
          extendPath: false,
        })
          .addResolver(new AccountingDetailsResolver())
          .breadcrumb()
          .addTabs([
            new Tab({
              path: '/summary',
              name: 'Summary',
              component: AccountingSummary,
            }),
            new Tab({
              path: '/financial',
              name: 'Financial',
              component: Financial,
              componentProps: {
                feeAccessPath: 'accounting',
                siteDataRootPath: ['accounting', 'accountingSite'],
                projectTypeSelector: AccountingSelectors.getProjectType,
                projectIdSelector: AccountingSelectors.getProjectId,
              },
            }),
          ]),
      ),
  ]);
