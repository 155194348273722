import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { SelectValue } from 'antd/es/select';
import { ProjectHttpService } from '@core/services/http';
import { dateWorker } from '@core/utils/date-worker';
import { getFormData, isValidForm } from '@core/utils/methods';
import { serverValidator, uniqueValidator } from '@core/utils/validators';
import { ModalMainTypes } from '@models/enums';
import {
  AdminFrequencyDDV,
  OptionProps,
  ProjectCategory,
} from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { ProjectCategorySumModalProps } from '@shared/components/project-category/models';
import { Autocomplete, Checkbox, DatePicker, Input } from '@shared/modules';
import { ProjectCategoriesActions } from '@store/actions';
import { ProjectCategoriesSelectors } from '@store/selectors';
import { useGetProjectCategoryOptions } from './hooks';

import './styles.scss';

const { useForm } = Form;

export const ProjectCategoryModal: FC<ProjectCategorySumModalProps> = ({
  itemValue,
  visible,
  modalType,
  toggleModal,
  projectCreate,
}: PropsWithChildren<ProjectCategorySumModalProps>) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const currentProjectCategories = useSelector(
    ProjectCategoriesSelectors.getProjectCategories,
  );
  const { dropdownsOptions, dropdownValues, currentProject } = useSelector(
    ProjectCategoriesSelectors.getProjectCategoryModalInfo,
  );

  const add = async (value: ProjectCategory): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        ProjectCategoriesActions.addProjectCategoriesItemAction.done(value),
      );
      toggleModal(modalType);
    }
  };

  const edit = async (value: ProjectCategory): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        ProjectCategoriesActions.updateProjectCategoriesItemAction.done(value),
      );
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        ...itemValue,
        projectAssignedDate: itemValue?.projectAssignedDate
          ? dateWorker(itemValue?.projectAssignedDate)
          : null,
      });
    }
  }, [visible, itemValue]);

  const validationValues =
    modalType === ModalMainTypes.Edit
      ? currentProjectCategories.filter(
          (record: ProjectCategory) =>
            record.generatedId !== itemValue.generatedId,
        )
      : currentProjectCategories;

  const projectCategoriesOptions = useGetProjectCategoryOptions();

  return (
    <AddOrEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getFormData(itemValue, form))
          : (): Promise<void> => edit(getFormData(itemValue, form))
      }
      onCancel={(): void => toggleModal(modalType)}
      visible={visible}
      type={modalType}
      formProps={{ labelCol: { span: 8 }, wrapperCol: { span: 15 }, form }}
      className="prov-project-category-modal"
      title="Project Category"
      cancelButtonProps={{ onClick: (): void => form.resetFields() }}
    >
      <Autocomplete
        id="projectCategory"
        label="Project Category"
        options={projectCategoriesOptions}
        formItemProps={{
          rules: [{ required: true, message: 'Project Category is required!' }],
        }}
      />
      <Checkbox id="isFirstNet" label="Is First Net?" />
      <Autocomplete
        id="projectRanking"
        label="Project Ranking"
        options={dropdownsOptions?.ProjectRanking}
      />
      <Input
        id="PACEProjectNumber"
        label="PACE Project Number"
        formItemProps={{
          validateFirst: true,
          rules: [
            ({ getFieldValue }) => ({
              required: !getFieldValue('IWMJobNumber'),
              message: 'PACE Project Number is required!',
            }),
            uniqueValidator(
              validationValues,
              'PACEProjectNumber',
              'PACE Project Number',
            ),
            serverValidator(
              {
                method: 'getProjectProjectCategoryValidation',
                errorMessage: 'PACE Project Number must be unique',
              },
              ProjectHttpService,
              !projectCreate && modalType === ModalMainTypes.Edit
                ? currentProject.id
                : undefined,
              'PACEProjectNumber',
            ),
          ],
        }}
        elementProps={{
          onChange: () => form.validateFields(['IWMJobNumber']),
        }}
      />
      <Input
        id="IWMJobNumber"
        label="IWM Job Number"
        formItemProps={{
          validateFirst: true,
          rules: [
            ({ getFieldValue }) => ({
              required: !getFieldValue('PACEProjectNumber'),
              message: 'IWM Job Number is required!',
            }),
            uniqueValidator(validationValues, 'IWMJobNumber', 'IWM Job Number'),
            serverValidator(
              {
                method: 'getProjectProjectCategoryValidation',
                errorMessage: 'IWM Job Number must be unique',
              },
              ProjectHttpService,
              !projectCreate && modalType === ModalMainTypes.Edit
                ? currentProject.id
                : undefined,
              'IWMJobNumber',
            ),
          ],
        }}
        elementProps={{
          onChange: () => form.validateFields(['PACEProjectNumber']),
        }}
      />
      <Input
        id="oracleProjectNumber"
        label="Oracle Project Number"
        formItemProps={{
          validateFirst: true,
          rules: [
            { required: true, message: 'Oracle Project Number is required!' },
            uniqueValidator(
              validationValues,
              'oracleProjectNumber',
              'Oracle Project Number',
            ),
            serverValidator(
              {
                method: 'getProjectProjectCategoryValidation',
                errorMessage: 'Oracle Project Number must be unique',
              },
              ProjectHttpService,
              !projectCreate && modalType === ModalMainTypes.Edit
                ? currentProject.id
                : undefined,
              'oracleProjectNumber',
            ),
          ],
        }}
      />
      <Autocomplete
        id="frequency"
        label="Project Frequency"
        options={dropdownsOptions?.Frequency.sort(
          (a: OptionProps, b: OptionProps) =>
            a.viewValue
              .toString()
              .localeCompare(b.viewValue.toString(), 'en', { numeric: true }),
        )}
        elementProps={{
          onChange: (id: SelectValue): void => {
            const frequency = dropdownValues.Frequency.find(
              (frequencyDDV: AdminFrequencyDDV) => frequencyDDV.id === id,
            );

            if (frequency) {
              form.setFieldsValue({ isFirstNet: frequency.isFirstNet });
            }
          },
        }}
      />
      <DatePicker id="projectAssignedDate" label="Project Assigned Date" />
      <Input id="strategicDriver" label="Strategic Driver" />
    </AddOrEditModal>
  );
};
