import { actionCreatorFactory } from '../../core/utils/factories';
import {
  ProcessEnvMap as KeyString,
  ProjectCategory,
} from '../../models/interfaces';

const actionCreator = actionCreatorFactory('PROJECT_CATEGORIES');

export namespace ProjectCategoriesActions {
  export const setProjectCategoriesAction = actionCreator.createAsyncAction<
    ProjectCategory[]
  >('CLEAR_PROJECT_CATEGORIES');
  export const addProjectCategoriesItemAction =
    actionCreator.createAsyncAction<ProjectCategory>(
      'ADD_PROJECT_CATEGORIES_ITEM',
    );
  export const updateProjectCategoriesItemAction =
    actionCreator.createAsyncAction<ProjectCategory>(
      'UPDATE_PROJECT_CATEGORIES_ITEM',
    );
  export const deleteProjectCategoriesItemAction =
    actionCreator.createAsyncAction<KeyString>(
      'DELETE_PROJECT_CATEGORIES_ITEM',
    );
  export const toggleProjectCategoriesTableAction =
    actionCreator.createAsyncAction<void>('TOGGLE_PROJECT_CATEGORIES_TABLE');
  export const setProjectCategoriesTableAction =
    actionCreator.createAsyncAction<boolean>('SET_PROJECT_CATEGORIES_TABLE');
  export const setCategoriesTableEditing =
    actionCreator.createAsyncAction<boolean>(
      'SET_PROJECT_CATEGORIES_TABLE_EDITING',
    );
}
