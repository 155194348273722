import React, { FC, PropsWithChildren, ReactText, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import { isValidForm, normalizeDecimal } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { RadData } from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { RADModalProps } from '@shared/components/rad-table/models/interfaces';
import { Input } from '@shared/modules';
import { ProjectActions } from '@store/actions';

const { useForm } = Form;

export const RADModal: FC<RADModalProps> = ({
  title,
  visible,
  toggleModal,
  permissions,
  formValue,
}: PropsWithChildren<RADModalProps>) => {
  const [form] = useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (formValue) {
      form.setFieldsValue(formValue);
    }
  }, [formValue]);

  const onOk = async (): Promise<void> => {
    if (await isValidForm(form)) {
      try {
        const result = Object.entries<ReactText>(form.getFieldsValue()).map(
          ([field, value]: Array<ReactText>) => ({
            RADField: field,
            [title]: value,
          }),
        ) as RadData[];

        dispatch(ProjectActions.updateProjectRADAction.done(result));

        toggleModal(ModalMainTypes.Edit);
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <AddOrEditModal
      onOk={onOk}
      onCancel={(): void => toggleModal(ModalMainTypes.Edit)}
      visible={visible}
      type={ModalMainTypes.Edit}
      title={title}
      formProps={{
        labelCol: { span: 5 },
        wrapperCol: { span: 14 },
        form,
      }}
      className="prov-rad-table__modal"
      cancelButtonProps={{
        onClick: (): void =>
          form.setFieldsValue({
            A: 0,
            B: 0,
            G: 0,
            D: 0,
          }),
      }}
    >
      <Input
        id="A"
        label="A"
        isEditing={permissions}
        formItemProps={{
          normalize: normalizeDecimal,
        }}
      />
      <Input
        id="B"
        label="B"
        isEditing={permissions}
        formItemProps={{
          normalize: normalizeDecimal,
        }}
      />
      <Input
        id="G"
        label="G"
        isEditing={permissions}
        formItemProps={{
          normalize: normalizeDecimal,
        }}
      />
      <Input
        id="D"
        label="D"
        isEditing={permissions}
        formItemProps={{
          normalize: normalizeDecimal,
        }}
      />
    </AddOrEditModal>
  );
};
