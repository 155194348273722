import React, { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { removeEmptyFields } from '@core/utils/methods';
import {
  BROWSE_DEFAULT_FILTER_VALUE,
  browseFiltersChange,
} from '@models/constants';
import { JurisdictionBody, MainBrowseFilter } from '@models/interfaces';
import { BrowsePage } from '@shared/modules';
import { PermittingSelectors, UserSelectors } from '@store/selectors';
import { JurisdictionModalForm } from './modals';
import { PERMITTING_JURISDICTIONS_COLUMNS } from './models';

import './styles.scss';

const { useForm } = Form;

export const JurisdictionBrowse: FC = () => {
  const permittingService = useMemo(
    () => HttpService.getHttpRequests(PermittingHttpService),
    [],
  );

  const { items, total } = useSelector(
    PermittingSelectors.getJurisdictionBrowseData,
  );

  const { canAddJurisdiction } = useSelector(
    UserSelectors.getPermittingJurisdictionPermissions,
  );

  const [form] = useForm();

  const [filters, setFilters] = useState<MainBrowseFilter>({
    ...BROWSE_DEFAULT_FILTER_VALUE,
  });

  useDidUpdateEffect(() => {
    permittingService.getJurisdictionBrowseData(
      removeEmptyFields({ ...filters }),
    );
  }, [filters]);

  const onSearchChanges = (search: string): void => {
    setFilters((prevState: MainBrowseFilter) => ({
      ...prevState,
      search,
    }));
  };

  const addJurisdiction = async (): Promise<void> => {
    await permittingService.addJurisdiction(
      removeEmptyFields({ ...filters }),
      form.getFieldsValue() as JurisdictionBody,
    );

    await permittingService.getJurisdictionList();
  };

  return (
    <BrowsePage
      columns={PERMITTING_JURISDICTIONS_COLUMNS}
      data={items}
      filters={<JurisdictionModalForm form={form} />}
      filterFormProps={{ labelCol: { span: 8 }, form }}
      className="prov-permitting-jurisdiction"
      onSearchChanges={onSearchChanges}
      onApplyFilter={addJurisdiction}
      onChange={browseFiltersChange(setFilters)}
      validation
      buttonProps={{
        title: 'Add Jurisdiction',
        icon: '+',
        isNew: true,
        disabled: !canAddJurisdiction,
      }}
      tableProps={{
        pagination: {
          total,
        },
        module: 'jurisdiction',
      }}
      destroyOnClose
      redirectToDetailsRowKey="jurisdictionName"
    />
  );
};
