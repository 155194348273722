import React, { memo, useCallback, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { IScopingTowerNote, Undefinable } from '@symfa-inc/providence-types';
import { Card, Empty } from 'antd';
import { ScopingTowerNoteModal } from './scoping-tower-note-modal';

import './styles.scss';

interface ScopingTowerNoteProps {
  scopingId?: string;
  data: Undefinable<IScopingTowerNote>;
  isEditing?: boolean;
}

export const ScopingTowerNote = memo((props: ScopingTowerNoteProps) => {
  const { data, isEditing = false, scopingId } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <Card
        className="prov-scoping-tower-note"
        title="Scoping Tower Note"
        extra={
          isEditing && (
            <span className="edit-btn">
              <SettingOutlined onClick={onOpenModal} />
            </span>
          )
        }
      >
        {data ? (
          <>
            <div className="actions">
              {data?.actions.map(action => {
                const actionType = action.replace(/(ADD|REMOVE|MOVE).*/, '$1');
                const description = action.replace(/(ADD|REMOVE|MOVE)\s/, '');

                return (
                  <span key={action}>
                    <b>{actionType}</b> {description}
                  </span>
                );
              })}
            </div>
            {data?.finalConfig && (
              <span className="final">
                <b>{data?.finalConfig.replace(/(FINAL).*/, '$1 ')}</b>
                {data?.finalConfig.replace(/FINAL\s/, '').toUpperCase()}
              </span>
            )}
          </>
        ) : (
          <Empty />
        )}
      </Card>
      <ScopingTowerNoteModal
        isOpen={isModalOpen}
        scopingId={scopingId}
        data={data}
        onClose={onCloseModal}
      />
    </>
  );
});

ScopingTowerNote.displayName = 'ScopingTowerNote';
