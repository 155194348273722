import { RuleObject } from 'rc-field-form/es/interface';

export const WHITESPACE_INPUT_LIMIT_RULES = [
  {
    whitespace: true,
    message: 'Input text cannot consists only of spaces!',
  },
  {
    validator: (_: RuleObject, inputValue: string): Promise<void> =>
      /\s{2,}/.test(inputValue)
        ? Promise.reject(new Error('Value should not have consecutive spaces.'))
        : Promise.resolve(),
  },
  {
    validator: (_: RuleObject, inputValue: string): Promise<void> =>
      inputValue?.charAt(inputValue.length - 1) === ' ' ||
      inputValue?.charAt(0) === ' '
        ? Promise.reject(
            new Error('The first or the last character should not be a space!'),
          )
        : Promise.resolve(),
  },
];
