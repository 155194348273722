export const DRAG_AND_DROP_ACCEPTED_TYPES: string[] = [
  'xlsx',
  'xlsm',
  'xlsb',
  'xltx',
  'xltm',
  'xls',
  'xlt',
  'xml',
  'xlam',
  'xla',
  'xlw',
  'xlr',
];
