import React, { Dispatch, FC, PropsWithChildren, useState } from 'react';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { PermittingHttpService, SiteHttpService } from '@core/services/http';
import { toggleModal, transformFields } from '@core/utils/methods';
import {
  CONTACT_FIELDS_TO_TRANSFORM,
  getContactsColumns,
} from '@models/constants';
import { ContactsPlace, ModalMainTypes } from '@models/enums';
import {
  ContactsData,
  ContactsProps,
  JurisdictionDetailsContacts,
} from '@models/interfaces';
import { CustomTable } from '../../components/custom-table';
import { DeleteModal } from '../../components/delete-modal';
import { GoBackButton } from '../../components/go-back-button';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { CONTACTS_DEFAULT_VALUE } from './contact-modal/models/constants';
import { PermittingContactModal } from './contact-modal/permitting-modal';
import { SiteContactModal } from './contact-modal/site-modal';
import { getPermittingContactsColumns } from './helpers';

import './styles.scss';

export const Contacts: FC<ContactsProps> = ({
  data = [],
  className,
  editable = false,
  place,
}: PropsWithChildren<ContactsProps>) => {
  const isSitePlace = place === ContactsPlace.Site;

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isAddEditModalVisible, setAddEditModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );
  const [selectedItem, setSelectedItem] = useState<
    ContactsData | JurisdictionDetailsContacts
  >(CONTACTS_DEFAULT_VALUE);

  const toggleAddAndEditStateModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);

    if (isAddEditModalVisible) {
      setSelectedItem(CONTACTS_DEFAULT_VALUE);
    }
  };

  const selectCurrentContact = (
    currentContact: ContactsData | JurisdictionDetailsContacts,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(currentContact);
    setter(!prevState);
  };

  const toggleAddAndEditModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditModalVisible(!isAddEditModalVisible);
  };

  const contactsColumns = isSitePlace
    ? getContactsColumns(
        (contact: ContactsData): void =>
          selectCurrentContact(
            contact,
            () => toggleAddAndEditModal(ModalMainTypes.Edit),
            isAddEditModalVisible,
          ),
        (contact: ContactsData): void =>
          selectCurrentContact(
            contact,
            setDeleteModalVisible,
            deleteModalVisible,
          ),
        editable,
      )
    : getPermittingContactsColumns(
        (contact: JurisdictionDetailsContacts): void =>
          selectCurrentContact(
            contact,
            () => toggleAddAndEditModal(ModalMainTypes.Edit),
            isAddEditModalVisible,
          ),
        (contact: JurisdictionDetailsContacts): void =>
          selectCurrentContact(
            contact,
            setDeleteModalVisible,
            deleteModalVisible,
          ),
        editable,
      );

  const deleteContact = async (): Promise<void> => {
    isSitePlace
      ? await HttpService.getHttpRequests(SiteHttpService).deleteSiteContact(
          selectedItem.id,
        )
      : await HttpService.getHttpRequests(
          PermittingHttpService,
        ).removeJurisdictionContact(selectedItem.id);

    setDeleteModalVisible(!deleteModalVisible);
    setSelectedItem(CONTACTS_DEFAULT_VALUE);
  };

  const currentContactModal = isSitePlace ? (
    <SiteContactModal
      visible={isAddEditModalVisible}
      modalType={currentModalType}
      toggleModal={toggleAddAndEditStateModal}
      formValue={transformFields(
        selectedItem as ContactsData,
        CONTACT_FIELDS_TO_TRANSFORM,
      )}
    />
  ) : (
    <PermittingContactModal
      visible={isAddEditModalVisible}
      modalType={currentModalType}
      toggleModal={toggleAddAndEditStateModal}
      formValue={selectedItem as JurisdictionDetailsContacts}
    />
  );

  return (
    <div className={classNames(className, 'prov-contacts')}>
      <div className="prov-contacts__form">
        {currentContactModal}
        <CustomTable columns={contactsColumns} dataSource={data} />
        <DeleteModal
          onOk={deleteContact}
          onCancel={toggleModal(setDeleteModalVisible)}
          visible={deleteModalVisible}
        />
        <section className="prov-contacts__actions">
          {editable && (
            <PrimaryButton
              onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
              icon="+"
              title="Add Contact"
            />
          )}
          <GoBackButton title="Back" />
        </section>
      </div>
    </div>
  );
};
