import React, { FC, useState } from 'react';
import { Layout } from 'antd';
import { showIfAuthorized } from '@core/utils/HOC';
import { MenuIcon } from './components/menu-icon';
import { SideMenu } from './components';

const { Sider: AntSider } = Layout;

const SiderComponent: FC = () => {
  const [isOpened, setIsOpened] = useState(false);

  const onMenuChange = () => setIsOpened((opened: boolean) => !opened);

  return (
    <AntSider
      trigger={null}
      collapsible
      collapsed={!isOpened}
      collapsedWidth={72}
      className="prov-layout__sider"
    >
      <MenuIcon isOpened={isOpened} onChange={onMenuChange} />
      <SideMenu />
    </AntSider>
  );
};

export const Sider: FC = showIfAuthorized(SiderComponent);
