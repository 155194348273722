import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { DriverManagementState } from '../../reducers/admin/driver-management.reducer';

const driverManagementState = (state: AppState): DriverManagementState =>
  state.admin.driverManagement;

export namespace DriverManagementSelectors {
  export const getDriversList = createSelector(
    [driverManagementState],
    (state: DriverManagementState) => state.driversList,
  );

  export const getDrivers = createSelector(
    [driverManagementState],
    (state: DriverManagementState) => state.drivers,
  );
}
