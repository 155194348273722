import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { ProjectState } from '../reducers/project.reducer';
import { ProjectCategoriesState } from '../reducers/project-categories.reducer';

const projectState = (state: AppState): ProjectState => state.project;
const projectCategoriesState = (state: AppState): ProjectCategoriesState =>
  state.projectCategories;

export namespace ProjectSelectors {
  export const getProjectBrowseInfo = createSelector(
    [projectState],
    (state: ProjectState) => state.projectBrowse,
  );

  export const getProjectDetailsInfo = createSelector(
    [projectState],
    (state: ProjectState) => state.projectSite,
  );

  export const getProjectCreateProvidenceAgent = createSelector(
    [projectState],
    (state: ProjectState) => state?.providenceAgentIdForCreateProject,
  );

  export const getProjectSummaryData = createSelector(
    [projectState, projectCategoriesState],
    (
      { currentProject, projectPaceTasks, projectSite, notes }: ProjectState,
      {
        currentProjectCategories,
        originalProjectCategories,
        projectCategoriesTableVisible,
      }: ProjectCategoriesState,
    ) => ({
      currentProject,
      projectPaceTasks,
      projectSite,
      notes,
      currentProjectCategories,
      originalProjectCategories,
      projectCategoriesTableVisible,
    }),
  );

  export const getProvidenceAgents = createSelector(
    [projectState],
    (state: ProjectState) => state.providenceAgents,
  );

  export const getProjectType = createSelector(
    [projectState],
    (state: ProjectState) => state.currentProjectType.value,
  );

  export const getProjectId = createSelector(
    [projectState],
    (state: ProjectState) => state.currentProject.id,
  );

  export const getProvidenceAgent = createSelector(
    [projectState],
    (state: ProjectState) => state.currentProvidenceAgent,
  );

  export const getProjectStatus = createSelector(
    [projectState],
    (state: ProjectState) => state.hold.projectStatus,
  );

  export const getHoldOptions = createSelector(
    [projectState],
    (state: ProjectState) => state.hold,
  );

  export const getProjectCategories = createSelector(
    [projectCategoriesState],
    ({
      currentProjectCategories,
      projectCategoriesTableVisible,
    }: ProjectCategoriesState) => ({
      currentProjectCategories,
      projectCategoriesTableVisible,
    }),
  );
}
