import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  AdminCountyData,
  StateWithCounties,
  UpdateCountyResponse,
} from '@models/interfaces';
import { CountyActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

type AdminCountyValidation = {
  countyId: string;
  stateId: string;
};

export class CountyStatesHttpService extends CommonHttpService {
  @StoredRequest(CountyActions.addAdminCountyStateDataAction)
  async addCountyState(name: string): Promise<AdminCountyData> {
    const id = await this.http.callApi.post(`${this.apiUrl}/admin/states`, {
      name: name.trim(),
    });

    return {
      id,
      name: name.trim(),
    };
  }

  @StoredRequest(CountyActions.updateAdminCountyStateDataAction)
  async updateCountyState(
    stateId: string,
    name: string,
  ): Promise<AdminCountyData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/admin/states`,
      { name: name.trim() },
      {
        params: { stateId },
      },
    );

    return { id: stateId, name: name.trim() };
  }

  @StoredRequest(CountyActions.deleteAdminCountyStateDataAction)
  async deleteCountyState(stateId: string): Promise<string> {
    await this.http.callApi.delete(`${this.apiUrl}/admin/states`, null, {
      params: { stateId },
    });

    return stateId;
  }

  getCountyStateValidation(id: string, name: string): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/states/validation`,
      null,
      {
        params: { name: name.trim(), id },
      },
    );
  }

  @StoredRequest(CountyActions.getStatesAction)
  getStates(): Promise<StateWithCounties[]> {
    return this.http.callApi.get(`${this.apiUrl}/admin/states/with-counties`);
  }

  // ADNIN COUNTIES
  @StoredRequest(CountyActions.addAdminCountiesDataAction)
  async addCounty(
    state: StateWithCounties,
    name: string,
  ): Promise<UpdateCountyResponse> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/admin/counties`,
      {
        name: name.trim(),
      },
      {
        params: { stateId: state.id },
      },
    );

    return {
      state,
      county: {
        id,
        name,
      },
    };
  }

  @StoredRequest(CountyActions.updateAdminCountiesDataAction)
  async updateCounty(
    state: StateWithCounties,
    { name, id }: AdminCountyData,
  ): Promise<UpdateCountyResponse> {
    await this.http.callApi.patch(
      `${this.apiUrl}/admin/counties`,
      { name: name.trim() },
      {
        params: { countyId: id },
      },
    );

    return {
      state,
      county: {
        id,
        name,
      },
    };
  }

  @StoredRequest(CountyActions.deleteAdminCountiesDataAction)
  async deleteCounty(countyId: string): Promise<string> {
    await this.http.callApi.delete(`${this.apiUrl}/admin/counties`, null, {
      params: { countyId },
    });

    return countyId;
  }

  getCountyValidation(
    { countyId, stateId }: AdminCountyValidation,
    value: string,
  ): Promise<boolean> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/counties/validation`,
      null,
      {
        params: { name: value.trim(), countyId, stateId },
      },
    );
  }
}
