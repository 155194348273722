import React, { ReactElement, ReactNode } from 'react';

const regExp = '(https?:\\/\\/)?(.+(\\.\\w+))';

export const linkElementRender = (
  value: string = '',
): ReactNode | ReactElement => {
  const link = value?.match(regExp);

  return (
    <a href={value} target="_blank" rel="noopener noreferrer">
      {link ? link[2] : ''}
    </a>
  );
};
