import { ReactText } from 'react';
import { OptionProps } from '@models/interfaces';

export const getCurrentOptions = <TEnum extends Record<string, any>, TValue>(
  someEnum: TEnum,
  cb: (value: TValue) => string,
  isNumericalEnum: boolean = false,
  needTitle: boolean = false,
): OptionProps[] => {
  const options: OptionProps[] = [];
  const enumValues = Object.values(someEnum);

  const values = !isNumericalEnum
    ? enumValues
    : enumValues.filter((v: ReactText) => typeof v === 'number');

  for (const value of values) {
    options.push({
      value,
      viewValue: cb(value),
      ...(needTitle ? { title: value } : {}),
    });
  }

  return options;
};
