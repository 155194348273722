import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { AssociatedProjects } from '@shared/components';
import { SiteSelectors } from '@store/selectors';

export const SiteAssociatedProjects: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { currentSite, associatedProjects } = useSelector(
    SiteSelectors.getSiteInformationTabInfo,
  );

  return (
    <AssociatedProjects
      {...props}
      linkId={currentSite?.FALocationNumber?.toString()}
      data={associatedProjects}
      isSiteOn={currentSite.isSiteOn || false}
    />
  );
};
