import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { SiteAuditHttpService } from '@core/services/http';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';

export class SiteAuditBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(SiteAuditHttpService).getSiteAuditBrowse({
      ...BROWSE_DEFAULT_FILTER_VALUE,
      showCompletedSiteAudits: false,
    });
  }
}
