import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { NotifyType, Nullable, UserRole } from '@symfa-inc/providence-types';
import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useForm } from 'antd/lib/form/util';
import { SelectValue } from 'antd/lib/select';
import Filter from 'assets/icons/filter.png';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { getCurrentOptions, isValidForm } from '@core/utils/methods';
import {
  RESPONSIBLE_FOR_PROJECT_TYPE_OPTIONS,
  USER_ROLE_OPTIONS,
} from '@models/constants';
import { Autocomplete, Modal, PrimaryButton, Select } from '@shared/modules';
import { NotificationSelectors } from '@store/selectors';
import { getCurrentNotifyType } from '../helpers';
import { TowerOwnerFilterProps } from '../models';

interface IDynamicControlsProps {
  notifyType: Nullable<NotifyType>;
  form: FormInstance;
}

const DynamicControls: FC<IDynamicControlsProps> = ({
  notifyType,
  form,
}: IDynamicControlsProps) => {
  const userList = useSelector(NotificationSelectors.getUserList);
  const [isAdminOrEMRole, setRoleChecker] = useState<boolean>(false);

  useDidUpdateEffect(() => {
    if (notifyType === NotifyType.User || !notifyType) {
      setRoleChecker(false);
    }
  }, [notifyType]);

  switch (notifyType) {
    case NotifyType.User:
      return (
        <Select
          id="userId"
          label="User"
          options={userList}
          elementProps={{
            allowClear: true,
          }}
        />
      );

    case NotifyType.Role:
      return (
        <>
          <Autocomplete
            id="role"
            label="Role"
            options={USER_ROLE_OPTIONS}
            elementProps={{
              allowClear: true,
              onChange: (value: SelectValue): void => {
                const hasCurrentRole = [
                  UserRole.Admin,
                  UserRole.EngineeringManager,
                ].includes(value as UserRole);

                setRoleChecker(hasCurrentRole);

                if (!hasCurrentRole) {
                  form.resetFields(['responsibleForProjectType']);
                }
              },
            }}
          />
          {isAdminOrEMRole && (
            <Select
              id="responsibleForProjectType"
              label="Responsible For Project Type"
              options={RESPONSIBLE_FOR_PROJECT_TYPE_OPTIONS}
              elementProps={{
                allowClear: true,
              }}
            />
          )}
        </>
      );
    default:
      return null;
  }
};

export const TriggerFilter: FC<TowerOwnerFilterProps> = ({
  towerOwners,
  place,
}: PropsWithChildren<TowerOwnerFilterProps>) => {
  const notificationsService = HttpService.getHttpRequests(
    NotificationsHttpService,
  );

  const [form] = useForm();

  const notifyTypeOptions = useMemo(
    () => getCurrentOptions(NotifyType, getCurrentNotifyType),
    [],
  );

  const [showModal, setShowModal] = useState<boolean>(false);
  const [notifyType, setNotifyType] = useState<Nullable<NotifyType>>(null);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [canUseApply, setUseApply] = useState<boolean>(false);

  useEffect(
    () => (): void => {
      if (form.isFieldsTouched()) {
        place === 'rules'
          ? notificationsService.getRules()
          : notificationsService.getSchedules();
      }
    },
    [],
  );

  const onReset = (): void => {
    form.resetFields();
    setNotifyType(null);
    setFilterApplied(false);
    setUseApply(false);
  };

  const onClose = (): void => {
    setShowModal(false);

    if (!filterApplied) {
      onReset();
    }
  };

  const applyFilter = async (): Promise<void> => {
    try {
      if (await isValidForm(form)) {
        const formValues = form.getFieldsValue();

        place === 'rules'
          ? await notificationsService.getRules(formValues)
          : await notificationsService.getSchedules(formValues);

        setShowModal(false);
        setUseApply(false);

        const isManualReset = Object.values(formValues).every(v =>
          Array.isArray(v) ? v.length === 0 : v === undefined,
        );

        setFilterApplied(!isManualReset);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const resetFilter = async (): Promise<void> => {
    onReset();

    place === 'rules'
      ? await notificationsService.getRules()
      : await notificationsService.getSchedules();

    setShowModal(false);
  };

  return (
    <>
      <PrimaryButton
        className="configure-btn"
        onClick={(): void => setShowModal(true)}
        icon={<img src={Filter} alt="filter icon" />}
        title="Configure filter"
      />
      <Modal
        title="Filters"
        okText="Apply"
        cancelText="Reset"
        onCancel={onClose}
        onOk={applyFilter}
        visible={showModal}
        // https://stackoverflow.com/questions/61056421/warning-instance-created-by-useform-is-not-connect-to-any-form-element
        forceRender
        okButtonProps={{
          disabled: !canUseApply,
        }}
        cancelButtonProps={{
          onClick: resetFilter,
          disabled: !filterApplied,
        }}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          onFieldsChange={() => {
            if (!canUseApply) {
              setUseApply(true);
            }
          }}
        >
          <Autocomplete
            id="towerOwnerIds"
            label="Tower Owners"
            elementProps={{
              allowClear: true,
              mode: 'multiple',
              virtual: true,
            }}
            options={towerOwners}
          />
          <Select
            id="notifyType"
            label="Notify Type"
            options={notifyTypeOptions}
            elementProps={{
              allowClear: true,
              onChange: (value: SelectValue): void => {
                form.resetFields([
                  'role',
                  'userId',
                  'responsibleForProjectType',
                ]);

                setNotifyType(value as NotifyType);
              },
            }}
          />
          <DynamicControls form={form} notifyType={notifyType} />
        </Form>
      </Modal>
    </>
  );
};
