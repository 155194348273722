import React, { ReactNode } from 'react';
import {
  PaymentType as DataBasePaymentType,
  UserRole,
} from '@symfa-inc/providence-types';
import { ColumnsType } from 'antd/lib/table/interface';
import {
  priceCommaReplacer,
  readOnlyDatePickerRenderer,
  readOnlyPriceRender,
  readOnlySelectRender,
} from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import { PaymentType } from '@models/enums';
import {
  AdminDropdownValuesData,
  Driver,
  FinancialOOMInformation,
  FinancialServicesTableData,
  FinancialSiteFeesData,
} from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';
import store from '@store/index';
import { ServicesColumnsProps, SiteFeesColumnsProps } from '../interfaces';

export const financialServicesColumns = ({
  editFunc,
  deleteFunc,
  feeAccessPath,
  accountingRecord,
  getDeleteFuncAccess,
}: ServicesColumnsProps): ColumnsType => {
  const columns: ColumnsType = [
    {
      title: 'Driver ID',
      dataIndex: 'driver',
      key: 'driverID',
      render: (driver: FinancialServicesTableData): ReactNode =>
        driver?.driverID,
    },
    {
      title: 'Driver Name',
      dataIndex: 'driver',
      key: 'driverName',
      render: (driver: FinancialServicesTableData): ReactNode =>
        driver?.driverName,
    },
    {
      title: 'Driver Notes',
      dataIndex: 'driver',
      key: 'driverNote',
      render: (driver: FinancialServicesTableData): ReactNode =>
        driver?.driverNote,
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      render: readOnlyPriceRender,
    },
    {
      title: 'Request Notes',
      dataIndex: 'requestNote',
      key: 'requestNote',
    },
  ];

  if (accountingRecord !== null && feeAccessPath) {
    columns.unshift(
      {
        title: 'Date Issued',
        key: 'issuedDate',
        dataIndex: 'issuedDate',
        render: readOnlyDatePickerRenderer,
      },
      {
        title: 'PO Number',
        dataIndex: 'PONumber',
        key: 'PONumber',
      },
    );
  }

  if (accountingRecord) {
    columns.push({
      title: 'Included In Bundle Pricing',
      key: 'isIncludedInBundlePricing',
      dataIndex: 'driver',
      render: (driver: Driver): ReactNode =>
        driver.isIncludedInBundlePricing ? 'Yes' : 'No',
    });
  }

  columns.push(
    actionButtons({
      editFunc,
      deleteFunc,
      width: 240,
      getDeleteFuncAccess,
      getEditFuncAccess: !feeAccessPath ? getDeleteFuncAccess : undefined,
    }),
  );

  return columns;
};

export const getSiteFeesColumns = ({
  exportFunc,
  editFunc,
  deleteFunc,
  getDeleteFuncAccess,
  feeAccessPath,
  accountingRecord,
}: SiteFeesColumnsProps): Array<VirtualTableColumn<FinancialSiteFeesData>> => {
  const {
    admin: {
      dropdownValues: { dropdownValues, dropdownsOptions },
    },
  } = store.getState();

  const columns = [
    {
      title: 'Date',
      key: 'paymentDate',
      dataIndex: 'paymentDate',
      width: 150,
      render: readOnlyDatePickerRenderer,
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: readOnlyPriceRender,
    },
    {
      title: 'Type',
      key: 'siteFeeType',
      render: (siteFeeItem: FinancialSiteFeesData): ReactNode =>
        readOnlySelectRender(
          dropdownsOptions.SiteFeeType,
          dropdownValues.SiteFeeType.find(
            (dropDownSiteFeeType: AdminDropdownValuesData) =>
              dropDownSiteFeeType.id === siteFeeItem?.siteFeeType,
          )?.id,
        ),
    },
    {
      title: 'Payment Type',
      key: 'paymentType',
      render: (siteFeeItem: FinancialSiteFeesData): ReactNode => {
        const typeKey = Object.keys(DataBasePaymentType).find(
          (objKey: string) =>
            DataBasePaymentType[objKey as keyof typeof DataBasePaymentType] ===
            siteFeeItem.paymentType,
        );

        return PaymentType[typeKey as keyof typeof PaymentType];
      },
    },
    {
      title: 'Check Number',
      dataIndex: 'checkNumber',
      key: 'checkNumber',
    },
    {
      title: 'Credit Card',
      key: 'creditCard',
      render: (siteFeeItem: FinancialSiteFeesData): ReactNode =>
        readOnlySelectRender(
          dropdownsOptions.CreditType,
          dropdownValues.CreditType.find(
            (dropDownSiteFeeType: AdminDropdownValuesData) =>
              dropDownSiteFeeType.id === siteFeeItem?.creditCard,
          )?.id,
        ),
    },
    {
      title: 'PO Number',
      dataIndex: 'PONumber',
      key: 'PONumber',
    },
    {
      title: 'Notes',
      dataIndex: 'siteFeesNote',
      key: 'siteFeesNote',
      render: (value: string): ReactNode => (
        <span className="ellipsis-3">{value}</span>
      ),
    },
    actionButtons({
      editFunc,
      deleteFunc,
      exportFunc,
      width: 240,
      getDeleteFuncAccess,
    }) as VirtualTableColumn,
  ];

  if (accountingRecord !== null && feeAccessPath) {
    columns.unshift({
      title: 'Date Issued',
      key: 'issuedDate',
      dataIndex: 'issuedDate',
      render: readOnlyDatePickerRenderer,
    });
  }

  return columns;
};

export const OOMInformationColumns = (
  roles: UserRole[],
  editFunc: ColumnsFunc<FinancialOOMInformation>,
  deleteFunc: ColumnsFunc<FinancialOOMInformation>,
): ColumnsType => [
  {
    title: 'PACE Task ID',
    key: 'PACETaskID',
    dataIndex: 'paceTaskID',
  },
  {
    title: 'PACE Task Name',
    key: 'PACETaskName',
    dataIndex: 'taskName',
  },
  {
    title: 'Actual Date',
    key: 'actualDate',
    dataIndex: 'actualDate',
    render: readOnlyDatePickerRenderer,
  },
  {
    title: 'OOM Amount',
    key: 'OOMAmount',
    dataIndex: 'OOMAmount',
    render: (value: number): ReactNode => priceCommaReplacer(`$ ${value}`),
  },
  {
    title: 'OOM Date',
    key: 'OOMDate',
    dataIndex: 'OOMDate',
    render: readOnlyDatePickerRenderer,
  },
  {
    title: 'OOM Total Amount',
    key: 'OOMTotalAmount',
    dataIndex: 'OOMTotalAmount',
    render: (value: number): ReactNode => priceCommaReplacer(`$ ${value}`),
  },
  actionButtons({
    editFunc,
    deleteFunc,
    isEditing: roles.includes(UserRole.Admin),
  }),
];
