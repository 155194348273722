import React, {
  Dispatch,
  FC,
  Key,
  memo,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import classNames from 'classnames';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { arraySort, toggleModal } from '@core/utils/methods';
import { EQUIPMENT_DEFAULT_VALUE } from '@models/constants';
import { CustomRow, ModalMainTypes } from '@models/enums';
import {
  EquipmentFullInfo,
  EquipmentModal as IEquipmentModal,
} from '@models/interfaces';
import {
  CustomTable,
  DeleteModal,
  EquipmentModal,
  Panel,
} from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { LeasingActions } from '@store/actions';
import { LeasingSelectors } from '@store/selectors';
import {
  getLeasingEquipmentSectionColumns,
  LeasingEquipmentSectionProps,
} from './models';

export const LeasingEquipmentSection: FC<LeasingEquipmentSectionProps> = memo(
  ({
    header,
    className,
    isEditing = true,
    data,
    sectionStorePath,
    tableProps,
  }: PropsWithChildren<LeasingEquipmentSectionProps>) => {
    const dispatch = useDispatch();

    const {
      allUsedEquipments,
      curPriorTableData,
      scopingTableData,
      curFinalTableData,
    } = useSelector(LeasingSelectors.getLeasingEquipmentSectionData);

    const [equipmentItem, setEquipmentItem] = useState<IEquipmentModal>(
      EQUIPMENT_DEFAULT_VALUE,
    );

    const [dataState, setDataState] = useState<IEquipmentModal[]>([]);
    const [allEquipments, setAllEquipments] = useState<EquipmentFullInfo[]>([]);
    const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
      ModalMainTypes.Add,
    );
    const [isAddEditModalVisible, setAddEditModalVisible] =
      useState<boolean>(false);
    const [selectedEquipment, setSelectedEquipment] = useState<IEquipmentModal>(
      EQUIPMENT_DEFAULT_VALUE,
    );
    const [deleteEquipmentModalVisible, setDeleteEquipmentModalVisible] =
      useState<boolean>(false);

    useEffect(() => setAllEquipments(allUsedEquipments), [allUsedEquipments]);

    useEffect(() => setDataState(data), [data]);

    useDidUpdateEffect(() => {
      const newDataState =
        currentModalType === ModalMainTypes.Add
          ? [...dataState, equipmentItem]
          : dataState.map((d: IEquipmentModal) =>
              equipmentItem.generatedId === d.generatedId
                ? { ...d, ...equipmentItem }
                : d,
            );

      dispatch(
        LeasingActions[
          currentModalType === ModalMainTypes.Add
            ? 'addTableItemAction'
            : 'editTableItemAction'
        ].done({
          data: equipmentItem,
          key: sectionStorePath,
        }),
      );

      setDataState(newDataState);
    }, [equipmentItem]);

    const selectEquipment = (
      { equipment, equipmentType, generatedId, quantity, RAD }: IEquipmentModal,
      setter: Dispatch<SetStateAction<boolean>>,
    ): void => {
      const manufacturer = allUsedEquipments.find(
        (e: EquipmentFullInfo) => e.id === equipment,
      )?.manufacturer.id;

      setSelectedEquipment({
        equipment,
        equipmentType,
        generatedId,
        manufacturer,
        quantity,
        RAD,
      });

      setter((prevState: boolean): boolean => !prevState);
    };

    const toggleAddAndEditModal = (type: ModalMainTypes): void => {
      setCurrentModalType(type);
      setAddEditModalVisible(!isAddEditModalVisible);

      if (isAddEditModalVisible) {
        setSelectedEquipment(EQUIPMENT_DEFAULT_VALUE);
      }
    };

    const onDeleteEquipment = async (): Promise<void> => {
      dispatch(
        LeasingActions.deleteTableItemAction.done({
          data: selectedEquipment,
          key: sectionStorePath,
        }),
      );

      setDeleteEquipmentModalVisible(false);
      setSelectedEquipment(EQUIPMENT_DEFAULT_VALUE);
    };

    const compareTables = (): string[] => {
      const compareArrays = [
        curPriorTableData,
        scopingTableData,
        curFinalTableData,
      ].filter((arr: IEquipmentModal[]) => arr !== dataState);

      return dataState?.map((equipmentData: IEquipmentModal) => {
        if (equipmentData?.isLeaseRight) {
          return CustomRow.Orange;
        }

        const [res1, res2] = compareArrays.map((arr: IEquipmentModal[]) =>
          arr.find(
            (compareItem: IEquipmentModal) =>
              compareItem.equipment === equipmentData.equipment,
          ),
        );

        return res1 &&
          res2 &&
          equipmentData.quantity === res1.quantity &&
          equipmentData.quantity === res2.quantity
          ? CustomRow.Secondary
          : CustomRow.Red;
      });
    };

    const resOfCompare = compareTables();
    const isScopingTable = header.includes('Scoping');

    return (
      <Panel
        header={header}
        collapseClassName={classNames(
          className,
          'prov-leasing-equipment-section',
        )}
      >
        <CustomTable
          className="prov-leasing-equipment-section__table"
          dataSource={dataState}
          pagination={false}
          onChange={(
            _tableInfo: TablePaginationConfig,
            _filters: Record<string, Key[] | null>,
            sorter:
              | SorterResult<IEquipmentModal>
              | SorterResult<IEquipmentModal>[],
          ): void =>
            setDataState(
              arraySort<IEquipmentModal>(
                [...dataState],
                (sorter as SorterResult<IEquipmentModal>).order === 'ascend'
                  ? 'ASC'
                  : 'DESC',
                (sorter as SorterResult<IEquipmentModal>)
                  .columnKey as keyof IEquipmentModal,
              ),
            )
          }
          columns={getLeasingEquipmentSectionColumns({
            editFunc: (equipment: IEquipmentModal): void =>
              selectEquipment(equipment, () =>
                toggleAddAndEditModal(ModalMainTypes.Edit),
              ),
            deleteFunc: (equipment: IEquipmentModal): void =>
              selectEquipment(equipment, () =>
                setDeleteEquipmentModalVisible(!deleteEquipmentModalVisible),
              ),
            isEditing,
            allEquipments,
            rowColors: isScopingTable ? [] : resOfCompare,
            isScopingTable,
          })}
          {...tableProps}
        />
        <EquipmentModal
          visible={isAddEditModalVisible}
          modalType={currentModalType}
          toggleModal={toggleAddAndEditModal}
          itemValue={selectedEquipment}
          setEquipmentItem={setEquipmentItem}
        />
        <DeleteModal
          onOk={onDeleteEquipment}
          onCancel={toggleModal(setDeleteEquipmentModalVisible)}
          visible={deleteEquipmentModalVisible}
        />
        {isEditing ? (
          <PrimaryButton
            icon="+"
            title="Add"
            className="prov-leasing-equipment-section__add-button"
            onClick={(): void => toggleAddAndEditModal(ModalMainTypes.Add)}
          />
        ) : null}
      </Panel>
    );
  },
);

LeasingEquipmentSection.displayName = 'LeasingEquipmentSection';
