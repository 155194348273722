import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/es/select';
import classNames from 'classnames';
import { SCOPING_SECTORS_VERSIONS_DATA } from '@models/constants';
import { SectorEquipmentType } from '@models/enums';
import { EditableComponentProps, InformationProps } from '@models/interfaces';
import { ScopingSectorsVersionData, SectorDataState } from '@models/types';
import { Select } from '@shared/modules';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { SECTOR_TYPES } from './models/constants';
import { SectorEquipment, SectorSetupModal } from './components';

import './styles.scss';

type SectorsOfEquipmentProps = InformationProps &
  EditableComponentProps & {
    sectorsDataVersions: ScopingSectorsVersionData[];
    setSectorsDataVersions: Dispatch<
      SetStateAction<ScopingSectorsVersionData[]>
    >;
  };

export const SectorsOfEquipment: FC<SectorsOfEquipmentProps> = ({
  className,
  isEditing,
  sectorsDataVersions,
  setSectorsDataVersions,
}: PropsWithChildren<SectorsOfEquipmentProps>) => {
  const [versionForm] = Form.useForm();

  const selectedVersion = useMemo(
    () =>
      sectorsDataVersions.find(
        sectorsDataVersion => sectorsDataVersion.isSelected,
      )!,
    [sectorsDataVersions],
  );

  const currentVersion = useMemo(
    () =>
      sectorsDataVersions.find(
        sectorsDataVersion => sectorsDataVersion.isCurrent,
      )!,
    [sectorsDataVersions],
  );

  useEffect(() => {
    versionForm.setFieldsValue({ version: selectedVersion.version });
  }, [selectedVersion, versionForm]);

  const versionOptions = useMemo(
    () =>
      sectorsDataVersions.map(sectorsDataVersion => ({
        value: sectorsDataVersion.version,
        viewValue: sectorsDataVersion.isCurrent
          ? `${sectorsDataVersion.version} (Current)`
          : sectorsDataVersion.version,
      }))!,
    [sectorsDataVersions],
  );

  const currentSectors = useMemo(
    () => Object.keys(selectedVersion.scopingSectors) as SectorEquipmentType[],
    [selectedVersion],
  );

  const [isSectorSetupOpen, setSectorSetup] = useState(false);

  const sectorsForDuplicate = SECTOR_TYPES.filter(
    (type: SectorEquipmentType) => !currentSectors.includes(type),
  );

  const handleSectorSetupModal = (): void => setSectorSetup(!isSectorSetupOpen);
  const onSave = (sector: SectorEquipmentType): void => {
    setSectorsDataVersions(prevState =>
      prevState.map(prevVersion => {
        const { scopingSectors } = prevVersion;

        return prevVersion.isSelected
          ? {
              ...prevVersion,
              scopingSectors: {
                ...scopingSectors,
                [sector]: [],
              },
            }
          : prevVersion;
      }),
    );
    handleSectorSetupModal();
  };
  const deleteSector = (sector: SectorEquipmentType): void => {
    setSectorsDataVersions((prevState: ScopingSectorsVersionData[]) =>
      prevState.map(
        ({ scopingSectors, ...version }: ScopingSectorsVersionData) => ({
          ...version,
          scopingSectors: version.isSelected
            ? (Object.keys(scopingSectors) as SectorEquipmentType[]).reduce(
                (acc: SectorDataState, key: SectorEquipmentType) => {
                  if (key !== sector) {
                    acc[key] = scopingSectors[key];
                  }

                  return acc;
                },
                {} as SectorDataState,
              )
            : scopingSectors,
        }),
      ),
    );
  };

  const onAddVersion = () => {
    setSectorsDataVersions(prevState => {
      const [defaultVersion] = SCOPING_SECTORS_VERSIONS_DATA;
      const version = { ...defaultVersion };

      version.isSelected = true;
      version.isCurrent = false;
      version.version = prevState[prevState.length - 1].version + 1;

      return [
        ...prevState.map(prevVersion => ({
          ...prevVersion,
          isSelected: false,
        })),
        version,
      ];
    });
  };

  const onChangeVersion = (version: SelectValue) => {
    setSectorsDataVersions(prevState =>
      prevState.map(prevVersion => {
        return {
          ...prevVersion,
          isSelected: prevVersion.version === version,
        };
      }),
    );
  };

  const onSetAsCurrentVersion = () => {
    setSectorsDataVersions(prevState =>
      prevState.map(prevVersion => ({
        ...prevVersion,
        isCurrent: prevVersion.isSelected,
      })),
    );
  };

  const onDuplicateSelectedVersion = () => {
    setSectorsDataVersions(prevState => {
      const duplicatedVersion = { ...selectedVersion };

      duplicatedVersion.isCurrent = false;
      delete duplicatedVersion.id;
      duplicatedVersion.version = prevState[prevState.length - 1].version + 1;

      return [
        ...prevState.map(prevVersion => ({
          ...prevVersion,
          scopingSectors: (
            Object.keys(prevVersion.scopingSectors) as SectorEquipmentType[]
          ).reduce(
            (acc, key: SectorEquipmentType) => ({
              ...acc,
              // remove id from equipments for avoid duplicates during saving
              [key]: prevVersion.scopingSectors[key]!.map(
                ({ id, equipments, ...rest }) => ({
                  equipments: equipments.map(
                    ({ id: removedId, ...equipment }) => equipment,
                  ),
                  ...rest,
                }),
              ),
            }),
            {} as SectorDataState,
          ),
          isSelected: false,
        })),
        duplicatedVersion,
      ];
    });
  };

  const filteredSelectors = useMemo(
    () =>
      currentSectors.map((sector: SectorEquipmentType) => (
        <SectorEquipment
          type={sector}
          key={sector}
          className={className}
          isEditing={isEditing}
          sectorsForDuplicate={sectorsForDuplicate}
          deleteSector={deleteSector}
          data={selectedVersion.scopingSectors}
          setSectorsDataVersions={setSectorsDataVersions}
        />
      )),
    [currentSectors, isEditing, sectorsDataVersions],
  );

  return (
    <div className={classNames('prov-equipment-sectors', className)}>
      <Row className="prov-equipment-sectors__version" justify="space-between">
        <Col span={6}>
          <Form
            initialValues={{
              version: selectedVersion.version,
            }}
            form={versionForm}
          >
            <Select
              id="version"
              label="Version"
              options={versionOptions}
              elementProps={{ onChange: onChangeVersion }}
              isEditing={isEditing}
            />
          </Form>
        </Col>
        <Col span={12}>
          <PrimaryButton
            title="Set as current"
            disabled={!isEditing || currentVersion === selectedVersion}
            onClick={onSetAsCurrentVersion}
          />
          <PrimaryButton
            title="Add version"
            onClick={onAddVersion}
            disabled={!isEditing}
          />
          <PrimaryButton
            title="Dublicate selected version"
            onClick={onDuplicateSelectedVersion}
            disabled={!isEditing}
          />
        </Col>
      </Row>
      {filteredSelectors}
      <Row>
        {isEditing ? (
          <PrimaryButton
            title="Add Sector"
            icon="+"
            onClick={handleSectorSetupModal}
            disabled={!sectorsForDuplicate.length}
          />
        ) : null}
        <SectorSetupModal
          sectorsForCreate={sectorsForDuplicate}
          className="sector-setup__modal"
          visible={isSectorSetupOpen}
          onCancel={handleSectorSetupModal}
          onOk={onSave}
        />
      </Row>
    </div>
  );
};
