import React, { FC, PropsWithChildren } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { DropDownSelectorValues } from '@symfa-inc/providence-types';
import { Dropdown, Menu } from 'antd';
import { getDDVByIdOrUndefined } from '@core/utils/methods';
import {
  TableContainer as ContentContainer,
  ViewElement,
} from '@shared/components';
import { getDateStringOrEmptyString } from '../../helpers';
import { LeasingAndEngineeringInformationProps } from '../../models';

export const LeasingAndEngineeringInformation: FC<
  LeasingAndEngineeringInformationProps
> = ({
  projectBundleID,
  projectCivilVendor,
  zip,
  scoping,
  leasing,
  paceTasks,
}: PropsWithChildren<LeasingAndEngineeringInformationProps>) => {
  const menu = (
    <Menu>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/leasing/${projectBundleID}/details`}
        >
          View Leasing Details
        </a>
      </Menu.Item>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/engineering/${projectBundleID}/details`}
        >
          View Engineering Details
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <ContentContainer header="Leasing & Engineering Information">
      <div className="information">
        <ViewElement
          additionClassName="left"
          label="SOF Issued Date"
          value={getDateStringOrEmptyString(paceTasks?.SOFIssued)}
        />
        <ViewElement
          additionClassName="right"
          label="Structural Ordered"
          value={getDateStringOrEmptyString(paceTasks?.structuralOrdered)}
        />
        <ViewElement
          additionClassName="left"
          label="Civil Vendor"
          value={
            getDDVByIdOrUndefined(
              DropDownSelectorValues.CivilVendor,
              projectCivilVendor,
            )?.value
          }
        />
        <ViewElement
          additionClassName="right"
          label="Structural Comments"
          value={leasing?.structural?.structuralComment}
        />
        <ViewElement
          additionClassName="left"
          label="Scoping Tower Notes"
          value={scoping?.scopingTowerNote}
        />
        <ViewElement
          additionClassName="right"
          label="Passing Structural Received"
          value={getDateStringOrEmptyString(
            paceTasks?.passingStructuralReceived,
          )}
        />
        <ViewElement
          additionClassName="left"
          label="Scoping Ground Notes"
          value={scoping?.scopingGroundNote}
        />
        <ViewElement additionClassName="right" label="Zip" value={zip} />
        <ViewElement
          additionClassName="left"
          label="Prelim CDs Received"
          value={getDateStringOrEmptyString(paceTasks?.prelimCDsReceived)}
        />
      </div>
      <Dropdown
        className="drop-down"
        openClassName="active"
        overlayClassName="leasing-and-engineering-drop-down-menu"
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
      >
        <div className="drop-down__context">
          <span className="text">View Details</span>
          <MoreOutlined className="icon" />
        </div>
      </Dropdown>
    </ContentContainer>
  );
};
