import { ElementType } from 'react';
import { Undefinable } from '@symfa-inc/providence-types';
import { RouteProps } from './models';
import { RouteGroup } from './route-group';
import { Tab } from './tab';

export class Route extends RouteGroup {
  #tabs: Tab[] = [];
  #exact?: boolean;
  readonly #component: ElementType;
  readonly #extendPath: boolean;

  constructor({ component, extendPath = true, ...props }: RouteProps) {
    super({ ...props });
    this.#component = component;
    this.#extendPath = extendPath;
  }

  // GETTERS & SETTERS
  get exact(): Undefinable<boolean> {
    return this.#exact;
  }

  get tabs(): Tab[] {
    return this.#tabs;
  }

  get extendPath(): boolean {
    return this.#extendPath;
  }

  get component(): ElementType {
    return this.#component;
  }

  // METHODS
  setExact(exact: boolean): this {
    this.#exact = exact;

    return this;
  }

  addTab(tab: Tab): this {
    this.#tabs.push(tab);

    return this;
  }

  addTabs(tabs: Tab[]): this {
    tabs.forEach((tab: Tab) => this.addTab(tab));

    return this;
  }

  protected changeTab(tab: Tab, path: string): void {
    tab.addBreadcrumbs(this.breadcrumbs);
    tab.setPath(RouteGroup.fixPath(`${path}/${tab.path}`));

    tab.tabs.forEach((childTab: Tab) => this.changeTab(childTab, tab.path));
  }

  protected changeRoute(route: RouteGroup): void {
    const path =
      route instanceof Route && route.extendPath ? this.path : this.group?.path;

    if (path) {
      route.setPath(RouteGroup.fixPath(`${path}/${route.path}`));
    }

    route.addResolvers(this.onlyInheritanceResolvers);

    route.addBreadcrumbs(this.breadcrumbs);

    if (this.guards.length) {
      route.addGuards(this.guards, this.redirectURL!);
    }

    route.setGroup(this.group!);
  }

  findRouteByPath(path: string): Undefinable<Route> {
    const route = super.findRouteByPath(path);

    if (route && route instanceof Route) {
      return route;
    }

    const tab = this.#tabs.find((t: Tab) => t.findRouteByPath(path));

    if (tab) {
      return tab.isLink ? tab : this;
    }
  }

  formatRoutes(): this {
    super.formatRoutes();

    this.#tabs.forEach((tab: Tab) => {
      this.changeRoute(tab);
      tab.formatRoutes();
    });

    return this;
  }
}
