import { actionCreatorFactory } from '@core/utils/factories';
import { AdminDriverManagementData, Driver } from '@models/interfaces';

const actionCreator = actionCreatorFactory('DRIVER_MANAGEMENT');

export namespace DriverManagementActions {
  export const getDriverManagementDataAction = actionCreator.createAsyncAction<
    AdminDriverManagementData[]
  >('GET_DRIVER_MANAGEMENT_DATA');

  export const addDriverAction =
    actionCreator.createAsyncAction<AdminDriverManagementData>('ADD_DRIVER');

  export const updateDriverAction =
    actionCreator.createAsyncAction<AdminDriverManagementData>('UPDATE_DRIVER');

  export const deleteDriverAction =
    actionCreator.createAsyncAction<string>('DELETE_DRIVER');

  export const getDriversListByWorkflowAction = actionCreator.createAsyncAction<
    Driver[]
  >('GET_DRIVERS_LIST_BY_WORKFLOW');
}
