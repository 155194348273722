import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { AdminProjectCategoryData } from '@models/interfaces';
import { IdResponse } from '@models/types';
import { ProjectCategoryActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class ProjectCategoryHttpService extends CommonHttpService {
  @StoredRequest(ProjectCategoryActions.getProjectCategoryDataAction)
  getProjectCategories(): Promise<AdminProjectCategoryData[]> {
    return this.http.callApi.get(`${this.apiUrl}/admin/project-categories`);
  }

  @StoredRequest(ProjectCategoryActions.addProjectCategoryAction)
  async addProjectCategoryData(
    body: AdminProjectCategoryData,
  ): Promise<AdminProjectCategoryData> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/admin/project-categories`,
      body,
    );

    return {
      id,
      value: body.value,
      isCanBeDuplicated: body.isCanBeDuplicated,
    };
  }

  @StoredRequest(ProjectCategoryActions.updateProjectCategoryAction)
  async updateProjectCategoryData({
    id,
    isCanBeDuplicated,
    value,
  }: AdminProjectCategoryData): Promise<AdminProjectCategoryData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/admin/project-categories`,
      { isCanBeDuplicated, value },
      {
        params: { projectCategoryId: id },
      },
    );

    return {
      id,
      value,
      isCanBeDuplicated,
    };
  }

  @StoredRequest(ProjectCategoryActions.deleteProjectCategoryAction)
  async deleteProjectCategory(projectCategoryId: string): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/admin/project-categories`,
      null,
      {
        params: { projectCategoryId },
      },
    );

    return projectCategoryId;
  }

  getProjectCategoryValidation(id: string, value: string): Promise<IdResponse> {
    return this.http.callApi.get(
      `${this.apiUrl}/admin/project-categories/validation`,
      null,
      {
        params: { id, value },
      },
    );
  }
}
