import React, { ReactNode } from 'react';
import {
  IDirectMessageReceivers,
  ISentDirectMessage,
} from '@symfa-inc/providence-types';
import { ColumnsType } from 'antd/es/table';
import { findFromSelectorOptions, tableSorter } from '@core/utils/methods';
import { actionButtons } from '@models/constants';

const accessCallback = (directMessage: ISentDirectMessage): boolean =>
  !directMessage.isCompleted;

export const DIRECT_MESSAGE_MANAGER_COLUMNS = (
  usersList: IDirectMessageReceivers[],
  editFn: (item: ISentDirectMessage) => void,
  removeFn: (item: ISentDirectMessage) => void,
): ColumnsType<ISentDirectMessage> => [
  {
    key: 'isCompleted',
    dataIndex: 'isCompleted',
    width: 45,
    className: 'empty-header',
    defaultSortOrder: 'ascend',
    sorter: tableSorter(['isCompleted']),
    render: (isCompleted: boolean): ReactNode => (
      <i className={`circle circle_${isCompleted ? 'gray' : 'green'}`} />
    ),
  },
  {
    title: 'Receivers',
    dataIndex: 'receivers',
    key: 'receivers',
    width: 250,
    render: (receivers: string[]): ReactNode => (
      <div className="list">
        {receivers.map((receiver: string) => (
          <span className="list__item" key={receiver}>
            {findFromSelectorOptions(receiver, usersList)}
          </span>
        ))}
      </div>
    ),
  },
  {
    title: 'Message',
    key: 'message',
    dataIndex: 'message',
    className: 'overflow',
  },
  actionButtons({
    editFunc: editFn,
    deleteFunc: removeFn,
    width: 140,
    getEditFuncAccess: accessCallback,
    getDeleteFuncAccess: accessCallback,
  }),
];
