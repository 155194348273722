import React, { FC, memo, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Route } from '@models/classes';
import { BreadcrumbItemProps } from './models/interfaces';

const BreadcrumbItemComponent: FC<BreadcrumbItemProps> = ({
  path,
  item,
  isLastItem,
  name,
}: PropsWithChildren<BreadcrumbItemProps>) => (
  <>
    {item instanceof Route && !isLastItem ? (
      <Link to={path} className="prov-breadcrumb-menu__link">
        {name}
      </Link>
    ) : (
      <span className="prov-breadcrumb-menu__link">{name}</span>
    )}
  </>
);

export const BreadcrumbItem = memo(BreadcrumbItemComponent);
