import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { ALLOWED_SYMBOLS_PATTERN } from '@equipment-tabs/models';
import { Form } from 'antd';
import { HttpService } from '@core/services';
import { EquipmentHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { EquipmentManufacturerData } from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { Input } from '@shared/modules';
import { EQUIPMENT_MANUFACTURER_DEFAULT_VALUE } from '../../models';
import { EquipmentManufacturerModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const EquipmentManufacturerModal: FC<
  EquipmentManufacturerModalProps<EquipmentManufacturerData>
> = ({
  value = EQUIPMENT_MANUFACTURER_DEFAULT_VALUE,
  visible,
  modalType,
  toggleModal,
  selectorValue,
}: PropsWithChildren<
  EquipmentManufacturerModalProps<EquipmentManufacturerData>
>) => {
  const [form] = useForm();

  const equipmentService = useMemo(
    () => HttpService.getHttpRequests(EquipmentHttpService),
    [],
  );

  const getEquipmentManufacturersData = (): EquipmentManufacturerData => ({
    ...value,
    ...form.getFieldsValue(),
  });

  const add = async ({ name }: EquipmentManufacturerData): Promise<void> => {
    if (await isValidForm(form)) {
      await equipmentService.addEquipmentManufacturer(
        selectorValue as string,
        name,
      );
      toggleModal(modalType);
    }
  };

  const edit = async (
    equipmentManufacturer: EquipmentManufacturerData,
  ): Promise<void> => {
    if (await isValidForm(form)) {
      await equipmentService.editEquipmentManufacturer(
        equipmentManufacturer,
        selectorValue as string,
      );
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(value);
    }
  }, [visible, value, form]);

  return (
    <AddOrEditModal
      visible={visible}
      type={modalType}
      okText="Save"
      cancelText="Cancel"
      className="prov-equipment-manufacturer-modal"
      formProps={{
        labelCol: { span: 5 },
        wrapperCol: { span: 15 },
        form,
        initialValues: value,
      }}
      title={`${modalType === ModalMainTypes.Add ? 'New' : ''} Item`}
      onCancel={(): void => toggleModal(modalType)}
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getEquipmentManufacturersData())
          : (): Promise<void> => edit(getEquipmentManufacturersData())
      }
    >
      <Input
        id="name"
        label="Manufacturer"
        elementProps={{
          autoFocus: true,
        }}
        formItemProps={{
          rules: [
            serverValidator(
              {
                method: 'getEquipmentManufacturerValidation',
                errorMessage: 'Manufacturer must be unique',
              },
              EquipmentHttpService,
              {
                equipmentManufacturerId: getEquipmentManufacturersData().id,
                equipmentTypeId: selectorValue,
              },
            ),
            { required: true, message: 'Manufacturer is required!' },
            { min: 2, message: 'Manufacturer must be at least 2 characters' },
            ALLOWED_SYMBOLS_PATTERN,
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
    </AddOrEditModal>
  );
};
