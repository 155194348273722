import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { FilterOperator, Undefinable } from '@symfa-inc/providence-types';
import { SelectValue } from 'antd/lib/select';
import { arraySort, getCurrentOptions } from '@core/utils/methods';
import { FiltersSetDataFilter } from '@models/interfaces';
import { FormInstanceExtender } from '@models/types';
import { DatePicker, RangePicker } from '@shared/modules';
import { ItemWithOperator } from '../item-with-operator';
import { ComponentWithOperatorProps, getFilterOperator } from '../models';

type DateWithOperatorProps = ComponentWithOperatorProps &
  FiltersSetDataFilter &
  FormInstanceExtender;

export const DateWithOperator: FC<DateWithOperatorProps> = ({
  value,
  form,
  ...props
}: PropsWithChildren<DateWithOperatorProps>) => {
  const operator = form?.getFieldsValue()[props.id]?.operator ?? value.operator;

  const [operatorValue, setOperatorValue] = useState<Undefinable<SelectValue>>(
    value.operator,
  );

  const onChange = (v: SelectValue): void => {
    if (
      (operatorValue !== FilterOperator.Between &&
        v === FilterOperator.Between) ||
      (operatorValue === FilterOperator.Between && v !== FilterOperator.Between)
    ) {
      form?.resetFields([[props.id, 'value']]);
    }

    setOperatorValue(v);
  };

  useEffect(() => {
    setOperatorValue(value.operator);
  }, [value]);

  return (
    <ItemWithOperator
      component={operator !== FilterOperator.Between ? DatePicker : RangePicker}
      className="prov-date-with-operator"
      selectProps={{
        options: arraySort(
          getCurrentOptions(FilterOperator, getFilterOperator, false, true),
          'ASC',
          'viewValue',
        ),
        label: '',
        elementProps: {
          onChange,
        },
      }}
      {...props}
    />
  );
};
