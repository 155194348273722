import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { CommonActions } from '@store/actions';

// TODO: потому что роутер у нас - гавно.
export const withDispatchRouter =
  <P extends Record<string, unknown> & RouteComponentProps>(
    Component: FC<P>,
  ): FC<P> =>
  (props: PropsWithChildren<P>) => {
    const dispatch = useDispatch();

    const {
      location: { pathname },
    } = props;

    useEffect(() => {
      dispatch(CommonActions.setRoutePath.done(pathname));

      return () => {
        dispatch(CommonActions.setRoutePath.done(''));
      };
    }, [pathname]);

    return <Component {...props} />;
  };
