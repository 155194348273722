import { AdminDriverManagementData } from '@models/interfaces';

export const DRIVER_MANAGEMENT_DEFAULT_VALUE: AdminDriverManagementData = {
  id: '',
  driverID: '',
  driverName: '',
  driverNote: '',
  workflows: [],
  isIncludedInBundlePricing: false,
};
