import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useDidUpdateEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
): void => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      effect();

      return;
    }

    didMountRef.current = true;
    // eslint-disable-next-line
  }, deps);
};
