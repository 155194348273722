import { RuleObject } from 'rc-field-form/lib/interface';

export const uniqueValidator = <T>(
  arr: T[],
  fieldName: keyof T,
  errorFiledName: string | keyof T = fieldName,
): RuleObject => ({
  validator: async (rules: RuleObject, value: any): Promise<void> => {
    const condition: boolean =
      value && !!arr.find((item: T): boolean => item[fieldName] === value);

    if (condition) {
      throw new Error(`${errorFiledName as string} must be unique`);
    }
  },
});
