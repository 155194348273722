import React, { forwardRef, PropsWithChildren, Ref } from 'react';
import { Switch, Tooltip } from 'antd';
import classNames from 'classnames';
import { SwitchBoxProps } from './models/interfaces';

import './styles.scss';

export const SwitchBox = forwardRef(
  (
    { title, tooltip, className, ...props }: PropsWithChildren<SwitchBoxProps>,
    ref: Ref<HTMLElement>,
  ) => (
    <div className="prov-switch-box">
      <Tooltip title={tooltip} placement="bottomLeft">
        <Switch
          ref={ref}
          className={classNames('prov-switch-box__switch', className)}
          title={tooltip ? undefined : (title as string)}
          {...props}
        />
      </Tooltip>
      {title}
    </div>
  ),
);
