import { RouteGroup } from '@models/classes';
import { MenuPlacement } from '@models/enums';
import { MenuItems } from '@models/types';
import { clientRouting } from '@modules/module.routing';

export const fetchItems = async (
  place: string,
): Promise<(RouteGroup | RouteGroup[])[][]> => {
  const menuItems: MenuItems<RouteGroup> = await clientRouting.getMenuItems(
    place,
  );

  const menu = Array.from(
    menuItems,
    ([item, submenu]: [RouteGroup, MenuItems<RouteGroup>]) => [
      item,
      Array.from(submenu.keys()),
    ],
  );

  return place === MenuPlacement.Main
    ? menu.filter(
        ([, submenu]: Array<RouteGroup | RouteGroup[]>) =>
          Array.isArray(submenu) && submenu.length,
      )
    : menu;
};
