import { UserRole, UserRoleView } from '@symfa-inc/providence-types';
import { arraySort } from '@core/utils/methods/array-sort';
import { OptionProps } from '../interfaces';

export const USER_ROLE_OPTIONS: OptionProps[] = arraySort(
  Object.entries(UserRoleView).map(([key, value]: [string, string]) => ({
    viewValue: value,
    value: UserRole[key as keyof typeof UserRole],
  })),
  'ASC',
  'viewValue',
);
