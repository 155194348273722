import React, { ReactNode } from 'react';
import { UserRole } from '@symfa-inc/providence-types';
import { ColumnsType } from 'antd/lib/table';
import {
  ACTUAL_DATE,
  FORECASTED_DATE,
  PACE_ACTUAL_DATE,
} from '@core/utils/validators';
import { PaceTaskPermissions } from '@models/interfaces';
import { DatePicker } from '@shared/modules';
import store from '@store/index';
import { UserSelectors } from '@store/selectors';

const allowedForSeeRoles = [
  UserRole.Admin,
  UserRole.ProjectDataSpecialist,
  UserRole.Accounting,
];

export const getPaceTaskColumns = (isEditing: boolean = false): ColumnsType => {
  const roles = UserSelectors.getUserRoles(store.getState());

  const getPermissionByRoles = (allowedRoles: UserRole[]): boolean =>
    roles?.some((role: UserRole): boolean => allowedRoles?.includes(role));

  const canSeePaceTaskColumn = <T,>(column: T): T[] =>
    getPermissionByRoles(allowedForSeeRoles) ? [column] : [];

  return [
    {
      title: 'PACE Task ID',
      key: 'paceTaskID',
      dataIndex: 'paceTaskID',
      width: 120,
    },
    {
      title: 'Task Name',
      key: 'taskName',
      dataIndex: 'taskName',
      width: 110,
    },
    {
      title: 'Forecasted Date',
      key: 'forecastedDate',
      dataIndex: 'permissions',
      width: 180,
      render: (
        permissions: PaceTaskPermissions,
        __: unknown,
        index: number,
      ): ReactNode => (
        <DatePicker
          id="forecastedDate"
          isEditing={
            isEditing &&
            getPermissionByRoles(permissions?.forecastedDateEditors)
          }
          formItemProps={{
            name: [index, 'projectPaceTaskData', 'forecastedDate'],
            rules: FORECASTED_DATE,
          }}
        />
      ),
    },
    ...canSeePaceTaskColumn({
      title: 'PACE Forecasted Date',
      key: 'PACEForecastedDate',
      dataIndex: 'permissions',
      width: 185,
      render: (
        permissions: PaceTaskPermissions,
        __: unknown,
        index: number,
      ): ReactNode => (
        <DatePicker
          id="PACEForecastedDate"
          isEditing={
            isEditing &&
            getPermissionByRoles(permissions?.PACEForecastedDateEditors)
          }
          formItemProps={{
            name: [index, 'projectPaceTaskData', 'PACEForecastedDate'],
          }}
        />
      ),
    }),
    {
      title: 'Actual Date',
      key: 'actualDate',
      dataIndex: 'permissions',
      width: 180,
      render: (
        permissions: PaceTaskPermissions,
        __: unknown,
        index: number,
      ): ReactNode => (
        <DatePicker
          id="actualDate"
          isEditing={
            isEditing && getPermissionByRoles(permissions?.actualDateEditors)
          }
          formItemProps={{
            name: [index, 'projectPaceTaskData', 'actualDate'],
            rules: ACTUAL_DATE(),
          }}
        />
      ),
    },
    ...canSeePaceTaskColumn({
      title: 'PACE Actual Date',
      key: 'PACEActualDate',
      dataIndex: 'permissions',
      width: 180,
      render: (
        permissions: PaceTaskPermissions,
        __: unknown,
        index: number,
      ): ReactNode => (
        <DatePicker
          id="PACEActualDate"
          isEditing={
            isEditing &&
            getPermissionByRoles(permissions?.PACEActualDateEditors)
          }
          formItemProps={{
            name: [index, 'projectPaceTaskData', 'PACEActualDate'],
            rules: PACE_ACTUAL_DATE(),
          }}
        />
      ),
    }),
  ];
};
