import React, { FC, PropsWithChildren } from 'react';
import { ROBoolean } from '../models';

export const ReadOnlyBoolean: FC<ROBoolean> = ({
  value,
}: PropsWithChildren<ROBoolean>) => {
  const textValue = value ? 'Yes' : 'No';

  return <>{value === undefined || value === null ? '' : textValue}</>;
};
