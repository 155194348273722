import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ContactsPlace } from '@models/enums';
import { Contacts } from '@shared/components';
import { PermittingSelectors, UserSelectors } from '@store/selectors';

export const PermittingContacts: FC = () => {
  const { contactsAccess } = useSelector(
    UserSelectors.getPermittingBrowsePermissions,
  );

  const { site } = useSelector(
    PermittingSelectors.getPermittingDetailsData(['site']),
  );

  return (
    <div className="prov-permitting-contacts">
      <Contacts
        place={ContactsPlace.Permitting}
        data={site?.jurisdiction?.contacts}
        editable={!!contactsAccess}
        hoverable={false}
      />
    </div>
  );
};
