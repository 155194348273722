import React, { Dispatch, FC, PropsWithChildren, useState } from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-types';
import { toggleModal } from '@core/utils/methods';
import { RadData, RADTableFields } from '@models/interfaces';
import { RadTableType } from '@models/types';
import { RADSelectors } from '@store/selectors';
import { CustomTable } from '../../components/custom-table';
import { getRADTableColumns } from './models/constants';
import { RADTableProps } from './models/interfaces';
import { RADModal } from './components';

import './styles.scss';

export const RADTable: FC<RADTableProps> = ({
  permissions,
  isEditing = false,
}: PropsWithChildren<RADTableProps>) => {
  const { RAD } = useSelector(RADSelectors.getRADInfo);

  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Undefinable<RadTableType>>();
  const [modalTitle, setModalTitle] = useState<string>('item');
  const [permissionsKey, setPermissionsKey] =
    useState<Undefinable<keyof RADTableFields>>(undefined);

  const toggleEditModal = (): void => {
    setEditModalVisible(!editModalVisible);

    if (editModalVisible) {
      setSelectedItem(undefined);
    }
  };

  const selectRAD = (
    title: string,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    const radItem = RAD.reduce(
      (acc: RadTableType, item: RadData) => ({
        ...acc,
        [item.RADField]: item[title as keyof RadData],
      }),
      {} as RadTableType,
    );

    setSelectedItem(radItem);
    setter(!prevState);
    setModalTitle(title);
    setPermissionsKey(`${title}Column` as keyof RADTableFields);
  };

  return (
    <>
      <CustomTable
        columns={getRADTableColumns(
          (title: string): void =>
            selectRAD(title, () => toggleEditModal(), editModalVisible),
          isEditing,
        )}
        dataSource={RAD}
        className="prov-rad-table"
        pagination={false}
      />
      <RADModal
        title={modalTitle}
        visible={editModalVisible}
        toggleModal={toggleModal(setEditModalVisible)}
        permissions={permissionsKey ? permissions?.[permissionsKey] : false}
        formValue={selectedItem}
      />
    </>
  );
};
