import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  PaceTaskHttpService,
  ProjectCategoryHttpService,
  ProjectManagementHttpService,
} from '@core/services/http';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';
import store from '@store/index';

export class ProjectManagementBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    const projectType =
      store.getState().user.activeUser?.settings?.defaultPMProjectType;

    await Promise.all([
      HttpService.getHttpRequests(
        ProjectManagementHttpService,
      ).getProjectManagementBrowse({
        ...BROWSE_DEFAULT_FILTER_VALUE,
        showCompleted: false,
        projectType,
      }),

      HttpService.getHttpRequests(PaceTaskHttpService).getPMPaceTasks(),

      HttpService.getHttpRequests(
        ProjectManagementHttpService,
      ).getPMBrowseFiltersOptions(),

      HttpService.getHttpRequests(
        ProjectManagementHttpService,
      ).getPMBrowseFiltersSets(),

      HttpService.getHttpRequests(
        ProjectCategoryHttpService,
      ).getProjectCategories(),
    ]);
  }
}
