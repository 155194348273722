import {
  adminDropdownValuesTabs,
  adminNotificationTabs,
  adminPaceTaskTabs,
} from '@client/pages/admin/admin.routing';
import { equipmentManagementTabs } from '@client/pages/equipment/equipment.routing';
import {
  allNotificationsTab,
  assignedToMeTab,
} from '@client/pages/home/home.routing';
import { projectDetailsSummaryTab } from '@client/pages/project/project.routing';
import { siteTabs } from '@client/pages/site/site.routing';
import { Tab } from '@models/classes';
import {
  engineeringSiteAuditTab,
  scopingSiteAuditTab,
  siteAuditDetailsRoute,
} from '@modules/client/pages/engineering/engineering.routing';
import { permittingJurisdictionTabs } from '@modules/client/pages/permitting/permitting.routing';

export const URL_REG_EXPS: RegExp[] = [
  projectDetailsSummaryTab.regexpPath,
  engineeringSiteAuditTab.regexpPath,
  scopingSiteAuditTab.regexpPath,
  allNotificationsTab.regexpPath,
  assignedToMeTab.regexpPath,
  siteAuditDetailsRoute.regexpPath,
  ...equipmentManagementTabs.map((tab: Tab) => tab.regexpPath),
  ...siteTabs.map((tab: Tab) => tab.regexpPath),
  ...adminPaceTaskTabs.map((tab: Tab) => tab.regexpPath),
  ...adminNotificationTabs.map((tab: Tab) => tab.regexpPath),
  ...adminDropdownValuesTabs.map((tab: Tab) => tab.regexpPath),
  ...permittingJurisdictionTabs.map((tab: Tab) => tab.regexpPath),
];
