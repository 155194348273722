import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  EngineeringBrowseFilter,
  EngineeringWorkflowUpdateData,
  OtherEquipmentData,
  PaginatedResponse,
  SiteAuditBrowse,
  UpdateEngineeringSOFData,
} from '@models/interfaces';
import { ProjectDetailsWithoutCategories } from '@models/types';
import { BreadcrumbsActions, EngineeringActions } from '@store/actions';
import { ProjectCategoriesEffects, ProjectsEffects } from '@store/effects';
import { CommonHttpService } from './common-http.service';

export class EngineeringHttpService extends CommonHttpService {
  @StoredRequest(
    EngineeringActions.getEngineeringAction,
    BreadcrumbsActions.Engineering.engineering,
  )
  async getEngineering(
    projectBundleID: string,
  ): Promise<Omit<ProjectDetailsWithoutCategories, 'paceTasks'>> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/engineering/${projectBundleID}`,
    );

    return ProjectsEffects.setHoldOptions(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectsEffects.setPaceTaskFields(
          ProjectsEffects.setPaceTasks(
            ProjectCategoriesEffects.setProjectCategories(response),
          ),
        ),
      ),
    );
  }

  @StoredRequest(EngineeringActions.updateEngineeringWorkflowAction)
  async updateEngineering(
    id: string,
    data: EngineeringWorkflowUpdateData,
  ): Promise<EngineeringWorkflowUpdateData> {
    await this.http.callApi.patch(`${this.apiUrl}/engineering/workflow`, data, {
      params: { id },
    });

    return data;
  }

  @StoredRequest(EngineeringActions.updateEngineeringSOFAction)
  async updateSOF(
    id: string,
    data: UpdateEngineeringSOFData,
  ): Promise<UpdateEngineeringSOFData> {
    const processedOtherEquipments = data.otherEquipments.map(
      (equipment: OtherEquipmentData) => {
        const { equipmentType, generatedId, manufacturer, ...itemToReq } =
          equipment;

        return itemToReq;
      },
    );

    await this.http.callApi.patch(
      `${this.apiUrl}/engineering/sof`,
      {
        ...data,
        otherEquipments: processedOtherEquipments,
        versions: data.versions.map(
          ({
            sectorsWithManufacturers,
            sectorsWithoutManufacturers,
            ...version
          }) => ({
            ...version,
            sectors: sectorsWithoutManufacturers,
          }),
        ),
      },
      {
        params: { id },
      },
    );

    return data;
  }

  @StoredRequest(EngineeringActions.getEngineeringBrowseDataAction)
  getEngineeringBrowse(
    params: EngineeringBrowseFilter,
  ): Promise<PaginatedResponse<SiteAuditBrowse[]>> {
    return this.http.callApi.get(`${this.apiUrl}/engineering`, null, {
      params,
    });
  }
}
