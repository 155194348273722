import { ReactText } from 'react';
import { actionCreatorFactory } from '../../core/utils/factories';
import {
  EquipmentModal,
  LeasingBrowseResponseItem,
  LeasingDetails,
  LeasingEquipmentResp,
  LeasingEquipmentUpdateData,
  LeasingWorkflowUpdateData,
  PaginatedResponse,
} from '../../models/interfaces';
import {
  LeasingEquipmentData,
  LeasingLeaseAuditUpdateData,
} from '../../models/types';

export type LeasingStateTableKey = keyof Omit<
  LeasingEquipmentData,
  'scopingNotes' | 'priorLeasingNote' | 'finalLeasingNote'
>;

export type LeasingActionType = {
  data: EquipmentModal;
  key: LeasingStateTableKey;
};

const actionCreator = actionCreatorFactory('LEASING');

export namespace LeasingActions {
  export const getLeasingBrowseDataAction =
    actionCreator.createAsyncAction<
      PaginatedResponse<LeasingBrowseResponseItem>
    >('GET_LEASING_BROWSE');
  export const getLeasingDetailsAction =
    actionCreator.createAsyncAction<LeasingDetails>('GET_LEASING_DETAILS');
  export const getLeasingEquipmentPriorProjectAction =
    actionCreator.createAsyncAction<LeasingEquipmentResp>(
      'GET_LEASING_EQUIPMENT_PRIOR_PROJECT',
    );
  export const updateLeasingWorkflowAction =
    actionCreator.createAsyncAction<LeasingWorkflowUpdateData>(
      'UPDATE_LEASING_WORKFLOW',
    );
  export const updateLeasingEquipmentAction =
    actionCreator.createAsyncAction<LeasingEquipmentUpdateData>(
      'UPDATE_LEASING_EQUIPMENT',
    );
  export const updateLeaseAuditAction = actionCreator.createAsyncAction<
    Partial<LeasingLeaseAuditUpdateData> & { area: 'engineering' | 'leasing' }
  >('UPDATE_LEASE_AUDIT');
  export const addTableItemAction =
    actionCreator.createAsyncAction<LeasingActionType>('ADD_TABLE_ITEM');
  export const editTableItemAction =
    actionCreator.createAsyncAction<LeasingActionType>('EDIT_TABLE_ITEM');
  export const deleteTableItemAction =
    actionCreator.createAsyncAction<LeasingActionType>('DELETE_TABLE_ITEM');
  export const emptyPriorTableAction =
    actionCreator.createAsyncAction<null>('EMPTY_PRIOR_TABLE');
  export const generateFinalLoadingAction = actionCreator.createAsyncAction<
    ReactText[]
  >('GENERATE_FINAL_LOADING');
  export const resetTablesDataAction =
    actionCreator.createAsyncAction<null>('RESET_TABLES_DATA');
}
