import React, { FC, useEffect, useRef, useState } from 'react';
import { Circle, LayersControl, Map, TileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { Map as LMap } from 'leaflet';
import { CandidatesSelectors } from '@store/selectors';
import { useMappingContext } from '../../mapping-context';
import { DEFAULT_MAP_COORDS, DEFAULT_ZOOM_LVL } from '../../models';
import { getMapMarkers } from './helpers';

import './styles.scss';

export const LeafletMap: FC = (): JSX.Element => {
  const METRES_IN_MILE: number = 1609.344;

  const mapRef = useRef<Map | null>(null);

  const [map, setMap] = useState<LMap | null>(null);

  const candidates = useSelector(CandidatesSelectors.getCandidates);

  const { coords, radius } = useMappingContext();

  useEffect(() => {
    const { latitude, longitude } = coords ?? {};

    if (latitude && longitude) {
      map?.setView({ lat: latitude, lng: longitude });
    }
  }, [coords]);

  return (
    <div className="map-container">
      <Map
        className="map"
        center={DEFAULT_MAP_COORDS}
        zoom={DEFAULT_ZOOM_LVL}
        whenReady={() => {
          if (mapRef.current) {
            setMap(mapRef.current.leafletElement);
          }
        }}
        ref={mapRef}
        minZoom={3}
      >
        <LayersControl>
          <LayersControl.BaseLayer name="Street">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              maxZoom={20}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked name="Topo">
            <TileLayer
              url="https://tile.opentopomap.org/{z}/{x}/{y}.png"
              maxZoom={17}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Aerial ArcGIS">
            <TileLayer
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
              attribution='&copy; <a href="Esri &mdash">Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community</a> contributors'
              maxZoom={19}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Aerial Mapbox">
            <TileLayer
              attribution='Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
              url="https://api.mapbox.com/styles/v1/goxa0603/cl8irhgcl002w15o9tm82asvi/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ294YTA2MDMiLCJhIjoiY2w4aXFobmN5MHRodTNwbWo1ZDZxdHZ6OSJ9.0J8RwqkTH4aQGTrR6pF3aQ"
              //  TODO: change to url={`https://api.mapbox.com/styles/v1/${VITE_USERNAME}/${VITE_STYLE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${VITE_ACCESS_TOKEN}`} if accepted
              maxZoom={20}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        {getMapMarkers(candidates)}
        {map && radius && coords && (
          <Circle
            center={[coords.latitude, coords.longitude]}
            pathOptions={{
              fillColor: '#c0f5c9',
              color: '#719dd5',
              weight: 2,
              opacity: 1,
              fillOpacity: 0.8,
            }}
            radius={(radius as number) * METRES_IN_MILE}
          />
        )}
      </Map>
    </div>
  );
};
