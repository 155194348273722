import React, { FC, PropsWithChildren } from 'react';
import { SOFToolOrderInfoData } from '@models/interfaces';
import { SOFTooDetailsDriversType } from '@models/types';
import { VirtualTable } from '@shared/components';
import { SOFConfigurationPanel } from '../configuration-panel';
import { getSOFTableColumns } from './helpers';
import { SOFToolTableProps } from './models';

import './styles.scss';

export const SOFServicesTable: FC<SOFToolTableProps> = ({
  detailsData,
  values: { orderedBy, orderDate },
  configurationPanelChanges,
  chooseData,
  projectTypePermission,
  isFetching = false,
  data,
  setData,
}: PropsWithChildren<SOFToolTableProps>) => {
  const onSelectChange = (selectedValue: string, index: number): void => {
    setData((prevState: SOFTooDetailsDriversType[]) => [
      ...data.slice(0, index),
      { ...prevState[index], distributionList: selectedValue },
      ...data.slice(index + 1),
    ]);
  };

  const onCheckboxChange = (
    checked: boolean,
    selectedRow: SOFToolOrderInfoData,
    index: number,
  ): void => {
    setData((prevState: SOFTooDetailsDriversType[]) => [
      ...data.slice(0, index),
      { ...prevState[index], servicesRequired: checked },
      ...prevState.slice(index + 1),
    ]);

    chooseData(checked, selectedRow);
  };

  return (
    <div className="prov-sof-tool-table">
      <SOFConfigurationPanel
        values={{ orderDate, orderedBy }}
        configurationPanelChanges={configurationPanelChanges}
        engineeringManagers={detailsData!.engineeringManagers}
        isFetching={isFetching}
        projectTypePermission={projectTypePermission}
      />
      <VirtualTable
        columns={getSOFTableColumns({
          onSelectChange,
          onCheckboxChange,
          vendorDistributionList: detailsData?.vendorDistributionList,
          isFetching,
          projectTypePermission,
        })}
        dataSource={data}
        rowHeight={72}
        maxViewRow={7}
      />
    </div>
  );
};
