import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { getParamFromURL } from '@core/utils/methods';

export class JurisdictionDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [, jurisdictionName] = getParamFromURL(
      'Jurisdiction Name',
      /^\/permitting\/jurisdiction\/(.+)\/details[/\w+]*$/,
    );

    await HttpService.getHttpRequests(
      PermittingHttpService,
    ).getJurisdictionDetailsData(jurisdictionName);
  }
}
