import { IScopingTowerNote } from '@symfa-inc/providence-types';
import { actionCreatorFactory } from '@core/utils/factories';
import { ModalMainTypes } from '@models/enums';
import {
  PaginatedResponse,
  ScopingBrowse,
  ScopingDetails,
  ScopingSummaryData,
  UpdateScopingEquipmentData,
} from '@models/interfaces';

const actionCreator = actionCreatorFactory('SCOPING');

export namespace ScopingActions {
  export const getScopingBrowseDataAction =
    actionCreator.createAsyncAction<PaginatedResponse<ScopingBrowse>>(
      'GET_SCOPING_BROWSE',
    );
  export const updateScopingBrowseDataAction = actionCreator.createAsyncAction<
    PaginatedResponse<ScopingBrowse>
  >('UPDATE_SCOPING_BROWSE');
  export const updateCurrentModalTypeAction =
    actionCreator.createAsyncAction<ModalMainTypes>(
      'UPDATE_CURRENT_MODAL_TYPE',
    );
  export const getScopingDetailsAction = actionCreator.createAsyncAction<
    Omit<ScopingDetails, 'categories' | 'RAD'>
  >('GET_SCOPING_SUMMARY');
  export const getScopingEquipmentAction = actionCreator.createAsyncAction<
    Omit<ScopingDetails, 'categories' | 'RAD'>
  >('GET_SCOPING_EQUIPMENT');
  export const getScopingAssociatedProjectsAction =
    actionCreator.createAsyncAction<Omit<ScopingDetails, 'categories' | 'RAD'>>(
      'GET_SCOPING_ASSOCIATED_PROJECTS',
    );
  export const updateScopingSummaryAction =
    actionCreator.createAsyncAction<ScopingSummaryData>(
      'UPDATE_SCOPING_SUMMARY',
    );
  export const updateScopingEquipmentAction =
    actionCreator.createAsyncAction<UpdateScopingEquipmentData>(
      'UPDATE_SCOPING_EQUIPMENT',
    );
  export const updateScopingTowerNoteAction =
    actionCreator.createAsyncAction<IScopingTowerNote>(
      'UPDATE_SCOPING_TOWER_NOTE',
    );
}
