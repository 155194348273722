import React, { FC, PropsWithChildren, useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Col, message, Upload } from 'antd';
import {
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/lib/upload/interface';
import classNames from 'classnames';
import { UploadEditImgModal } from '../upload-edit-img-modal';
import { MAX_COUNT, MAX_SIZE, SitePicturesUploadParams } from './models';

import './styles.scss';

export const SitePicturesUpload: FC<SitePicturesUploadParams> = ({
  fileList,
  setFileList,
}: PropsWithChildren<SitePicturesUploadParams>) => {
  const [previewFile, setPreviewFile] = useState<UploadFile>();
  const [previewFileModalVisible, setPreviewFileModalVisible] =
    useState<boolean>(false);
  const props: UploadProps = {
    fileList,
    listType: 'picture',
    multiple: true,
    accept: 'image/png, image/jpeg',
    // TODO: change validation when version of antd will be updated
    beforeUpload: ({ type, size, name }: UploadFile) => {
      const isAllowedType = ['image/png', 'image/jpeg'].includes(type);
      const isAllowedAmount = fileList.length < MAX_COUNT;
      const isAllowedSize = size <= MAX_SIZE;

      if (!isAllowedType) {
        message.error(`${name} is not PNG or JPEG file`);
      }

      if (!isAllowedAmount) {
        message.error('You can upload no more than 10 files');
      }

      if (!isAllowedSize) {
        message.error(`${name}'s size exceeds 5mb limit`);
      }

      return false;
    },
    onChange: (info: UploadChangeParam<UploadFile>) => {
      setFileList(info.fileList);
    },
    onPreview: (file: UploadFile) => {
      setPreviewFile(file);
      setPreviewFileModalVisible(true);
    },
  };

  const onEditModalClose = () => {
    setPreviewFileModalVisible(false);
    setPreviewFile(undefined);
  };

  return (
    <div className="prov-site-upload">
      <Col className="prov-site-upload__rules">
        You can upload up to 10 files.
        <br />
        The image size must not exceed 5 mb.
        <br />
        File names must be unique.
      </Col>
      <Upload.Dragger
        className={classNames('prov-site-upload__uploader', {
          filled: fileList.length,
        })}
        {...props}
      >
        {!fileList.length ? (
          <div className="empty-block">
            <InboxOutlined style={{ fontSize: '700%' }} />
            <span>No files</span>
          </div>
        ) : (
          <div>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p>Click or drag file to this area to upload</p>
          </div>
        )}
      </Upload.Dragger>
      <Col className="prov-site-upload__rules bottom">
        To edit an image, click on uploading list item.
      </Col>
      <UploadEditImgModal
        onSubmit={(file: File) => {
          setFileList((prevValue: UploadFile[]) => {
            return prevValue.map((item: UploadFile) =>
              item.uid === previewFile?.uid
                ? ({
                    uid: previewFile?.uid,
                    name: previewFile?.name,
                    originFileObj: file,
                  } as UploadFile)
                : item,
            );
          });
        }}
        visible={previewFileModalVisible}
        onClose={onEditModalClose}
        previewFile={previewFile}
      />
    </div>
  );
};
