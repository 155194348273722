import React from 'react';
import { Form, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IProjectPACETask } from '@models/interfaces';
import { DatePicker } from '@shared/modules';

export const COLUMNS = (isEditing?: boolean): ColumnsType<IProjectPACETask> => [
  {
    dataIndex: 'taskName',
    title: 'Task Name',
    render: (value: string, _, index: number) => {
      return (
        <>
          <Form.Item name={[index, 'id']}>
            <Input type="hidden" />
          </Form.Item>
          {value}
        </>
      );
    },
  },
  {
    dataIndex: 'paceTaskID',
    title: 'Pace Task ID',
  },
  {
    dataIndex: ['projectPaceTaskData', 'actualDate'],
    title: 'Actual date',
    render: (_, __, index) => (
      <DatePicker
        elementProps={{ disabled: isEditing }}
        id="actualDate"
        formItemProps={{
          name: [index, 'projectPaceTaskData', 'actualDate'],
        }}
      />
    ),
  },
  {
    dataIndex: ['projectPaceTaskData', 'forecastedDate'],
    title: 'Forecasted date',
    render: (_, __, index) => (
      <DatePicker
        elementProps={{ disabled: isEditing }}
        id="actualDate"
        formItemProps={{
          name: [index, 'projectPaceTaskData', 'forecastedDate'],
        }}
      />
    ),
  },
  {
    dataIndex: ['projectPaceTaskData', 'PACEActualDate'],
    title: 'PACE Actual date',
    render: (_, __, index) => (
      <DatePicker
        elementProps={{ disabled: isEditing }}
        id="actualDate"
        formItemProps={{
          name: [index, 'projectPaceTaskData', 'PACEActualDate'],
        }}
      />
    ),
  },
  {
    dataIndex: ['projectPaceTaskData', 'PACEForecastedDate'],
    title: 'PACE Forecasted date',
    render: (_, __, index) => (
      <DatePicker
        elementProps={{ disabled: isEditing }}
        id="actualDate"
        formItemProps={{
          name: [index, 'projectPaceTaskData', 'PACEForecastedDate'],
        }}
      />
    ),
  },
];
