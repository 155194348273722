import {
  SCOPING_SECTORS_DATA,
  SCOPING_SECTORS_VERSIONS_DATA,
} from '@models/constants';
import {
  ScopingEquipmentTableItem,
  SectorEquipmentData,
  SectorEquipmentDataResp,
  SectorEquipmentResp,
  SectorEquipmentTypeResp,
} from '@models/interfaces';
import {
  OtherEquipmentSumData,
  ScopingSectorsVersionData,
  ScopingSectorsVersionRequestData,
  ScopingSectorsVersionResp,
  SectorDataState,
} from '@models/types';

type ScopingSectorsAcc = {
  equipmentTypeNames: string[];
  versions: ScopingSectorsVersionData[];
};

export const getOtherEquipmentInfo = (
  otherEquipments: ScopingEquipmentTableItem[],
): OtherEquipmentSumData =>
  otherEquipments?.reduce(
    (
      summary: OtherEquipmentSumData,
      curItem: ScopingEquipmentTableItem,
    ): OtherEquipmentSumData => ({
      otherEquipments: [
        ...summary.otherEquipments,
        {
          generatedId: curItem?.id,
          equipment: curItem?.equipment?.id,
          equipmentType: curItem?.equipment?.equipmentType?.id,
          manufacturer: curItem?.equipment?.manufacturer?.id,
          quantity: curItem?.quantity,
        },
      ],
      equipmentTypeNames: [
        ...summary.equipmentTypeNames,
        curItem?.equipment?.equipmentType?.name,
      ],
    }),
    { otherEquipments: [], equipmentTypeNames: [] },
  );

export const getScopingSectorsInfo = (
  scopingSectorsVersions: ScopingSectorsVersionResp[],
): ScopingSectorsAcc => {
  const equipmentTypeNames: string[] = [];
  const versions: ScopingSectorsVersionData[] = scopingSectorsVersions.map(
    scopingVersion => ({
      ...scopingVersion,
      isSelected: scopingVersion.isCurrent,
      scopingSectors: scopingVersion.scopingSectors?.length
        ? scopingVersion.scopingSectors.reduce(
            (
              acc: ScopingSectorsVersionData['scopingSectors'],
              sector: SectorEquipmentDataResp,
            ) => {
              equipmentTypeNames.push(
                ...sector.sectorEquipmentTypes.map(
                  (equipmentType: SectorEquipmentTypeResp) =>
                    equipmentType.equipmentType.name,
                ),
              );

              return {
                ...acc,
                [sector.type]: sector.sectorEquipmentTypes
                  .sort(
                    (a: SectorEquipmentTypeResp, b: SectorEquipmentTypeResp) =>
                      a.equipmentType.name.localeCompare(b.equipmentType.name),
                  )
                  .map((equipmentType: SectorEquipmentTypeResp) => ({
                    equipmentType: equipmentType.equipmentType.id,
                    equipments: equipmentType.equipments.map(
                      (equipment: SectorEquipmentResp) => ({
                        ...equipment,
                        equipment: equipment?.equipment?.id,
                        manufacturer: equipment?.equipment?.manufacturer?.id,
                      }),
                    ),
                  })),
              };
            },
            {},
          )
        : SCOPING_SECTORS_DATA,
    }),
  );

  return {
    equipmentTypeNames,
    versions,
  };
};
export const getScopingSectorsFromUpdateData = (
  versions: ScopingSectorsVersionRequestData[],
): ScopingSectorsVersionData[] =>
  versions?.length
    ? versions.map(
        ({
          sectorsWithManufacturers,
          ...version
        }: ScopingSectorsVersionRequestData) => ({
          ...version,
          isSelected: version.isCurrent,
          scopingSectors: sectorsWithManufacturers.length
            ? sectorsWithManufacturers.reduce(
                (acc: SectorDataState, curSector: SectorEquipmentData) => ({
                  ...acc,
                  [curSector.type]: curSector.sectorEquipmentTypes,
                }),
                {},
              )
            : SCOPING_SECTORS_DATA,
        }),
      )
    : SCOPING_SECTORS_VERSIONS_DATA;
