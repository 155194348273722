import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { transformFields } from '@core/utils/methods';
import { SiteBrowse } from '@models/interfaces';
import { Details } from '@shared/modules/details';
import { ProjectManagementSelectors } from '@store/selectors';

export const ProjectManagementDetails: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const site = useSelector(ProjectManagementSelectors.getPMDetailsInfo);

  return (
    <Details
      {...props}
      category
      site={transformFields<SiteBrowse>(site, ['towerOwner', 'riskCategory'])}
    />
  );
};
