import { StatusColors } from '@models/enums';
import { ProcessEnvMap } from '@models/interfaces';

export const color: ProcessEnvMap = {
  Active: StatusColors.Green,
  Completed: StatusColors.Gray,
  Complete: StatusColors.Gray,
  Suspended: StatusColors.Orange,
  Hold: StatusColors.Orange,
  Authorized: StatusColors.Green,
  Inactive: StatusColors.Gray,
};
