import React, { FC, PropsWithChildren } from 'react';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { Autocomplete, Input } from '@shared/modules';
import { SiteInformationNSBControlsProps } from '../../models/interfaces';

export const NSBControls: FC<SiteInformationNSBControlsProps> = ({
  isEditing,
  dropdownsOptions,
  permissions: {
    overallCompoundSize,
    topGuyTorqueOrPullOff,
    secondTorqueArmOrPullOff,
    thirdTorqueArmOrPullOff,
    fourthTorqueArmOrPullOff,
    fifthTorqueArmOrPullOff,
    powerProvider,
    telcoProvider,
  },
}: PropsWithChildren<SiteInformationNSBControlsProps>) => (
  <>
    <Autocomplete
      id="powerProvider"
      label="Power Provider"
      options={dropdownsOptions?.PowerProvider}
      isEditing={isEditing && !!powerProvider}
    />
    <Autocomplete
      id="telcoProvider"
      label="Telco Provider"
      options={dropdownsOptions?.TelcoProvider}
      isEditing={isEditing && !!telcoProvider}
    />
    <Input
      id="overallCompoundSize"
      label="Overall Compound Size"
      formItemProps={{
        rules: WHITESPACE_INPUT_LIMIT_RULES,
      }}
      isEditing={isEditing && !!overallCompoundSize}
    />
    <Input
      id="topGuyTorqueOrPullOff"
      label="Top Guy Torque/Pull Off"
      formItemProps={{
        rules: WHITESPACE_INPUT_LIMIT_RULES,
      }}
      isEditing={isEditing && !!topGuyTorqueOrPullOff}
    />
    <Input
      id="secondTorqueArmOrPullOff"
      label="2nd Torque Arm/Pull Off"
      formItemProps={{
        rules: WHITESPACE_INPUT_LIMIT_RULES,
      }}
      isEditing={isEditing && !!secondTorqueArmOrPullOff}
    />
    <Input
      id="thirdTorqueArmOrPullOff"
      label="3nd Torque Arm/Pull Off"
      formItemProps={{
        rules: WHITESPACE_INPUT_LIMIT_RULES,
      }}
      isEditing={isEditing && !!thirdTorqueArmOrPullOff}
    />
    <Input
      id="fourthTorqueArmOrPullOff"
      label="4nd Torque Arm/Pull Off"
      formItemProps={{
        rules: WHITESPACE_INPUT_LIMIT_RULES,
      }}
      isEditing={isEditing && !!fourthTorqueArmOrPullOff}
    />
    <Input
      id="fifthTorqueArmOrPullOff"
      label="5nd Torque Arm/Pull Off"
      formItemProps={{
        rules: WHITESPACE_INPUT_LIMIT_RULES,
      }}
      isEditing={isEditing && !!fifthTorqueArmOrPullOff}
    />
  </>
);
