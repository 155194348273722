import { RuleObject } from 'rc-field-form/es/interface';
import { getDiff, isExcludedValidationDate } from '@core/utils/methods';
import { DateWorkerNull } from '@models/types';

export const FORECASTED_DATE = [
  {
    validator: (_: RuleObject, inputValue?: DateWorkerNull): Promise<void> => {
      if (inputValue && !isExcludedValidationDate(inputValue)) {
        const diff = getDiff(inputValue!);

        return diff < 0
          ? Promise.reject(new Error('Must be today’s date or a future date.'))
          : Promise.resolve();
      }

      return Promise.resolve();
    },
  },
];
