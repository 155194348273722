import { Undefinable } from '@symfa-inc/providence-types';

export const removeLastEntity = (
  map: Undefinable<Microsoft.Maps.Map>,
  entitiesLength: Undefinable<number>,
  condition: boolean = true,
) => {
  const currentEntitiesLength = map?.entities.getLength();

  if (
    condition &&
    currentEntitiesLength &&
    currentEntitiesLength !== entitiesLength
  ) {
    map?.entities.removeAt(currentEntitiesLength - 1);
  }
};
