import React, { ReactNode } from 'react';
import { ColumnsType } from 'antd/es/table';
import {
  arrsToSelectableObject,
  readOnlySelectRender,
} from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import {
  EquipmentFullInfo,
  EquipmentTabData,
  ProceccedSiteAuditRaycapsData,
  SiteAuditCablesData,
} from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { ReadOnlyInputNumber, ReadOnlySelect } from '@shared/modules';
import store from '@store/index';

export const getSiteAuditCablesColumns = (
  editFunc: ColumnsFunc<SiteAuditCablesData>,
  deleteFunc: ColumnsFunc<SiteAuditCablesData>,
  isEditing: boolean,
): ColumnsType => {
  const {
    equipment: { cableEquipments },
  } = store.getState();

  return [
    {
      title: 'Manufacturer',
      key: 'manufacturer',
      dataIndex: 'equipment',
      render: ({ id }: EquipmentTabData): ReactNode =>
        cableEquipments?.find(
          (equipmentItem: EquipmentFullInfo) => equipmentItem.id === id,
        )?.manufacturer?.name,
    },
    {
      title: 'Cables',
      key: 'equipment',
      dataIndex: 'equipment',
      render: ({ id }: EquipmentTabData): ReactNode =>
        readOnlySelectRender(
          arrsToSelectableObject(cableEquipments, 'id', 'modelNumber'),
          id,
        ),
    },
    {
      title: 'Width',
      key: 'width',
      dataIndex: 'equipment',
      render: ({ id }: EquipmentTabData): ReactNode => (
        <ReadOnlyInputNumber
          value={
            cableEquipments?.find(
              (equipmentItem: EquipmentFullInfo) => equipmentItem.id === id,
            )?.width
          }
        />
      ),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    actionButtons({ editFunc, deleteFunc, isEditing }),
  ];
};

export const getSiteAuditRaycapsColumns = (
  editFunc: ColumnsFunc<ProceccedSiteAuditRaycapsData>,
  deleteFunc: ColumnsFunc<ProceccedSiteAuditRaycapsData>,
  isEditing: boolean,
): ColumnsType => {
  const {
    equipment: { raycapEquipments },
    admin: {
      projectCategory: { projectCategories },
    },
  } = store.getState();

  return [
    {
      title: 'Raycap',
      key: 'equipment',
      dataIndex: 'equipment',
      width: 200,
      render: ({ id }: EquipmentTabData): ReactNode =>
        readOnlySelectRender(
          arrsToSelectableObject(raycapEquipments, 'id', 'modelNumber'),
          id,
        ),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
      width: 110,
    },
    {
      title: 'Carriers',
      key: 'projectCategories',
      dataIndex: 'projectCategories',
      width: 300,
      render: (ids: string[]): ReactNode => (
        <ul className="prov-site-audit-form__carriers-cell">
          {ids?.map((id: string) => (
            <li key={id}>
              <ReadOnlySelect
                options={arrsToSelectableObject(
                  projectCategories,
                  'id',
                  'value',
                )}
                value={id}
              />
            </li>
          ))}
        </ul>
      ),
    },
    actionButtons({ editFunc, deleteFunc, isEditing }),
  ];
};
