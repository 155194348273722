import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import store from '../../store';
import { CountyValuesState } from '../../store/reducers/admin/county.reducer';
import { DropdownValuesState } from '../../store/reducers/admin/dropdown-values.reducer';
import { PermittingState } from '../../store/reducers/permitting.reducer';
import {
  CountyStatesHttpService,
  DropdownHttpService,
  PermittingHttpService,
} from '../services/http';
import { requestWithStoreWaiting } from '../utils/methods';

export class ClientResolver implements Resolver {
  private static get _state(): DropdownValuesState {
    return store.getState().admin.dropdownValues;
  }

  private static get _stateState(): CountyValuesState {
    return store.getState().admin.countyValues;
  }

  private static get _permittingState(): PermittingState {
    return store.getState().permitting;
  }

  async resolve(): Promise<void> {
    if (!Object.keys(ClientResolver._state.dropdownValues).length) {
      await requestWithStoreWaiting(
        () =>
          HttpService.getHttpRequests(DropdownHttpService).getDropdownValues(),
        () => ClientResolver._state,
      );
    }

    if (!ClientResolver._stateState.states.length) {
      await requestWithStoreWaiting(
        () => HttpService.getHttpRequests(CountyStatesHttpService).getStates(),
        () => ClientResolver._stateState,
      );
    }

    if (!ClientResolver._permittingState.jurisdictionList.length) {
      await requestWithStoreWaiting(
        () =>
          HttpService.getHttpRequests(
            PermittingHttpService,
          ).getJurisdictionList(),
        () => ClientResolver._permittingState,
      );
    }
  }
}
