import { createSelector, OutputSelector } from 'reselect';
import { SiteAuditProjectInfo } from '@models/interfaces';
import { EngineeringSiteAuditState } from '@store/reducers/engineering/site-audit.reducer';
import { AppState } from '../../reducers';
import { EngineeringState } from '../../reducers/engineering';
import { EquipmentState } from '../../reducers/equipment.reducer';

const appState = (state: AppState): AppState => state;
const engineeringState = (state: AppState): EngineeringState =>
  state.engineering;
const engineeringSiteAuditState = (
  state: AppState,
): EngineeringSiteAuditState => state.engineering.siteAudit;
const equipmentState = (state: AppState): EquipmentState => state.equipment;

export namespace SiteAuditSelectors {
  export const getSiteAuditBrowseData = createSelector(
    [engineeringSiteAuditState],
    ({ siteAuditBrowseData }: EngineeringSiteAuditState) => siteAuditBrowseData,
  );

  export const getSiteAuditSiteInfo = createSelector(
    [engineeringSiteAuditState],
    ({ siteAuditSiteInfo }: EngineeringSiteAuditState) => siteAuditSiteInfo,
  );

  export const getSiteAuditFormData = createSelector(
    [appState],
    ({
      admin: {
        dropdownValues: { dropdownsOptions },
      },
      engineering: {
        siteAudit: {
          siteAuditData,
          siteAuditSiteInfo: { id, equipmentType, towerType },
          cablesTableData,
          raycapsTableData,
          originalCablesTableData,
          originalRaycapsTableData,
        },
      },
    }: AppState) => ({
      dropdownsOptions,
      siteAuditData,
      siteAuditSiteInfo: { id, equipmentType, towerType },
      cablesTableData,
      raycapsTableData,
      originalCablesTableData,
      originalRaycapsTableData,
    }),
  );

  export const getProjectInfo = (
    ...currentField: Array<keyof SiteAuditProjectInfo>
  ): OutputSelector<
    [typeof engineeringState],
    Partial<SiteAuditProjectInfo>,
    (res: EngineeringState) => Partial<SiteAuditProjectInfo>
  > =>
    createSelector([engineeringState], (state: EngineeringState) => {
      let result: Partial<SiteAuditProjectInfo> = state.siteAudit.projectInfo;

      if (currentField.length) {
        result = currentField.reduce(
          (
            acc: Partial<SiteAuditProjectInfo>,
            value: keyof SiteAuditProjectInfo,
          ) => ({ ...acc, [value]: state.siteAudit.projectInfo[value] }),
          {},
        );
      }

      return result;
    });

  export const isFetching = createSelector(
    [engineeringSiteAuditState],
    (state: EngineeringSiteAuditState) => state.isFetching,
  );

  export const getCableModalInfo = createSelector(
    [equipmentState, engineeringSiteAuditState],
    (
      { cableEquipments, equipmentTypes }: EquipmentState,
      { cablesTableData }: EngineeringSiteAuditState,
    ) => ({
      cableEquipments,
      equipmentTypes,
      cablesTableData,
    }),
  );

  export const getRaycapModalInfo = createSelector(
    [equipmentState, engineeringSiteAuditState],
    (
      { raycapEquipments }: EquipmentState,
      { raycapsTableData }: EngineeringSiteAuditState,
    ) => ({
      raycapEquipments,
      raycapsTableData,
    }),
  );
}
