import { ReactNode } from 'react';
import { DateStringOrNull } from '@symfa-inc/providence-types';
import { ColumnsType } from 'antd/lib/table/interface';
import { readOnlyDatePickerRenderer } from '@core/utils/methods';
import { Browse } from '@models/types';

export const EXPORTS_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'PACE Project Number',
    dataIndex: 'PACEProjectNumber',
    key: 'PACEProjectNumber',
    width: 150,
    sorter: true,
  },
  {
    title: 'IWM Job Number',
    dataIndex: 'IWMJobNumber',
    key: 'IWMJobNumber',
    width: 200,
    sorter: true,
  },
  {
    title: 'Project Bundle ID',
    dataIndex: 'projectBundleID',
    key: 'projectBundleID',
    width: 140,
    sorter: true,
  },
  {
    title: 'FA Location Number',
    dataIndex: 'FALocationNumber',
    key: 'FALocationNumber',
    width: 140,
    sorter: true,
  },
  {
    title: 'Site Number',
    dataIndex: 'siteNumber',
    key: 'siteNumber',
    width: 100,
    sorter: true,
  },
  {
    title: 'PACE Task ID',
    dataIndex: 'paceTaskID',
    key: 'paceTaskID',
    width: 100,
    sorter: true,
  },
  {
    title: 'Actual Date',
    dataIndex: 'actualDate',
    key: 'actualDate',
    width: 100,
    sorter: true,
    render: (actualDate: DateStringOrNull): ReactNode =>
      readOnlyDatePickerRenderer(actualDate),
  },
  {
    title: 'PACE Forecasted Date',
    dataIndex: 'PACEForecastedDate',
    key: 'PACEForecastedDate',
    width: 100,
    sorter: true,
    render: (PACEForecastedDate: DateStringOrNull): ReactNode =>
      readOnlyDatePickerRenderer(PACEForecastedDate),
  },
  {
    title: 'Export Date',
    dataIndex: 'exportedAt',
    key: 'exportedAt',
    width: 100,
    render: (exportedAt: DateStringOrNull): ReactNode =>
      readOnlyDatePickerRenderer(exportedAt),
  },
];
