import {
  PermittingWorkflowBody,
  PermittingWorkflowBodyOptions,
} from '@models/interfaces';
import { PaceTasksToObjectMapper, WorkflowData } from '../models';

const transform = (
  data: WorkflowData['zoningSection'] | WorkflowData['permittingSection'],
  options: PaceTasksToObjectMapper.Options[],
): PermittingWorkflowBodyOptions[] =>
  Object.keys(data).reduce(
    (acc: PermittingWorkflowBodyOptions[], key: string) => {
      const currentOption = options.find(
        (opt: PaceTasksToObjectMapper.Options) => opt.fieldKey === key,
      );

      return !currentOption
        ? acc
        : [
            ...acc,
            {
              PACETaskId: currentOption.id,
              fieldKey: currentOption.fieldKey,
              value: data[key as keyof typeof data],
            },
          ];
    },
    [],
  );

export const dataToPaceTasksMapper = (
  data: WorkflowData,
  permittingSectionOptions: PaceTasksToObjectMapper.Options[],
  zoningSectionOptions: PaceTasksToObjectMapper.Options[],
): PermittingWorkflowBody => ({
  ...data,
  permittingSection: transform(
    data.permittingSection,
    permittingSectionOptions,
  ),
  zoningSection: transform(data.zoningSection, zoningSectionOptions),
});
