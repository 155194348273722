import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { NotificationsHttpService } from '@core/services/http';

export class NotificationsResolver implements Resolver {
  async resolve(): Promise<void> {
    const httpService = HttpService.getHttpRequests(NotificationsHttpService);

    await Promise.all([
      httpService.getPaceTaskList(),
      httpService.getUsersList(),
      httpService.getRules(),
      httpService.getSchedules(),
      httpService.getEmailCertificationDate(),
      httpService.getDirectMessages(),
    ]);
  }
}
