import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { SelectValue } from 'antd/es/select';
import {
  ConfigureFilterProps,
  RangePickerValues,
  SOFToolBrowseFilter,
} from '@models/interfaces';
import { Autocomplete, RangePicker } from '@shared/modules';
import { SOFToolSelectors } from '@store/selectors';
import { BrowseFilterType, SOF_FILTERS } from '../models';

export const SOFFilters: FC<ConfigureFilterProps<SOFToolBrowseFilter>> = ({
  value,
  onChanges,
}: PropsWithChildren<ConfigureFilterProps<SOFToolBrowseFilter>>) => {
  const filtersOptions = useSelector(SOFToolSelectors.browseFilters);

  return (
    <>
      {SOF_FILTERS.map(({ id, name, valueKey }: BrowseFilterType) => (
        <Autocomplete
          id={id}
          key={id}
          label={name}
          options={filtersOptions ? filtersOptions[valueKey] : []}
          elementProps={{
            allowClear: true,
            value: value[id as keyof SOFToolBrowseFilter] as string,
            onChange: (e: SelectValue): void => onChanges({ [id]: e }),
          }}
        />
      ))}
      <RangePicker
        id="SS014ActualDate"
        key="SS014ActualDate"
        label="SS014 - Actual Date"
        formItemProps={{ className: 'aligned-form-item' }}
        onChange={(date?: RangePickerValues): void =>
          onChanges({ SS014ActualDate: date })
        }
      />
    </>
  );
};
