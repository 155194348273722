import React, { Dispatch, FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import editIcon from 'assets/icons/edit-icon.svg';
import Info from 'assets/icons/info.png';
import { HttpService } from '@core/services';
import { CountyStatesHttpService } from '@core/services/http';
import { arrsToSelectableObject, toggleModal } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { AdminCountyData, StateWithCounties } from '@models/interfaces';
import { ScrollToInitRef } from '@models/types';
import { DeleteModal, VirtualTable } from '@shared/components';
import { Autocomplete, PrimaryButton } from '@shared/modules';
import { CountySelectors } from '@store/selectors';
import {
  COUNTY_DEFAULT_VALUE,
  getAdminCountyColumns,
} from './models/constants';
import { CountyModal, StateModal } from './components';

import './styles.scss';

const { useForm } = Form;

export const County: FC = () => {
  const [form] = useForm();

  const virtualTableRef = useRef<ScrollToInitRef>(null);

  const states = useSelector(CountySelectors.getStatesWithCounties);

  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [isAddEditCountyModalVisible, setAddEditCountyModalVisible] =
    useState<boolean>(false);
  const [isAddEditStateModalVisible, setAddEditStateModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );

  const [selectorValue, setSelectorValue] = useState<SelectValue>('');
  const [selectedItem, setSelectedItem] =
    useState<AdminCountyData>(COUNTY_DEFAULT_VALUE);
  const [counties, setCounties] = useState<AdminCountyData[]>([]);

  const toggleAddAndEditCountyModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditCountyModalVisible(!isAddEditCountyModalVisible);

    if (isAddEditCountyModalVisible) {
      setSelectedItem({ ...COUNTY_DEFAULT_VALUE });
    }
  };

  const toggleAddAndEditStateModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditStateModalVisible(!isAddEditStateModalVisible);
  };

  useEffect(() => {
    if (selectorValue) {
      setCounties(
        states.find((state: StateWithCounties) => state.id === selectorValue)
          ?.counties || [],
      );
    }
  }, [selectorValue, states]);

  const changeSelectorValue = (value: string): void => {
    form.setFieldsValue({
      equipmentType: value,
    });

    setSelectorValue(value);
  };

  const selectCurrentCountyValues = (
    currentCountyValues: AdminCountyData,
    setter: Dispatch<boolean>,
    prevState: boolean,
  ): void => {
    setSelectedItem(currentCountyValues);
    setter(!prevState);
  };

  const deleteCounty = async (): Promise<void> => {
    await HttpService.getHttpRequests(CountyStatesHttpService).deleteCounty(
      selectedItem.id,
    );

    setDeleteModalVisible(!deleteModalVisible);
  };

  return (
    <div className="prov-admin-county">
      <Row className="prov-admin-county__table-handler">
        <Col span="7">
          <Form form={form}>
            <Autocomplete
              label={
                <span className="select-text">
                  <img src={Info} alt="Edit row" className="select-img" />
                  Please, select state what you want to edit
                </span>
              }
              id="equipmentType"
              options={arrsToSelectableObject<StateWithCounties>(
                states,
                'id',
                'name',
              )}
              elementProps={{
                onChange: (value: SelectValue): void => {
                  changeSelectorValue(value as string);
                  virtualTableRef?.current?.scrollToInit();
                },
              }}
            />
          </Form>
        </Col>
        <div className="prov-admin-county__btn-box">
          <PrimaryButton
            className="edit-state-btn"
            title="Edit"
            icon={
              <img src={editIcon} alt="Edit state" className="county-icon" />
            }
            onClick={(): void =>
              toggleAddAndEditStateModal(ModalMainTypes.Edit)
            }
            disabled={!selectorValue}
          />
          <PrimaryButton
            icon="+"
            title="Add new state"
            className="add-state-btn"
            onClick={(): void => toggleAddAndEditStateModal(ModalMainTypes.Add)}
          />
        </div>
      </Row>
      <VirtualTable
        ref={virtualTableRef}
        dataSource={selectorValue ? counties : []}
        columns={getAdminCountyColumns(
          (county: AdminCountyData): void =>
            selectCurrentCountyValues(
              county,
              () => toggleAddAndEditCountyModal(ModalMainTypes.Edit),
              isAddEditCountyModalVisible,
            ),
          (county: AdminCountyData): void =>
            selectCurrentCountyValues(
              county,
              setDeleteModalVisible,
              deleteModalVisible,
            ),
        )}
        maxViewRow={10}
      />
      <PrimaryButton
        icon="+"
        title="Add"
        className="prov-admin-county__add-button"
        onClick={(): void => toggleAddAndEditCountyModal(ModalMainTypes.Add)}
        disabled={!selectorValue}
      />
      <CountyModal
        visible={isAddEditCountyModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditCountyModal}
        itemValue={selectedItem}
        selectorValue={selectorValue}
      />
      <StateModal
        visible={isAddEditStateModalVisible}
        modalType={currentModalType}
        toggleModal={toggleAddAndEditStateModal}
        selectorValue={selectorValue}
        setSelector={changeSelectorValue}
      />
      <DeleteModal
        onOk={deleteCounty}
        onCancel={toggleModal(setDeleteModalVisible)}
        visible={deleteModalVisible}
      />
    </div>
  );
};
