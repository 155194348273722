import React, { FC, PropsWithChildren, useState } from 'react';
import { InputNumber, Select } from 'antd';
import {
  ColumnSelectEquipment,
  EquipmentFullInfo,
  OptionProps,
  ProcessEnvMap as KeyString,
} from '@models/interfaces';
import { InputNumberFormatterType as UndefinableStringNumber } from '@models/types';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { ColumnsSelectProps } from './models';

import './styles.scss';

export const ColumnsSelect: FC<ColumnsSelectProps> = ({
  isEditing,
  data,
  equipments,
  label,
  type,
  onChange,
}: PropsWithChildren<ColumnsSelectProps>) => {
  const [isAddInputVisible, setAddInputVisible] = useState<boolean>(
    !equipments.length,
  );

  const equipmentIds = equipments.map(
    (equipment: ColumnSelectEquipment) => equipment.id,
  );

  const allowSelectOptions = data
    ?.map((item: KeyString) => ({
      value: item?.id,
      label: item?.[label] || '',
    }))
    ?.filter((v: OptionProps) => !equipmentIds.includes(v.value as string));

  const addOrChangeEquipment = (
    id: string,
    index: number = equipments.length,
  ): void => {
    if (index === equipments.length && !id) {
      return;
    }

    const values = [...equipments];
    const manufacturer = (data as EquipmentFullInfo[]).find(
      (e: EquipmentFullInfo) => e.id === id,
    )?.manufacturer.id;

    values.splice(index, 1, {
      id,
      quantity: values[index]?.quantity || 1,
      manufacturer: manufacturer || '',
    });

    const result = values.filter((v: ColumnSelectEquipment) => !!v.id);

    onChange(result);
    setAddInputVisible(!result.length);
  };

  const quantityChange = (
    value: UndefinableStringNumber,
    index: number = equipments.length,
  ): void => {
    if (value) {
      const values = [...equipments];
      const currentValue = values[index];

      values.splice(index, 1, { ...currentValue, quantity: +value });
      onChange(values);
    }
  };

  const filterOption = (inputValue: string, option: any): boolean =>
    !!option &&
    String(option.label)
      .toUpperCase()
      .indexOf(String(inputValue).toUpperCase()) !== -1;

  return (
    <div className="prov-equipment-select">
      {equipments.map(
        (
          { id: equipmentId, quantity }: ColumnSelectEquipment,
          index: number,
        ) => {
          const item = data.find(
            (equipment: KeyString) => equipment.id === equipmentId,
          );
          const id = item?.id;
          const key = `${type}-${id}-${equipmentId}`;

          return isEditing ? (
            <div
              key={key}
              className="prov-equipment-select__item prov-equipment-select__item--quantity"
            >
              <Select
                value={id}
                options={
                  item
                    ? [
                        {
                          value: id,
                          label: item?.[label] || '',
                        },
                        ...allowSelectOptions,
                      ]
                    : allowSelectOptions
                }
                allowClear
                onChange={(value: string): void =>
                  addOrChangeEquipment(value, index)
                }
                showSearch
                filterOption={filterOption}
              />
              <InputNumber
                value={quantity || 1}
                disabled={!id}
                min={1}
                onChange={(value: UndefinableStringNumber): void =>
                  quantityChange(value, index)
                }
              />
            </div>
          ) : (
            item && (
              <div className="prov-equipment-select__item" key={key}>
                {item?.[label]}
                {quantity > 1 ? ` (${quantity})` : ''}
              </div>
            )
          );
        },
      )}
      {isEditing && isAddInputVisible && (
        <Select
          options={allowSelectOptions}
          className="prov-equipment-select__item"
          onChange={(value: string): void => addOrChangeEquipment(value)}
          showSearch
          filterOption={filterOption}
          allowClear
        />
      )}
      {isEditing && (
        <PrimaryButton
          title="Add Other Equipment"
          className="prov-equipment-select__add-other-equipment"
          icon="+"
          disabled={isAddInputVisible}
          onClick={(): void => setAddInputVisible(true)}
        />
      )}
    </div>
  );
};
