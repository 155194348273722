import {
  PaginatedResponse,
  PayloadAndState,
  ProjectCategory,
} from '@models/interfaces';

type IncomingType = { categories: ProjectCategory[] };
type ResultType<T extends IncomingType> = Record<string, PaginatedResponse<T>>;

export const browseReducer =
  <P extends IncomingType, S>(key: string) =>
  ({ payload }: PayloadAndState<PaginatedResponse<P>, S>): ResultType<P> => ({
    [key]: {
      ...payload,
      items: payload.items.map((item: P) => {
        item.categories.sort(
          (a: ProjectCategory, b: ProjectCategory) =>
            a?.projectRanking?.value?.localeCompare(
              b?.projectRanking?.value ?? 'Secondary',
            ) ?? 0,
        );

        return item;
      }),
    },
  });
