import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Undefinable } from '@symfa-inc/providence-types';
import { arrsToSelectableObject } from '@core/utils/methods';
import {
  AdminCountyData,
  JurisdictionOption,
  OptionProps,
  StateWithCounties,
} from '@models/interfaces';
import { CountySelectors, PermittingSelectors } from '@store/selectors';

type UseFindCountyAndJurisdictionsProps = {
  stateId: Undefinable<string>;
  effectDepArray: Array<StateWithCounties[] | Undefinable<string>>;
  setCountyOptions?: React.Dispatch<React.SetStateAction<OptionProps[]>>;
  setJurisdictionOptions: React.Dispatch<React.SetStateAction<OptionProps[]>>;
};

export const useFindCountyAndJurisdictions = ({
  stateId,
  effectDepArray,
  setCountyOptions,
  setJurisdictionOptions,
}: UseFindCountyAndJurisdictionsProps) => {
  const states = useSelector(CountySelectors.getStatesWithCounties);
  const jurisdictions = useSelector(PermittingSelectors.getJurisdictionList);

  const setData = (): void => {
    setCountyOptions?.(
      arrsToSelectableObject<AdminCountyData>(
        states.find((state: StateWithCounties) => state.id === stateId)
          ?.counties || [],
        'id',
        'name',
      ),
    );

    setJurisdictionOptions(
      arrsToSelectableObject<JurisdictionOption>(
        jurisdictions.filter(
          (jurisdictionItem: JurisdictionOption) =>
            jurisdictionItem.state === stateId,
        ) || [],
        'id',
        'jurisdictionName',
      ),
    );
  };

  const clearData = (): void => {
    setCountyOptions?.([]);
    setJurisdictionOptions([]);
  };

  useEffect(() => {
    stateId ? setData() : clearData();
  }, [...effectDepArray, jurisdictions]);
};
