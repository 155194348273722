import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { VendorHttpService } from '@core/services/http';
import { isValidForm } from '@core/utils/methods';
import { serverValidator } from '@core/utils/validators';
import { WHITESPACE_INPUT_LIMIT_RULES } from '@models/constants';
import { ModalMainTypes } from '@models/enums';
import { AdminVendorDistributionListData } from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { Input } from '@shared/modules';
import { VendorsSelectors } from '@store/selectors';
import { VendorDistributionListModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

export const VendorDistributionListModal: FC<
  VendorDistributionListModalProps
> = ({
  visible,
  modalType,
  toggleModal,
  selectorValue,
  setSelector,
}: PropsWithChildren<VendorDistributionListModalProps>) => {
  const [form] = useForm();

  const vendorService = useMemo(
    () => HttpService.getHttpRequests(VendorHttpService),
    [],
  );

  const vendorsLists = useSelector(VendorsSelectors.getVendorsLists);

  const getInputData = (): { name: string } => ({
    name: form.getFieldsValue().name,
  });

  const add = async ({
    name,
  }: Pick<AdminVendorDistributionListData, 'name'>): Promise<void> => {
    if (await isValidForm(form)) {
      const { id } = await vendorService.addVendorList({
        name,
      });

      setSelector(id);
      toggleModal(modalType);
    }
  };

  const edit = async ({
    name,
  }: Pick<AdminVendorDistributionListData, 'name'>): Promise<void> => {
    if ((await isValidForm(form)) && selectorValue) {
      const { id } = await vendorService.editVendorList({
        name,
        id: selectorValue,
      });

      setSelector(id);
      toggleModal(modalType);
    }
  };

  const deleteVendorList = async (): Promise<void> => {
    await vendorService.deleteVendorList(selectorValue as string);
    setSelector(undefined);
    toggleModal(modalType);
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        name:
          modalType === ModalMainTypes.Edit
            ? vendorsLists.find(vendorsList => vendorsList.id === selectorValue)
                ?.name
            : undefined,
      });
    }
  }, [visible, selectorValue, modalType, form]);

  return (
    <AddOrEditModal
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getInputData())
          : (): Promise<void> => edit(getInputData())
      }
      onCancel={(): void => toggleModal(modalType)}
      visible={visible}
      type={modalType}
      title="Vendor List"
      okText="Save"
      cancelText="Delete"
      className={classNames('prov-admin-vendors-distribution-modal', {
        'add-modal': modalType === ModalMainTypes.Add,
      })}
      formProps={{
        labelCol: { span: 5 },
        wrapperCol: { span: 15 },
        form,
      }}
      cancelButtonProps={{
        onClick: deleteVendorList,
      }}
    >
      <Input
        id="name"
        label="List Name"
        formItemProps={{
          rules: [
            serverValidator(
              {
                method: 'getVendorListValidation',
                errorMessage: 'List Name must be unique!',
              },
              VendorHttpService,
              modalType === ModalMainTypes.Add ? undefined : selectorValue,
            ),
            ...WHITESPACE_INPUT_LIMIT_RULES,
          ],
        }}
      />
    </AddOrEditModal>
  );
};
