import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Select } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';

interface ProjectTypeControlsProps {
  isEditMode: boolean;
}

export const ProjectTypeControls: FC<ProjectTypeControlsProps> = ({
  isEditMode,
}: ProjectTypeControlsProps) => {
  const { ProjectTypeDDV, ProjectSubtypeDDV } = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByTypes([
      'ProjectType',
      'ProjectSubtype',
    ]),
  );

  return (
    <>
      <Select
        id="projectTypeId"
        label="Project Type"
        options={ProjectTypeDDV}
        isEditing
        elementProps={{ allowClear: !isEditMode }}
      />
      <Select
        id="projectSubTypeId"
        label="Project Subtype"
        options={ProjectSubtypeDDV}
        isEditing
        elementProps={{ allowClear: !isEditMode }}
      />
    </>
  );
};
