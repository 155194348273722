import { ModalMainTypes } from '@models/enums';

export const validationRequestParams = <T>(
  modalType: ModalMainTypes,
  itemValue: T & { id?: string },
  field: string,
): { id?: string; field: string } => ({
  id: modalType === ModalMainTypes.Edit ? itemValue.id : undefined,
  field,
});
