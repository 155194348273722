import { UploadFile } from 'antd/es/upload/interface';
import { UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { NotificationsLoader } from '../../shared/components';

export const UPLOADER_PROPS: UploadProps = {
  name: 'file',
  multiple: false,
  onChange(info: UploadChangeParam<UploadFile>) {
    if (info.file.status === 'done') {
      NotificationsLoader.notificationSuccess(
        `${info.file.name} file uploaded successfully`,
      );
    }
  },
};
