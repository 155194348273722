import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { FormItem, SwitchBox } from '@shared/modules';
import { SwitchProps } from './models/interfaces';

import './styles.scss';

export const Switch: FC<SwitchProps> = ({
  id,
  switchState,
  elementProps,
  ...props
}: PropsWithChildren<SwitchProps>) => (
  <FormItem
    id={id}
    readOnlyElement={<SwitchBox {...elementProps} disabled />}
    editingElement={
      <div className={classNames('switch', { switch__active: switchState })}>
        <SwitchBox {...elementProps} />
      </div>
    }
    {...props}
  />
);
