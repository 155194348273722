import React, { FC, PropsWithChildren } from 'react';
import { ModalProps } from '@models/interfaces';
import { Modal } from '@shared/modules';

import './styles.scss';

export const DeleteJurisdictionModal: FC<ModalProps> = (
  props: PropsWithChildren<ModalProps>,
) => (
  <Modal
    title="Delete Jurisdiction"
    okText="Delete"
    className="prov-delete-jurisdiction-modal"
    {...props}
  >
    Are you sure you want to delete this Jurisdiction?
  </Modal>
);
