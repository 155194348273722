import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import Logo from 'assets/images/main-logo.png';
import { MainLayoutProps } from './models/interfaces';
import {
  BellNotifications,
  BreadcrumbMenu,
  ClientLayout,
  HeaderMenu,
  Sider,
} from './components';

import './styles.scss';

const { Header, Content } = Layout;

export const MainLayout: FC<MainLayoutProps> = ({
  children,
}: PropsWithChildren<MainLayoutProps>) => (
  <Layout className="prov-layout">
    <Header className="prov-layout__header">
      <Link to="/">
        <img src={Logo} alt="main logo" />
      </Link>
      <BellNotifications />
      <HeaderMenu />
    </Header>
    <Sider />
    <Content className="prov-layout__content">
      <BreadcrumbMenu />
      <ClientLayout>{children}</ClientLayout>
    </Content>
  </Layout>
);
