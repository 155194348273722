import { Rule } from 'antd/lib/form';
import { PermittingHttpService } from '@core/services/http';
import { serverValidator } from '@core/utils/validators';

export const getJurisdictionNameValidationRules = (
  jurisdictionId: string,
  state?: string,
): Rule[] => [
  serverValidator(
    {
      method: 'getJurisdictionNameValidation',
      errorMessage: 'Jurisdiction Name must be unique',
    },
    PermittingHttpService,
    jurisdictionId,
    state,
  ),
  { required: true, message: 'Jurisdiction Name is required!' },
];
