import React, { FC, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';
import { GoBackButtonProps } from './model';

export const GoBackButton: FC<GoBackButtonProps> = ({
  title = 'Cancel',
  type = 'default',
  className = '',
  disabled = false,
  closingWindow = false,
}: PropsWithChildren<GoBackButtonProps>) => {
  const history = useHistory();

  return (
    <PrimaryButton
      title={title}
      className={className}
      type={type}
      disabled={disabled}
      onClick={(): void =>
        history.length === 1 && closingWindow
          ? window.close()
          : history.goBack()
      }
    />
  );
};
