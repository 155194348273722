import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import {
  FinancialData,
  FinancialOOMInformation,
  FinancialServicesData,
  FinancialSiteFeesData,
} from '@models/interfaces';
import { FinancialActions } from '@store/actions';
import { financialRoutesHandler, removeEmptyFields } from '../../utils/methods';
import { CommonHttpService } from './common-http.service';

export class FinancialHttpService extends CommonHttpService {
  @StoredRequest(FinancialActions.getFinancialDataAction)
  getFinancial(
    projectBundleID: string,
    entity: string,
  ): Promise<FinancialData> {
    return this.http.callApi.get(
      `${this.apiUrl}/${entity}/financial/${projectBundleID}`,
    );
  }

  @StoredRequest(FinancialActions.addFinancialSiteFeeAction)
  async addFinancialSiteFee(
    body: FinancialSiteFeesData,
    projectId: string,
    entity: string,
    creator: string,
  ): Promise<FinancialSiteFeesData> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/${financialRoutesHandler(entity)}/${projectId}/site-fees`,
      removeEmptyFields(body),
    );

    return { id, ...body, creator: { id: creator } };
  }

  @StoredRequest(FinancialActions.updateFinancialSiteFeeAction)
  async updateFinancialSiteFee(
    { id, ...body }: FinancialSiteFeesData,
    projectId: string,
    entity: string,
  ): Promise<FinancialSiteFeesData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/${financialRoutesHandler(entity)}/${projectId}/site-fees`,
      body,
      {
        params: { siteFeeId: id },
      },
    );

    return { id, ...body };
  }

  @StoredRequest(FinancialActions.deleteFinancialSiteFeeAction)
  async deleteFinancialSiteFee(
    siteFeeId: string,
    projectId: string,
    entity: string,
  ): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/${financialRoutesHandler(entity)}/${projectId}/site-fees`,
      null,
      {
        params: { siteFeeId },
      },
    );

    return siteFeeId;
  }

  @StoredRequest(FinancialActions.addFinancialServiceAction)
  async addFinancialService(
    body: FinancialServicesData,
    projectId: string,
    entity: string,
    creator: string,
  ): Promise<FinancialServicesData> {
    const { driver, ...data } = body;
    const id = await this.http.callApi.post(
      `${this.apiUrl}/${financialRoutesHandler(
        entity,
      )}/${projectId}/services-required`,
      { ...data, driver: driver.id },
    );

    return {
      ...body,
      driver,
      id,
      issuedDate: null,
      PONumber: null,
      creator: { id: creator },
    };
  }

  @StoredRequest(FinancialActions.updateFinancialServiceAction)
  async updateFinancialService(
    { id, PONumber, issuedDate, ...data }: FinancialServicesData,
    projectId: string,
    entity: string,
  ): Promise<FinancialServicesData> {
    const body = {
      ...data,
      ...(entity === 'accounting' ? { PONumber, issuedDate } : {}),
      driver: data.driver.id,
    };

    delete body.creator;

    await this.http.callApi.patch(
      `${this.apiUrl}/${financialRoutesHandler(
        entity,
      )}/${projectId}/services-required`,
      body,
      {
        params: { servicesRequiredId: id },
      },
    );

    return { id, ...body, driver: data.driver };
  }

  @StoredRequest(FinancialActions.deleteFinancialServiceAction)
  async deleteFinancialService(
    servicesRequiredId: string,
    projectId: string,
    entity: string,
  ): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/${financialRoutesHandler(
        entity,
      )}/${projectId}/services-required`,
      null,
      {
        params: { servicesRequiredId },
      },
    );

    return servicesRequiredId;
  }

  @StoredRequest(FinancialActions.addOOMInformationDataAction)
  async addFinancialOOM(
    projectId: string,
    body: FinancialOOMInformation,
    entity: string,
  ): Promise<FinancialOOMInformation> {
    const id = await this.http.callApi.post(
      `${this.apiUrl}/${financialRoutesHandler(
        entity,
      )}/${projectId}/oom-information`,
      body,
    );

    return {
      id,
      ...body,
    };
  }

  @StoredRequest(FinancialActions.updateOOMInformationDataAction)
  async updateFinancialOOM(
    projectId: string,
    { id, ...body }: FinancialOOMInformation,
    entity: string,
  ): Promise<FinancialOOMInformation> {
    await this.http.callApi.patch(
      `${this.apiUrl}/${financialRoutesHandler(
        entity,
      )}/${projectId}/oom-information`,
      body,
      {
        params: { OOMInformationId: id },
      },
    );

    return { id, ...body };
  }

  @StoredRequest(FinancialActions.deleteOOMInformationDataAction)
  async deleteFinancialOOM(
    OOMInformationId: string,
    projectId: string,
    entity: string,
  ): Promise<string> {
    await this.http.callApi.delete(
      `${this.apiUrl}/${financialRoutesHandler(
        entity,
      )}/${projectId}/oom-information`,
      null,
      {
        params: { OOMInformationId },
      },
    );

    return OOMInformationId;
  }
}
