import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { AdditionalFilter, AdditionalFiltersProps } from '@models/interfaces';
import { PrimaryButton } from '../../modules/ui-kit/primary-button';

import './styles.scss';

export const AdditionalFilters: FC<AdditionalFiltersProps> = ({
  filters,
  onChange,
  activeFilters,
  mode = 'default',
}: PropsWithChildren<AdditionalFiltersProps>) => {
  const toggleFilter = (filter: string): void => {
    let result: string | string[] = '';

    switch (mode) {
      case 'default':
        if (!Array.isArray(activeFilters)) {
          throw new Error(
            'Mode is default. Please use array type fot activeFilters',
          );
        }

        result = activeFilters.includes(filter)
          ? activeFilters.filter(
              (activeFilter: string) => activeFilter !== filter,
            )
          : [...activeFilters, filter];

        break;
      case 'switch':
        result = filter;

        break;
      default:
        throw new Error('Incorrect mode!');
    }

    onChange(result);
  };

  const clearFilters = (): void => onChange(mode === 'default' ? [] : '');

  return (
    <div className="prov-additional-filters">
      <div className="prov-additional-filters__button-group">
        {filters.map((filter: AdditionalFilter) => (
          <PrimaryButton
            title={filter.name}
            onClick={(): void => toggleFilter(filter.key)}
            key={filter.key}
            type="default"
            className={classNames({
              'not-active': !activeFilters.includes(filter.key),
            })}
          />
        ))}
      </div>
      <PrimaryButton
        title="Clear"
        onClick={clearFilters}
        disabled={
          (Array.isArray(activeFilters) && !activeFilters.length) ||
          !activeFilters
        }
      />
    </div>
  );
};
