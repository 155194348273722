import React, { FC, PropsWithChildren, useState } from 'react';
import { useSelector } from 'react-redux';
import { ToTopOutlined } from '@ant-design/icons/lib';
import { SiteAuditForm } from '@client/pages/engineering/components';
import { HttpService } from '@core/services';
import { SiteAuditHttpService } from '@core/services/http';
import { toggleModal } from '@core/utils/methods';
import { EditablePage } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { SiteAuditSelectors } from '@store/selectors';
import { SiteAuditComponentProps } from './models/interfaces';
import { SiteAccessNotesModal } from './components';

import './styles.scss';

export const SiteAuditComponent: FC<SiteAuditComponentProps> = ({
  defaultEditing = false,
  canUserEdit = true,
  permissions,
}: PropsWithChildren<SiteAuditComponentProps>) => {
  const [siteAccessNotesModalVisible, setSiteAccessNotesModalVisible] =
    useState<boolean>(false);

  const { id: currentProjectId } = useSelector(
    SiteAuditSelectors.getProjectInfo('id'),
  );

  return (
    <div className="prov-site-audit">
      <EditablePage
        editableComponent={SiteAuditForm}
        canUserEdit={canUserEdit}
        defaultEditing={defaultEditing}
        permissions={permissions}
      >
        <div className="prov-site-audit__header-buttons">
          <PrimaryButton
            title="Site Access Notes"
            onClick={toggleModal(setSiteAccessNotesModalVisible)}
          />
          <PrimaryButton
            title="Export"
            icon={<ToTopOutlined />}
            onClick={(): Promise<void> =>
              HttpService.getHttpRequests(SiteAuditHttpService).getSiteAuditPDF(
                currentProjectId!,
              )
            }
          />
          <SiteAccessNotesModal
            visible={siteAccessNotesModalVisible}
            onCancel={toggleModal(setSiteAccessNotesModalVisible)}
          />
        </div>
      </EditablePage>
    </div>
  );
};
