import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { EngineeringHttpService } from '@core/services/http';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';

export class EngineeringBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      EngineeringHttpService,
    ).getEngineeringBrowse({
      ...BROWSE_DEFAULT_FILTER_VALUE,
      showCompleted: false,
    });
  }
}
