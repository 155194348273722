import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { RADState } from '../reducers/rad.reducer';

const radState = (state: AppState): RADState => state.rad;

export namespace RADSelectors {
  export const getRADInfo = createSelector(
    [radState],
    ({ RAD, originalRADInfo }: RADState) => ({
      RAD,
      originalRADInfo,
    }),
  );
}
