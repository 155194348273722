import React, { ReactNode } from 'react';
import { ColumnsType } from 'antd/lib/table';
import {
  arrsToSelectableObject,
  readOnlySelectRender,
} from '@core/utils/methods';
import { actionButtons } from '@models/constants';
import {
  EquipmentFullInfo,
  EquipmentManufacturer,
  EquipmentModal,
  EquipmentTypeData,
  OtherEquipmentData,
} from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { ReadOnlyInputNumber } from '@shared/modules';
import store from '@store/index';

export const getOtherEquipmentColumns = (
  isEditing?: boolean,
  editFunc?: ColumnsFunc<EquipmentModal>,
  deleteFunc?: ColumnsFunc<EquipmentModal>,
): ColumnsType => {
  const {
    equipment: { equipmentTypes, allUsedEquipments },
  } = store.getState();

  return [
    {
      title: 'Equipment Type',
      key: 'equipmentType',
      dataIndex: 'equipmentType',
      render: (equipmentTypeId: string): ReactNode =>
        readOnlySelectRender(
          arrsToSelectableObject<EquipmentTypeData>(
            equipmentTypes,
            'id',
            'name',
          ),
          equipmentTypeId,
        ),
    },
    {
      title: 'Manufacturer',
      key: 'manufacturer',
      render: ({
        equipmentType: equipmentTypeId,
        manufacturer: manufacturerId,
      }: OtherEquipmentData): ReactNode => {
        const currentEquipmentType = equipmentTypes?.find(
          (equipmentType: EquipmentTypeData) =>
            equipmentType.id === equipmentTypeId,
        );

        const currentManufacturer = currentEquipmentType?.manufacturers?.find(
          (manufacturer: EquipmentManufacturer) =>
            manufacturer.id === manufacturerId,
        );

        return currentManufacturer?.name || '';
      },
    },
    {
      title: 'Equipment Model Number',
      key: 'equipment',
      dataIndex: 'equipment',
      render: (equipment: string): ReactNode =>
        readOnlySelectRender(
          arrsToSelectableObject(allUsedEquipments, 'id', 'modelNumber'),
          equipment,
        ),
    },
    {
      title: 'Description',
      key: 'description',
      render: (equipment: OtherEquipmentData): ReactNode =>
        allUsedEquipments.find(
          (equipmentItem: EquipmentFullInfo) =>
            equipmentItem.id === equipment?.equipment,
        )?.description || '',
    },
    {
      title: 'Dimensions',
      key: 'dimensions',
      render: (equipment: OtherEquipmentData): ReactNode => {
        const item = allUsedEquipments.find(
          (equipmentItem: EquipmentFullInfo) =>
            equipmentItem.id === equipment?.equipment,
        );

        return `${item?.length || '0'}x${item?.width || '0'}x${
          item?.depth || '0'
        }`;
      },
    },
    {
      title: 'Weight',
      key: 'weight',
      render: (equipment: OtherEquipmentData): ReactNode => (
        <ReadOnlyInputNumber
          value={
            allUsedEquipments.find(
              (equipmentItem: EquipmentFullInfo) =>
                equipmentItem.id === equipment?.equipment,
            )?.weight
          }
        />
      ),
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    actionButtons({ editFunc, deleteFunc, isEditing }),
  ];
};
