export const getParamFromURL = (name: string, regexp: RegExp): string[] => {
  const preResult = window.location.pathname.match(regexp);

  if (!preResult) {
    throw new Error(`${name} in url is not exist!`);
  }

  return preResult;
};

export const getProjectBundleIDParamFromUrl = (): string[] =>
  getParamFromURL('Project Bundle ID', /\d+/);

export const getFALocationParamFromUrl = (): string[] =>
  getParamFromURL('FA Location Number', /\d+/);
