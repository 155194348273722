import nextId from 'react-id-generator';
import { transformFields } from '../../core/utils/methods';
import { distructAndDispatch } from '../../core/utils/methods/destruct-and-dispatch';
import { PROJECT_CATEGORY_TRANSFORM_FIELDS } from '../../models/constants/transform-fields';
import {
  FinancialData,
  FinancialOOMInformation,
  ProjectCategory,
  ProjectManagementDetails,
  ProjectPaceTask,
  User,
} from '../../models/interfaces';
import { FinancialActions } from '../actions/financial.actions';
import { ProjectActions } from '../actions/project.actions';
import { ProjectCategoriesActions } from '../actions/project-categories.actions';

export namespace ProjectManagementEffects {
  export const setFinancial = distructAndDispatch<
    ProjectManagementDetails,
    FinancialData
  >('financial', FinancialActions.getFinancialDataAction);

  export const setOOMInformation = distructAndDispatch<
    ProjectManagementDetails,
    FinancialOOMInformation[]
  >('OOMInformation', FinancialActions.getOOMInformationDataAction);

  export const setCurrentProvidenceAgent = distructAndDispatch<
    ProjectManagementDetails,
    User
  >('providenceAgent', ProjectActions.setCurrentProvidenceAgent);

  export const setPaceTasks = distructAndDispatch<
    ProjectManagementDetails,
    ProjectPaceTask[]
  >('paceTasks', ProjectActions.updateProjectPaceTasksAction);

  export const setPaceTaskFields = distructAndDispatch<
    ProjectManagementDetails,
    ProjectPaceTask[]
  >('paceTaskFields', ProjectActions.updateProjectPaceTaskFields);

  export const setProjectCategories = distructAndDispatch<
    ProjectManagementDetails,
    ProjectCategory[]
  >(
    'categories',
    ProjectCategoriesActions.setProjectCategoriesAction,
    (categories: ProjectCategory[]) =>
      categories?.map((category: ProjectCategory) =>
        transformFields<ProjectCategory>(
          { ...category, generatedId: nextId('PCCategory') },
          PROJECT_CATEGORY_TRANSFORM_FIELDS,
        ),
      ),
  );
}
