import { UserManagementData } from '@models/interfaces';

export const USER_MANAGEMENT_DEFAULT_VALUE: UserManagementData = {
  id: '',
  firstName: '',
  lastName: '',
  roles: [],
  email: '',
  isActive: true,
  lastLoggedIn: '',
  responsibleForProjectType: undefined,
};
