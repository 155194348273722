import React, { FC, PropsWithChildren } from 'react';
import { PrimaryButton } from '@shared/modules';
import { SOFToolActionButtonsProps } from './models';

import './styles.scss';

export const SOFActionButtons: FC<SOFToolActionButtonsProps> = ({
  send,
  cancel,
  preview,
  disabled,
  cancelDisabled = false,
}: PropsWithChildren<SOFToolActionButtonsProps>) => (
  <div className="prov-sof-tool-action-buttons">
    <div className="prov-sof-tool-action-buttons__general-actions">
      <PrimaryButton
        title="Send"
        onClick={send}
        disabled={disabled}
        className="send-button"
      />
      <PrimaryButton
        title="Cancel"
        type="default"
        onClick={cancel}
        disabled={cancelDisabled}
      />
    </div>
    <PrimaryButton title="Preview" onClick={preview} disabled={disabled} />
  </div>
);
