import { SettingOutlined } from '@ant-design/icons/lib';
import { SubPage } from '@symfa-inc/providence-types';
import { RedirectGuard } from '@core/guards';
import { tabPropsToTabs } from '@core/utils/methods';
import { Route, RouteGroup } from '@models/classes';
import { MenuPlacement } from '@models/enums';
import {
  PERMITTING_TABS_PROPS,
  PermittingBrowse,
  PermittingBrowseResolver,
  PermittingDetails,
  PermittingDetailsResolver,
} from './browse';
import {
  JurisdictionBrowse,
  JurisdictionBrowseResolver,
  JurisdictionDetails,
  JurisdictionDetailsResolver,
  PERMITTING_JURISDICTION_TABS,
} from './jurisdiction';

export const permittingJurisdictionTabs = tabPropsToTabs(
  PERMITTING_JURISDICTION_TABS,
);

export const permittingRoutes = new RouteGroup({
  name: 'Permitting',
  path: '/permitting',
})
  .breadcrumb()
  .addGuard(new RedirectGuard('permitting'), '/')
  .setMenu(MenuPlacement.Main, SettingOutlined)
  .addRoutes([
    new Route({
      name: 'Browse',
      path: '/browse',
      component: PermittingBrowse,
    })
      .breadcrumb()
      .addGuard(new RedirectGuard('permitting', SubPage.PermittingBrowse), '/')
      .addResolver(new PermittingBrowseResolver(), false)
      .setMenu(MenuPlacement.Main)
      .addRoute(
        new Route({
          name: ':siteNumber(:projectBundleID)',
          path: '/:permittingId/details',
          component: PermittingDetails,
          extendPath: false,
        })
          .addTabs(tabPropsToTabs(PERMITTING_TABS_PROPS))
          .addResolver(new PermittingDetailsResolver())
          .breadcrumb(),
      ),
    new RouteGroup({
      name: 'Jurisdiction',
      path: '/jurisdiction',
    }).addRoute(
      new Route({
        name: 'Jurisdiction',
        path: '/browse',
        component: JurisdictionBrowse,
      })
        .breadcrumb()
        .setMenu(MenuPlacement.Main)
        .addGuard(
          new RedirectGuard('permitting', SubPage.PermittingJurisdiction),
          '/',
        )
        .addResolver(new JurisdictionBrowseResolver(), false)
        .addRoute(
          new Route({
            name: ':jurisdictionName',
            path: '/:jurisdictionId/:state/details',
            component: JurisdictionDetails,
            extendPath: false,
          })
            .addTabs(permittingJurisdictionTabs)
            .addResolver(new JurisdictionDetailsResolver())
            .breadcrumb(),
        ),
    ),
  ]);
