import { Dispatch, SetStateAction } from 'react';
import { Guard } from '@wellyes/react-router-extended';
import { Location } from 'history';
import { Tab } from '@models/classes';
import { clientRouting } from '@modules/module.routing';

export const setCurrentTabs = (
  location: Location<unknown>,
  tabs: Tab[],
  setTabs: Dispatch<SetStateAction<Tab[]>>,
): void => {
  const route = clientRouting.findRouteByPath(location.pathname);

  route!.tabs
    .reduce(
      async (computedTabsPrev: Promise<Tab[]>, tab: Tab): Promise<Tab[]> => {
        const computedTabs = await computedTabsPrev;

        const canActivate = (
          await Promise.all(
            tab.guards.map((guard: Guard) => guard.canActivate()),
          )
        ).every((v: boolean): boolean => v);

        if (canActivate) {
          computedTabs.push(tab);
        }

        return computedTabs;
      },
      Promise.resolve([...tabs]),
    )
    .then((resultedTabs: Tab[]): void => setTabs(() => resultedTabs));
};
