import { RADField as RADFieldEnum } from '@symfa-inc/providence-types';
import { RadData } from '../interfaces';

export const RAD_DATA: RadData[] = (['A', 'B', 'G', 'D'] as RADFieldEnum[]).map(
  (RADField: RADFieldEnum) => ({
    RADField,
    leased: 0,
    RFDS: 0,
    scoping: 0,
    structural: 0,
  }),
);
