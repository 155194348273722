import { DriverWorkflow } from '@symfa-inc/providence-types';
import { DetailsResolver } from '@core/resolvers';
import { HttpService } from '@core/services';
import {
  DriverManagementHttpService,
  EngineeringHttpService,
  EquipmentHttpService,
  PermittingHttpService,
  ProjectCategoryHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';
import store from '@store/index';
import { EngineeringBrowseState } from '@store/reducers/engineering/browse.reducer';

export class PermittingDetailsResolver extends DetailsResolver {
  private static get _engineeringState(): EngineeringBrowseState {
    return store.getState().engineering.browse;
  }

  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await HttpService.getHttpRequests(EngineeringHttpService).getEngineering(
      projectBundleID,
    );

    await HttpService.getHttpRequests(
      PermittingHttpService,
    ).getPermittingDetailsData(projectBundleID);

    await HttpService.getHttpRequests(
      ProjectCategoryHttpService,
    ).getProjectCategories();

    await HttpService.getHttpRequests(
      DriverManagementHttpService,
    ).getDriversListByWorkflow(DriverWorkflow.Permitting);

    await HttpService.getHttpRequests(EquipmentHttpService).getEquipmentTypes();

    if (
      PermittingDetailsResolver._engineeringState?.equipmentTypeNames?.length
    ) {
      await HttpService.getHttpRequests(
        EquipmentHttpService,
      ).getEquipmentsByTypes(
        PermittingDetailsResolver._engineeringState?.equipmentTypeNames,
      );
    }
  }
}
