import axios, { AxiosError, Method } from 'axios';
import qs from 'qs';
import { NotificationsLoader } from '@shared/components';
import { ApiMethods, StorageItem } from '../../models/enums';
import {
  CallApi,
  HttpClientResult,
  ImportError,
  ProcessEnvMap,
} from '../../models/interfaces';
import { Config } from '../config/config';

export class HttpClient {
  callApi: CallApi;

  constructor() {
    this.callApi = HttpClient._callApi();
  }

  private static get _accessToken(): string {
    return localStorage.getItem(StorageItem.ACCESS_TOKEN)!;
  }

  private static _httpHeaders(): ProcessEnvMap {
    return {
      'Content-Type': 'application/json',
      Authorization: HttpClient._accessToken
        ? `Bearer ${HttpClient._accessToken}`
        : '',
    };
  }

  private static _callApi(): CallApi {
    const accumulator = {};

    return [
      ApiMethods.Post,
      ApiMethods.Get,
      ApiMethods.Put,
      ApiMethods.Delete,
      ApiMethods.Patch,
    ].reduce(
      (obj: CallApi, method: Method): CallApi => ({
        ...obj,
        [method]: (
          url: string,
          body: any = {},
          options: any = {},
        ): HttpClientResult =>
          HttpClient._doRequest(method, url, body, options),
      }),
      accumulator as CallApi,
    );
  }

  private static async _doRequest(
    method: Method,
    url: string,
    body: any,
    options: any,
  ): HttpClientResult {
    const credentialHeaders = HttpClient._httpHeaders();
    const {
      params,
      headers,
      cOnSuccess,
      cOnFail,
      needHeaders,
      ...restOptions
    } = options;

    try {
      const cancelRequestSource = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        cancelRequestSource.cancel();
      }, Config.get().connectionConfig.requestTimeout);

      const resp = await axios({
        method,
        url,
        ...(body ? { data: body } : {}),
        params,
        headers: {
          ...headers,
          ...credentialHeaders,
        },
        ...restOptions,
        paramsSerializer: (sendParams: any) => qs.stringify(sendParams),
        cancelToken: cancelRequestSource.token,
      });

      clearTimeout(timeout);

      if (cOnSuccess) {
        await cOnSuccess(resp);
      }

      return await Promise.resolve(
        needHeaders ? { data: resp.data, headers: resp.headers } : resp.data,
      );
    } catch (e) {
      if (e instanceof AxiosError) {
        const notificationParams = axios.isCancel(e)
          ? {
              description: 'Timeout',
              message:
                'Your request is taking a long time. Please try reload page in few minutes.',
            }
          : {
              message: e.response?.statusText || 'Something went wrong...',
              description: /import/.test(url)
                ? e.response?.data.message
                    ?.map(({ description }: ImportError) => description)
                    ?.join(';\n')
                : e.response?.data.message,
            };

        NotificationsLoader.notificationError(notificationParams);
      }

      if (cOnFail) {
        await cOnFail(e);
      }

      return Promise.reject(e);
    }
  }
}
