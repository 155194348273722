import { FRONTEND_DATE_FORMAT } from '@symfa-inc/providence-types';
import { dateWorker } from '@core/utils/date-worker';
import { ObjectComparatorService } from './object-comparator.service';

type ObjectType = Record<string, any>;

export class ObjectDifferencesService {
  static getObjectsDiff<T extends ObjectType>(
    prevObj: T,
    curObj: T,
    reqKeys?: Array<keyof T>,
  ): ObjectType {
    const neededKeys = reqKeys
      ? reqKeys.reduce(
          (allKeys: Partial<T>, key: keyof T) => ({
            ...allKeys,
            [key]: curObj[key],
          }),
          {},
        )
      : {};

    return Object.keys(curObj).reduce((diff: ObjectType, key: string) => {
      switch (true) {
        case dateWorker(prevObj[key], FRONTEND_DATE_FORMAT, true).isValid() &&
          dateWorker(curObj[key], FRONTEND_DATE_FORMAT, true).isValid():
          return {
            ...neededKeys,
            ...diff,
            ...(dateWorker(prevObj[key]).diff(dateWorker(curObj[key]))
              ? { [key]: curObj[key] }
              : {}),
          };
        case Array.isArray(prevObj[key]):
          return this.getArraysDiff(prevObj[key], curObj[key]);
        case ObjectComparatorService.isObjects(prevObj[key], curObj[key]):
          return {
            ...neededKeys,
            ...ObjectDifferencesService.getObjectsDiff(
              prevObj[key],
              curObj[key],
            ),
          };
        case prevObj[key] !== curObj[key]:
          return {
            ...neededKeys,
            ...diff,
            [key]: curObj[key],
          };
        default:
          return {
            ...neededKeys,
            ...diff,
          };
      }
    }, {});
  }

  static getArraysDiff<T extends Record<string, any>>(
    arr1: T[],
    arr2: T[],
    reqKeys?: Array<keyof T>,
  ): ObjectType[] {
    const arr1Sorted = [...arr1].sort();
    const arr2Sorted = [...arr2].sort();

    return [
      ...(arr1Sorted.length === arr2Sorted.length
        ? arr1Sorted
            .map(
              (sortedArrayItem: T, index: number): ObjectType =>
                ObjectComparatorService.isObjects(
                  sortedArrayItem,
                  arr2Sorted[index],
                )
                  ? this.getObjectsDiff(
                      sortedArrayItem,
                      arr2Sorted[index],
                      reqKeys,
                    )
                  : {},
            )
            .filter((sortedArrayItem: ObjectType) =>
              reqKeys
                ? Object.keys(sortedArrayItem).length &&
                  !ObjectComparatorService.arraysCompare(
                    Object.keys(sortedArrayItem),
                    reqKeys,
                  )
                : !!Object.keys(sortedArrayItem).length,
            )
        : []),
    ];
  }
}
