const CONTAINER_CLASSES: string[] = [
  'ant-modal',
  'tabs-wrap',
  'ant-collapse-content',
];

const hasContainerClass = (classList: DOMTokenList): boolean =>
  CONTAINER_CLASSES.some((className: string) => classList.contains(className));

export const getPopupContainer = (node: HTMLElement): HTMLElement => {
  let container = node;

  while (
    container !== document.body &&
    hasContainerClass(container.classList)
  ) {
    container = container.parentElement || document.body;
  }

  return container;
};
