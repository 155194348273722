import { ISelectorOption, IToDo } from '@symfa-inc/providence-types';
import { mainDateFormat } from '@core/utils/methods';
import { DashboardItem, DashboardProjectData } from '@models/interfaces';
import { assigneesIdsToNamedAssignees } from '../../../helpers';

export const toDoSearcher = (
  data: DashboardItem<IToDo>[],
  searchValue: string,
  adminsList: ISelectorOption[],
): DashboardItem<IToDo>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: { date, taskNote, actionField, message, assignees },
          projectData: {
            projectBundleID,
            projectType,
            siteNumber,
            PACEProjectNumbers,
            oracleProjectNumbers,
          },
        }: DashboardItem<IToDo>) =>
          [
            mainDateFormat(date),
            taskNote,
            actionField,
            message,
            ...assigneesIdsToNamedAssignees(assignees, adminsList),
            projectBundleID.toString(),
            siteNumber,
            projectType,
            ...PACEProjectNumbers.map(
              ({ value }: DashboardProjectData['PACEProjectNumbers'][number]) =>
                value,
            ),
            ...oracleProjectNumbers.map(
              ({
                value,
              }: DashboardProjectData['oracleProjectNumbers'][number]) => value,
            ),
          ].some(
            (item: string | null | undefined) => item && regExp.test(item),
          ),
      );
};
