import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouterProps } from 'react-router';
import { PaceTaskScreen } from '@symfa-inc/providence-types';
import { Form } from 'antd';
import classNames from 'classnames';
import { HttpService } from '@core/services';
import { ProjectHttpService } from '@core/services/http';
import { useDidUpdateEffect, useSaveChanged } from '@core/utils/hooks';
import {
  canUserEditByProjectStatusAndRoles,
  isValidForm,
} from '@core/utils/methods';
import { FormProps } from '@models/interfaces';
import {
  EditablePage,
  getPaceTaskDifference,
  NotificationsLoader,
  paceTaskMapper,
  PaceTaskTable,
  Panel,
  ViewElement,
} from '@shared/components';
import { PrimaryButton, ProgressStatus, ProjectStatus } from '@shared/modules';
import { CommonActions, ProjectActions } from '@store/actions';
import {
  AccountingSelectors,
  PaceTasksSelectors,
  ProjectCategoriesSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';

import './styles.scss';

const { useForm } = Form;

const AccountingSummaryView: FC<FormProps> = ({
  isEditing = false,
  toggleEditing,
}: PropsWithChildren<FormProps>) => {
  const dispatch = useDispatch();

  const [paceTaskForm] = useForm();

  const [duringReqDisabled, setDuringReqDisabled] = useState<boolean>(false);

  const projectCategoriesTableVisible = useSelector(
    ProjectCategoriesSelectors.getProjectCategoriesTableVisible,
  );
  const {
    FALocationNumber,
    projectBundleID,
    projectType,
    projectWorkType,
    projectSubtype,
    projectStatus,
    progressStatus,
    ATnTPM,
    siteAcqVendor,
    providenceAgent,
    civilVendor,
    projectNote,
    projectId,
    legacySiteID,
  } = useSelector(AccountingSelectors.getProjectData);
  const { engineeringNote, siteNotes, permittingNotes, leasingNote } =
    useSelector(AccountingSelectors.getNotesData);
  const arePaceTasksChanged = useSelector(
    PaceTasksSelectors.arePaceTasksChanged,
  );
  const currentPaceTasks = useSelector(PaceTasksSelectors.getPaceTasks);
  const roles = useSelector(UserSelectors.getUserRoles);

  useEffect(
    () => (): void => {
      dispatch(CommonActions.setHasUnsubmittedData.done(false));
    },
    [],
  );

  useDidUpdateEffect(() => {
    dispatch(CommonActions.setHasUnsubmittedData.done(arePaceTasksChanged));
  }, [arePaceTasksChanged]);

  const onSubmit = async (): Promise<void> => {
    const [paceTasksData, keys] = getPaceTaskDifference(
      currentPaceTasks,
      paceTaskForm,
      roles,
    );

    if (await isValidForm(paceTaskForm, keys)) {
      try {
        setDuringReqDisabled(true);

        await HttpService.getHttpRequests(
          ProjectHttpService,
        ).updateProjectPaceTasks(
          projectId,
          PaceTaskScreen.Accounting,
          paceTasksData,
        );

        toggleEditing?.();

        dispatch(
          ProjectActions.updateProjectPaceTaskFieldsFromSummaryPageTable.done(
            paceTasksData,
          ),
        );

        dispatch(ProjectActions.setArePACETasksChanged.done(false));

        NotificationsLoader.notificationSuccess(
          `PACE tasks have been updated!`,
        );

        setDuringReqDisabled(false);
      } catch (e) {
        console.error(e);

        setDuringReqDisabled(false);
      }
    }
  };

  const onCancel = (): void => {
    paceTaskForm.setFieldsValue(paceTaskMapper(currentPaceTasks));
    dispatch(ProjectActions.setArePACETasksChanged.done(false));

    toggleEditing?.();
  };

  const buttonsDisabled = duringReqDisabled || !arePaceTasksChanged;

  useSaveChanged(isEditing, onSubmit, onCancel);

  return (
    <>
      <div
        className={classNames('prov-accounting-summary', 'tabs-wrap', {
          'tabs-wrap_with-actions': isEditing,
          'categories-table-open': projectCategoriesTableVisible,
        })}
      >
        <div className="prov-accounting-summary__information">
          <Panel header="Project Information" className="project">
            <ViewElement label="FA Location Number" value={FALocationNumber} />
            <ViewElement label="Legacy Site ID" value={legacySiteID} />
            <ViewElement label="Project Bundle ID" value={projectBundleID} />
            <ViewElement label="Project Type" value={projectType} />
            <ViewElement label="Project Work Type" value={projectWorkType} />
            <ViewElement label="Project Subtype" value={projectSubtype} />
            <ViewElement
              label="Project Status"
              element={<ProjectStatus status={projectStatus} />}
            />
            <ViewElement
              label="Progress Status"
              element={<ProgressStatus status={progressStatus} />}
            />
            <ViewElement label="AT&T PM" value={ATnTPM} />
            <ViewElement label="Site Acq Vendor" value={siteAcqVendor} />
            <ViewElement label="Providence Agent" value={providenceAgent} />
            <ViewElement label="Civil Vendor" value={civilVendor} />
            <ViewElement label="Project Notes" value={projectNote} />
          </Panel>

          <Panel header="Notes" className="notes">
            <ViewElement label="Site Notes" value={siteNotes} />
            <ViewElement label="Engineering Notes" value={engineeringNote} />
            <ViewElement label="Leasing Notes" value={leasingNote} />
            <ViewElement label="Permitting Notes" value={permittingNotes} />
          </Panel>
        </div>
        <PaceTaskTable
          isEditing={isEditing}
          data={currentPaceTasks || []}
          form={paceTaskForm}
        />
      </div>
      {isEditing && (
        <div className="prov-project-summary__btns">
          <div className="action-wrap">
            <PrimaryButton
              title="Submit"
              disabled={buttonsDisabled}
              onClick={onSubmit}
            />
            <PrimaryButton
              title="Cancel"
              disabled={buttonsDisabled}
              type="default"
              onClick={onCancel}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const AccountingSummary: FC<RouterProps> = ({
  history,
}: PropsWithChildren<RouterProps>) => {
  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <EditablePage
      editableComponent={AccountingSummaryView}
      history={history}
      canUserEdit={canUserEditByProjectStatusAndRoles(
        projectStatusId,
        userRoles,
      )}
    />
  );
};
