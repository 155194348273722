import {
  IAssignee,
  IReceivedDirectMessage,
  IRequiresAttention,
  ISelectorOption,
  IToDo,
} from '@symfa-inc/providence-types';
import { createReducer, on } from '@core/utils/store';
import { ERROR_MASK } from '@models/constants';
import {
  AccountingNotificationData,
  DashboardItem,
  MultiSetCompletedDirectMessage,
  MultiUpdateAssignees,
  PayloadAndState,
  SetCompletedDirectMessage,
  ToDoOrRequiresAttentionsUpdateData,
} from '@models/interfaces';
import { DashboardActions } from '../actions/dashboard.actions';

export interface DashboardState {
  adminList: ISelectorOption[];
  userList: ISelectorOption[];
  accountingItems: DashboardItem<AccountingNotificationData>[];
  isAccountingItemsFetching: boolean;
  toDoItems: DashboardItem<IToDo>[];
  isToDoItemsFetching: boolean;
  requiresAttentionItems: DashboardItem<IRequiresAttention>[];
  isRequiresAttentionItemsFetching: boolean;
  directMessages: DashboardItem<IReceivedDirectMessage>[];
  isDirectMessagesFetching: boolean;
  isFetching: boolean;
  errors: boolean;
}

const initialState: DashboardState = {
  adminList: [],
  userList: [],
  accountingItems: [],
  isAccountingItemsFetching: false,
  toDoItems: [],
  isToDoItemsFetching: false,
  requiresAttentionItems: [],
  isRequiresAttentionItemsFetching: false,
  directMessages: [],
  isDirectMessagesFetching: false,
  isFetching: false,
  errors: false,
};

const dashboardItemUpdate = <T extends IToDo | IRequiresAttention>(
  stateData: DashboardItem<T>[],
  { _id, assignees, taskNote, reporter }: ToDoOrRequiresAttentionsUpdateData,
): DashboardItem<T>[] =>
  stateData.map((dashboardItem: DashboardItem<T>) =>
    dashboardItem._id === _id
      ? {
          ...dashboardItem,
          notificationData: {
            ...dashboardItem.notificationData,
            assignees: assignees.map((assignee: string) => ({
              reporter,
              assignee,
            })),
            taskNote,
          },
        }
      : dashboardItem,
  );

const multiSetAssignees = <T extends IToDo | IRequiresAttention>(
  stateData: DashboardItem<T>[],
  { recordIds, assignees, reporter }: MultiUpdateAssignees,
): DashboardItem<T>[] =>
  stateData.map((dashboardItem: DashboardItem<T>) =>
    recordIds.includes(dashboardItem._id)
      ? {
          ...dashboardItem,
          notificationData: {
            ...dashboardItem.notificationData,
            assignees: [
              ...assignees.reduce(
                (acc: IAssignee[], newAssignee: string) => {
                  const isAssigneeExist =
                    dashboardItem.notificationData.assignees.find(
                      ({ assignee }: IAssignee) => assignee === newAssignee,
                    );

                  if (!isAssigneeExist) {
                    acc.push({ assignee: newAssignee, reporter });
                  }

                  return acc;
                },
                [...dashboardItem.notificationData.assignees],
              ),
            ],
          },
        }
      : dashboardItem,
  );

export const reducer = createReducer(
  initialState,
  on(
    DashboardActions.getAdminList,
    ({
      payload: adminList,
    }: PayloadAndState<ISelectorOption[], DashboardState>) => ({
      adminList,
    }),
  ),
  on(
    DashboardActions.getUserList,
    ({
      payload: userList,
    }: PayloadAndState<ISelectorOption[], DashboardState>) => ({
      userList,
    }),
  ),
  // get To Do Items
  on(
    DashboardActions.getAssignedToDoItems,
    ({
      payload: toDoItems,
    }: PayloadAndState<DashboardItem<IToDo>[], DashboardState>) => ({
      toDoItems,
    }),
    { ...ERROR_MASK, fetchingMask: 'isToDoItemsFetching' },
  ),
  on(
    DashboardActions.getAllToDoItems,
    ({
      payload: toDoItems,
    }: PayloadAndState<DashboardItem<IToDo>[], DashboardState>) => ({
      toDoItems,
    }),
    { ...ERROR_MASK, fetchingMask: 'isToDoItemsFetching' },
  ),
  // update ToDoItem
  on(
    DashboardActions.updateToDoItem,
    ({
      payload,
      state: { toDoItems },
    }: PayloadAndState<
      ToDoOrRequiresAttentionsUpdateData,
      DashboardState
    >) => ({
      toDoItems: [...dashboardItemUpdate(toDoItems, payload)],
    }),
  ),
  // multi update ToDoItem assignees
  on(
    DashboardActions.multiUpdateToDoAssignees,
    ({
      payload,
      state: { toDoItems },
    }: PayloadAndState<MultiUpdateAssignees, DashboardState>) => ({
      toDoItems: [...multiSetAssignees(toDoItems, payload)],
    }),
  ),
  // get Requires Attention Items
  on(
    DashboardActions.getAssignedRequiresAttentionItems,
    ({
      payload: requiresAttentionItems,
    }: PayloadAndState<
      DashboardItem<IRequiresAttention>[],
      DashboardState
    >) => ({
      requiresAttentionItems,
    }),
    { ...ERROR_MASK, fetchingMask: 'isRequiresAttentionItemsFetching' },
  ),
  on(
    DashboardActions.getAllRequiresAttentionItems,
    ({
      payload: requiresAttentionItems,
    }: PayloadAndState<
      DashboardItem<IRequiresAttention>[],
      DashboardState
    >) => ({
      requiresAttentionItems,
    }),
    { ...ERROR_MASK, fetchingMask: 'isRequiresAttentionItemsFetching' },
  ),
  // update RequiresAttentionItem
  on(
    DashboardActions.updateRequiresAttentionItem,
    ({
      payload,
      state: { requiresAttentionItems },
    }: PayloadAndState<
      ToDoOrRequiresAttentionsUpdateData,
      DashboardState
    >) => ({
      requiresAttentionItems: [
        ...dashboardItemUpdate(requiresAttentionItems, payload),
      ],
    }),
  ),
  // multi update RequiresAttentionItem assignees
  on(
    DashboardActions.multiUpdateRequiresAttentionAssignees,
    ({
      payload,
      state: { requiresAttentionItems },
    }: PayloadAndState<MultiUpdateAssignees, DashboardState>) => ({
      requiresAttentionItems: [
        ...multiSetAssignees(requiresAttentionItems, payload),
      ],
    }),
  ),
  // get AccountingItems
  on(
    DashboardActions.getAccountingItems,
    ({
      payload: accountingItems,
    }: PayloadAndState<
      DashboardItem<AccountingNotificationData>[],
      DashboardState
    >) => ({
      accountingItems,
    }),
    { ...ERROR_MASK, fetchingMask: 'isAccountingItemsFetching' },
  ),
  // get ReceivedDirectMessage
  on(
    DashboardActions.getDirectMessage,
    ({
      payload: directMessages,
    }: PayloadAndState<
      DashboardItem<IReceivedDirectMessage>[],
      DashboardState
    >) => ({
      directMessages,
    }),
    { ...ERROR_MASK, fetchingMask: 'isDirectMessagesFetching' },
  ),
  on(
    DashboardActions.setCompletedDirectMessage,
    ({
      payload: { _id, isCompleted },
      state: { directMessages },
    }: PayloadAndState<SetCompletedDirectMessage, DashboardState>) => ({
      directMessages: isCompleted
        ? directMessages.map(
            (directMessage: DashboardItem<IReceivedDirectMessage>) => ({
              ...directMessage,
              notificationData: {
                ...directMessage.notificationData,
                isCompleted:
                  directMessage._id === _id
                    ? true
                    : directMessage.notificationData.isCompleted,
              },
            }),
          )
        : directMessages.filter(
            (directMessage: DashboardItem<IReceivedDirectMessage>) =>
              directMessage._id !== _id,
          ),
    }),
  ),
  on(
    DashboardActions.multiSetCompletedDirectMessage,
    ({
      payload: { recordIds, isCompleted },
      state: { directMessages },
    }: PayloadAndState<MultiSetCompletedDirectMessage, DashboardState>) => ({
      directMessages: isCompleted
        ? directMessages.map(
            (directMessage: DashboardItem<IReceivedDirectMessage>) => ({
              ...directMessage,
              notificationData: {
                ...directMessage.notificationData,
                isCompleted: recordIds.includes(directMessage._id)
                  ? true
                  : directMessage.notificationData.isCompleted,
              },
            }),
          )
        : directMessages.filter(
            (directMessage: DashboardItem<IReceivedDirectMessage>) =>
              !recordIds.includes(directMessage._id),
          ),
    }),
  ),
);
