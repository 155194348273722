import React, { FC, PropsWithChildren } from 'react';
import { DropDownSelectorValues } from '@symfa-inc/providence-types';
import { getDDVByIdOrUndefined } from '@core/utils/methods';
import {
  TableContainer as ContentContainer,
  ViewElement,
} from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import { LeasingInfoProps } from '../../models';

export const LeasingInformation: FC<LeasingInfoProps> = ({
  leasingData,
  projectBundleID,
}: PropsWithChildren<LeasingInfoProps>) => (
  <ContentContainer header="Leasing Information">
    <div className="information">
      <ViewElement
        additionClassName="left"
        label="Structural Result"
        value={
          getDDVByIdOrUndefined(
            DropDownSelectorValues.StructuralResult,
            leasingData?.structural?.structuralResult,
          )?.value
        }
      />
      <ViewElement
        additionClassName="left"
        label="Leasing Notes"
        value={leasingData?.leasingNote}
      />
    </div>
    <PrimaryButton
      className="action-button"
      title="View Details"
      target="_blank"
      href={`/leasing/${projectBundleID}/details`}
    />
  </ContentContainer>
);
