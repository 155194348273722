import {
  ExtendedRouterProps,
  PropsResolvers,
  Resolver,
} from '@wellyes/react-router-extended/dist/router/types';
import { Route, Tab } from '@models/classes';
import { MapRouterFn } from '@models/types';

export const routeMapper: MapRouterFn<ExtendedRouterProps> = (
  route: Route,
) => ({
  path: route.path,
  component: route.component,
  ...(route.guards.length
    ? {
        guards: route.guards,
        redirectUrl: route.redirectURL,
      }
    : {}),
  ...(route.resolvers.length
    ? {
        resolvers: route.resolvers.reduce(
          (acc: PropsResolvers, resolver: Resolver, index: number) => ({
            ...acc,
            [index.toString()]: resolver,
          }),
          {},
        ),
      }
    : {}),
  ...(route.tabs.length
    ? {
        childs: route.tabs.filter((tab: Tab) => !tab.isLink).map(routeMapper),
      }
    : {}),
  exact: route.exact,
});
