import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { ApiMethods } from '@models/enums';
import {
  PaginatedResponse,
  SiteAuditBrowse,
  SiteAuditBrowseFilter,
  SiteAuditUpdateData,
} from '@models/interfaces';
import { FileType, ProjectDetailsWithoutCategories } from '@models/types';
import { BreadcrumbsActions, SiteAuditActions } from '@store/actions';
import { ProjectCategoriesEffects, ProjectsEffects } from '@store/effects';
import { CommonHttpService } from './common-http.service';

export class SiteAuditHttpService extends CommonHttpService {
  @StoredRequest(SiteAuditActions.getSiteAuditBrowseDataAction)
  getSiteAuditBrowse(
    params: SiteAuditBrowseFilter,
  ): Promise<PaginatedResponse<SiteAuditBrowse[]>> {
    return this.http.callApi.get(
      `${this.apiUrl}/engineering/site-audits`,
      null,
      {
        params,
      },
    );
  }

  @StoredRequest(SiteAuditActions.updateSiteAuditBrowseDataAction)
  updateSiteAuditBrowse(
    params: SiteAuditBrowseFilter,
    body: { date: string },
  ): Promise<PaginatedResponse<SiteAuditBrowse[]>> {
    return this.http.callApi.patch(
      `${this.apiUrl}/engineering/site-audits`,
      body,
      {
        params,
      },
    );
  }

  @StoredRequest(
    SiteAuditActions.getSiteAuditDetailsAction,
    BreadcrumbsActions.Engineering.siteAudit,
  )
  async getSiteAudit(
    projectBundleID: string,
  ): Promise<ProjectDetailsWithoutCategories> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/engineering/site-audits/${projectBundleID}`,
    );

    return ProjectsEffects.setHoldOptions(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectCategoriesEffects.setProjectCategories(response),
      ),
    );
  }

  getSiteAuditPDF(id: string): Promise<void> {
    return this.downloadFile(
      ApiMethods.Get,
      `${this.apiUrl}/engineering/site-audits/${id}/export`,
    );
  }

  @StoredRequest(SiteAuditActions.updateSiteAuditDetailsAction)
  async updateSiteAudit(
    id: string,
    body: SiteAuditUpdateData,
  ): Promise<ProjectDetailsWithoutCategories> {
    const response = await this.http.callApi.patch(
      `${this.apiUrl}/engineering/site-audits/${id}`,
      body,
      { params: { id } },
    );

    return ProjectCategoriesEffects.setProjectCategories(response);
  }

  uploadSiteAuditPDF(file: FileType): Promise<string> {
    return this.uploadFile(
      ApiMethods.Post,
      `${this.apiUrl}/engineering/site-audits/upload-pdf`,
      file,
    ) as Promise<string>;
  }
}
