import { ReactNode } from 'react';
import { ColumnsType } from 'antd/es/table';
import { homeProjectCategoryRender } from '@core/utils/methods/renders';
import { tableSorter } from '@core/utils/methods/table-sorter';
import { DashboardProjectData } from '@models/interfaces';

export const PROJECT_DATA_COLUMNS: ColumnsType = [
  {
    title: 'Site Number',
    dataIndex: 'projectData',
    key: 'siteNumber',
    sorter: tableSorter(['projectData', 'siteNumber']),
    render: ({ siteNumber }: DashboardProjectData): ReactNode => siteNumber,
  },
  {
    title: 'Project Bundle ID',
    dataIndex: 'projectData',
    key: 'projectBundleID',
    sorter: tableSorter(['projectData', 'projectBundleID']),
    render: ({ projectBundleID }: DashboardProjectData): ReactNode =>
      projectBundleID,
  },
  {
    title: 'Project Type',
    dataIndex: 'projectData',
    key: 'projectType',
    sorter: tableSorter(['projectData', 'projectType']),
    render: ({ projectType }: DashboardProjectData): ReactNode => projectType,
  },
  {
    title: 'PACE Project',
    dataIndex: 'projectData',
    key: 'PACEProjectNumber',
    render: ({ PACEProjectNumbers }: DashboardProjectData): ReactNode =>
      homeProjectCategoryRender(PACEProjectNumbers),
  },
  {
    title: 'IWM Job Number',
    dataIndex: 'projectData',
    key: 'IWMJobNumber',
    render: ({ IWMJobNumbers }: DashboardProjectData): ReactNode =>
      homeProjectCategoryRender(IWMJobNumbers),
  },
  {
    title: 'Oracle Project',
    dataIndex: 'projectData',
    key: 'oracleProjectNumber',
    render: ({ oracleProjectNumbers }: DashboardProjectData): ReactNode =>
      homeProjectCategoryRender(oracleProjectNumbers),
  },
];
