import { PaceTaskType } from '@symfa-inc/providence-types';

export const getCurrentDateType = (value: PaceTaskType): string => {
  switch (value) {
    case PaceTaskType.ActualDate:
      return 'Actual Date';
    case PaceTaskType.ForecastedDate:
      return 'Forecasted Date';
    case PaceTaskType.PACEActualDate:
      return 'PACE Actual Date';
    case PaceTaskType.PACEForecastedDate:
      return 'PACE Forecasted Date';
    default:
      throw new Error('Incorrect value');
  }
};
