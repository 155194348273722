import React, { ReactNode } from 'react';
import { Popover } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import {
  AdminCountyData,
  AdminDropdownValuesData,
  JurisdictionDetails,
  SiteBrowse,
} from '@models/interfaces';
import { Browse } from '@models/types';
import { RiskPopup } from '@shared/components';
import { SiteRisk } from '@shared/modules/browse-page/models/enums';

export const SITE_BROWSE_COLUMNS: ColumnsType<Browse> = [
  {
    dataIndex: 'isHighRisk',
    key: 'isHighRisk',
    width: 40,
    render: (risk: boolean, recordProp: Browse): ReactNode => {
      const record = recordProp as SiteBrowse;

      switch (true) {
        case record.isSiteOn === false:
          return (
            <Popover
              content={
                <RiskPopup
                  risk={SiteRisk.Inactive}
                  disablingNote={record.disablingNote}
                />
              }
              placement="bottomLeft"
            >
              <i className={`circle circle_${SiteRisk.Inactive}`} />
            </Popover>
          );
        case risk:
          return (
            <Popover
              content={
                <RiskPopup
                  risk={SiteRisk.High}
                  riskCategory={record.riskCategory?.value}
                />
              }
              placement="bottomLeft"
            >
              <i className={`circle circle_${SiteRisk.High}`} />
            </Popover>
          );
        default:
          return null;
      }
    },
  },
  {
    title: 'Alternate Site Info',
    dataIndex: 'alternateSiteInfo',
    width: 170,
    key: 'alternateSiteInfo',
    sorter: true,
  },
  {
    title: 'City',
    key: 'city',
    width: 175,
    dataIndex: 'city',
    sorter: true,
  },
  {
    title: 'County',
    key: 'county',
    width: 96,
    dataIndex: 'county',
    sorter: true,
    render: (county: AdminCountyData): ReactNode => county?.name,
  },
  {
    title: 'FA Location Number',
    dataIndex: 'FALocationNumber',
    width: 190,
    key: 'FALocationNumber',
    sorter: true,
  },
  {
    title: 'Jurisdiction',
    key: 'jurisdiction',
    width: 165,
    dataIndex: 'jurisdiction',
    sorter: true,
    render: (jurisdiction: JurisdictionDetails): ReactNode =>
      jurisdiction?.jurisdictionName,
  },
  {
    title: 'Legacy Site ID',
    dataIndex: 'legacySiteID',
    width: 150,
    key: 'legacySiteID',
    sorter: true,
  },
  {
    title: 'Site Number',
    dataIndex: 'siteNumber',
    width: 135,
    key: 'siteNumber',
    sorter: true,
  },
  {
    title: 'State',
    key: 'state',
    width: 100,
    dataIndex: 'state',
    sorter: true,
    render: (state: AdminCountyData): ReactNode => state?.name,
  },
  {
    title: 'Street Address',
    key: 'streetAddress',
    width: 165,
    dataIndex: 'streetAddress',
    sorter: true,
  },
  {
    title: 'Submarket',
    key: 'submarket',
    width: 140,
    dataIndex: 'submarket',
    sorter: true,
    render: (submarket: AdminDropdownValuesData): ReactNode => submarket?.value,
  },
  {
    title: 'Tower Owner',
    key: 'towerOwner',
    width: 140,
    dataIndex: 'towerOwner',
    sorter: true,
    render: (towerOwner: AdminDropdownValuesData): ReactNode =>
      towerOwner?.value,
  },
  {
    title: 'Tower Owner Site ID',
    key: 'towerOwnerSiteID',
    width: 186,
    dataIndex: 'towerOwnerSiteID',
    sorter: true,
  },
  {
    title: 'Tower Type',
    key: 'towerType',
    width: 126,
    dataIndex: 'towerType',
    sorter: true,
    render: (towerType: AdminDropdownValuesData): ReactNode => towerType?.value,
  },
  {
    title: 'Zip',
    key: 'zip',
    width: 95,
    dataIndex: 'zip',
    sorter: true,
  },
  {
    title: 'USID',
    dataIndex: 'USID',
    width: 150,
    key: 'USID',
    sorter: true,
  },
];
