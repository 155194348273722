import { LeasingLeaseAuditUpdateData } from '@models/types';

export const LEASE_AUDIT_DATES: Array<keyof LeasingLeaseAuditUpdateData> = [
  'leaseAuditComplete',
  'documentDate',
  'currentTermStartDate',
  'currentTermEndDate',
  'finalTermEndDate',
  'mostRecentSADate',
];
