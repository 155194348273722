import { IRule, ISchedule, ISelectorOption } from '@symfa-inc/providence-types';
import { actionCreatorFactory } from '@core/utils/factories';
import { DirectMessageData, OptionProps } from '@models/interfaces';

const actionCreator = actionCreatorFactory('NOTIFICATION');

export namespace NotificationActions {
  export const getPaceTaskList = actionCreator.createAsyncAction<OptionProps[]>(
    'GET_PACE_TASKS_LIST',
  );
  export const getUserList =
    actionCreator.createAsyncAction<ISelectorOption[]>('GET_USER_LIST');
  // Schedule
  export const getSchedules =
    actionCreator.createAsyncAction<ISchedule[]>('GET_SCHEDULES');
  export const createSchedule =
    actionCreator.createAsyncAction<ISchedule>('CREATE_SCHEDULE');
  export const updateSchedule =
    actionCreator.createAsyncAction<ISchedule>('UPDATE_SCHEDULE');
  export const deleteSchedule =
    actionCreator.createAsyncAction<string>('DELETE_SCHEDULE');
  // Rules
  export const getRules = actionCreator.createAsyncAction<IRule[]>('GET_RULES');
  export const createRule =
    actionCreator.createAsyncAction<IRule>('CREATE_RULE');
  export const updateRule =
    actionCreator.createAsyncAction<IRule>('UPDATE_RULE');
  export const deleteRule =
    actionCreator.createAsyncAction<string>('DELETE_RULE');
  // Email
  export const getEmailCertificationDate =
    actionCreator.createAsyncAction<string>('GET_EMAIL_CERTIFICATION_DATE');
  // Direct Message
  export const getDirectMessages = actionCreator.createAsyncAction<
    DirectMessageData[]
  >('GET_DIRECT_MESSAGES');
  export const removeDirectMessage = actionCreator.createAsyncAction<string>(
    'REMOVE_DIRECT_MESSAGE',
  );
  export const multiRemoveDirectMessages = actionCreator.createAsyncAction<
    string[]
  >('MULTI_REMOVE_DIRECT_MESSAGES');
}
