import React, { FC, PropsWithChildren } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import deleteIcon from 'assets/icons/delete-icon.svg';
import editIcon from 'assets/icons/edit-icon.svg';
import classNames from 'classnames';
import { ModalMainTypes } from '@models/enums';
import {
  ConfigurablePanelHeaderProps,
  JURISDICTION_CAN_USER_EDIT,
} from '../../models';

import './styles.scss';

export const ConfigurablePanelHeader: FC<ConfigurablePanelHeaderProps> = ({
  header,
  openModal,
  openDeleteModal,
  isEditing,
}: PropsWithChildren<ConfigurablePanelHeaderProps>) => {
  const formValues = {
    ...header,
    projectType: header.projectType.id,
    projectSubType: header.projectSubType.id,
  };

  const menu = (
    <Menu>
      <Menu.Item
        onClick={(): void => openModal(ModalMainTypes.Edit, formValues)}
      >
        <img src={editIcon} alt="edit-icon" />
      </Menu.Item>
      <Menu.Item onClick={(): void => openDeleteModal(header.id)}>
        <img src={deleteIcon} alt="delete-icon" />
      </Menu.Item>
    </Menu>
  );

  const handleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    event.stopPropagation();
  };

  return (
    <div className="prov-jurisdiction-configurable-panel-header">
      <span className="prov-jurisdiction-configurable-panel-header__header">
        {header.projectType.value} - {header.projectSubType.value}
      </span>
      <div
        className={classNames(
          'prov-jurisdiction-configurable-panel-header__actions',
          { 'no-hoverable': isEditing },
        )}
        onClick={handleClick}
      >
        {JURISDICTION_CAN_USER_EDIT() && (
          <Dropdown
            overlay={menu}
            placement="bottomCenter"
            className="action"
            openClassName="active"
            overlayClassName="prov-jurisdiction-configurable-panel-header__panel-actions"
          >
            <MoreOutlined className="more-icon" />
          </Dropdown>
        )}
      </div>
    </div>
  );
};
