import React, { FC, PropsWithChildren } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Details } from '@shared/modules/details';

import './styles.scss';

export const EquipmentManagement: FC<RouteComponentProps> = (
  props: PropsWithChildren<RouteComponentProps>,
) => (
  <Details
    {...props}
    className="prov-equipment-management"
    headerBar={false}
    destroyInactiveTabPane
  />
);
