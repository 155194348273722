import React, { FC, PropsWithChildren } from 'react';
import { Layout } from 'antd';
import { AuthLayoutProps } from './models/interfaces';

import './styles.scss';

const { Content } = Layout;

export const AuthLayout: FC<AuthLayoutProps> = ({
  children,
  header = 'Log In',
}: PropsWithChildren<AuthLayoutProps>) => (
  <Content className="prov-auth-layout">
    <div className="prov-auth-layout__form">
      <div className="header">{header}</div>
      <div className="wrap">{children}</div>
    </div>
  </Content>
);
