import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { HttpService } from '@core/services';
import { ScopingHttpService } from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';
import { AssociatedProjects } from '@shared/components';
import { ScopingSelectors } from '@store/selectors';

export const ScopingAssociatedProjects: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const [projectBundleID] = getProjectBundleIDParamFromUrl();

  const scopingAssociatedProjects =
    HttpService.getHttpRequests(ScopingHttpService);

  const { FALocationNumber, projects, isSiteOn } = useSelector(
    ScopingSelectors.getScopingSiteInfo,
  );

  const getData = useCallback(async (): Promise<void> => {
    await scopingAssociatedProjects.getScopingAssociatedProjects(
      projectBundleID,
    );
  }, []);

  useEffect(() => {
    getData();
  }, []);

  return (
    <AssociatedProjects
      {...props}
      linkId={FALocationNumber?.toString()}
      data={projects ?? []}
      isSiteOn={isSiteOn || false}
    />
  );
};
