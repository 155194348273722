import React from 'react';
import { Modal } from '@shared/modules';
import { PreviewModalProps } from './models';

import './styles.scss';

export const PreviewModal = ({
  visible,
  toggleModal,
  imagePath,
}: PreviewModalProps) => (
  <Modal
    visible={visible}
    onCancel={toggleModal}
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={{ style: { display: 'none' } }}
    className="prov-preview-img-modal"
    width="fit-content"
  >
    <img
      src={imagePath}
      alt="site pictures upload preview"
      crossOrigin="use-credentials"
    />
  </Modal>
);
