import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { SubPage, Tab } from '@symfa-inc/providence-types';
import { canUserEditByProjectStatusAndRoles } from '@core/utils/methods';
import { TabComponentProps } from '@models/interfaces';
import { EditablePage } from '@shared/components';
import {
  LeasingSelectors,
  ProjectSelectors,
  UserSelectors,
} from '@store/selectors';
import { Workflow } from './components';

export const LeasingWorkflow: FC<TabComponentProps> = ({
  isActive,
}: PropsWithChildren<TabComponentProps>) => {
  const { browse } = useSelector(UserSelectors.getLeasingPermissions);
  const projectType = useSelector(LeasingSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit('leasing', SubPage.LeasingBrowse, Tab.Workflow),
  );
  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <EditablePage
      isActive={isActive}
      editableComponent={Workflow}
      permissions={browse}
      canUserEdit={
        canEdit &&
        projectTypePermission &&
        canUserEditByProjectStatusAndRoles(projectStatusId, userRoles)
      }
    />
  );
};
