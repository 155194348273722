import { actionCreatorFactory } from '@core/utils/factories';
import { OptionProps, SiteImageData } from '@models/interfaces';

const actionCreator = actionCreatorFactory('SITE_PICTURES_UPLOAD');

export namespace SitePicturesUploadActions {
  export const getFALocationNumberOptionsList = actionCreator.createAsyncAction<
    OptionProps[]
  >('GET_FA_LOCATION_NUMBER_OPTIONS');

  export const getSiteImages =
    actionCreator.createAsyncAction<SiteImageData[]>('GET_SITE_IMAGES');

  export const uploadSiteImages =
    actionCreator.createAsyncAction<SiteImageData[]>('UPLOAD_SITE_IMAGES');

  export const deleteSiteImageAction =
    actionCreator.createAsyncAction<string>('DELETE_SITE_IMAGE');
}
