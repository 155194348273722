import { distructAndDispatch } from '@core/utils/methods/destruct-and-dispatch';
import {
  LeasingDetails,
  ProjectCategory,
  ProjectPaceTask,
  RadData,
} from '@models/interfaces';
import { ProjectActions } from '../actions/project.actions';
import { ProjectCategoriesActions } from '../actions/project-categories.actions';
// eslint-disable-next-line no-restricted-imports
import store from '../index';

export namespace LeasingEffects {
  export const setPACETasksTableData = distructAndDispatch<
    LeasingDetails,
    ProjectPaceTask[]
  >('PACETasksTableData', ProjectActions.updateProjectPaceTasksAction);

  export const setPACETasks = distructAndDispatch<
    LeasingDetails,
    ProjectPaceTask[]
  >('PACETasks', ProjectActions.updateProjectPaceTaskFields);

  export const setProjectCategories = distructAndDispatch<
    LeasingDetails,
    ProjectCategory[]
  >('categories', ProjectCategoriesActions.setProjectCategoriesAction);

  export const setRAD = distructAndDispatch<LeasingDetails, RadData[]>(
    'RAD',
    ProjectActions.setRADAction,
  );

  export const setCurrentProvidenceAgent = distructAndDispatch<
    LeasingDetails,
    string
  >('providenceAgentName', ProjectActions.setCurrentProvidenceAgentAsString);

  export const setHoldOptions = (leasing: LeasingDetails) => {
    store.dispatch(ProjectActions.setHoldOptionsByLeasing.done(leasing));

    return leasing;
  };
}
