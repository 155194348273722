import { PaceTaskType } from '@symfa-inc/providence-types';
import { getPaceTaskViewValue } from '@models/constants';
import { arraySort } from '../../core/utils/methods';
import { browseReducer } from '../../core/utils/methods/browse-sorter-reducer';
import { createReducer, on } from '../../core/utils/store';
import {
  FiltersSetData,
  FiltersSetDataExtended,
  FiltersSetDataPaceTaskFilter,
  OptionProps,
  PaceTask,
  PaginatedResponse,
  PayloadAndState,
  PMBrowseFilterOptionsResponse,
  ProjectBrowse,
  ProjectManagementDetails,
} from '../../models/interfaces';
import { ProjectManagementActions } from '../actions/project-management.actions';

const mainSorter = <T>(data: T[]): T[] =>
  arraySort(data, 'ASC', 'name' as keyof T);

const getExtendedFilterSet = (
  filterSet: FiltersSetData,
): FiltersSetDataExtended => ({
  ...filterSet,
  paceTaskFilter: filterSet.paceTaskFilter.map(
    ({ id, field, ...rest }: FiltersSetDataPaceTaskFilter) => ({
      ...rest,
      id: `${id}/${field}`,
    }),
  ),
  setFields: Object.keys(filterSet.filter),
  paceTaskFields: filterSet.paceTaskFilter.map(
    ({ id, field }: FiltersSetDataPaceTaskFilter) => `${id}/${field}`,
  ),
});

export interface ProjectManagementState {
  projectManagementBrowse: PaginatedResponse<ProjectBrowse>;
  currentProjectManagement: ProjectManagementDetails;
  filtersOptions: PMBrowseFilterOptionsResponse;
  filterSets: FiltersSetDataExtended[];
  pmScreenPaceTasks: PaceTask[];
  pmScreenPaceTasksOptions: OptionProps[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: ProjectManagementState = {
  projectManagementBrowse: {
    items: [],
    total: 0,
  },
  currentProjectManagement: {} as ProjectManagementDetails,
  filterSets: [],
  filtersOptions: {} as PMBrowseFilterOptionsResponse,
  pmScreenPaceTasks: [],
  pmScreenPaceTasksOptions: [],
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  on(
    ProjectManagementActions.getPMBrowseDataAction,
    browseReducer('projectManagementBrowse'),
  ),
  on(
    ProjectManagementActions.getPMDetailsDataAction,
    ({
      payload: currentProjectManagement,
    }: PayloadAndState<ProjectManagementDetails, ProjectManagementState>) => ({
      currentProjectManagement,
    }),
  ),
  // get filters data
  on(
    ProjectManagementActions.getPMBrowseFiltersData,
    ({
      payload,
    }: PayloadAndState<FiltersSetData[], ProjectManagementState>) => ({
      filterSets: payload.map((set: FiltersSetData) =>
        getExtendedFilterSet(set),
      ),
    }),
  ),
  // ADD SET
  on(
    ProjectManagementActions.createPMBrowseFiltersSetAction,
    ({
      payload,
      state: { filterSets },
    }: PayloadAndState<FiltersSetData, ProjectManagementState>) => ({
      filterSets: mainSorter([...filterSets, getExtendedFilterSet(payload)]),
    }),
  ),
  // EDIT SET
  on(
    ProjectManagementActions.updatePMBrowseFiltersSetAction,
    ({
      payload,
      state: { filterSets },
    }: PayloadAndState<FiltersSetData, ProjectManagementState>) => ({
      filterSets: mainSorter(
        filterSets.map((filterSet: FiltersSetDataExtended) =>
          filterSet.id === payload.id
            ? getExtendedFilterSet(payload)
            : filterSet,
        ),
      ),
    }),
  ),
  // DELETE SET
  on(
    ProjectManagementActions.deletePMBrowseFiltersSetAction,
    ({
      payload: id,
      state: { filterSets },
    }: PayloadAndState<string, ProjectManagementState>) => ({
      filterSets: [
        ...filterSets.filter((set: FiltersSetData) => set.id !== id),
      ],
    }),
  ),
  // GET PM SCREEN PACE TASKS OPTIONS
  on(
    ProjectManagementActions.getPMScreenPaceTasks,
    ({ payload }: PayloadAndState<PaceTask[], ProjectManagementState>) => ({
      pmScreenPaceTasks: payload,
      pmScreenPaceTasksOptions: payload.flatMap(
        ({ id, taskName, paceTaskID }: PaceTask) =>
          Object.entries(PaceTaskType)
            .sort(
              (
                [_, a]: [string, PaceTaskType],
                [__, b]: [string, PaceTaskType],
              ) => a.localeCompare(b),
            )
            .map(([key, value]: [string, PaceTaskType]) => ({
              value: `${id}/${value}`,
              viewValue: getPaceTaskViewValue(taskName, key, paceTaskID),
            })),
      ),
    }),
  ),
  on(
    ProjectManagementActions.getPMBrowseOptions,
    ({
      payload: filtersOptions,
    }: PayloadAndState<
      PMBrowseFilterOptionsResponse,
      ProjectManagementState
    >) => ({
      filtersOptions,
    }),
  ),
);
