import React, { FC, PropsWithChildren, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sticky, StickyContainer } from 'react-sticky';
import { DatabaseOutlined, MoreOutlined } from '@ant-design/icons';
import {
  DIRECT_MESSAGE_AREA_PATTERNS,
  DropDownSelectorValues,
  ProjectStatus,
} from '@symfa-inc/providence-types';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import { ActionRes, OptionProps, SiteOptions } from '@models/interfaces';
import { Modal } from '@shared/modules';
import { ProjectCategoriesActions } from '@store/actions';
import {
  CommonSelectors,
  DDVSelectors,
  ProjectSelectors,
} from '@store/selectors';
import { SiteImagesCarousel } from '../site-images-carousel';
import { HeaderBarProps } from './models/interfaces';
import { InfoBox, Item } from './components';
import { DirectMessageManager } from './modals';

import './styles.scss';

export const HeaderBar: FC<HeaderBarProps> = ({
  className,
  category = false,
  site,
  siteOptions = [
    {
      key: 'FALocationNumber',
      label: 'FA Location Number',
    },
    {
      key: 'siteNumber',
      label: 'Site Number',
    },
  ],
  showTowerOwner = true,
  withoutTabPanel = false,
}: PropsWithChildren<HeaderBarProps>) => {
  const dispatch = useDispatch();

  const { currentProjectCategories, projectCategoriesTableVisible } =
    useSelector(ProjectSelectors.getProjectCategories);
  const holdOptions = useSelector(ProjectSelectors.getHoldOptions);
  const providenceAgent = useSelector(ProjectSelectors.getProvidenceAgent);
  const towerOwners = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(DropDownSelectorValues.TowerOwner),
  );
  const riskCategories = useSelector(
    DDVSelectors.getDDVValuesAsOptionsByType(
      DropDownSelectorValues.RiskCategory,
    ),
  );
  const routePath = useSelector(CommonSelectors.getRoutePath);

  const [visibleDMManager, setVisibleDMManager] = useState<boolean>(false);
  const [imgModalVisible, setImgModalVisible] = useState<boolean>(false);

  const menu = (
    <Menu>
      {DIRECT_MESSAGE_AREA_PATTERNS.has(routePath) && (
        <Menu.Item onClick={(): void => setVisibleDMManager(true)}>
          Direct Messages
        </Menu.Item>
      )}
      <Menu.Item onClick={(): void => setImgModalVisible(true)}>
        Site Pictures
      </Menu.Item>
    </Menu>
  );

  return (
    <StickyContainer>
      <Sticky>
        {(): ReactElement => (
          <div className={classNames('prov-header-bar', className)}>
            <div className="prov-header-bar__left">
              {siteOptions.map(
                ({ label, key }: SiteOptions) =>
                  site && <Item key={key} label={label} value={site[key]} />,
              )}

              <Item
                tooltip
                key="legacySiteID"
                label="Legacy Site ID"
                value={site?.legacySiteID}
              />

              {showTowerOwner && (
                <Item
                  key="towerOwner"
                  label="Tower Owner"
                  value={
                    towerOwners.find(
                      ({ value }: OptionProps) =>
                        (site?.towerOwner as unknown as string) === value,
                    )?.viewValue
                  }
                />
              )}
              {category && (
                <>
                  <Item
                    key="projectCategory"
                    label="Project Category"
                    element={
                      <span
                        className={classNames(
                          'item__element project-category',
                          {
                            'project-category--active':
                              !projectCategoriesTableVisible,
                          },
                        )}
                        onClick={(): ActionRes<void> =>
                          dispatch(
                            ProjectCategoriesActions.toggleProjectCategoriesTableAction.done(),
                          )
                        }
                      >
                        {projectCategoriesTableVisible
                          ? 'X'
                          : currentProjectCategories?.length}
                      </span>
                    }
                  />
                  <Item
                    key="providenceAgent"
                    label="Providence Agent"
                    value={providenceAgent}
                  />
                </>
              )}
            </div>
            <div
              className={classNames('prov-header-bar__right', {
                margin: withoutTabPanel,
              })}
            >
              {site?.isSiteOn === false ? (
                <InfoBox
                  status="inactive"
                  title="Off"
                  value={site?.disablingNote}
                />
              ) : (
                site?.isHighRisk && (
                  <InfoBox
                    status="default"
                    title="High Risk Site"
                    value={
                      riskCategories?.find(
                        (riskCategory: OptionProps) =>
                          riskCategory.value ===
                          (site?.riskCategory as unknown as string),
                      )?.viewValue
                    }
                  />
                )
              )}
              {holdOptions?.projectStatus === ProjectStatus.Hold && (
                <InfoBox
                  status="hold"
                  title="Hold"
                  value={holdOptions?.holdReason}
                />
              )}
              <Dropdown
                overlay={menu}
                placement="bottomCenter"
                className="action"
                openClassName="active"
                overlayClassName="prov-header-bar__right__dropdown-menu"
              >
                <Button type="primary">
                  <DatabaseOutlined />
                  <MoreOutlined />
                </Button>
              </Dropdown>
              <Modal
                visible={imgModalVisible}
                onCancel={() => setImgModalVisible(false)}
                title="Site Pictures"
                className="prov-header-bar__site-pictures-modal"
                width="80%"
              >
                <SiteImagesCarousel FALocationNumber={site?.FALocationNumber} />
              </Modal>
              {DIRECT_MESSAGE_AREA_PATTERNS.has(routePath) && (
                <DirectMessageManager
                  visible={visibleDMManager}
                  areaKey={DIRECT_MESSAGE_AREA_PATTERNS.get(routePath)!}
                  onClose={(): void => setVisibleDMManager(false)}
                />
              )}
            </div>
          </div>
        )}
      </Sticky>
    </StickyContainer>
  );
};
