import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { CandidatesState } from '../reducers/candidates.reducer';

const candidatesState = (state: AppState): CandidatesState => state.candidates;

export namespace CandidatesSelectors {
  export const getCandidates = createSelector(
    [candidatesState],
    (state: CandidatesState) => state.candidates,
  );
}
