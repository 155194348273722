import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ModalProps } from '@models/interfaces';
import { ScopingSectorsVersionData } from '@models/types';
import { Modal, Select } from '../../modules/ui-kit';

interface ChooseSectorVersionModalProps
  extends Omit<
    ModalProps,
    | 'type'
    | 'okType'
    | 'cancelType'
    | 'okButtonProps'
    | 'cancelButtonProps'
    | 'onOk'
    | 'title'
    | 'footer'
    | 'titleModify'
  > {
  versions: ScopingSectorsVersionData[];
  onSave: (version: number) => Promise<void> | void;
}
export const ChooseSectorVersionModal: FC<ChooseSectorVersionModalProps> = ({
  onSave,
  onCancel,
  versions,
  ...props
}: PropsWithChildren<ChooseSectorVersionModalProps>) => {
  const [versionForm] = useForm();
  const versionOptions = useMemo(
    () =>
      versions.map(version => ({
        value: version.version,
        viewValue: version.isCurrent
          ? `${version.version} (Current)`
          : version.version,
      }))!,
    [versions],
  );
  const selectedVersion = useMemo(
    () => versions.find(version => version.isSelected)!,
    [versions],
  );

  useEffect(() => {
    versionForm.setFieldsValue({ version: selectedVersion.version });
  }, [selectedVersion]);

  const onYes = () => {
    const version = versionForm.getFieldValue('version');

    onSave(version);
  };

  return (
    <Modal
      title="Save Version as Current"
      onCancel={onCancel}
      okText="Yes"
      cancelText="No"
      onOk={onYes}
      {...props}
    >
      <Form form={versionForm} labelCol={{ span: 8 }} wrapperCol={{ span: 11 }}>
        <Select options={versionOptions} id="version" label="Version" />
      </Form>
    </Modal>
  );
};
