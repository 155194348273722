import React, { FC, PropsWithChildren } from 'react';
import { Form } from 'antd';
import { GeneralUIKit } from '../../models/interfaces';

export const FormItem: FC<GeneralUIKit> = ({
  label,
  id,
  isEditing = true,
  className,
  editingElement,
  readOnlyElement,
  formItemProps: { name, ...formItemProps } = {},
}: PropsWithChildren<GeneralUIKit>) => (
  <Form.Item
    className={className}
    label={label}
    htmlFor={id}
    shouldUpdate={!isEditing}
    {...(isEditing ? { name: name ?? id } : {})}
    {...formItemProps}
  >
    {isEditing || !readOnlyElement ? editingElement : readOnlyElement}
  </Form.Item>
);
