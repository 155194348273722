import { AdminDefaultTowerAgentData } from '@models/interfaces';

export const DEFAULT_TOWER_AGENT_DEFAULT_VALUE: AdminDefaultTowerAgentData = {
  id: '',
  providenceAgent: {
    id: '',
    firstName: '',
    lastName: '',
  },
  towerOwners: [],
};
