import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Radio } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormItem } from '@shared/modules';
import {
  FormElementRadioGroup,
  RadioButtonProps,
} from '../../models/interfaces';

export const RadioGroup: FC<FormElementRadioGroup> = ({
  id,
  elementProps,
  buttons,
  ...props
}: PropsWithChildren<FormElementRadioGroup>) => (
  <FormItem
    id={id}
    editingElement={
      <Radio.Group id={id} {...elementProps}>
        {buttons.map((button: RadioButtonProps) => (
          <Radio {...button} key={id + button.value}>
            {button.viewValue}
          </Radio>
        ))}
      </Radio.Group>
    }
    readOnlyElement={(formInstance: FormInstance): ReactNode => {
      const value = formInstance.getFieldValue(props.formItemProps?.name ?? id);

      return (
        value !== null &&
        value !== undefined &&
        buttons.find(
          (button: RadioButtonProps): boolean => button.value === value,
        )?.viewValue
      );
    }}
    {...props}
  />
);
