import {
  DateStringOrNull,
  FRONTEND_DATE_FORMAT,
} from '@symfa-inc/providence-types';
import { RuleObject } from 'rc-field-form/es/interface';
import { DateWorker, dateWorker } from '@core/utils/date-worker';
import { DateWorkerNull } from '@models/types';
import { getDiff, isExcludedValidationDate } from '../methods';

export const ACTUAL_DATE = (dataValue?: DateWorker | DateStringOrNull) => [
  {
    validator: (_: RuleObject, inputValue?: DateWorkerNull): Promise<void> => {
      if (
        dateWorker(dataValue).format(FRONTEND_DATE_FORMAT) !==
          dateWorker(inputValue).format(FRONTEND_DATE_FORMAT) &&
        inputValue &&
        !isExcludedValidationDate(inputValue)
      ) {
        const MAX_DAYS = 30;
        const diff = getDiff(inputValue!);
        const isFutureDate = diff >= 1;

        let errorMessage = '';

        if (isFutureDate || Math.abs(diff) > MAX_DAYS) {
          errorMessage = isFutureDate
            ? 'Must be today’s date or a prior date.'
            : 'Must be within 30 calendar days of current date.';
        }

        return errorMessage
          ? Promise.reject(new Error(errorMessage))
          : Promise.resolve();
      }

      return Promise.resolve();
    },
  },
];
