import {
  FinancialOOMInformation,
  FinancialServicesData,
  FinancialSiteFeesData,
} from '@models/interfaces';

export const FINANCIAL_SITE_FEES_DEFAULT_VALUE: FinancialSiteFeesData = {
  id: '',
  paymentDate: null,
  issuedDate: null,
  amount: undefined,
  type: undefined,
  siteFeeType: null,
  paymentType: null,
  checkNumber: null,
  PONumber: null,
  creditCard: null,
  payee: undefined,
  siteFeesNote: undefined,
};

export const FINANCIAL_SERVICES_DEFAULT_VALUE: FinancialServicesData = {
  id: '',
  issuedDate: null,
  driver: {
    id: '',
    driverID: '',
    driverName: '',
    driverNote: '',
  },
  PONumber: null,
  cost: null,
  requestNote: '',
};

export const FINANCIAL_OOM_INFORMATION_DEFAULT_VALUE: FinancialOOMInformation =
  {
    id: '',
    OOMDate: null,
    OOMAmount: 0,
    OOMTotalAmount: 0,
    PACETaskIdentification: '',
    paceTaskID: '',
    taskName: '',
    actualDate: null,
  };
