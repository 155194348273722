import React, { ReactElement, ReactNode } from 'react';
import {
  IRequiresAttention,
  ISelectorOption,
  IToDo,
} from '@symfa-inc/providence-types';
import { assigneesIdsToNamedAssignees } from './assignees-ids-to-named-assignees';

export const assigneesRenderer =
  (adminsList: ISelectorOption[]) =>
  <RecordType extends IRequiresAttention | IToDo>({
    assignees,
  }: RecordType): ReactElement => {
    const namedAssignees = assigneesIdsToNamedAssignees(assignees, adminsList);

    return (
      <div className="list">
        {namedAssignees.map(
          (namedAssignee: string): ReactNode => (
            <span key={namedAssignee} className="list__item">
              {namedAssignee}
            </span>
          ),
        )}
      </div>
    );
  };
