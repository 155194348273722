import { arraySort } from '@core/utils/methods';
import { createReducer, on } from '@core/utils/store';
import {
  AdminDefaultTowerAgentData,
  AdminDefaultTowerAgentsOptions,
  IdAndValue,
  OptionProps,
  PayloadAndState,
  TowerOwnersOptions,
} from '@models/interfaces';
import { DefaultTowerAgentActions } from '@store/actions';

export interface DefaultTowerAgentState {
  agentsOptions: OptionProps[];
  ownersOptions: TowerOwnersOptions[];
  defaultTowerAgentRecords: AdminDefaultTowerAgentData[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: DefaultTowerAgentState = {
  agentsOptions: [],
  ownersOptions: [],
  defaultTowerAgentRecords: [],
  isFetching: false,
  errors: false,
};

const mainSorter = (data: TowerOwnersOptions[]): TowerOwnersOptions[] =>
  arraySort(data, 'ASC', 'viewValue');

const processTowerOptions = (
  data: AdminDefaultTowerAgentData[],
  ownersOptions: TowerOwnersOptions[],
): TowerOwnersOptions[] =>
  mainSorter(
    ownersOptions.map((ownerOption: TowerOwnersOptions) => ({
      ...ownerOption,
      isAssigned: data.some((defaultTowerAgent: AdminDefaultTowerAgentData) =>
        defaultTowerAgent.towerOwners.find(
          (owner: IdAndValue) => owner.id === ownerOption.value,
        ),
      ),
    })),
  );

export const reducer = createReducer(
  initialState,
  // GET ALL TABLE VALUES
  on(
    DefaultTowerAgentActions.getDefaultTowerAgentDataAction,
    ({
      payload: defaultTowerAgentRecords,
    }: PayloadAndState<
      AdminDefaultTowerAgentData[],
      DefaultTowerAgentState
    >) => ({
      defaultTowerAgentRecords,
    }),
  ),
  // GET ALL OPTIONS
  on(
    DefaultTowerAgentActions.getDefaultTowerAgentOptionsAction,
    ({
      payload: { providenceAgents, towerOwners },
    }: PayloadAndState<
      AdminDefaultTowerAgentsOptions,
      DefaultTowerAgentState
    >) => ({
      agentsOptions: providenceAgents,
      ownersOptions: mainSorter(towerOwners),
    }),
  ),
  // ADD NEW RELATION
  on(
    DefaultTowerAgentActions.addDefaultTowerAgentDataAction,
    ({
      payload,
      state: { defaultTowerAgentRecords, ownersOptions },
    }: PayloadAndState<AdminDefaultTowerAgentData, DefaultTowerAgentState>) => {
      const processedPayload = [
        ...defaultTowerAgentRecords.filter(
          (agent: AdminDefaultTowerAgentData) => !(payload.id === agent.id),
        ),
        payload,
      ].sort((a: AdminDefaultTowerAgentData, b: AdminDefaultTowerAgentData) =>
        a.providenceAgent.firstName.localeCompare(b.providenceAgent.firstName),
      );

      return {
        defaultTowerAgentRecords: processedPayload,
        ownersOptions: processTowerOptions(processedPayload, ownersOptions),
      };
    },
  ),
  // UPDATE RELATION
  on(
    DefaultTowerAgentActions.updateDefaultTowerAgentDataAction,
    ({
      payload,
      state: { defaultTowerAgentRecords, ownersOptions },
    }: PayloadAndState<AdminDefaultTowerAgentData, DefaultTowerAgentState>) => {
      const processedPayload = [
        ...defaultTowerAgentRecords.filter(
          (defaultTowerAgentRecord: AdminDefaultTowerAgentData) =>
            defaultTowerAgentRecord.id !== payload.id,
        ),
        payload,
      ].sort((a: AdminDefaultTowerAgentData, b: AdminDefaultTowerAgentData) =>
        a.providenceAgent.firstName.localeCompare(b.providenceAgent.firstName),
      );

      return {
        defaultTowerAgentRecords: processedPayload,
        ownersOptions: processTowerOptions(processedPayload, ownersOptions),
      };
    },
  ),
  // DELETE RELATION
  on(
    DefaultTowerAgentActions.deleteDefaultTowerAgentAction,
    ({
      payload: id,
      state: { defaultTowerAgentRecords, ownersOptions },
    }: PayloadAndState<string, DefaultTowerAgentState>) => {
      const filteredRecords = defaultTowerAgentRecords.filter(
        (defaultTowerAgentRecord: AdminDefaultTowerAgentData) =>
          defaultTowerAgentRecord.id !== id,
      );

      return {
        defaultTowerAgentRecords: filteredRecords,
        ownersOptions: processTowerOptions(filteredRecords, ownersOptions),
      };
    },
  ),
);
