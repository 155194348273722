import { IScopingTowerNote, RADField } from '@symfa-inc/providence-types';
import { browseReducer } from '@core/utils/methods/browse-sorter-reducer';
import { transformFields } from '@core/utils/methods/transform-fields';
import { createReducer, on } from '@core/utils/store';
import {
  REGULATORY_COMPLIANCE_INFO_TRANSFORM_FIELDS,
  RFDS_INFO_TRANSFORM_FIELDS,
  SCOPING_SECTORS_VERSIONS,
  STRUCTURAL_INFO_TRANSFORM_FIELDS,
} from '@models/constants';
import {
  EngineeringBrowse,
  EngineeringDetails,
  EngineeringLeasingInformation,
  EngineeringRegulatoryCompliance,
  EngineeringRFDSPhase1,
  EngineeringWorkflowUpdateData,
  OtherEquipmentData,
  PaginatedResponse,
  PayloadAndState,
  RadData,
  ScopingInfo,
  SiteBrowse,
  StructuralInformation,
  UpdateEngineeringSOFData,
} from '@models/interfaces';
import { CuttedProjectDetails, ScopingSectorsVersion } from '@models/types';
import { EngineeringActions } from '@store/actions';
import {
  getOtherEquipmentInfo,
  getScopingSectorsFromUpdateData,
  getScopingSectorsInfo,
} from '../helpers';

type RADType = { [key in RADField]?: number };

interface EngineeringProjectData
  extends Omit<CuttedProjectDetails, 'civilVendor'> {
  civilVendor?: string;
}

export interface EngineeringBrowseState {
  engineeringBrowseData: PaginatedResponse<EngineeringBrowse>;
  engineeringSiteData: SiteBrowse;
  engineeringProjectData: EngineeringProjectData;
  engineeringLeasingData: EngineeringLeasingInformation;
  engineeringWorkflowData: EngineeringWorkflowUpdateData;
  otherEquipments: OtherEquipmentData[];
  equipmentTypeNames: string[];
  scopingSectorsVersions: ScopingSectorsVersion[];
  scopingInfo: Omit<ScopingInfo, 'otherEquipments' | 'scopingSectorsVersions'>;
  isFetching: boolean;
  errors: boolean;
  RAD: RADType;
}

const initialState: EngineeringBrowseState = {
  engineeringSiteData: {} as SiteBrowse,
  engineeringProjectData: {} as EngineeringProjectData,
  engineeringLeasingData: {} as EngineeringLeasingInformation,
  engineeringWorkflowData: {} as EngineeringWorkflowUpdateData,
  engineeringBrowseData: { items: [], total: 0 },
  isFetching: false,
  errors: false,
  scopingInfo: {} as ScopingInfo,
  scopingSectorsVersions: SCOPING_SECTORS_VERSIONS,
  otherEquipments: [],
  equipmentTypeNames: [],
  RAD: {
    [RADField.A]: undefined,
    [RADField.B]: undefined,
    [RADField.D]: undefined,
    [RADField.G]: undefined,
  },
};

export const reducer = createReducer(
  initialState,
  // GET ENGINEERING BROWSE DATA
  on(
    EngineeringActions.getEngineeringBrowseDataAction,
    browseReducer('engineeringBrowseData'),
  ),
  // GET ENGINEERING DETAILS DATA
  on(
    EngineeringActions.getEngineeringAction,
    ({
      payload: {
        RAD = [],
        site,
        leasing: leasingPayload,
        engineering: engineeringPayload,
        scoping: scopingPayload,
        ...engineeringProjectData
      },
      state: { RAD: RADState, engineeringLeasingData },
    }: PayloadAndState<EngineeringDetails, EngineeringBrowseState>) => {
      const leasing = leasingPayload ?? {};
      const engineering =
        engineeringPayload ?? ({} as EngineeringWorkflowUpdateData);
      const { structural, PALOrNPARequired, ...restLeasingData } = leasing;
      const { RFDSPhase1, regulatoryCompliance } = engineering;
      const scopingData = scopingPayload ?? ({} as ScopingInfo);
      const { otherEquipments, scopingSectorsVersions, ...scopingInfo } =
        scopingData;

      const otherEquipmentInfo = getOtherEquipmentInfo(otherEquipments ?? []);

      const scopingSectorsInfo = scopingSectorsVersions?.length
        ? getScopingSectorsInfo(scopingSectorsVersions)
        : {
            versions: SCOPING_SECTORS_VERSIONS,
            equipmentTypeNames: [],
          };

      return {
        scopingInfo: transformFields(scopingInfo, ['previousMountModels']),
        scopingSectorsVersions: scopingSectorsInfo?.versions,
        otherEquipments: otherEquipmentInfo?.otherEquipments || [],
        equipmentTypeNames: [
          ...(otherEquipmentInfo?.equipmentTypeNames || []),
          ...(scopingSectorsInfo?.equipmentTypeNames || []),
        ],
        RAD: (Object.keys(RADState) as RADField[]).reduce(
          (acc: RADType, key: RADField) => ({
            ...acc,
            [key]: RAD?.find((RADData: RadData) => RADData.RADField === key)
              ?.scoping,
          }),
          {} as RADType,
        ),
        engineeringProjectData: transformFields(engineeringProjectData, [
          'civilVendor',
        ]) as EngineeringProjectData,
        engineeringSiteData: transformFields<SiteBrowse>(site, [
          'towerOwner',
          'riskCategory',
        ]),
        engineeringLeasingData: {
          ...engineeringLeasingData,
          ...restLeasingData,
          structural: transformFields<StructuralInformation>(
            structural,
            STRUCTURAL_INFO_TRANSFORM_FIELDS.splice(1, 1),
          ),
        },
        engineeringWorkflowData: {
          RFDSPhase1: transformFields<EngineeringRFDSPhase1>(
            RFDSPhase1,
            RFDS_INFO_TRANSFORM_FIELDS,
          ),
          regulatoryCompliance:
            transformFields<EngineeringRegulatoryCompliance>(
              { ...regulatoryCompliance, PALOrNPARequired },
              REGULATORY_COMPLIANCE_INFO_TRANSFORM_FIELDS,
            ),
        },
      };
    },
  ),
  // UPDATE ENGINEERING DETAILS WORKFLOW TAB
  on(
    EngineeringActions.updateEngineeringWorkflowAction,
    ({
      payload: { RFDSPhase1, regulatoryCompliance },
    }: PayloadAndState<
      EngineeringWorkflowUpdateData,
      EngineeringBrowseState
    >) => ({
      engineeringWorkflowData: {
        RFDSPhase1,
        regulatoryCompliance,
      },
    }),
  ),
  // UPDATE ENGINEERING DETAILS SOF TAB
  on(
    EngineeringActions.updateEngineeringSOFAction,
    ({
      payload: { scopingNotes, projectInfo, otherEquipments, versions },
      state,
    }: PayloadAndState<UpdateEngineeringSOFData, EngineeringBrowseState>) => {
      const projectInfoData = projectInfo ?? {};
      const scopingNotesInfo = scopingNotes ?? {};

      const {
        isCraneRequired,
        craneHeight,
        RADA,
        RADB,
        RADD,
        RADG,
        azimuthA,
        azimuthB,
        azimuthD,
        azimuthG,
        mountTypes,
        civilVendor,
      } = projectInfoData;
      const { RFDSStatus, ...notes } = scopingNotesInfo;

      return {
        RAD: {
          ...state.RAD,
          ...(RADA ? { A: RADA } : {}),
          ...(RADB ? { B: RADB } : {}),
          ...(RADG ? { G: RADG } : {}),
          ...(RADD ? { D: RADD } : {}),
        },
        scopingInfo: {
          ...state.scopingInfo,
          ...notes,
          ...(mountTypes?.length ? { previousMountModels: mountTypes } : {}),
        },
        engineeringProjectData: {
          ...state.engineeringProjectData,
          ...(civilVendor ? { civilVendor } : {}),
        },
        engineeringWorkflowData: {
          ...state.engineeringWorkflowData,
          RFDSPhase1: {
            ...state.engineeringWorkflowData?.RFDSPhase1,
            ...(RFDSStatus ? { RFDSStatus } : {}),
          },
          regulatoryCompliance: {
            ...state.engineeringWorkflowData?.regulatoryCompliance,
            ...(isCraneRequired ? { isCraneRequired } : {}),
            ...(craneHeight ? { craneHeight } : {}),
          },
        },
        engineeringLeasingData: {
          ...state.engineeringLeasingData,
          leaseAudit: {
            ...state.engineeringLeasingData?.leaseAudit,
            ...(azimuthA ? { azimuthA } : {}),
            ...(azimuthB ? { azimuthB } : {}),
            ...(azimuthD ? { azimuthD } : {}),
            ...(azimuthG ? { azimuthG } : {}),
          },
        },
        otherEquipments,
        scopingSectorsVersions: getScopingSectorsFromUpdateData(versions),
      };
    },
  ),
  // UPDATE SCOPING TOWER NOTE
  on(
    EngineeringActions.updateScopingTowerNoteAction,
    ({
      payload: scopingTowerNote,
      state,
    }: PayloadAndState<IScopingTowerNote, EngineeringBrowseState>) => {
      return {
        ...state,
        scopingInfo: {
          ...state.scopingInfo,
          scopingTowerNote,
        },
      };
    },
  ),
);
