import { Undefinable } from '@symfa-inc/providence-types';
import { createReducer, on } from '../../core/utils/store';
import {
  PaginatedResponse,
  PayloadAndState,
  UserManagementData,
} from '../../models/interfaces';
import { UserManagementActions } from '../actions/user-management.actions';

export interface UserManagementState {
  users: UserManagementData[];
  total: Undefinable<number>;
  isFetching: boolean;
  errors: boolean;
}

const initialState: UserManagementState = {
  users: [],
  total: undefined,
  isFetching: false,
  errors: false,
};

const rewriteScreenUserManagement = ({
  payload: { items: users, total },
}: PayloadAndState<
  PaginatedResponse<UserManagementData>,
  UserManagementState
>): Partial<UserManagementState> => ({
  users,
  total,
});

export const reducer = createReducer(
  initialState,
  // GET ALL USERS
  on(UserManagementActions.getAllUsersAction, rewriteScreenUserManagement),
  // CREATE NEW USER
  on(UserManagementActions.createUserAction, rewriteScreenUserManagement),
  // DELETE USER
  on(UserManagementActions.deleteUserAction, rewriteScreenUserManagement),
  // UPDATE USER
  on(
    UserManagementActions.updateUserAction,
    ({
      state: { users: userParams },
      payload,
    }: PayloadAndState<UserManagementData, UserManagementState>) => {
      const index = userParams.findIndex(
        (user: UserManagementData) => user.id === payload.id,
      );

      const users = [...userParams];

      users.splice(index, 1, payload);

      return {
        users,
      };
    },
  ),
);
