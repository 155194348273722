import { RuleObject } from 'rc-field-form/es/interface';
import { RangePickerValues } from '@models/interfaces';
import { reverseType } from './reverse-type';

export const validator = (
  type: keyof RangePickerValues,
  required: boolean,
  value?: RangePickerValues,
): RuleObject => ({
  validator: () => {
    const oppositeType = reverseType(type);
    const error = `${type === 'from' ? 'Start' : 'End'} Date is required`;

    return (!value?.[type] && required) ||
      (!value?.[type] && value?.[oppositeType])
      ? Promise.reject(new Error(error))
      : Promise.resolve();
  },
});
