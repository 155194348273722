import { Rule } from 'antd/lib/form';
import { RuleObject } from 'rc-field-form/es/interface';

export const isPhoneNumber = (): Rule => ({
  validator: (_: RuleObject, inputValue: string): Promise<void> =>
    inputValue.length !== 11 || inputValue[0] !== '1'
      ? Promise.reject(
          new Error('Length should be 11 digits with the country code (1)!'),
        )
      : Promise.resolve(),
});
