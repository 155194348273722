import { AuthGuard, UserGuard } from '../core/guards';
import { ClientResolver } from '../core/resolvers';
import { Route } from '../models/classes';
import * as AuthRoutes from './auth/auth.routing';
import { CLIENT_ROUTES } from './client/client.routing';
import { Home } from './client/pages/home';
import {
  allNotificationsTab,
  assignedToMeTab,
} from './client/pages/home/home.routing';
import { HomeResolver } from './client/pages/home/resolvers';
import { NotFound } from './not-found';

export const clientRouting = new Route({
  path: '/',
  name: 'Home',
  component: Home,
})
  .addResolver(new HomeResolver(), false)
  .breadcrumb()
  .addGuard(new UserGuard(), '/login')
  .addGuard(new AuthGuard(), '/login')
  .addTabs([assignedToMeTab, allNotificationsTab])
  .addRoutes(CLIENT_ROUTES)
  .addRoute(
    new Route({
      name: 'Not Found',
      path: '/404',
      component: NotFound,
    }).setExact(true),
  )
  .addResolver(new ClientResolver())
  .formatRoutes();

export const APP_ROUTES: Route[] = [
  AuthRoutes.loginRoute,
  AuthRoutes.forgotPasswordRoute,
  ...clientRouting.getChildrenRoutes(),
  clientRouting,
];
