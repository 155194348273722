import React, { ReactNode } from 'react';
import { actionButtons } from '@models/constants';
import { AdminCountyData } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';

export const getAdminCountyColumns = (
  editFunc: ColumnsFunc<AdminCountyData>,
  deleteFunc: ColumnsFunc<AdminCountyData>,
): Array<VirtualTableColumn> => [
  {
    title: 'Value',
    dataIndex: 'name',
    key: 'name',
    render: (value: string): ReactNode => (
      <span className="column-text">{value}</span>
    ),
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];
