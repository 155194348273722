import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/selectors';

export const showIfAuthorized =
  <P extends Record<string, unknown> = Record<string, unknown>>(
    Component: FC<P>,
  ): FC<P> =>
  (props: PropsWithChildren<P>) => {
    const isAuthorized = useSelector(UserSelectors.isAuthorized);

    return isAuthorized ? <Component {...props} /> : null;
  };
