import {
  SubPage,
  Tab,
  TabPermissionsType,
  Undefinable,
} from '@symfa-inc/providence-types';
import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  EngineeringHttpService,
  EquipmentHttpService,
  ProjectCategoryHttpService,
  SiteAuditHttpService,
  SiteHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';
import { EquipmentType } from '@models/enums';
import store from '@store/index';
import { AppState } from '@store/reducers';
import { EngineeringBrowseState } from '@store/reducers/engineering/browse.reducer';

export class EngineeringDetailsResolver implements Resolver {
  private static get _state(): AppState {
    return store.getState();
  }

  private static get _engineeringState(): EngineeringBrowseState {
    return EngineeringDetailsResolver._state.engineering.browse;
  }

  private static get _userTabPermissions(): Undefinable<TabPermissionsType> {
    return EngineeringDetailsResolver._state.user.activeUser?.permissions
      ?.engineering?.tabs;
  }

  private static get _SOFPermissions(): Undefinable<boolean> {
    return EngineeringDetailsResolver._userTabPermissions?.[
      SubPage.EngineeringBrowse
    ]?.[Tab.SOF].haveAccess;
  }

  private static get _SiteAuditPermissions(): Undefinable<boolean> {
    return EngineeringDetailsResolver._userTabPermissions?.[
      SubPage.EngineeringBrowse
    ]?.[Tab.SiteAudit].haveAccess;
  }

  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await Promise.all([
      HttpService.getHttpRequests(EngineeringHttpService).getEngineering(
        projectBundleID,
      ),

      HttpService.getHttpRequests(
        ProjectCategoryHttpService,
      ).getProjectCategories(),

      HttpService.getHttpRequests(SiteHttpService).getFALocationNumberOptions(),
    ]);

    if (EngineeringDetailsResolver._SiteAuditPermissions) {
      await HttpService.getHttpRequests(SiteAuditHttpService).getSiteAudit(
        projectBundleID,
      );
    }

    if (EngineeringDetailsResolver._SOFPermissions) {
      await HttpService.getHttpRequests(
        EquipmentHttpService,
      ).getEquipmentTypes();

      const equipmentTypeNames = new Set([
        ...(EngineeringDetailsResolver._engineeringState?.equipmentTypeNames ||
          []),
        EquipmentType.Cable,
      ]);

      await HttpService.getHttpRequests(
        EquipmentHttpService,
      ).getEquipmentsByTypes([...equipmentTypeNames]);
    }
  }
}
