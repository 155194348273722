import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { PermittingHttpService } from '@core/services/http';
import { BROWSE_DEFAULT_FILTER_VALUE } from '@models/constants';

export class JurisdictionBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      PermittingHttpService,
    ).getJurisdictionBrowseData(BROWSE_DEFAULT_FILTER_VALUE);
  }
}
