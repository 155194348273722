import React, { FC, PropsWithChildren } from 'react';
import { FormItem, PrimaryButton } from '@shared/modules';
import { ButtonProps } from './models/interfaces';

export const Button: FC<ButtonProps> = ({
  id,
  elementProps,
  ...props
}: PropsWithChildren<ButtonProps>) => (
  <FormItem
    id={id}
    readOnlyElement={<PrimaryButton disabled id={id} {...elementProps} />}
    editingElement={<PrimaryButton id={id} {...elementProps} />}
    {...props}
  />
);
