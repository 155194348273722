import { createSelector } from 'reselect';
import { DefaultTowerAgentState } from '@store/reducers/admin/default-tower-agent.reducer';
import { AppState } from '../../reducers';

const defaultTowerAgentState = (state: AppState): DefaultTowerAgentState =>
  state.admin.defaultTowerAgent;

export namespace DefaultTowerAgentSelectors {
  export const getAgentOptions = createSelector(
    [defaultTowerAgentState],
    (state: DefaultTowerAgentState) => state.agentsOptions,
  );

  export const getOwnersOptions = createSelector(
    [defaultTowerAgentState],
    (state: DefaultTowerAgentState) => state.ownersOptions,
  );

  export const getDefaultTowerAgentRecords = createSelector(
    [defaultTowerAgentState],
    (state: DefaultTowerAgentState) => state.defaultTowerAgentRecords,
  );
}
