import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SubPage, Tab } from '@symfa-inc/providence-types';
import { Equipment } from '@client/pages/engineering/components';
import {
  canUserEditByProjectStatusAndRoles,
  getProjectBundleIDParamFromUrl,
} from '@core/utils/methods';
import { EditablePage } from '@shared/components';
import { PrimaryButton } from '@shared/modules';
import {
  ProjectSelectors,
  ScopingSelectors,
  UserSelectors,
} from '@store/selectors';

export const EquipmentTab: FC = () => {
  const [projectBundleID] = getProjectBundleIDParamFromUrl();

  const { canCreateSOF } = useSelector(
    UserSelectors.getEngineeringScopingPermissions,
  );
  const projectType = useSelector(ScopingSelectors.getProjectType);
  const projectTypePermission = useSelector(
    UserSelectors.canEditProjectByProjectType(projectType),
  );
  const canEdit = useSelector(
    UserSelectors.canEdit(
      'engineering',
      SubPage.EngineeringScoping,
      Tab.Equipment,
    ),
  );

  const userRoles = useSelector(UserSelectors.getUserRoles);
  const projectStatusId = useSelector(ProjectSelectors.getProjectStatus);

  return (
    <EditablePage
      editableComponent={Equipment}
      canUserEdit={
        canEdit &&
        projectTypePermission &&
        canUserEditByProjectStatusAndRoles(projectStatusId, userRoles)
      }
    >
      {canCreateSOF && projectTypePermission && (
        <PrimaryButton
          title="Create SOF"
          icon="+"
          target="_blank"
          href={`/engineering/sof-tool/${projectBundleID}/details/summary`}
        />
      )}
    </EditablePage>
  );
};
