import React, { FC } from 'react';
import { HttpService } from '@core/services';
import { SiteHttpService } from '@core/services/http';
import { FileType } from '@models/types';
import { Import } from '@shared/modules/import';

export const SiteImport: FC = () => (
  <Import
    template="SiteTemplate"
    sendFile={(file: FileType): Promise<void> =>
      HttpService.getHttpRequests(SiteHttpService).importSites(file)
    }
  />
);
