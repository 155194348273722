import { Undefinable } from '@symfa-inc/providence-types';
import { dateWorker } from '@core/utils/date-worker';
import {
  FiltersSetDataFilter,
  FiltersSetDataPaceTaskFilter,
  RangePickerValues,
} from '@models/interfaces';
import { DateWorkerString, FiltersSetDateValue } from '@models/types';

const revertMoment = (
  value: Undefinable<DateWorkerString>,
  revert: boolean,
): DateWorkerString =>
  revert ? dateWorker(value).format() : dateWorker(value);

export const momentValue = (
  v: FiltersSetDateValue,
  revert: boolean = false,
): FiltersSetDateValue => {
  const rangePickerValues = v as RangePickerValues<string>;

  switch (true) {
    case revert ? dateWorker.isMomento(v) : typeof v === 'string':
      return revertMoment(v as DateWorkerString, revert);
    case typeof v === 'object' &&
      !!(rangePickerValues?.from && rangePickerValues?.to): {
      return {
        from: revertMoment(rangePickerValues.from, revert),
        to: revertMoment(rangePickerValues.to, revert),
      };
    }
    default:
      return undefined;
  }
};

export const convertPaceTaskFilterToObj = (
  paceTaskFilter: FiltersSetDataPaceTaskFilter[],
): FiltersSetDataFilter =>
  paceTaskFilter.reduce(
    (
      acc: FiltersSetDataFilter,
      { id, value, operator, isVisible }: FiltersSetDataPaceTaskFilter,
    ) => ({
      ...acc,
      [id]: {
        value: momentValue(value),
        operator,
        isVisible,
      },
    }),
    {},
  );
