import React, { ReactNode } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { ColumnsFunc } from '@models/types';
import {
  arrsToSelectableObject,
  getRankingValue,
  getRankingValueAsKeyModifier,
  phoneElementRender,
  readOnlyDatePickerRenderer,
  readOnlySelectRender,
} from '../../core/utils/methods';
import { ReadOnlySelect } from '../../shared/modules';
import { ProjectRankStatus } from '../../shared/modules/ui-kit/status';
import store from '../../store';
import {
  AdminCountyData,
  AdminDropdownValuesData,
  ContactsData,
  LeasingDetails,
  ProjectCategory,
  ProjectCategoryTableRecord,
} from '../interfaces';
import { actionButtons } from './action-buttons';

export const getProjectCategoryColumns = (
  isEditing: boolean,
  isLeasingDetails: boolean,
  editFunc?: ColumnsFunc<ProjectCategory>,
  deleteFunc?: ColumnsFunc<ProjectCategory>,
): ColumnsType => {
  const {
    admin: {
      dropdownValues: { dropdownsOptions },
      projectCategory: { projectCategories },
    },
  } = store.getState();

  return [
    {
      title: 'Project Category',
      key: 'projectCategory',
      render: (
        projectCategoryRecord:
          | ProjectCategoryTableRecord
          | LeasingDetails['categories'][number],
      ): ReactNode => {
        const value = isLeasingDetails
          ? (projectCategoryRecord as LeasingDetails['categories'][number])
              .projectCategoryId
          : (projectCategoryRecord as ProjectCategoryTableRecord)
              .projectCategory;

        return (
          <>
            <ReadOnlySelect
              options={arrsToSelectableObject(projectCategories, 'id', 'value')}
              value={value}
            />
            {!isLeasingDetails && projectCategoryRecord?.isFirstNet
              ? ' FN'
              : ''}
          </>
        );
      },
    },
    {
      title: 'PACE Project Number',
      key: 'PACEProjectNumber',
      dataIndex: 'PACEProjectNumber',
    },
    {
      title: 'IWM Job Number',
      key: 'IWMJobNumber',
      dataIndex: 'IWMJobNumber',
    },
    {
      title: 'Oracle Project Number',
      key: 'oracleProjectNumber',
      dataIndex: 'oracleProjectNumber',
    },
    {
      title: 'Project Ranking',
      key: 'projectRanking',
      render: (
        projectCategoryRecord:
          | ProjectCategoryTableRecord
          | LeasingDetails['categories'][number],
      ): ReactNode => {
        const projectRankingId = isLeasingDetails
          ? (projectCategoryRecord as LeasingDetails['categories'][number])
              .projectRankingId
          : (projectCategoryRecord as ProjectCategoryTableRecord)
              .projectRanking;
        const value = getRankingValue(projectRankingId, dropdownsOptions);
        const status = getRankingValueAsKeyModifier(
          projectRankingId,
          dropdownsOptions,
        );

        return <ProjectRankStatus status={status} value={value} />;
      },
    },
    {
      title: 'Frequency',
      key: 'frequency',
      render: (
        projectCategoryRecord:
          | ProjectCategoryTableRecord
          | LeasingDetails['categories'][number],
      ): ReactNode => {
        const value = isLeasingDetails
          ? (projectCategoryRecord as LeasingDetails['categories'][number])
              .frequencyId
          : (projectCategoryRecord as ProjectCategoryTableRecord).frequency;

        return readOnlySelectRender(dropdownsOptions.Frequency, value);
      },
    },
    {
      title: 'Project Assigned Date',
      key: 'projectAssignedDate',
      dataIndex: 'projectAssignedDate',
      render: readOnlyDatePickerRenderer,
    },
    {
      title: 'Strategic Driver',
      key: 'strategicDriver',
      dataIndex: 'strategicDriver',
    },
    ...(isEditing
      ? [actionButtons<ProjectCategory>({ editFunc, deleteFunc })]
      : []),
  ];
};

export const getContactsColumns = (
  editFunc: ColumnsFunc<ContactsData>,
  deleteFunc: ColumnsFunc<ContactsData>,
  isEditing?: boolean,
): ColumnsType => [
  {
    title: 'Contact Type',
    dataIndex: 'contactType',
    key: 'contactType',
    width: 150,
    render: (adminDropdownValuesData: AdminDropdownValuesData): ReactNode =>
      adminDropdownValuesData?.value,
  },
  {
    title: 'Contact Name',
    dataIndex: 'contactName',
    key: 'lastValidatedDate',
    width: 150,
  },
  {
    title: 'Contact Address',
    dataIndex: 'contactAddress',
    key: 'address',
    width: 200,
  },
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
    width: 165,
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    width: 120,
    render: (state: AdminCountyData): ReactNode => state?.name,
  },
  {
    title: 'Zip',
    dataIndex: 'zip',
    key: 'zip',
    width: 120,
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    width: 165,
    render: (phone: string): ReactNode => phoneElementRender(phone),
  },
  {
    title: 'Fax',
    dataIndex: 'fax',
    key: 'fax',
    width: 165,
    render: (phone: string): ReactNode => phoneElementRender(phone),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 190,
  },
  {
    title: 'Contact Notes',
    dataIndex: 'contactNote',
    key: 'contactNote',
    width: 180,
  },
  {
    ...actionButtons({ editFunc, deleteFunc, isEditing }),
    fixed: 'right',
  } as ColumnType<unknown>,
];
