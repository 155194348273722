import React, { FC, PropsWithChildren } from 'react';
import { Modal as AntModal } from 'antd';
import classNames from 'classnames';
import { ModalProps } from '@models/interfaces';

export const Modal: FC<ModalProps> = ({
  okButtonProps,
  cancelButtonProps,
  okType = 'primary',
  cancelType = 'default',
  width = 570,
  ...props
}: PropsWithChildren<ModalProps>) => (
  <AntModal
    okButtonProps={{
      ...okButtonProps,
      className: classNames(okButtonProps?.className, `primary-btn_${okType}`),
    }}
    cancelButtonProps={{
      ...cancelButtonProps,
      className: classNames(
        cancelButtonProps?.className,
        `primary-btn_${cancelType}`,
      ),
    }}
    width={width}
    {...props}
  />
);
