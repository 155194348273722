import React, { FC, PropsWithChildren } from 'react';
import { Checkbox as AntCheckbox, Tooltip } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import { FormItem } from '@shared/modules';
import { getReadonlyElementFunction } from '../../models/constants';
import { FormElement } from '../../models/interfaces';

type PrivateCheckboxProps = {
  id: string;
  tooltipMessage?: string;
  elementProps?: CheckboxProps;
};

const PrivateCheckbox: FC<PrivateCheckboxProps> = ({
  id,
  tooltipMessage,
  ...elementProps
}: PropsWithChildren<PrivateCheckboxProps>) =>
  tooltipMessage ? (
    <Tooltip title={tooltipMessage}>
      <AntCheckbox id={id} {...elementProps} />
    </Tooltip>
  ) : (
    <AntCheckbox id={id} {...elementProps} />
  );

export const Checkbox: FC<
  FormElement<CheckboxProps> & { tooltipMessage?: string }
> = ({
  id,
  elementProps,
  formItemProps,
  tooltipMessage,
  ...props
}: PropsWithChildren<
  FormElement<CheckboxProps> & { tooltipMessage?: string }
>) => (
  <FormItem
    id={id}
    editingElement={
      <PrivateCheckbox
        tooltipMessage={tooltipMessage}
        id={id}
        {...elementProps}
      />
    }
    readOnlyElement={getReadonlyElementFunction<CheckboxProps>(
      id,
      props,
      (value: boolean) => (
        <Tooltip title={tooltipMessage}>
          <AntCheckbox
            className="read-only-checkbox"
            id={id}
            checked={value}
            disabled
            {...elementProps}
          />
        </Tooltip>
      ),
    )}
    formItemProps={{
      valuePropName: 'checked',
      ...formItemProps,
    }}
    {...props}
  />
);
