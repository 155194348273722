import {
  EngineeringRegulatoryCompliance,
  EngineeringRFDSPhase1,
  FinancialSiteFeesData,
  LeasingInformation,
  ProjectCategory,
  ProjectInformationData,
  ScopingInfo,
  SiteBrowse,
  StructuralInformation,
} from '../interfaces';

export const SITE_INFO_TRANSFORM_FIELDS: Array<keyof SiteBrowse> = [
  'towerOwner',
  'state',
  'county',
  'jurisdiction',
  'submarket',
  'towerType',
  'equipmentType',
  'generatorInstalled',
  'riskCategory',
];

export const PROJECT_INFO_TRANSFORM_FIELDS: Array<
  keyof ProjectInformationData
> = [
  'projectType',
  'projectWorkType',
  'projectStatus',
  'progressStatus',
  'ATnTPM',
  'siteAcqVendor',
  'civilVendor',
  'projectSubtype',
  'providenceAgent',
];

export const PROJECT_CATEGORY_TRANSFORM_FIELDS: Array<keyof ProjectCategory> = [
  'projectCategory',
  'projectRanking',
  'frequency',
];

export const LEASING_INFO_TRANSFORM_FIELDS: Array<keyof LeasingInformation> = [
  'landlordRequiresMA',
  'PALOrNPARequired',
  'eSignature',
  'legalCompany',
  'leaseType',
];

export const LEASING_ENGINEERING_TRANSFORM_FIELDS: Array<keyof ScopingInfo> = [
  'CDsLandlordApprovalRequired',
  'mountAnalysisDetails',
];

export const STRUCTURAL_INFO_TRANSFORM_FIELDS: Array<
  keyof StructuralInformation
> = [
  'structuralResult',
  'IBMEligible',
  'structuralService',
  'structuralTIARevCode',
  'structuralVendor',
];

export const RFDS_INFO_TRANSFORM_FIELDS: Array<keyof EngineeringRFDSPhase1> = [
  'RFDSStatus',
  'prelimRFDSDemotedTo',
  'revisedRFDSDemotedTo',
  'phase1Vendor',
];

export const REGULATORY_COMPLIANCE_INFO_TRANSFORM_FIELDS: Array<
  keyof EngineeringRegulatoryCompliance
> = ['RFSSRVendor', 'PALOrNPARequired'];

export const FINANCIAL_SITE_FEES_TRANSFORM_FIELDS: Array<
  keyof FinancialSiteFeesData
> = ['siteFeeType', 'creditCard'];

export const SCOPING_PROJECT_INFO_TRANSFORM_FIELDS: Array<
  keyof ProjectInformationData
> = [
  'projectType',
  'projectStatus',
  'civilVendor',
  'projectSubtype',
  'siteAcqVendor',
];

export const SCOPING_INFO_TRANSFORM_FIELDS: Array<
  keyof Omit<ScopingInfo, 'otherEquipments' | 'scopingSectorsVersions'>
> = [
  'scopingMountAssessment',
  'AEVendor',
  'AEMountVendor',
  'mountModelReplacements',
  'previousMountModels',
];
