import {
  JurisdictionDetailsPermitting,
  PermittingDetailsJurisdictionZoning,
  PermittingSection,
} from '../interfaces';

type JurisdictionPanelCommonFields =
  | PermittingSection
  | PermittingDetailsJurisdictionZoning
  | Omit<
      JurisdictionDetailsPermitting,
      'permittingTypes' | 'projectType' | 'projectSubType' | 'id'
    >;

export const JURISDICTION_DATES_FOR_MOMENTIZE: Array<
  keyof JurisdictionPanelCommonFields
> = ['jurisdictionReviewDate'];
