import React, { FC, PropsWithChildren } from 'react';
import { Divider } from 'antd';
import { DEFAULT_ITEM_WITH_OPERATOR_VALUE } from '@models/constants';
import { OptionProps } from '@models/interfaces';
import { DateWithOperator } from '@shared/modules';
import { FiltersListProps, ItemToRender } from '../../models';
import {
  getProjectFieldsList,
  getSiteFieldsList,
} from '../../models/fields-lists';

export const FiltersList: FC<FiltersListProps> = ({
  form,
  fieldsList,
  data,
  options,
  pmScreenPaceTasksOptions,
  checkbox,
}: PropsWithChildren<FiltersListProps>) => {
  const getPMFilterFieldsList = (
    value: ItemToRender[],
  ): Record<string, ItemToRender> =>
    value.reduce(
      (acc: Record<string, ItemToRender>, curItem: ItemToRender) => ({
        ...acc,
        [curItem.id]: curItem,
      }),
      {},
    );

  const allPossibleFields = getPMFilterFieldsList(
    pmScreenPaceTasksOptions
      ? pmScreenPaceTasksOptions?.map((option: OptionProps) => ({
          component: DateWithOperator,
          label: `${option.viewValue}`,
          id: `${option.value}`,
        }))
      : [...getSiteFieldsList(options), ...getProjectFieldsList(options)],
  );

  return (
    <div>
      {fieldsList?.length ? <Divider /> : null}
      {fieldsList?.map((field: string) => {
        const { component, componentProps, ...formItemIdAndLabel } =
          allPossibleFields?.[field] || {};
        const value =
          data?.[formItemIdAndLabel?.id] ?? DEFAULT_ITEM_WITH_OPERATOR_VALUE;

        return React.createElement(component, {
          ...componentProps,
          ...formItemIdAndLabel,
          key: formItemIdAndLabel.id,
          form,
          value,
          checkbox,
        });
      })}
    </div>
  );
};
