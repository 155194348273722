import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { DropdownValuesState } from '../reducers/admin/dropdown-values.reducer';
import { ProjectState } from '../reducers/project.reducer';
import { ProjectCategoriesState } from '../reducers/project-categories.reducer';

const projectCategoriesState = (state: AppState): ProjectCategoriesState =>
  state.projectCategories;
const projectState = (state: AppState): ProjectState => state.project;
const DDVState = (state: AppState): DropdownValuesState =>
  state.admin.dropdownValues;

export namespace ProjectCategoriesSelectors {
  export const getProjectCategoriesTableVisible = createSelector(
    [projectCategoriesState],
    ({ projectCategoriesTableVisible }: ProjectCategoriesState) =>
      projectCategoriesTableVisible,
  );

  export const getProjectCategories = createSelector(
    [projectCategoriesState],
    ({ currentProjectCategories }: ProjectCategoriesState) =>
      currentProjectCategories,
  );

  export const getProjectCategoryInfo = createSelector(
    [projectCategoriesState],
    ({
      projectCategoriesTableVisible,
      currentProjectCategories,
      isCategoriesTableEditing,
    }: ProjectCategoriesState) => ({
      projectCategoriesTableVisible,
      currentProjectCategories,
      isCategoriesTableEditing,
    }),
  );

  export const getProjectCategoryModalInfo = createSelector(
    [DDVState, projectState],
    (
      { dropdownsOptions, dropdownValues }: DropdownValuesState,
      { currentProject }: ProjectState,
    ) => ({
      dropdownsOptions,
      dropdownValues,
      currentProject,
    }),
  );
}
