import { SelectValue } from 'antd/lib/select';
import { FiltersSetDataExtended } from '@models/interfaces';
import { DEFAULT_FILTERS_SET_VALUE } from '../../filters/models';

export const findFiltersSet = (
  filterSets: FiltersSetDataExtended[],
  selectorValue?: SelectValue,
): FiltersSetDataExtended =>
  filterSets.find((set: FiltersSetDataExtended) => set?.id === selectorValue) ||
  DEFAULT_FILTERS_SET_VALUE;
