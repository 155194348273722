import React, { FC } from 'react';
import { Col, Form, Row } from 'antd';
import { Coordinate, PrimaryButton } from '@shared/modules';
import { useMappingContext } from '../../mapping-context';
import { ICoords } from '../../models';

export const CoordinatesBar: FC = () => {
  const [coordinatesForm] = Form.useForm();

  const { setCoords } = useMappingContext();

  const onCoordinatesBarGo = (value: ICoords) => {
    if (value.latitude && value.longitude) {
      setCoords?.(value);
      coordinatesForm.resetFields();
    }
  };

  return (
    <Col className="prov-maps-handler__bar">
      <Form form={coordinatesForm}>
        <Row className="title">Move by coordinates</Row>
        <Row>
          <Coordinate id="latitude" label="Latitude" />
          <Coordinate id="longitude" label="Longitude" />
          <PrimaryButton
            title="Go"
            className="prov-maps-handler__button"
            onClick={() =>
              onCoordinatesBarGo(coordinatesForm.getFieldsValue() as ICoords)
            }
          />
        </Row>
      </Form>
    </Col>
  );
};
