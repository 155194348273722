import React, { FC, PropsWithChildren } from 'react';
import PhoneInput from 'react-phone-input-2';
import { InputProps } from 'antd/lib/input';
import { FormItem } from '@shared/modules';
import { getReadonlyElementFunction } from '../../models/constants';
import { FormElement } from '../../models/interfaces';

export const PhoneNumber: FC<FormElement<InputProps>> = ({
  id,
  elementProps,
  readOnlyElementRender,
  ...props
}: PropsWithChildren<FormElement<InputProps>>) => (
  <FormItem
    id={id}
    editingElement={
      <PhoneInput
        country="us"
        inputClass="ant-input"
        disableDropdown
        inputProps={{
          id,
          ...elementProps,
        }}
      />
    }
    readOnlyElement={getReadonlyElementFunction<InputProps>(
      id,
      props,
      readOnlyElementRender,
    )}
    {...props}
  />
);
