import { createSelector } from 'reselect';
import { FinancialOOMInformation } from '@models/interfaces';
import { AppState } from '../reducers';
import { FinancialState } from '../reducers/financial.reducer';

const financialState = (state: AppState): FinancialState => state.financial;

export namespace FinancialSelectors {
  export const isFetching = createSelector(
    [financialState],
    (state: FinancialState): boolean => state.isFetching,
  );

  export const getOOMInformationData = createSelector(
    [financialState],
    (state: FinancialState) => state.OOMInformation,
  );

  export const getFinancialData = createSelector(
    [financialState],
    (state: FinancialState) => state.financialData,
  );

  export const getOMMInformationPACETaskIds = createSelector(
    [financialState],
    (state: FinancialState) =>
      state.OOMInformation.map(
        (v: FinancialOOMInformation) => v.PACETaskIdentification,
      ),
  );
}
