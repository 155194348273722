import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  AccountingHttpService,
  DriverManagementHttpService,
  ProjectCategoryHttpService,
  SiteHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';

export class AccountingDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await Promise.all([
      HttpService.getHttpRequests(AccountingHttpService).getAccounting(
        projectBundleID,
      ),

      HttpService.getHttpRequests(
        ProjectCategoryHttpService,
      ).getProjectCategories(),

      HttpService.getHttpRequests(SiteHttpService).getFALocationNumberOptions(),

      HttpService.getHttpRequests(
        DriverManagementHttpService,
      ).getDriversListByWorkflow(),
    ]);
  }
}
