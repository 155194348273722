import { AccountingBrowseUpdateData } from '@models/interfaces';

export const ACCOUNTING_MODAL_DATES_TO_MOMENTIZE: Array<
  keyof AccountingBrowseUpdateData
> = [
  'RE015InvoiceDate',
  'RE020InvoiceDate',
  'RE020ActualDate',
  'SS010InvoiceDate',
  'RE066InvoiceDate',
];
