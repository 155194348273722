import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { ProjectCategoryState } from '../../reducers/admin/project-category.reducer';

const projectCategoriesState = (state: AppState): ProjectCategoryState =>
  state.admin.projectCategory;

export namespace AdminProjectCategoriesSelectors {
  export const getAdminProjectCategories = createSelector(
    [projectCategoriesState],
    (state: ProjectCategoryState) => state.projectCategories,
  );
}
