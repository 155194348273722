import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import nextId from 'react-id-generator';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { EquipmentAvailableSelectorValueService } from '@core/services';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { arrsToSelectableObject, isValidForm } from '@core/utils/methods';
import { uniqueValidator } from '@core/utils/validators';
import { EquipmentTypeId, ModalMainTypes } from '@models/enums';
import {
  EquipmentFullInfo,
  EquipmentManufacturerData,
  EquipmentTabData,
  SiteAuditCablesData,
} from '@models/interfaces';
import { AddOrEditModal } from '@shared/components';
import { Autocomplete, InputNumber } from '@shared/modules';
import { SiteAuditActions } from '@store/actions';
import { SiteAuditSelectors } from '@store/selectors';
import { SiteAuditModalFormData } from '../../models/interfaces';
import { SiteAuditCableModalProps } from './models';

import './styles.scss';

const { useForm } = Form;

const availableSelectorValueService =
  new EquipmentAvailableSelectorValueService();

export const CableModal: FC<SiteAuditCableModalProps> = ({
  visible,
  modalType,
  toggleModal,
  itemValue,
}: PropsWithChildren<SiteAuditCableModalProps>) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const { cableEquipments, equipmentTypes, cablesTableData } = useSelector(
    SiteAuditSelectors.getCableModalInfo,
  );

  const [equipmentManufacturerValue, setEquipmentManufacturerValue] =
    useState<SelectValue>();
  const [equipmentManufacturerOptions, setEquipmentManufacturerOptions] =
    useState<EquipmentManufacturerData[]>([]);
  const [modelNumberOptions, setModelNumberOptions] = useState<
    EquipmentFullInfo[]
  >([]);
  const [areValuesSet, setValuesSet] = useState<boolean>(false);

  const getFormData = (): SiteAuditModalFormData => {
    const { equipment, ...rest } = itemValue ?? {};

    return {
      ...rest,
      equipment: equipment?.modelNumber || '',
      ...form.getFieldsValue(),
    };
  };

  const add = async (value: SiteAuditModalFormData): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        SiteAuditActions.addCableItemAction.done({
          ...value,
          equipment: { id: value.equipment } as EquipmentTabData,
          generatedId: nextId('Cable'),
        }),
      );
      toggleModal(modalType);
    }
  };

  const edit = async (value: SiteAuditModalFormData): Promise<void> => {
    if (await isValidForm(form)) {
      dispatch(
        SiteAuditActions.updateCableItemAction.done({
          ...value,
          equipment: { id: value.equipment } as EquipmentTabData,
        }),
      );
      toggleModal(modalType);
    }
  };

  useEffect(() => {
    if (visible) {
      availableSelectorValueService.equipmentTypes = equipmentTypes;
      availableSelectorValueService.init();

      availableSelectorValueService.selectEquipmentType(EquipmentTypeId.Cable);

      setEquipmentManufacturerOptions(
        availableSelectorValueService.availableEquipmentManufacturerValue,
      );

      setModelNumberOptions(cableEquipments);

      if (itemValue) {
        setEquipmentManufacturerValue(itemValue?.manufacturer);
        setValuesSet(true);
      }
    } else {
      form.resetFields();
      setEquipmentManufacturerValue(undefined);
    }
  }, [equipmentTypes, cableEquipments, visible, itemValue]);

  useDidUpdateEffect(() => {
    availableSelectorValueService.selectEquipmentManufacturer(
      equipmentManufacturerValue,
    );

    setModelNumberOptions(
      cableEquipments.filter((eqip: EquipmentFullInfo) =>
        equipmentManufacturerValue
          ? eqip.manufacturer.id === equipmentManufacturerValue
          : true,
      ),
    );
  }, [equipmentManufacturerValue]);

  useDidUpdateEffect(() => {
    if (areValuesSet && itemValue) {
      const { equipment, ...rest } = itemValue ?? {};

      form.setFieldsValue({ ...rest, equipment: equipment?.id || '' });

      setValuesSet(false);
    }
  }, [areValuesSet]);

  const validationValues =
    modalType === ModalMainTypes.Edit
      ? cablesTableData.filter(
          (cable: SiteAuditCablesData) =>
            cable.generatedId !== itemValue?.generatedId,
        )
      : cablesTableData || [];

  return (
    <AddOrEditModal
      visible={visible}
      type={modalType}
      title={modalType === ModalMainTypes.Add ? 'Add New Cable' : 'Edit cable'}
      onOk={
        modalType === ModalMainTypes.Add
          ? (): Promise<void> => add(getFormData())
          : (): Promise<void> => edit(getFormData())
      }
      onCancel={(): void => toggleModal(modalType)}
      formProps={{ labelCol: { span: 7 }, form }}
      className="prov-engineering-cable-modal"
      okText={modalType === ModalMainTypes.Add ? 'Add Cable' : 'Save'}
    >
      <Autocomplete
        id="manufacturer"
        label="Manufacturer"
        options={arrsToSelectableObject(
          equipmentManufacturerOptions,
          'id',
          'name',
        )}
        elementProps={{
          allowClear: true,
          onChange: (value: SelectValue): void => {
            setEquipmentManufacturerValue(value);
            form.setFieldsValue({ equipment: undefined });
          },
        }}
      />
      <Autocomplete
        id="equipment"
        label="Model Number"
        options={arrsToSelectableObject(
          modelNumberOptions,
          'id',
          'modelNumber',
        )}
        formItemProps={{
          rules: [
            { required: true, message: 'Model Number is required!' },
            uniqueValidator(
              validationValues?.map((validationValue: SiteAuditCablesData) => ({
                ...validationValue.equipment,
              })),
              'id',
              'Model Number',
            ),
          ],
        }}
      />
      <InputNumber
        id="quantity"
        label="Quantity"
        formItemProps={{
          rules: [{ required: true, message: 'Quantity is required!' }],
        }}
      />
    </AddOrEditModal>
  );
};
