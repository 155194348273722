import { MAIN_PACE_TASK_IDs } from '@symfa-inc/providence-types';
import { PaceTasksToObjectMapper } from './pace-task-to-object-mapper.interface';

export const PERMITTING_SECTION_MAPPER_OPTIONS: PaceTasksToObjectMapper.Options[] =
  [
    {
      id: MAIN_PACE_TASK_IDs.jurisdictionAuditComplete,
      fieldKey: 'jurisdictionAuditComplete',
    },
    {
      id: MAIN_PACE_TASK_IDs.E911AddressValidation,
      fieldKey: 'e911AddressValidation',
    },
    {
      id: MAIN_PACE_TASK_IDs.prelimCDsReviewedPermitting,
      fieldKey: 'prelimCDsReviewedPermitting',
    },
  ];
