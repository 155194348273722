import { combineReducers } from 'redux';
import * as countyValues from './county.reducer';
import * as defaultTowerAgent from './default-tower-agent.reducer';
import * as driverManagement from './driver-management.reducer';
import * as dropdownValues from './dropdown-values.reducer';
import * as notification from './notification.reducer';
import * as paceTaskValues from './pace-task.reducer';
import * as paceTasksBrowse from './pace-tasks-browse.reducer';
import * as projectCategory from './project-category.reducer';
import * as sitePicturesUpload from './site-pictures-upload.reducer';
import * as vendors from './vendors.reducer';

export interface AdminState {
  dropdownValues: dropdownValues.DropdownValuesState;
  countyValues: countyValues.CountyValuesState;
  paceTask: paceTaskValues.PaceTaskState;
  paceTasksBrowse: paceTasksBrowse.PaceTasksBrowseState;
  defaultTowerAgent: defaultTowerAgent.DefaultTowerAgentState;
  driverManagement: driverManagement.DriverManagementState;
  projectCategory: projectCategory.ProjectCategoryState;
  vendors: vendors.VendorsState;
  notification: notification.NotificationState;
  sitePicturesUpload: sitePicturesUpload.SitePicturesUploadState;
}

export const reducers = combineReducers<AdminState>({
  dropdownValues: dropdownValues.reducer,
  countyValues: countyValues.reducer,
  paceTask: paceTaskValues.reducer,
  paceTasksBrowse: paceTasksBrowse.reducer,
  defaultTowerAgent: defaultTowerAgent.reducer,
  driverManagement: driverManagement.reducer,
  projectCategory: projectCategory.reducer,
  vendors: vendors.reducer,
  notification: notification.reducer,
  sitePicturesUpload: sitePicturesUpload.reducer,
});
