import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { VendorHttpService } from '@core/services/http';

export class VendorsResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(VendorHttpService).getVendors();

    await HttpService.getHttpRequests(
      VendorHttpService,
    ).getVendorDistributionLists();
  }
}
