import React, { ReactNode } from 'react';
import { PaceTaskType } from '@symfa-inc/providence-types';
import { Tooltip } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import {
  arrsToSelectableObject,
  convertToDMS,
  projectCategoryRender,
  providenceAgentRender,
  readOnlyDatePickerRenderer,
  readOnlyInputNumberRender,
  readOnlySelectRender,
} from '@core/utils/methods';
import { getPaceTaskViewValue } from '@models/constants';
import {
  EngineeringWorkflowUpdateData,
  IdAndValue,
  LeasingSite,
  PaceTask,
  SiteBrowse,
  StateWithCounties,
} from '@models/interfaces';
import { Browse, ProjectPaceTaskDataType } from '@models/types';
import { ProjectStatus } from '@shared/modules';
import store from '@store/index';
import { StructuralLeasing } from '../interfaces';

const AVAILABLE_FIELDS_FOR_SORTING: string[] = [
  'FALocationNumber',
  'siteNumber',
  'longitude',
  'towerType',
  'towerHeight',
  'state',
  'zip',
  'county',
  'jurisdiction',
  'USID',
  'towerOwner',
  'towerOwnerSiteID',
  'legacySiteID',
  'alternateSiteInfo',
  'submarket',
  'projectBundleID',
  'projectWorkType',
  'projectStatus',
  'progressStatus',
  'ATnTPM',
  'siteAcqVendor',
  'providenceAgent',
  'civilVendor',
  'projectCategory',
  'PACEProjectNumber',
  'IWMJobNumber',
  'oracleProjectNumber',
  'projectRanking',
  'frequency',
  'projectAssignedDate',
  'AEVendor',
  'structuralResult',
];

export const getProjectManagementBrowseColumns = (
  fields: string[],
  selectorValue?: SelectValue,
): ColumnsType<Browse> => {
  const {
    projectManagement: { pmScreenPaceTasks },
    admin: {
      dropdownValues: { dropdownsOptions },
      countyValues: { states },
    },
  } = store.getState();

  const getPMPaceTaskColumns = (): ColumnType<unknown>[] =>
    pmScreenPaceTasks.flatMap(
      ({ id, taskName, paceTaskID }: PaceTask): ColumnType<unknown>[] =>
        Object.entries(PaceTaskType).map(
          ([key, value]: [string, PaceTaskType]): ColumnType<unknown> => ({
            title: getPaceTaskViewValue(taskName, key, paceTaskID), // не убирать 3 аргумент - наебнет оч много
            key: `${id}/${value}`,
            width: 195,
            dataIndex: 'projectPaceTaskData',
            render: (
              projectPaceTasks: ProjectPaceTaskDataType[],
            ): ReactNode => {
              const currentValue = projectPaceTasks.find(
                (projectPaceTask: ProjectPaceTaskDataType) =>
                  projectPaceTask?.paceTask?.id === id,
              )?.[value];

              return readOnlyDatePickerRenderer(currentValue);
            },
          }),
        ),
    );

  const addFilterFieldsToColumns = (): ColumnType<unknown>[] => {
    const columnsInObject = getPMPaceTaskColumns().reduce(
      (
        acc: Record<string, ColumnType<unknown>>,
        item: ColumnType<unknown>,
      ) => ({
        ...acc,
        [item.key as string]: item,
      }),
      {},
    );

    return fields.reduce(
      (acc: ColumnType<unknown>[], field: string) =>
        columnsInObject?.[field] ? [...acc, columnsInObject[field]] : acc,
      [],
    );
  };

  return (
    [
      {
        title: 'A/E CD Vendor',
        dataIndex: 'scoping',
        key: 'AEVendor',
        width: 180,
        render: ({ AEVendor }: { AEVendor: IdAndValue }): ReactNode =>
          AEVendor?.value,
      },
      {
        title: 'A/E Mount Vendor',
        dataIndex: 'scoping',
        key: 'AEMountVendor',
        width: 180,
        render: ({ AEMountVendor }: { AEMountVendor: IdAndValue }): ReactNode =>
          AEMountVendor?.value,
      },
      {
        title: 'AT&T PM',
        key: 'ATnTPM',
        width: 165,
        dataIndex: 'ATnTPM',
        render: (ATnTPM: IdAndValue): ReactNode => ATnTPM?.value,
      },
      {
        title: 'Alternate Site Info',
        width: 170,
        key: 'alternateSiteInfo',
        dataIndex: 'site',
        render: (site: SiteBrowse): ReactNode => site?.alternateSiteInfo,
      },
      {
        title: 'City',
        key: 'city',
        width: 175,
        dataIndex: 'site',
        render: (site: LeasingSite): ReactNode => site?.city,
      },
      {
        title: 'Civil Vendor',
        key: 'civilVendor',
        width: 165,
        dataIndex: 'civilVendor',
        render: (civilVendor: IdAndValue): ReactNode => civilVendor?.value,
      },
      {
        title: 'County',
        dataIndex: 'site',
        key: 'county',
        width: 165,
        render: (siteBroweItem: SiteBrowse): ReactNode =>
          readOnlySelectRender(
            arrsToSelectableObject(
              states.flatMap((state: StateWithCounties) => state.counties),
              'id',
              'name',
            ),
            siteBroweItem?.county?.id,
          ),
      },
      {
        title: 'Crane Required',
        key: 'isCraneRequired',
        width: 165,
        dataIndex: 'engineering',
        render: ({
          regulatoryCompliance,
        }: EngineeringWorkflowUpdateData): ReactNode =>
          regulatoryCompliance?.isCraneRequired,
      },
      {
        title: 'Date Assigned',
        key: 'projectAssignedDate',
        width: 165,
        dataIndex: 'categories',
        render: projectCategoryRender({
          field: 'projectAssignedDate',
          dateField: true,
        }),
      },
      {
        title: 'Determination Date',
        key: 'determinationDate',
        width: 165,
        dataIndex: 'engineering',
        render: ({
          regulatoryCompliance,
        }: EngineeringWorkflowUpdateData): ReactNode =>
          readOnlyDatePickerRenderer(regulatoryCompliance?.determinationDate),
      },
      {
        title: 'Escalate Date',
        key: 'escalateDate',
        width: 165,
        dataIndex: 'engineering',
        render: ({
          regulatoryCompliance,
        }: EngineeringWorkflowUpdateData): ReactNode =>
          readOnlyDatePickerRenderer(regulatoryCompliance?.escalateDate),
      },
      {
        title: 'FA Location Number',
        dataIndex: 'site',
        width: 190,
        key: 'FALocationNumber',
        render: (site: SiteBrowse): ReactNode =>
          readOnlyInputNumberRender(site?.FALocationNumber),
      },
      {
        title: 'FAA Case Number',
        key: 'FAACaseNumber',
        width: 165,
        dataIndex: 'engineering',
        render: ({
          regulatoryCompliance,
        }: EngineeringWorkflowUpdateData): ReactNode =>
          regulatoryCompliance?.FAACaseNumber,
      },
      {
        title: 'FAA File Date',
        key: 'FAAFileDate',
        width: 165,
        dataIndex: 'engineering',
        render: ({
          regulatoryCompliance,
        }: EngineeringWorkflowUpdateData): ReactNode =>
          readOnlyDatePickerRenderer(regulatoryCompliance?.FAAFileDate),
      },
      {
        title: 'Frequency',
        key: 'frequency',
        width: 165,
        dataIndex: 'categories',
        render: projectCategoryRender({
          field: 'frequency',
          renderElement: (record: IdAndValue) =>
            readOnlySelectRender(dropdownsOptions.Frequency, record?.id),
        }),
      },
      {
        title: 'Jurisdiction',
        key: 'jurisdiction',
        width: 165,
        dataIndex: 'site',
        render: (site: SiteBrowse): ReactNode =>
          site?.jurisdiction?.jurisdictionName,
      },
      {
        title: 'Latitude',
        key: 'latitude',
        width: 165,
        dataIndex: 'site',
        render: (siteBroweItem: SiteBrowse): ReactNode => (
          <Tooltip title={convertToDMS(siteBroweItem?.latitude as number)}>
            <span>{siteBroweItem?.latitude}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Legacy Site ID',
        width: 150,
        key: 'legacySiteID',
        dataIndex: 'site',
        render: (site: SiteBrowse): ReactNode => site?.legacySiteID,
      },
      {
        title: 'Longitude',
        key: 'longitude',
        width: 165,
        dataIndex: 'site',
        render: (siteBroweItem: SiteBrowse): ReactNode => (
          <Tooltip title={convertToDMS(siteBroweItem?.longitude as number)}>
            <span>{siteBroweItem?.longitude}</span>
          </Tooltip>
        ),
      },
      {
        title: 'Mod Cost',
        key: 'modCost',
        width: 165,
        dataIndex: 'leasing',
        render: ({ structural }: StructuralLeasing): ReactNode =>
          structural?.modCost,
      },
      {
        title: 'Oracle Project Number',
        dataIndex: 'categories',
        key: 'oracleProjectNumber',
        width: 205,
        render: projectCategoryRender({
          field: 'oracleProjectNumber',
        }),
      },
      {
        title: 'PACE Project Number',
        dataIndex: 'categories',
        key: 'PACEProjectNumber',
        width: 200,
        render: projectCategoryRender({
          field: 'PACEProjectNumber',
        }),
      },
      {
        title: 'IWM Job Number',
        key: 'IWMJobNumber',
        dataIndex: 'categories',
        render: projectCategoryRender({
          field: 'IWMJobNumber',
        }),
        width: 200,
      },
      {
        title: 'Progress Status',
        key: 'progressStatus',
        width: 165,
        dataIndex: 'progressStatus',
        render: (progressStatus: IdAndValue): ReactNode =>
          progressStatus?.value,
      },
      {
        title: 'Project Bundle ID',
        dataIndex: 'projectBundleID',
        key: 'projectBundleID',
        width: 165,
        render: (projectBundleID: number): ReactNode => projectBundleID,
      },
      {
        title: 'Project Category',
        dataIndex: 'categories',
        key: 'projectCategory',
        width: 195,
        render: projectCategoryRender({
          field: 'projectCategory',
        }),
      },
      {
        title: 'Project Ranking',
        key: 'projectRanking',
        width: 165,
        dataIndex: 'categories',
        render: projectCategoryRender({
          field: 'projectRanking',
        }),
      },
      {
        title: 'Project Status',
        dataIndex: 'projectStatus',
        key: 'projectStatus',
        width: 145,
        render: (status: IdAndValue): ReactNode => (
          <ProjectStatus status={status?.value} />
        ),
      },
      {
        title: 'Project Work Type',
        dataIndex: 'projectWorkType',
        key: 'projectWorkType',
        width: 165,
        render: (projectWorkType: IdAndValue): ReactNode =>
          projectWorkType?.value,
      },
      {
        title: 'Providence Agent',
        key: 'providenceAgent',
        width: 180,
        dataIndex: 'providenceAgent',
        render: providenceAgentRender,
      },
      {
        title: 'Site Acq Vendor',
        key: 'siteAcqVendor',
        width: 165,
        dataIndex: 'siteAcqVendor',
        render: (siteAcqVendor: IdAndValue): ReactNode => siteAcqVendor?.value,
      },
      {
        title: 'Site Number',
        dataIndex: 'site',
        width: 135,
        key: 'siteNumber',
        defaultSortOrder: 'ascend',
        render: (site: SiteBrowse): ReactNode =>
          readOnlyInputNumberRender(site?.siteNumber),
      },
      {
        title: 'State',
        dataIndex: 'site',
        key: 'state',
        width: 165,
        render: (siteBroweItem: SiteBrowse): ReactNode =>
          readOnlySelectRender(
            arrsToSelectableObject(states, 'id', 'name'),
            siteBroweItem?.state?.id,
          ),
      },
      {
        title: 'Street Address',
        key: 'streetAddress',
        dataIndex: 'site',
        width: 170,
        render: (site: LeasingSite): ReactNode => site?.streetAddress,
      },
      {
        title: 'Structural Foundation Percent',
        key: 'structuralFoundationPercent',
        width: 165,
        dataIndex: 'leasing',
        render: ({ structural }: StructuralLeasing): ReactNode =>
          structural?.structuralFoundationPercent,
      },
      {
        title: 'Structural Result',
        key: 'structuralResult',
        width: 165,
        dataIndex: 'leasing',
        render: ({ structural }: StructuralLeasing): ReactNode =>
          structural?.structuralResult?.value,
      },
      {
        title: 'Structural Tower Percent',
        key: 'structuralTowerPercent',
        width: 165,
        dataIndex: 'leasing',
        render: ({ structural }: StructuralLeasing): ReactNode =>
          structural?.structuralTowerPercent,
      },
      {
        title: 'Structural Vendor',
        key: 'structuralVendor',
        width: 165,
        dataIndex: 'leasing',
        render: ({ structural }: StructuralLeasing): ReactNode =>
          structural?.structuralVendor?.value,
      },
      {
        title: 'Submarket',
        key: 'submarket',
        width: 140,
        dataIndex: 'site',
        render: (site: SiteBrowse): ReactNode => site.submarket?.value,
      },
      {
        title: 'Tower Height (ft)',
        key: 'towerHeight',
        width: 165,
        dataIndex: 'site',
        render: (siteBroweItem: SiteBrowse): ReactNode =>
          siteBroweItem?.towerHeight,
      },
      {
        title: 'Tower Owner',
        key: 'towerOwner',
        width: 140,
        dataIndex: 'site',
        render: (site: SiteBrowse): ReactNode => site?.towerOwner?.value,
      },
      {
        title: 'Tower Owner Site ID',
        key: 'towerOwnerSiteID',
        width: 186,
        dataIndex: 'site',
        render: (site: SiteBrowse): ReactNode => site?.towerOwnerSiteID,
      },
      {
        title: 'Tower Type',
        key: 'towerType',
        width: 165,
        dataIndex: 'site',
        render: (siteBroweItem: SiteBrowse): ReactNode =>
          readOnlySelectRender(
            dropdownsOptions.TowerType,
            siteBroweItem?.towerType?.id,
          ),
      },
      {
        title: 'USID',
        width: 90,
        key: 'USID',
        dataIndex: 'site',
        render: (site: SiteBrowse): ReactNode => site?.USID,
      },
      {
        title: 'Zip',
        key: 'zip',
        width: 165,
        dataIndex: 'site',
        render: (site: SiteBrowse): ReactNode => site?.zip,
      },
      ...(selectorValue ? addFilterFieldsToColumns() : []),
    ] as ColumnsType<Browse>
  ).map((column: ColumnType<Browse>) => ({
    ...column,
    sorter: AVAILABLE_FIELDS_FOR_SORTING.includes(column.key as string),
  }));
};
