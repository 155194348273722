import React, { FC, PropsWithChildren } from 'react';
import { ModalProps } from '@models/interfaces';
import { Modal } from '../../modules/ui-kit/modal';

import './styles.scss';

export const SaveChangesModal: FC<ModalProps> = ({
  ...props
}: PropsWithChildren<any>) => (
  <Modal
    okText="Save"
    cancelText="Discard Changes"
    className="prov-save-modal"
    cancelType="danger-outline"
    centered
    {...props}
  >
    <span className="prov-save-modal__title">
      Do you want to save all changes?
    </span>
  </Modal>
);
