import React, { FC, memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import classNames from 'classnames';
import { fetchItems } from '@core/utils/methods';
import { RouteGroup } from '@models/classes';
import { MenuPlacement } from '@models/enums';
import { MenuGroup, MenuItem } from '@models/types';

import './styles.scss';

const { SubMenu } = Menu;

const SideMenuComponent: FC = () => {
  const [menuItems, setMenu] = useState<MenuGroup>([]);
  const { pathname } = useLocation();

  useEffect(() => {
    fetchItems(MenuPlacement.Main).then((menu: MenuGroup) => setMenu(menu));
  }, []);

  return (
    <Menu mode="inline" className="side">
      {menuItems?.map(([item, submenu]: MenuItem[]) => {
        const menuItem = item as RouteGroup;
        const submenuItems = submenu as RouteGroup[];

        return (
          <SubMenu
            key={menuItem.path}
            title={
              <span className="side__icon-box">
                {menuItem.icon &&
                  React.createElement(menuItem.icon, {
                    className: 'side__icon',
                  })}
                <span>{menuItem.name}</span>
              </span>
            }
            className={classNames({
              'menu-item-selected': !!menuItem.findRouteByPath(pathname),
            })}
          >
            {submenuItems.map((submenuItem: RouteGroup) => (
              <Menu.Item
                className={classNames(
                  {
                    'menu-item-selected':
                      !!submenuItem.findRouteByPath(pathname),
                  },
                  'side__submenu-item',
                )}
                key={submenuItem.path}
              >
                <Link to={submenuItem.path}>{submenuItem.name}</Link>
              </Menu.Item>
            ))}
          </SubMenu>
        );
      })}
    </Menu>
  );
};

export const SideMenu: FC = memo(SideMenuComponent);
