import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { InformationProps } from '@models/interfaces';
import { Panel } from '@shared/components';
import { Autocomplete, Input, PrimaryButton, TextArea } from '@shared/modules';
import { DDVSelectors } from '@store/selectors';

const { useForm } = Form;

export const SiteInformation: FC<InformationProps> = ({
  className,
  data,
  expectedId,
}: PropsWithChildren<InformationProps>) => {
  const [form] = useForm();

  const dropdownsOptions = useSelector(DDVSelectors.getDDVOptions);

  useEffect(() => {
    if (data) {
      const {
        state,
        towerType,
        equipmentType,
        generatorInstalled,
        ...restData
      } = data;

      form.setFieldsValue({
        ...restData,
        state: state?.name,
        towerType: towerType?.value,
        equipmentType: equipmentType?.value,
        generatorInstalled: generatorInstalled?.value,
      });
    }
  }, [data]);

  return (
    <Panel header="Site Information" className={className}>
      <Form labelCol={{ span: 12 }} wrapperCol={{ span: 13 }} form={form}>
        <Row>
          <Col span="12">
            <Input
              id="FALocationNumber"
              label="FA Location Number"
              isEditing={false}
            />
            <Input id="siteNumber" label="Site Number" isEditing={false} />
            <Autocomplete
              id="towerOwner"
              label="Tower Owner"
              isEditing={false}
              options={dropdownsOptions?.TowerOwner}
            />
            <Input
              id="towerOwnerSiteID"
              label="Tower Owner Site ID"
              isEditing={false}
            />
            <Input id="state" label="State" isEditing={false} />
            <TextArea id="siteNote" label="Site Notes" isEditing={false} />
          </Col>
          <Col span="12">
            <Input id="towerType" label="Tower Type" isEditing={false} />
            <Input
              id="towerHeight"
              label="Tower Height (ft)"
              isEditing={false}
            />
            <Input id="DNE" label="DNE (ft)" isEditing={false} />
            <Input
              id="leasedGroundSpace"
              label="Leased Ground Space"
              isEditing={false}
            />
            <Input
              id="equipmentType"
              label="Equipment Type"
              isEditing={false}
            />
            <Input
              id="generatorInstalled"
              label="Generator Installed"
              isEditing={false}
            />
            <Input
              id="sqInchesEquipmentAllowed"
              label="Sq. Inches Equipment Allowed"
              isEditing={false}
            />
            <Input
              id="sqInchesEquipmentUsed"
              label="Sq. Inches Equipment Used"
              isEditing={false}
            />
            <Input
              id="sqInchesCablesAllowed"
              label="Sq. Inches Cables Allowed"
              isEditing={false}
            />
            <Input
              id="sqInchesCablesUsed"
              label="Sq. Inches Cables Used"
              isEditing={false}
            />
          </Col>
        </Row>
        <Row className="action-buttons">
          <PrimaryButton
            title="View details"
            target="_blank"
            href={`/site/${expectedId}/details`}
          />
        </Row>
      </Form>
    </Panel>
  );
};
