import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { ProjectManagementState } from '../reducers/project-management.reducer';

const pmState = (state: AppState): ProjectManagementState =>
  state.projectManagement;
const appState = (state: AppState): AppState => state;

export namespace ProjectManagementSelectors {
  export const getProjectType = createSelector(
    [pmState],
    (state: ProjectManagementState) =>
      state.currentProjectManagement.projectType.value,
  );

  export const getProjectId = createSelector(
    [pmState],
    (state: ProjectManagementState) => state.currentProjectManagement.id,
  );

  export const getPMBrowseInfo = createSelector(
    [appState],
    ({
      projectManagement: {
        projectManagementBrowse,
        filterSets,
        pmScreenPaceTasksOptions,
      },
      user: { settingsColumns },
    }: AppState) => ({
      projectManagementBrowse,
      filterSets,
      pmScreenPaceTasksOptions,
      settingsColumns,
    }),
  );

  export const getPMBrowseFiltersInfo = createSelector(
    [appState],
    ({
      projectManagement: {
        filterSets,
        pmScreenPaceTasksOptions,
        filtersOptions,
      },
      permitting: { jurisdictionList },
      admin: {
        countyValues: { states },
      },
    }: AppState) => ({
      filterSets,
      pmScreenPaceTasksOptions,
      filtersOptions,
      jurisdictionList,
      states,
    }),
  );

  export const getPMDetailsInfo = createSelector(
    [pmState],
    (state: ProjectManagementState) => state.currentProjectManagement.site,
  );

  export const getPMSummaryInfo = createSelector(
    [pmState],
    ({ currentProjectManagement }: ProjectManagementState) =>
      currentProjectManagement,
  );
}
