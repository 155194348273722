export const arraySort = <T>(
  array: T[],
  strategy: 'ASC' | 'DESC',
  ...sortFiled: Array<keyof T>
): T[] =>
  array.sort(
    (a: T, b: T) =>
      sortFiled
        .map((field: keyof T) => {
          const itemA = Number.isNaN(+a[field])
            ? `${a[field]}`.toLowerCase()
            : a[field];

          const itemB = Number.isNaN(+b[field])
            ? `${b[field]}`.toLowerCase()
            : b[field];

          if (itemA > itemB) {
            return strategy === 'ASC' ? 1 : -1;
          }

          if (itemA < itemB) {
            return strategy === 'ASC' ? -1 : 1;
          }

          return 0;
        })
        .find((item: number) => item) || 0,
  );
