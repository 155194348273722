import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Details } from '@shared/modules/details';
import { EngineeringSelectors } from '@store/selectors';

export const EngineeringDetails: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const site = useSelector(EngineeringSelectors.getDetailsData);

  return <Details {...props} category site={site} />;
};
