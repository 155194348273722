import {
  JurisdictionDetailsPermitting,
  JurisdictionDetailsSummary,
  JurisdictionPermittingData,
} from '@models/interfaces';

export const getDefaultPermittingData = (
  summary?: Omit<JurisdictionDetailsSummary, 'id'>,
  permitting?: JurisdictionDetailsPermitting[],
): JurisdictionPermittingData => ({
  summarySection: summary || ({} as JurisdictionDetailsSummary),
  permittingSection: permitting
    ? permitting.map((currentPermitting: JurisdictionDetailsPermitting) => {
        const { permittingTypes, ...restData } = currentPermitting;

        return restData;
      })
    : [],
});
