import {
  EngineeringSOFData,
  EngineeringSOFProjectData,
  RadData,
  UpdateEngineeringSOFDataRequest,
} from '@models/interfaces';
import { processScopingEquipmentInfo } from '@modules/client/pages/engineering/helpers';

export const processUpdateSOFBody = (
  data: EngineeringSOFData,
): UpdateEngineeringSOFDataRequest => {
  const {
    projectInfo: { RADA, RADB, RADG, RADD, ...restProjectInfo },
    ...dataWithoutRADs
  } = processScopingEquipmentInfo(data);

  const RADs = {
    RADA,
    RADB,
    RADG,
    RADD,
  } as EngineeringSOFProjectData;

  const RAD = Object.keys(RADs).reduce((acc: RadData[], RADKey: string) => {
    const RADScopingValue = RADs[RADKey as keyof EngineeringSOFProjectData];

    return [
      ...acc,
      ...(RADScopingValue
        ? [
            {
              RADField: RADKey.substring(3),
              scoping: RADScopingValue,
            } as RadData,
          ]
        : []),
    ];
  }, []);

  return { ...dataWithoutRADs, projectInfo: { ...restProjectInfo, RAD } };
};
