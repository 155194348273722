import { RuleObject } from 'rc-field-form/es/interface';

export const INPUT_POSITIVE_NUMBER_VALIDATOR = {
  validator: (_: RuleObject, inputValue: number): Promise<void> => {
    if (inputValue || inputValue === 0) {
      return inputValue > 0
        ? Promise.resolve()
        : Promise.reject(new Error('Should be a number, that greater than 0'));
    }

    return Promise.resolve();
  },
};
