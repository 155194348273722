import { useSelector } from 'react-redux';
import { arrsToSelectableObject } from '@core/utils/methods';
import { OptionProps } from '@models/interfaces';
import {
  AdminProjectCategoriesSelectors,
  ProjectCategoriesSelectors,
} from '@store/selectors';

export const useGetProjectCategoryOptions = (): OptionProps[] => {
  const currentProjectCategories = useSelector(
    ProjectCategoriesSelectors.getProjectCategories,
  );
  const projectCategories = useSelector(
    AdminProjectCategoriesSelectors.getAdminProjectCategories,
  );

  return arrsToSelectableObject(projectCategories, 'id', 'value').map(option =>
    currentProjectCategories.find(
      c => (c.projectCategory as unknown as string) === option.value,
    )
      ? {
          ...option,
          disabled: !projectCategories.find(({ id }) => id === option.value)
            ?.isCanBeDuplicated,
        }
      : option,
  );
};
