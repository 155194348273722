import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Undefinable } from '@symfa-inc/providence-types';
import { SelectValue } from 'antd/lib/select';
import { ICoords } from './models';

type ContextData = {
  coords: Undefinable<ICoords>;
  radius: Undefinable<SelectValue>;
  // TODO: (for Max) check if it's possible to change types to () => void
  setCoords: Undefinable<Dispatch<SetStateAction<ICoords | undefined>>>;
  setRadius: Undefinable<Dispatch<SetStateAction<SelectValue | undefined>>>;
};

const MappingContext = createContext<ContextData>({
  coords: undefined,
  radius: undefined,
  setCoords: undefined,
  setRadius: undefined,
});

export const MappingProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [coords, setCoords] = useState<ICoords>();
  const [radius, setRadius] = useState<Undefinable<SelectValue>>();

  const providerValue = useMemo(
    () => ({ coords, setCoords, radius, setRadius }),
    [coords, radius, setCoords, setRadius],
  );

  return (
    <MappingContext.Provider value={providerValue}>
      {children}
    </MappingContext.Provider>
  );
};

export const useMappingContext = (): ContextData => useContext(MappingContext);
