import { StorageItem } from '@models/enums';
import { Config } from '../config/config';
import { LocalStorageService } from './local-storage.service';

export class ZohoService {
  private static _instance: ZohoService;
  private _localStorageService: LocalStorageService;
  private _zohoScriptId: string = 'zoho-script';

  private constructor() {
    this._localStorageService = new LocalStorageService();
  }

  static get get(): ZohoService {
    if (!this._instance) {
      this._instance = new ZohoService();
    }

    return this._instance;
  }

  inject(): void {
    (window as any).ZohoHCAsapSettings = {
      userInfo: {
        token: `${this._localStorageService.getItem(StorageItem.ACCESS_TOKEN)}`,
      },
    };

    const zohoScript = document.createElement('script');

    zohoScript.setAttribute('id', this._zohoScriptId);
    zohoScript.setAttribute('type', 'text/javascript');
    zohoScript.setAttribute('src', Config.get().zohoSrc);
    zohoScript.setAttribute('defer', 'true');

    document.body.appendChild(zohoScript);

    this._overrideColorScheme();
  }

  remove(): void {
    delete (window as any).ZohoHCAsapSettings;

    const zohoContainer = document.getElementById(
      'zohohc-asap-web-helper-main',
    );
    const zohoScript = document.getElementById(this._zohoScriptId);

    if (zohoContainer) {
      document.body.removeChild(zohoContainer);
    }

    if (zohoScript) {
      document.body.removeChild(zohoScript);
    }
  }

  private _overrideColorScheme(): void {
    new Promise((resolve: (element: HTMLElement) => void) => {
      const observer = new MutationObserver(() => {
        const zohoCSSVariables = document.getElementById(
          'zohohc-asap-web-cssvariables',
        );

        if (zohoCSSVariables) {
          resolve(zohoCSSVariables);
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    }).then((element: HTMLElement) => {
      const overrideStyles = document.createElement('style');

      overrideStyles.setAttribute('id', 'override-styles');
      overrideStyles.innerHTML = `
            :root {
              --zohohc_asap_web_applauncher_bg: #178AF5;
              --zohohc_asap_web_appclose_iconbg: #178AF5;
              --zohohc_asap_web_apptabitem_selectedbg: #0b1f3b;
              --zohohc_asap_web_apptabitem_iconclr: rgba(255,255,255,.65);
              --zohohc_asap_web_apptabitem_selectedtextclr: #fff;
              --zohohc_asap_web_appfootermenu_bg: #178AF5;
              --zohohc_asap_web_apppage_bg: #133463;
              --zohohc_asap_web_appheader_bg: #153d74;
              --zohohc_asap_web_appsecondary_bg: #133463;
              --zohohc_asap_web_appthemebase_bdr_clr: #178AF5;
              --zohohc_asap_web_appprimary_textclr: #fff;
              --zohohc_asap_web_appaccent_clr: #178AF5;
              --zohohc_asap_web_appsecondary_textclr: rgba(255,255,255,.65);
              --zohohc_asap_web_appthird_bgclr: #0b1f3b;
              --zohohc_asap_web_appeditorhvr_bg: #0b1f3b;
              --zohohc_asap_web_apppopupview_bg_clr: #133463;
              --zohohc_asap_web_appbutton_primary_bg: #1890FF;
              --zohohc_asap_web_appthemebase_txtclr: rgba(255,255,255,.65);
              --zohohc_asap_web_appthemedefault_bgclr: #0e284c;
              --zohohc_asap_web_apploading_bg: #133463;
            }

           input[data-id='product_name_search_input']::placeholder { color: rgba(255, 255, 255, 0.45); }
          `;

      element.parentNode?.insertBefore(overrideStyles, element.nextSibling);
    });
  }
}
