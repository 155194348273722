import { IScopingTowerNote } from '@symfa-inc/providence-types';
import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { ApiMethods } from '@models/enums';
import {
  OtherEquipmentData,
  PaginatedResponse,
  RangePickerValues,
  ScopingBrowse,
  ScopingSummaryData,
  UpdateScopingEquipmentData,
} from '@models/interfaces';
import {
  MainScopingBrowseFilter,
  ProjectDetailsWithoutCategories,
} from '@models/types';
import type { ScopingTowerNoteUpdateBody } from '@shared/components';
import {
  BreadcrumbsActions,
  EngineeringActions,
  ProjectActions,
  ScopingActions,
} from '@store/actions';
import { ProjectCategoriesEffects, ProjectsEffects } from '@store/effects';
import store from '@store/index';
import { CommonHttpService } from './common-http.service';

export class ScopingHttpService extends CommonHttpService {
  @StoredRequest(ScopingActions.getScopingBrowseDataAction)
  getScopingBrowse(
    params: MainScopingBrowseFilter,
  ): Promise<PaginatedResponse<ScopingBrowse[]>> {
    return this.http.callApi.get(`${this.apiUrl}/engineering/scoping`, null, {
      params,
    });
  }

  exportScheduledScoping(date: RangePickerValues): Promise<void> {
    return this.downloadFile(
      ApiMethods.Get,
      `${this.apiUrl}/engineering/scoping/report`,
      { params: { date } },
    );
  }

  @StoredRequest(ScopingActions.updateScopingBrowseDataAction)
  updateScopingBrowse(
    params: MainScopingBrowseFilter,
    body: { date: string },
  ): Promise<PaginatedResponse<ScopingBrowse[]>> {
    return this.http.callApi.patch(`${this.apiUrl}/engineering/scoping`, body, {
      params,
    });
  }

  @StoredRequest(
    ScopingActions.getScopingDetailsAction,
    BreadcrumbsActions.Engineering.scoping,
  )
  async getScopingDetails(
    projectBundleID: string,
  ): Promise<Omit<ProjectDetailsWithoutCategories, 'RAD'>> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/engineering/scoping/details/${projectBundleID}`,
    );

    store.dispatch(
      ProjectActions.updateProjectPaceTaskFields.done(response.paceTasks),
    );

    return ProjectsEffects.setHoldOptions(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectsEffects.setRAD(
          ProjectCategoriesEffects.setProjectCategories(response),
        ),
      ),
    );
  }

  @StoredRequest(
    ScopingActions.getScopingEquipmentAction,
    BreadcrumbsActions.Engineering.scoping,
  )
  async getScopingEquipment(
    projectBundleID: string,
  ): Promise<Omit<ProjectDetailsWithoutCategories, 'RAD'>> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/engineering/scoping/equipment/${projectBundleID}`,
    );

    store.dispatch(
      ProjectActions.updateProjectPaceTaskFields.done(response.paceTasks),
    );

    return ProjectsEffects.setHoldOptions(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectsEffects.setRAD(
          ProjectCategoriesEffects.setProjectCategories(response),
        ),
      ),
    );
  }

  @StoredRequest(
    ScopingActions.getScopingAssociatedProjectsAction,
    BreadcrumbsActions.Engineering.scoping,
  )
  async getScopingAssociatedProjects(
    projectBundleID: string,
  ): Promise<Omit<ProjectDetailsWithoutCategories, 'RAD'>> {
    const response = await this.http.callApi.get(
      `${this.apiUrl}/engineering/scoping/associated-projects/${projectBundleID}`,
    );

    store.dispatch(
      ProjectActions.updateProjectPaceTaskFields.done(response.paceTasks),
    );

    return ProjectsEffects.setHoldOptions(
      ProjectsEffects.setCurrentProvidenceAgent(
        ProjectsEffects.setRAD(
          ProjectCategoriesEffects.setProjectCategories(response),
        ),
      ),
    );
  }

  @StoredRequest(ScopingActions.updateScopingSummaryAction)
  async updateScopingSummary(
    id: string,
    body: ScopingSummaryData,
  ): Promise<ScopingSummaryData> {
    await this.http.callApi.patch(
      `${this.apiUrl}/engineering/scoping/summary/${id}`,
      body,
    );

    return body;
  }

  @StoredRequest(ScopingActions.updateScopingEquipmentAction)
  async updateScopingEquipment(
    id: string,
    body: UpdateScopingEquipmentData,
  ): Promise<UpdateScopingEquipmentData> {
    const processedOtherEquipments = body.otherEquipments.map(
      (equipment: OtherEquipmentData) => {
        const { equipmentType, generatedId, manufacturer, ...itemToReq } =
          equipment;

        return itemToReq;
      },
    );

    await this.http.callApi.patch(
      `${this.apiUrl}/engineering/scoping/equipment/${id}`,
      {
        ...body,
        otherEquipments: processedOtherEquipments,
        versions: body.versions.map(
          ({
            sectorsWithManufacturers,
            sectorsWithoutManufacturers,
            ...version
          }) => ({
            ...version,
            sectors: sectorsWithoutManufacturers,
          }),
        ),
      },
    );

    return body;
  }

  @StoredRequest(
    ScopingActions.updateScopingTowerNoteAction,
    EngineeringActions.updateScopingTowerNoteAction,
  )
  updateScopingTowerNote(
    scopingId: string,
    body: ScopingTowerNoteUpdateBody,
  ): Promise<IScopingTowerNote> {
    return this.http.callApi.patch(
      `${this.apiUrl}/engineering/scoping/scoping-tower-note/${scopingId}`,
      body,
    );
  }
}
