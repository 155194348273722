import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { DefaultTowerAgentHttpService } from '@core/services/http';

export class DefaultTowerAgentResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      DefaultTowerAgentHttpService,
    ).getDefaultTowerAgentOptions(null);
  }
}
