import React, { FC, PropsWithChildren } from 'react';
import { FormElementSelect } from '../../models/interfaces';
import { Select } from '../select';

export const Autocomplete: FC<FormElementSelect> = ({
  id,
  title = '',
  tooltip = false,
  options = [],
  elementProps,
  ...props
}: PropsWithChildren<FormElementSelect>) => (
  <Select
    id={id}
    title={title}
    tooltip={tooltip}
    options={options}
    elementProps={{
      showSearch: true,
      filterOption: (inputValue: string, option: any): boolean =>
        !!option &&
        String(option.children)
          .toUpperCase()
          .indexOf(String(inputValue).toUpperCase()) !== -1,
      ...elementProps,
    }}
    {...props}
  />
);
