import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CandidatesSelectors } from '@store/selectors';
import { useMappingContext } from '../../mapping-context';
import { useCreateMap, useCreateRadius } from './helpers';

import './styles.scss';

export const UsualMap: FC = () => {
  const mapRef = useRef<HTMLDivElement>(null);

  const { coords, radius } = useMappingContext();

  const candidates = useSelector(CandidatesSelectors.getCandidates);

  const [map, setMap] = useState<Microsoft.Maps.Map>();

  useCreateMap({ map, setMap, mapRef, candidates });

  useEffect(() => {
    if (coords) {
      map?.setView({
        center: new Microsoft.Maps.Location(coords.latitude, coords.longitude),
      });
    }
  }, [coords]);

  useCreateRadius({ map, coords, radius });

  return (
    <div className="map-wrapper">
      <div className="map-container" ref={mapRef} />
    </div>
  );
};
