import React, { ReactNode } from 'react';
import { FileSearchOutlined } from '@ant-design/icons';
import {
  IReceivedDirectMessage,
  ISelectorOption,
} from '@symfa-inc/providence-types';
import { ColumnsType } from 'antd/es/table';
import {
  findFromSelectorOptions,
  mainDateFormat,
  tableSorter,
} from '@core/utils/methods';
import { DashboardItem } from '@models/interfaces';
import { PROJECT_DATA_COLUMNS } from '../../../models';

export const DIRECT_MESSAGE_COLUMNS = (
  usersList: ISelectorOption[],
  showMessage: (dashboardItem: DashboardItem<IReceivedDirectMessage>) => void,
): ColumnsType =>
  [
    {
      dataIndex: 'notificationData',
      key: 'status',
      width: 45,
      sorter: tableSorter(['notificationData', 'isCompleted']),
      defaultSortOrder: 'ascend',
      className: 'empty-header',
      render: ({ isCompleted }: IReceivedDirectMessage): ReactNode => (
        <i className={`circle circle_${isCompleted ? 'gray' : 'green'}`} />
      ),
    },
    {
      title: 'Sender',
      dataIndex: 'notificationData',
      key: 'senderId',
      width: 250,
      render: ({ senderId }: IReceivedDirectMessage): ReactNode =>
        findFromSelectorOptions(senderId, usersList),
    },
    {
      title: 'Area',
      dataIndex: 'notificationData',
      key: 'area',
      width: 250,
      sorter: tableSorter(['notificationData', 'areaKey']),
      render: ({ areaKey }: IReceivedDirectMessage): ReactNode => areaKey,
    },
    ...PROJECT_DATA_COLUMNS,
    {
      title: 'Message',
      dataIndex: 'notificationData',
      key: 'message',
      sorter: tableSorter(['notificationData', 'message']),
      className: 'overflow',
      render: ({ message }: IReceivedDirectMessage): ReactNode => message,
    },
    {
      title: 'Created At',
      dataIndex: 'notificationData',
      key: 'createdAt',
      width: 150,
      sorter: tableSorter(['notificationData', 'createdAt'], true),
      render: ({ createdAt }: IReceivedDirectMessage): ReactNode =>
        mainDateFormat(createdAt),
    },
    {
      key: 'action-button',
      width: 56,
      render: (
        dashboardItem: DashboardItem<IReceivedDirectMessage>,
      ): ReactNode => (
        <FileSearchOutlined
          className="view-icon"
          onClick={(): void => showMessage(dashboardItem)}
        />
      ),
    },
  ] as ColumnsType;
