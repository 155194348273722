import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { ViewElementProps } from './models';

import './styles.scss';

export const ViewElement: FC<ViewElementProps> = ({
  label,
  value,
  element,
  additionClassName,
}: PropsWithChildren<ViewElementProps>) => {
  const valueElement = value && (
    <span className="prov-view-element__element">{value}</span>
  );

  return (
    <div className={classNames('prov-view-element', additionClassName)}>
      <span className="prov-view-element__label">{label}</span>
      <span className="prov-view-element__divider">:</span>
      {element
        ? React.cloneElement(element, {
            className: 'prov-view-element__element',
          })
        : valueElement}
    </div>
  );
};
