import { SectorEquipmentType as SectorEquipmentTypeEnum } from '@models/enums';
import {
  BaseUpdateEquipmentData,
  SectorEquipment,
  SectorEquipmentData,
  SectorEquipmentType,
} from '@models/interfaces';
import {
  ScopingSectorsVersionData,
  ScopingSectorsVersionRequestData,
} from '@models/types';

export const processScopingEquipmentInfo = <T extends BaseUpdateEquipmentData>({
  otherEquipments,
  scopingSectorsVersions,
  ...restData
}: T) => {
  const processedVersions: ScopingSectorsVersionRequestData[] =
    scopingSectorsVersions.map((version: ScopingSectorsVersionData) => {
      const { scopingSectors: sectors, isSelected, ...restVersion } = version;

      const [sectorsWithManufacturers, sectorsWithoutManufacturers] =
        Object.keys(sectors)
          .filter(
            (sectorName: string) =>
              sectors[sectorName as SectorEquipmentTypeEnum]!.length,
          )
          .reduce<[SectorEquipmentData[], SectorEquipmentData[]]>(
            (
              [sectorWithManufactures, sectorWithoutManufacturers]: [
                SectorEquipmentData[],
                SectorEquipmentData[],
              ],
              sectorName: string,
            ): [SectorEquipmentData[], SectorEquipmentData[]] => {
              const sectorEquipmentData = {
                type: sectorName,
                sectorEquipmentTypes: sectors[
                  sectorName as SectorEquipmentTypeEnum
                ]!.map((sector: SectorEquipmentType) => ({
                  ...sector,
                  equipments: sector.equipments.filter(
                    (equipmentItem: SectorEquipment) =>
                      !!equipmentItem.equipment,
                  ),
                })),
              } as SectorEquipmentData;

              sectorWithManufactures.push(sectorEquipmentData);
              sectorWithoutManufacturers.push({
                ...sectorEquipmentData,
                sectorEquipmentTypes:
                  sectorEquipmentData.sectorEquipmentTypes.map(
                    (sector: SectorEquipmentType) => ({
                      ...sector,
                      equipments: sector.equipments.map(
                        ({ manufacturer, ...equipment }) => equipment,
                      ),
                    }),
                  ),
              } as SectorEquipmentData);

              return [sectorWithManufactures, sectorWithoutManufacturers];
            },
            [[], []],
          );

      return {
        ...restVersion,
        sectorsWithManufacturers,
        sectorsWithoutManufacturers,
      };
    });

  return {
    ...restData,
    versions: processedVersions,
    otherEquipments,
  };
};
