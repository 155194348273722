import { arrsToSelectableObject } from '@core/utils/methods';
import { OptionProps } from '@models/interfaces';
import { getProjectFieldsList, getSiteFieldsList } from './fields-lists';

export const getSetFieldsOptions = (): OptionProps[] => {
  const fieldsList = [...getSiteFieldsList(), ...getProjectFieldsList()];

  fieldsList.sort((a, b) => a.label.localeCompare(b.label));

  return arrsToSelectableObject(fieldsList, 'id', 'label');
};
