import React, { FC, PropsWithChildren } from 'react';
import { Collapse } from 'antd';
import { ConfigurablePanelProps, Panel } from '../../models';
import { ConfigurablePanelHeader } from '../configurable-panel-header';

import './styles.scss';

export const ConfigurablePanel: FC<ConfigurablePanelProps> = ({
  panels,
  openModal,
  openDeleteModal,
  formUpdating,
  isEditing,
}: PropsWithChildren<ConfigurablePanelProps>) => (
  <Collapse
    className="prov-jurisdiction-configurable-panel"
    accordion
    expandIconPosition="right"
  >
    {panels.map((panel: Panel) => (
      <Collapse.Panel
        key={panel.header.id}
        header={
          <ConfigurablePanelHeader
            header={panel.header}
            openModal={openModal}
            openDeleteModal={openDeleteModal}
            isEditing={isEditing}
          />
        }
      >
        {React.cloneElement(panel.content, { isEditing, formUpdating })}
      </Collapse.Panel>
    ))}
  </Collapse>
);
