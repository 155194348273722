import React, { ReactNode } from 'react';
import { actionButtons } from '@models/constants';
import { AdminDefaultTowerAgentData, IdAndValue } from '@models/interfaces';
import { ColumnsFunc } from '@models/types';
import { VirtualTableColumn } from '@shared/components';

export const getDefaultTowerAgentColumns = (
  editFunc: ColumnsFunc<AdminDefaultTowerAgentData>,
  deleteFunc: ColumnsFunc<AdminDefaultTowerAgentData>,
): Array<VirtualTableColumn> => [
  {
    title: 'Providence Agent',
    key: 'providenceAgent',
    render: ({
      providenceAgent: { firstName, lastName },
    }: AdminDefaultTowerAgentData): ReactNode => (
      <span>
        {firstName} {lastName}
      </span>
    ),
  },
  {
    title: 'Tower Owner',
    key: 'towerOwner',
    render: ({ towerOwners }: AdminDefaultTowerAgentData): ReactNode => (
      <div className="project-category-cell">
        {towerOwners
          .map((towerOwner: IdAndValue) => towerOwner.value)
          .join(', ')}
      </div>
    ),
  },
  actionButtons({ editFunc, deleteFunc }) as VirtualTableColumn,
];
