import { createSelector } from 'reselect';
import { AppState } from '../reducers';
import { CommonState } from '../reducers/common.reducer';

const commonState = (state: AppState): CommonState => state.common;

export namespace CommonSelectors {
  export const getHasUnsubmittedData = createSelector(
    [commonState],
    (state: CommonState) => state.hasUnsubmittedData,
  );

  export const getRouteParams = createSelector(
    [commonState],
    (state: CommonState) => state.params,
  );

  export const getRoutePath = createSelector(
    [commonState],
    (state: CommonState) => state.routePath,
  );
}
