import React, { FC } from 'react';
import { GoBackButton } from '@shared/components';

import './styles.scss';

export const NoJurisdiction: FC = () => (
  <section className="prov-no-jurisdiction">
    <span className="prov-no-jurisdiction__info">
      This Project has no assigned Jurisdiction!
    </span>
    <GoBackButton />
  </section>
);
