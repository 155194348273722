import React, { ReactNode } from 'react';
import { FileDoneOutlined, FileSearchOutlined } from '@ant-design/icons';
import {
  IDirectMessageReceivers,
  IReceivedDirectMessageForArea,
} from '@symfa-inc/providence-types';
import { ColumnsType } from 'antd/es/table';
import { findFromSelectorOptions, tableSorter } from '@core/utils/methods';

export const RECEIVED_DIRECT_MESSAGE_MANAGER_COLUMNS = (
  usersList: IDirectMessageReceivers[],
  showMessage: (item: IReceivedDirectMessageForArea) => void,
  setCompleted: (item: string) => void,
): ColumnsType<IReceivedDirectMessageForArea> => [
  {
    key: 'isCompleted',
    dataIndex: 'isCompleted',
    width: 45,
    className: 'empty-header',
    defaultSortOrder: 'ascend',
    sorter: tableSorter(['isCompleted']),
    render: (isCompleted: boolean): ReactNode => (
      <i className={`circle circle_${isCompleted ? 'gray' : 'green'}`} />
    ),
  },
  {
    title: 'Sender',
    dataIndex: 'senderId',
    key: 'senderId',
    width: 250,
    render: (senderId: string): ReactNode =>
      findFromSelectorOptions(senderId, usersList),
  },
  {
    title: 'Message',
    key: 'message',
    dataIndex: 'message',
    className: 'overflow',
  },
  {
    key: 'show-message',
    width: 56,
    render: (directMessage: IReceivedDirectMessageForArea): ReactNode => (
      <FileSearchOutlined
        className="view-icon"
        onClick={(): void => showMessage(directMessage)}
      />
    ),
  },
  {
    key: 'set-completed',
    width: 56,
    render: (directMessage: IReceivedDirectMessageForArea): ReactNode =>
      !directMessage.isCompleted ? (
        <FileDoneOutlined
          className="view-icon"
          onClick={(): void => setCompleted(directMessage._id)}
        />
      ) : null,
  },
];
