import { actionCreatorFactory } from '../../core/utils/factories';
import {
  AccountingBrowse,
  AccountingBrowseUpdateData,
  AccountingDetails,
  PaginatedResponse,
} from '../../models/interfaces';

const actionCreator = actionCreatorFactory('ACCOUNTING');

export namespace AccountingActions {
  export const getAccountingBrowseDataAction = actionCreator.createAsyncAction<
    PaginatedResponse<AccountingBrowse>
  >('GET_ACCOUNTING_BROWSE');
  export const updateAccountingBrowseAction =
    actionCreator.createAsyncAction<AccountingBrowseUpdateData>(
      'UPDATE_ACCOUNTING_BROWSE',
    );
  export const getAccountingDetailsAction =
    actionCreator.createAsyncAction<AccountingDetails>(
      'GET_ACCOUNTING_DETAILS',
    );
}
