import React, { FC, PropsWithChildren } from 'react';
import { InformationProps } from '@models/interfaces';
import { Panel, RADTable } from '@shared/components';

export const RADInformation: FC<InformationProps> = ({
  className,
}: PropsWithChildren<InformationProps>) => (
  <Panel header="RAD Information" className={className}>
    <RADTable />
  </Panel>
);
