import React, { FC, PropsWithChildren } from 'react';
import { Tooltip } from 'antd';
import { ItemProps } from '../models/interfaces';

export const Item: FC<ItemProps> = ({
  label,
  value,
  element,

  tooltip,
}: PropsWithChildren<ItemProps>) => (
  <div className="item">
    <span className="item__label">{label} :</span>
    {element ||
      (tooltip ? (
        <Tooltip title={value}>
          <span className="item__value tooltip">{value}</span>
        </Tooltip>
      ) : (
        <span className="item__value">{value}</span>
      ))}
  </div>
);
