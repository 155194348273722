import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { ProjectCategoryHttpService } from '@core/services/http';

export class ProjectCategoryResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(
      ProjectCategoryHttpService,
    ).getProjectCategories();
  }
}
