import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import deleteIcon from 'assets/icons/delete-icon.svg';
import editIcon from 'assets/icons/edit-icon.svg';
import { HttpService } from '@core/services';
import { ProjectManagementHttpService } from '@core/services/http';
import { arrsToSelectableObject } from '@core/utils/methods';
import { ModalMainTypes } from '@models/enums';
import { AdminCountyData, StateWithCounties } from '@models/interfaces';
import { NotificationsLoader } from '@shared/components';
import { Autocomplete, PrimaryButton } from '@shared/modules';
import { ProjectManagementSelectors } from '@store/selectors';
import { findFiltersSet } from '../models';
import { PMFiltersProps } from '../models/interfaces';
import { FiltersList, FiltersSetModal } from './components';
import { convertPaceTaskFilterToObj } from './models';

export const Filters: FC<PMFiltersProps> = ({
  formProps,
  selectorValue,
  setSelectorValue,
  filtersSet,
  setFiltersSet,
}: PropsWithChildren<PMFiltersProps>) => {
  const {
    filterSets,
    pmScreenPaceTasksOptions,
    filtersOptions,
    jurisdictionList,
    states,
  } = useSelector(ProjectManagementSelectors.getPMBrowseFiltersInfo);

  const [isAddEditSetModalVisible, setAddEditSetModalVisible] =
    useState<boolean>(false);
  const [currentModalType, setCurrentModalType] = useState<ModalMainTypes>(
    ModalMainTypes.Add,
  );

  const toggleAddAndEditSetModal = (type: ModalMainTypes): void => {
    setCurrentModalType(type);
    setAddEditSetModalVisible(!isAddEditSetModalVisible);
  };

  useEffect(() => {
    if (selectorValue) {
      const data = findFiltersSet(filterSets, selectorValue);
      const { name, filter, paceTaskFilter } = data;

      setFiltersSet(data);

      formProps?.form.setFieldsValue({
        name,
        ...filter,
        ...convertPaceTaskFilterToObj(paceTaskFilter),
      });
    }
  }, [filterSets, selectorValue]);

  return (
    <div className="prov-project-management__filters">
      <Row className="prov-project-management__filters-handler">
        <Autocomplete
          id="name"
          label="Filter Set Name"
          options={arrsToSelectableObject(filterSets, 'id', 'name')}
          elementProps={{
            allowClear: true,
            onChange: setSelectorValue,
          }}
        />
        <PrimaryButton
          title=""
          icon="+"
          className="prov-project-management__filters-handler__add-button"
          onClick={(): void => {
            toggleAddAndEditSetModal(ModalMainTypes.Add);
          }}
        />
        <PrimaryButton
          title=""
          icon={<img src={editIcon} alt="Edit" className="county-icon" />}
          onClick={(): void => {
            toggleAddAndEditSetModal(ModalMainTypes.Edit);
          }}
          disabled={!selectorValue}
        />
        <PrimaryButton
          icon={<img src={deleteIcon} alt="delete-icon" />}
          title=""
          type="danger-outline"
          disabled={!selectorValue}
          onClick={async (): Promise<void> => {
            if (selectorValue) {
              try {
                await HttpService.getHttpRequests(
                  ProjectManagementHttpService,
                ).deletePMBrowseFiltersSet(selectorValue as string);

                NotificationsLoader.notificationSuccess(
                  `Filter's set has been deleted!`,
                );

                setSelectorValue(undefined);
              } catch (e) {
                console.error(e);
              }
            }
          }}
        />
        <FiltersSetModal
          visible={isAddEditSetModalVisible}
          modalType={currentModalType}
          toggleModal={toggleAddAndEditSetModal}
          selectorValue={
            currentModalType === ModalMainTypes.Edit
              ? (selectorValue as string)
              : undefined
          }
          setSelector={(selectValue?: string): void => {
            setSelectorValue(selectValue);
            formProps?.form.setFieldsValue({ name: undefined });
          }}
        />
      </Row>
      {selectorValue && (
        <>
          <FiltersList
            form={formProps?.form}
            fieldsList={filtersSet.setFields}
            data={filtersSet?.filter}
            checkbox
            options={{
              jurisdictionOptions: arrsToSelectableObject(
                jurisdictionList,
                'id',
                'jurisdictionName',
              ),
              countyOptions: arrsToSelectableObject<AdminCountyData>(
                states.flatMap(
                  (stateItem: StateWithCounties) => stateItem.counties,
                ),
                'id',
                'name',
              ),
              ...filtersOptions,
            }}
          />
          <FiltersList
            form={formProps?.form}
            fieldsList={filtersSet?.paceTaskFields}
            data={convertPaceTaskFilterToObj(filtersSet?.paceTaskFilter)}
            pmScreenPaceTasksOptions={pmScreenPaceTasksOptions}
            checkbox
          />
        </>
      )}
    </div>
  );
};
