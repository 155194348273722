import {
  DateStringOrNull,
  FRONTEND_DATE_FORMAT,
  Undefinable,
} from '@symfa-inc/providence-types';
import { dateWorker } from '@core/utils/date-worker';

export const mainDateFormat = (
  date?: DateStringOrNull | Date,
  utc: boolean = false,
): Undefinable<string> => {
  let result;

  if (date) {
    result = utc
      ? dateWorker(date).local().format(FRONTEND_DATE_FORMAT)
      : dateWorker(date).format(FRONTEND_DATE_FORMAT);
  }

  return result;
};
