import React, { FC, PropsWithChildren } from 'react';
import { ImportError } from '@models/interfaces';
import { Panel } from '@shared/components';
import { ErrorAreaProps } from './models/interfaces';

import './styles.scss';

export const ErrorArea: FC<ErrorAreaProps> = ({
  errors = [],
}: PropsWithChildren<ErrorAreaProps>) => (
  <Panel
    collapseClassName="prov-error-area"
    className="prov-error-area__body"
    header="Errors"
    disabled
  >
    {Array.isArray(errors) && !!errors.length && (
      <ul className="error-list">
        {errors?.map(({ row, column, description }: ImportError) => (
          <li className="error-list__element" key={row + column + description}>
            <div className="error-list__element__row-number element-panel">
              Row: {row}
            </div>
            <div className="error-list__element__column-name element-panel">
              Column: {column}
            </div>
            <div className="error-list__element__description element-panel">
              {description}
            </div>
          </li>
        ))}
      </ul>
    )}

    {!errors.length && <p className="no-errors-text">No errors found</p>}
  </Panel>
);
