import React, {
  FC,
  PropsWithChildren,
  ReactText,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { HttpService } from '@core/services';
import { PaceTaskHttpService } from '@core/services/http';
import { useDidUpdateEffect } from '@core/utils/hooks';
import { PaceTaskScreen } from '@models/interfaces';
import { CustomTable } from '@shared/components';
import { Modal } from '@shared/modules';
import { PaceTaskSelectors } from '@store/selectors';
import { ArchiveModalProps } from './models';

import './styles.scss';

export const ArchiveModal: FC<ArchiveModalProps<PaceTaskScreen>> = ({
  columns,
  screen,
  visible,
  onOk,
  ...props
}: PropsWithChildren<ArchiveModalProps<PaceTaskScreen>>) => {
  const paceTaskService = useMemo(
    () => HttpService.getHttpRequests(PaceTaskHttpService),
    [],
  );

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [disabledReturn, setDisabledReturn] = useState<boolean>(true);
  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (rows: ReactText[]): void => setSelectedRows(rows as string[]),
  };
  const archiveData = useSelector(PaceTaskSelectors.getArchiveData);
  const [archive, setArchive] = useState<PaceTaskScreen[]>(archiveData);

  useEffect(() => {
    if (visible) {
      paceTaskService.getArchivedPaceTasks(screen);
    }
  }, [screen, visible]);

  useEffect(() => {
    setArchive(archiveData);
  }, [archiveData]);

  useDidUpdateEffect(() => {
    setDisabledReturn(!selectedRows.length);
  }, [selectedRows]);

  const save = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): Promise<void> => {
    await paceTaskService.movePaceTaskFromArchive(screen, selectedRows);

    if (onOk) {
      onOk(e);
    }
  };

  return (
    <Modal
      title="Archive"
      okText="Return to Table"
      centered
      width={1300}
      className="prov-archive-modal"
      onOk={save}
      okButtonProps={{
        disabled: disabledReturn,
      }}
      visible={visible}
      {...props}
    >
      <CustomTable
        columns={columns}
        dataSource={archive}
        pagination={false}
        scroll={{ y: 300 }}
        rowSelection={rowSelection}
      />
    </Modal>
  );
};
