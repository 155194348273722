import { DriverWorkflow } from '@symfa-inc/providence-types';
import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import {
  DriverManagementHttpService,
  ProjectCategoryHttpService,
  ProjectManagementHttpService,
} from '@core/services/http';
import { getProjectBundleIDParamFromUrl } from '@core/utils/methods';

export class ProjectManagementDetailsResolver implements Resolver {
  async resolve(): Promise<void> {
    const [projectBundleID] = getProjectBundleIDParamFromUrl();

    await Promise.all([
      HttpService.getHttpRequests(
        ProjectManagementHttpService,
      ).getProjectManagementDetails(projectBundleID),

      HttpService.getHttpRequests(
        ProjectCategoryHttpService,
      ).getProjectCategories(),

      HttpService.getHttpRequests(
        DriverManagementHttpService,
      ).getDriversListByWorkflow(DriverWorkflow.ProjectManagement),
    ]);
  }
}
