import {
  IRule,
  ISchedule,
  ISelectorOption,
  NotifyType,
} from '@symfa-inc/providence-types';
import { createReducer, on } from '@core/utils/store';
import {
  DirectMessageData,
  OptionProps,
  PayloadAndState,
} from '@models/interfaces';
import { NotificationActions } from '@store/actions';

export interface NotificationState {
  paceTasksList: OptionProps[];
  userList: ISelectorOption[];
  rules: IRule[];
  schedules: ISchedule[];
  emailCertificationDate: string;
  directMessages: DirectMessageData[];
}

const initialState: NotificationState = {
  paceTasksList: [],
  userList: [],
  rules: [],
  schedules: [],
  emailCertificationDate: '',
  directMessages: [],
};

export const reducer = createReducer(
  initialState,
  on(
    NotificationActions.getPaceTaskList,
    ({ payload }: PayloadAndState<OptionProps[], NotificationState>) => ({
      paceTasksList: payload,
    }),
  ),
  on(
    NotificationActions.getUserList,
    ({ payload }: PayloadAndState<ISelectorOption[], NotificationState>) => ({
      userList: payload,
    }),
  ),
  // Rules
  on(
    NotificationActions.getRules,
    ({ payload }: PayloadAndState<IRule[], NotificationState>) => ({
      rules: payload,
    }),
  ),
  on(
    NotificationActions.createRule,
    ({ payload, state }: PayloadAndState<IRule, NotificationState>) => ({
      ...state,
      rules: [...state.rules, payload],
    }),
  ),
  on(
    NotificationActions.updateRule,
    ({
      payload: {
        _id,
        userIds,
        roles,
        responsibleForProjectType,
        towerOwners,
        ...updateData
      },
      state,
    }: PayloadAndState<IRule, NotificationState>) => ({
      ...state,
      rules: state.rules.map((rule: IRule) => {
        const condition = rule._id !== _id;

        const overrideFields = {
          ...(updateData.notifyType === NotifyType.Role
            ? {
                roles,
                userIds: [],
                responsibleForProjectType,
                towerOwners: towerOwners?.length ? towerOwners : [],
              }
            : {
                roles: [],
                userIds,
                responsibleForProjectType: undefined,
                towerOwners: [],
              }),
        };

        return condition ? rule : { ...rule, ...updateData, ...overrideFields };
      }),
    }),
  ),
  on(
    NotificationActions.deleteRule,
    ({ payload: _id, state }: PayloadAndState<string, NotificationState>) => ({
      ...state,
      rules: state.rules.filter((rule: IRule) => rule._id !== _id),
    }),
  ),
  // Schedule
  on(
    NotificationActions.getSchedules,
    ({ payload }: PayloadAndState<ISchedule[], NotificationState>) => ({
      schedules: payload,
    }),
  ),
  on(
    NotificationActions.createSchedule,
    ({ payload, state }: PayloadAndState<ISchedule, NotificationState>) => ({
      ...state,
      schedules: [...state.schedules, payload],
    }),
  ),
  on(
    NotificationActions.updateSchedule,
    ({
      payload: {
        _id,
        userIds,
        roles,
        responsibleForProjectType,
        towerOwners,
        ...updateData
      },
      state,
    }: PayloadAndState<ISchedule, NotificationState>) => ({
      ...state,
      schedules: state.schedules.map((schedule: ISchedule) => {
        const condition = schedule._id !== _id;
        const overrideFields = {
          ...(updateData.notifyType === NotifyType.Role
            ? {
                roles,
                userIds: [],
                responsibleForProjectType,
                towerOwners: towerOwners?.length ? towerOwners : [],
              }
            : {
                roles: [],
                userIds,
                responsibleForProjectType: undefined,
                towerOwners: [],
              }),
          ...(updateData.hasAutoWarningChecking
            ? {}
            : {
                warningDaysCount: undefined,
                warningMessage: undefined,
                needNotifyAdmin: undefined,
              }),
          ...(updateData.isImmediately
            ? {
                daysCount: undefined,
                ...(updateData.hasAutoWarningChecking
                  ? {}
                  : { operator: undefined }),
              }
            : {}),
        };

        return condition
          ? schedule
          : { ...schedule, ...updateData, ...overrideFields };
      }),
    }),
  ),
  on(
    NotificationActions.deleteSchedule,
    ({ payload: _id, state }: PayloadAndState<string, NotificationState>) => ({
      ...state,
      schedules: state.schedules.filter(
        (schedule: ISchedule) => schedule._id !== _id,
      ),
    }),
  ),
  // Mailer
  on(
    NotificationActions.getEmailCertificationDate,
    ({
      payload: emailCertificationDate,
    }: PayloadAndState<string, NotificationState>) => ({
      emailCertificationDate,
    }),
  ),
  // Direct Messages
  on(
    NotificationActions.getDirectMessages,
    ({
      payload: directMessages,
    }: PayloadAndState<DirectMessageData[], NotificationState>) => ({
      directMessages,
    }),
  ),
  on(
    NotificationActions.removeDirectMessage,
    ({
      payload: directMessageId,
      state: { directMessages },
    }: PayloadAndState<string, NotificationState>) => ({
      directMessages: directMessages.filter(
        ({ _id }: DirectMessageData) => _id !== directMessageId,
      ),
    }),
  ),
  on(
    NotificationActions.multiRemoveDirectMessages,
    ({
      payload: directMessageIds,
      state: { directMessages },
    }: PayloadAndState<string[], NotificationState>) => ({
      directMessages: directMessages.filter(
        ({ _id }: DirectMessageData) => !directMessageIds.includes(_id),
      ),
    }),
  ),
);
