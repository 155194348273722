import { createReducer, on } from '@core/utils/store';
import {
  OptionProps,
  PayloadAndState,
  SiteImageData,
} from '@models/interfaces';
import { SitePicturesUploadActions } from '@store/actions';

export interface SitePicturesUploadState {
  FALocationNumberOptions: OptionProps[];
  currentSiteImages: SiteImageData[];
  isFetching: boolean;
  errors: boolean;
}

const initialState: SitePicturesUploadState = {
  FALocationNumberOptions: [],
  currentSiteImages: [],
  isFetching: false,
  errors: false,
};

export const reducer = createReducer(
  initialState,
  // GET FA Loction Number Options
  on(
    SitePicturesUploadActions.getFALocationNumberOptionsList,
    ({
      payload: FALocationNumberOptions,
    }: PayloadAndState<OptionProps[], SitePicturesUploadState>) => ({
      FALocationNumberOptions,
    }),
  ),
  // GET Site images
  on(
    SitePicturesUploadActions.getSiteImages,
    ({
      payload: currentSiteImages,
    }: PayloadAndState<SiteImageData[], SitePicturesUploadState>) => ({
      currentSiteImages,
    }),
  ),
  // Upload Site images
  on(
    SitePicturesUploadActions.uploadSiteImages,
    ({
      payload,
      state: { currentSiteImages },
    }: PayloadAndState<SiteImageData[], SitePicturesUploadState>) => ({
      currentSiteImages: [...currentSiteImages, ...payload],
    }),
  ),
  // Delete site image
  on(
    SitePicturesUploadActions.deleteSiteImageAction,
    ({
      payload: id,
      state: { currentSiteImages },
    }: PayloadAndState<string, SitePicturesUploadState>) => ({
      currentSiteImages: currentSiteImages.filter(image => image.id !== id),
    }),
  ),
);
