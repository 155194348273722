import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Undefinable } from '@symfa-inc/providence-types';
import { Col, InputNumber, Row } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { arrsToSelectableObject } from '@core/utils/methods';
import { Candidate } from '@models/interfaces';
import { Autocomplete, Details, PrimaryButton } from '@shared/modules';
import { CandidatesSelectors } from '@store/selectors';
import { CoordinatesBar } from './components';
import { MappingProvider, useMappingContext } from './mapping-context';

import './styles.scss';

const PrivateMappingArea: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const candidates = useSelector(CandidatesSelectors.getCandidates);

  const { radius, setCoords, setRadius } = useMappingContext();

  const [selectorValue, setSelectorValue] =
    useState<Undefinable<SelectValue>>();

  const onSelectorChange = (value: SelectValue): void => {
    setSelectorValue(value);

    if (value) {
      const { latitude, longitude } =
        candidates.find(
          ({ FALocationNumber }: Candidate) =>
            FALocationNumber.toString() === value.toString(),
        ) ?? {};

      if (latitude && longitude) {
        setCoords?.({ latitude, longitude });
      }
    }

    setRadius?.(undefined);
  };

  return (
    <>
      <div className="prov-maps-handler">
        <CoordinatesBar />
        <Col className="prov-maps-handler__bar">
          <Row className="title">Move by selected value</Row>
          <Row>
            <Autocomplete
              id="candidate"
              key="candidate"
              label=""
              options={arrsToSelectableObject(
                candidates,
                'FALocationNumber',
                'FALocationNumber',
              )}
              elementProps={{
                value: selectorValue,
                allowClear: true,
                onChange: onSelectorChange,
              }}
            />
          </Row>
        </Col>
        <Col className="prov-maps-handler__bar">
          <Row className="title">Set Radius, Miles</Row>
          <Row>
            <InputNumber
              id="radius"
              onChange={setRadius}
              value={Number(radius ?? 0)}
            />
            <PrimaryButton
              title="Clear"
              className="prov-maps-handler__button clear-radius-btn"
              onClick={() => setRadius?.(undefined)}
            />
          </Row>
        </Col>
      </div>
      <Details {...props} headerBar={false} animated={false} />
    </>
  );
};

export const MappingArea: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => (
  <MappingProvider>
    <PrivateMappingArea {...props} />
  </MappingProvider>
);
