import {
  IReceivedDirectMessage,
  ISelectorOption,
} from '@symfa-inc/providence-types';
import { findFromSelectorOptions, mainDateFormat } from '@core/utils/methods';
import { DashboardItem, DashboardProjectData } from '@models/interfaces';

export const directMessagesSearcher = (
  data: DashboardItem<IReceivedDirectMessage>[],
  usersList: ISelectorOption[],
  searchValue: string,
): DashboardItem<IReceivedDirectMessage>[] => {
  const regExp = new RegExp(searchValue, 'gi');

  return !searchValue
    ? data
    : data.filter(
        ({
          notificationData: { senderId, message, createdAt, areaKey },
          projectData: {
            projectBundleID,
            projectType,
            siteNumber,
            PACEProjectNumbers,
            oracleProjectNumbers,
          },
        }: DashboardItem<IReceivedDirectMessage>) =>
          [
            projectBundleID.toString(),
            siteNumber,
            message,
            areaKey,
            projectType,
            ...PACEProjectNumbers.map(
              ({ value }: DashboardProjectData['PACEProjectNumbers'][number]) =>
                value,
            ),
            ...oracleProjectNumbers.map(
              ({
                value,
              }: DashboardProjectData['oracleProjectNumbers'][number]) => value,
            ),
            mainDateFormat(createdAt),
            findFromSelectorOptions(senderId, usersList),
          ].some(
            (item: string | null | undefined) => item && regExp.test(item),
          ),
      );
};
