import { SiteOptions } from '@models/interfaces';

export const DETAILS_HEADER_OPTIONS: SiteOptions[] = [
  {
    key: 'FALocationNumber',
    label: 'FA Location Number',
  },
  {
    key: 'siteNumber',
    label: 'Site Number',
  },
];
