import PushpinImg from 'assets/icons/pushpin.png';
import { Candidate } from '@models/interfaces';
import { getDescriptionContent } from '../../../models';

const pushpinClicked = (
  event: Microsoft.Maps.IMouseEventArgs,
  infoBox: Microsoft.Maps.Infobox,
): void => {
  const target = event.target as Microsoft.Maps.Pushpin;
  const { metadata } = target;

  if (metadata) {
    infoBox.setOptions({
      location: target.getLocation(),
      title: target.getTitle(),
      description: getDescriptionContent(metadata),
      visible: true,
    });
  }
};

export const createPushpin = (
  data: Candidate[],
  infoBox: Microsoft.Maps.Infobox,
): Microsoft.Maps.Pushpin[] =>
  data.map(
    ({
      longitude,
      FALocationNumber,
      latitude,
      USID,
      siteNumber,
    }: Candidate) => {
      const pushpin = new Microsoft.Maps.Pushpin(
        new Microsoft.Maps.Location(latitude, longitude),
        {
          title: FALocationNumber.toString(),
          icon: PushpinImg,
          anchor: new Microsoft.Maps.Point(8, 8),
        },
      );

      pushpin.metadata = {
        siteNumber,
        USID,
      };

      Microsoft.Maps.Events.addHandler(
        pushpin,
        'click',
        (event: Microsoft.Maps.IMouseEventArgs) =>
          pushpinClicked(event, infoBox),
      );

      return pushpin;
    },
  );
