import { SelectValue } from 'antd/lib/select';

export const createCircle = (
  center: Microsoft.Maps.Location,
  radius: SelectValue,
  fillColor: string,
) =>
  new Microsoft.Maps.Polygon(
    Microsoft.Maps.SpatialMath.getRegularPolygon(
      center,
      Number(radius),
      36,
      Microsoft.Maps.SpatialMath.DistanceUnits.Miles,
    ),
    {
      fillColor,
    },
  );
