import React, { useCallback, useMemo } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { IScopingTowerNote, Undefinable } from '@symfa-inc/providence-types';
import { Button, Form, Input, Select, Space } from 'antd';
import { HttpService } from '@core/services';
import { ScopingHttpService } from '@core/services/http';
import type { ScopingTowerNoteUpdateBody } from '@shared/components';
import { Modal } from '@shared/modules';

import './styles.scss';

interface ScopingTowerNoteModalProps {
  isOpen: boolean;
  scopingId?: string;
  data: Undefinable<IScopingTowerNote>;
  onClose: VoidFunction;
}

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    span: 20,
    offset: 4,
  },
};

export const ScopingTowerNoteModal = (props: ScopingTowerNoteModalProps) => {
  const { isOpen, data, onClose, scopingId } = props;

  const [form] = Form.useForm();

  const onCancel = useCallback(() => {
    onClose();
    form.resetFields();
  }, [onClose, form]);

  const onOk = useCallback(async () => {
    if (scopingId) {
      await HttpService.getHttpRequests(
        ScopingHttpService,
      ).updateScopingTowerNote(scopingId, {
        ...form.getFieldsValue(),
        id: data?.id,
      } as ScopingTowerNoteUpdateBody);

      onClose();
    }
  }, [scopingId, onClose]);

  const initialValues = useMemo(() => {
    if (isOpen && data) {
      const { actions, finalConfig } = data;

      const serializedActions = actions.map(action => {
        const type = action.replace(/(ADD|REMOVE|MOVE).*/, '$1');
        const description = action.replace(/(ADD|REMOVE|MOVE)\s/, '');

        return { type, description };
      });

      return {
        actions: serializedActions,
        finalConfig: finalConfig?.replace(/FINAL\s/, ''),
      };
    }
  }, [data, isOpen]);

  return (
    <Modal visible={isOpen} onCancel={onCancel} className="modal" onOk={onOk}>
      <Form
        form={form}
        initialValues={initialValues}
        {...formItemLayoutWithOutLabel}
      >
        <Form.List name="actions">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? 'Actions' : ''}
                  required={false}
                  key={key}
                >
                  <Space key={key} align="center">
                    <Form.Item
                      {...restField}
                      name={[name, 'type']}
                      rules={[
                        { required: true, message: 'Missing first name' },
                      ]}
                    >
                      <Select style={{ width: 120 }}>
                        <Select.Option value="REMOVE">Remove</Select.Option>
                        <Select.Option value="MOVE">Move</Select.Option>
                        <Select.Option value="ADD">Add</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      rules={[{ required: true, message: 'Missing last name' }]}
                      normalize={value => value.toUpperCase()}
                    >
                      <Input
                        placeholder="Description"
                        style={{ width: '298px', height: '40px' }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add action
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item
          name="finalConfig"
          label="Final"
          normalize={value => value.toUpperCase()}
          {...formItemLayout}
        >
          <Input.TextArea rows={7} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
