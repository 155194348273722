import { StoredRequest } from '@core/utils/store/stored-request-decorator';
import { ApiMethods } from '@models/enums';
import {
  ExportsBrowse,
  ExportsBrowseFilterOptionsResponse,
  ExportsDownloadReportsParams,
  MainBrowseFilter,
  PaginatedResponse,
} from '@models/interfaces';
import { ExportsActions } from '@store/actions';
import { CommonHttpService } from './common-http.service';

export class ExportsHttpService extends CommonHttpService {
  @StoredRequest(ExportsActions.getExportsBrowseDataAction)
  getExportsBrowse(
    params: MainBrowseFilter,
  ): Promise<PaginatedResponse<ExportsBrowse>> {
    return this.http.callApi.get(`${this.apiUrl}/exports`, null, {
      params,
    });
  }

  @StoredRequest(ExportsActions.getExportsBrowseFilters)
  getExportsBrowseFiltersOptions(): Promise<ExportsBrowseFilterOptionsResponse> {
    return this.http.callApi.get(`${this.apiUrl}/exports/filter-options`);
  }

  dowloadReports(params: ExportsDownloadReportsParams): Promise<void> {
    return this.downloadFile(ApiMethods.Get, `${this.apiUrl}/exports/report`, {
      params,
    });
  }
}
