import { actionCreatorFactory } from '../../core/utils/factories';
import {
  CreateEquipmentManufacturerResponse,
  EquipmentFullInfo,
  EquipmentManufacturerData,
  EquipmentManufacturerResponse,
  EquipmentsBrowseResponse,
  EquipmentTabData,
  EquipmentTypeData,
  RemoveEquipmentManufacturerResponse,
} from '../../models/interfaces';

const actionCreator = actionCreatorFactory('EQUIPMENT');

export namespace EquipmentActions {
  // Equipment Management - Equipment Types
  export const getEquipmentTypes = actionCreator.createAsyncAction<
    EquipmentTypeData[]
  >('GET_EQUIPMENT_TYPES');
  export const addEquipmentType =
    actionCreator.createAsyncAction<EquipmentTypeData>('ADD_EQUIPMENT_TYPE');
  export const editEquipmentType =
    actionCreator.createAsyncAction<EquipmentTypeData>('EDIT_EQUIPMENT_TYPE');
  export const removeEquipmentType = actionCreator.createAsyncAction<string>(
    'REMOVE_EQUIPMENT_TYPE',
  );
  // Equipment Management - Equipment Manufacturers
  export const getEquipmentManufacturers = actionCreator.createAsyncAction<
    EquipmentManufacturerData[]
  >('GET_EQUIPMENT_MANUFACTURERS');
  export const addEquipmentManufacturer =
    actionCreator.createAsyncAction<CreateEquipmentManufacturerResponse>(
      'ADD_EQUIPMENT_MANUFACTURER',
    );
  export const editEquipmentManufacturer =
    actionCreator.createAsyncAction<EquipmentManufacturerResponse>(
      'EDIT_EQUIPMENT_MANUFACTURER',
    );
  export const removeEquipmentManufacturer =
    actionCreator.createAsyncAction<RemoveEquipmentManufacturerResponse>(
      'REMOVE_EQUIPMENT_MANUFACTURER',
    );
  // Equipment Management - Equipments
  export const getEquipments =
    actionCreator.createAsyncAction<EquipmentTabData[]>('GET_EQUIPMENTS');
  export const addEquipment =
    actionCreator.createAsyncAction<EquipmentTabData>('ADD_EQUIPMENT');
  export const editEquipment =
    actionCreator.createAsyncAction<EquipmentTabData>('EDIT_EQUIPMENT');
  export const removeEquipment =
    actionCreator.createAsyncAction<string>('REMOVE_EQUIPMENT');
  // Browse - Equipments
  export const getEquipmentsByFilter =
    actionCreator.createAsyncAction<EquipmentsBrowseResponse>(
      'GET_EQUIPMENTS_BY_FILTER',
    );
  export const getEquipmentsByTypes = actionCreator.createAsyncAction<
    EquipmentFullInfo[]
  >('GET_EQUIPMENTS_BY_TYPES');
  export const getEquipmentsByManufacturers = actionCreator.createAsyncAction<
    EquipmentFullInfo[]
  >('GET_EQUIPMENTS_BY_MANUFACTURERS');
}
