import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { InformationProps } from '@models/interfaces';
import { CustomTable } from '../custom-table';
import { Panel } from '../panel';
import { getEquipmentTotalsColumns } from './models/constants';

export const EquipmentTotals: FC<InformationProps> = ({
  className,
  data,
}: PropsWithChildren<InformationProps>) => (
  <Panel
    header="Equipment Totals"
    className={classNames('prov-equipment-totals', className)}
    collapsible
  >
    <CustomTable
      columns={getEquipmentTotalsColumns()}
      dataSource={data}
      pagination={false}
    />
  </Panel>
);
