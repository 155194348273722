import { Resolver } from '@wellyes/react-router-extended';
import { HttpService } from '@core/services';
import { ExportsHttpService } from '@core/services/http';
import { EXPORTS_BROWSE_DEF_FILTER_VALUE } from '../components/browse/models';

export class ExportsBrowseResolver implements Resolver {
  async resolve(): Promise<void> {
    await HttpService.getHttpRequests(ExportsHttpService).getExportsBrowse(
      EXPORTS_BROWSE_DEF_FILTER_VALUE,
    );

    await HttpService.getHttpRequests(
      ExportsHttpService,
    ).getExportsBrowseFiltersOptions();
  }
}
