import { ColumnsType } from 'antd/lib/table';
import { AdminCountyData } from '@models/interfaces';
import { Browse } from '@models/types';

export const PERMITTING_JURISDICTIONS_COLUMNS: ColumnsType<Browse> = [
  {
    title: 'Jurisdiction Name',
    key: 'jurisdictionName',
    dataIndex: 'jurisdictionName',
    sorter: true,
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    sorter: true,
    render: (state: AdminCountyData): string => state?.name,
  },
];
